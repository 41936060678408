import React, {useEffect, useMemo} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { FormControl, InputLabel, Grid, MenuItem } from '@mui/material';
import CustomSelect from '../../assets/GlobalStyles/CustomSelect';
import MutPositionSearch from '../Tree/MutPositionSearch';
import { setParameters, setMeasureScale } from '../../redux/actions/parametersActions';
import { resetGenotypeStatus } from '../../redux/actions/genotypeActions';
import { fetchMeasureScalesDomains } from '../../redux/actions/settingsActions';
import { fetchMutationsPositionsDictionary } from '../../redux/actions/genotypeActions';
import { isFetchNeeded } from '../../functions/functions';
import { bindActionCreators } from 'redux';


const useStyles =  makeStyles({
    formControl: {
        margin: '8px 0px'
    },
    input: {
        height: '35px',
        lineHeight: '35px',
        boxSizing: 'border-box'
    }
});

const GenotypeSelector = props => {
    const { lineage, mutgene, mutposition, genotypeFilterGenesList, genotypeDomainStatus,
        lineageStatus, mutationsPositionsDictStatus, 
        resetGenotypeStatus, fetchMeasureScalesDomains, setParameters, resetData, fetchMutationsPositionsDictionary } = props;
    const classes = useStyles();

    const _genotypeFilterGenesList = useMemo(() => {        
        return [ { label:'', val: null, order: 0 }, ...genotypeFilterGenesList]
    }, [genotypeFilterGenesList]);


    const handleMutChange = event => {
        const mutposition = null;
        setParameters({ mutgene: event.target.value, mutposition });
        resetGenotypeStatus();
        if (resetData) {
            resetData();
        }
    }
    useEffect(() => {
        if (lineageStatus !== 'loaded') return;
        if (isFetchNeeded(mutationsPositionsDictStatus))
            fetchMutationsPositionsDictionary({ lineage });
    }, [lineageStatus])

    useEffect(() => {
        if (mutgene && mutposition && isFetchNeeded(genotypeDomainStatus))
            fetchMeasureScalesDomains( {lineage, colorBy: 'genotype', mutgene, mutposition})
    }, [mutgene, mutposition])

    return <Grid container spacing={1}>
   <Grid item sm={12} md={6}>
        <FormControl className={classes.formControl} fullWidth>
            <InputLabel htmlFor="genotypeGene">
                Gene
            </InputLabel>
            <CustomSelect
                value={mutgene||''}
                onChange={handleMutChange}
                inputProps={{ name: `${mutgene}`, id: `${mutgene}` }}
            >
                {_genotypeFilterGenesList.map(g => (
                    <MenuItem key={g.val} value={g.val}>{g.label}</MenuItem>
                ))}
            </CustomSelect>
        </FormControl>
    </Grid>
     <Grid item sm={12} md={6}>
        <FormControl className={classes.formControl} fullWidth>
            <MutPositionSearch label="Position" context="genotype" resetData={resetData}/>
        </FormControl>
    </Grid>
</Grid>
}



GenotypeSelector.propTypes = {
    colorBy: PropTypes.string,
    mutposition: PropTypes.number,
    mutgene: PropTypes.string,
    resetGenotypeStatus: PropTypes.func,
    fetchMeasureScalesDomains: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => {
    return ({
        lineage: state.parameters.lineage,
        // intro: state.parameters.intro,
        colorBy: ownProps.mapSelector
            ? state.parameters.geoMapColorBy
            : state.parameters.colorBy,
        freqCategory: state.parameters.freqCategory,
        mutgene: state.parameters.mutgene,
        mutPositions: state.genotype.mutPositions,
        mutposition: +state.parameters.mutposition,
        genotypeFilterGenesList: state.genotype.genotypeFilterGenesList,
        lineageStatus: state.lineages.lineageStatus,
        mutationsPositionsDictStatus: state.genotype.mutationsPositionsDictStatus,
        genotypeDomainStatus: state.metadata.measureDomainStatuses?.genotype || 'none'
    });
};

const mapDispatchToProps = dispatch => bindActionCreators({
    resetGenotypeStatus,
    setParameters,
    fetchMeasureScalesDomains,
    fetchMutationsPositionsDictionary
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(GenotypeSelector);
