import { isFirefox } from '../../../functions/checkBrowser';

export const styles = theme => ({
    root: {
        maxHeight: 'calc(100% - 44px)',
        overflowY: isFirefox ? 'auto' : 'overlay',
        display: 'flex',
        flexWrap: 'wrap',
        textAlign: 'left',
        backgroundColor: '#fff',
        padding: '0px 15px 10px 15px',
    },
    rootWithAlerts: {
        overflowY: isFirefox ? 'auto' : 'overlay',
        maxHeight: 'calc(100% - 20px)',
        // position: 'absolute',
        display: 'flex',
        flexWrap: 'wrap',
        textAlign: 'left',
        padding: '10px 15px',
        backgroundColor: '#fff',
        margin: '0px 0px 24px',
    },
    rootWithoutAlerts: {
        overflowY: isFirefox ? 'auto' : 'overlay',
        maxHeight: 'calc(100%)',
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        textAlign: 'left',
        padding: '0px',
        backgroundColor: '#fff',
        margin: '0px 0px 24px',
    },
    form: {
        padding: '10px 15px',
    },
    colFormControl: {
        minWidth: 120,
        width: '40%',
        margin: '8px 4px 8px 0px',
        // padding: '1px'
    },
    lastColFormControl: {
        minWidth: 120,
        width: '40%',
        margin: '8px 0px 8px 2px',
        // padding: '1px'
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    },
    icon: {
        padding: 0,
        marginLeft: '3px',
    },
    formControl: {
        width: '100%',
        marginTop: '10px',
    },
    button: {
        backgroundColor: '#e0e0e0',
        color: '#000000',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#e0e0e0',
        }
    },
    textField: {
        width: '100%',
    },
    expandButton: {
        marginTop: '7px'
    },
    hideButton: {
        float: 'right',
        padding: '0px'
    },
    buttonWrapper: {
        width: '100%',
        height: '30px'
    },
});
