import { makeStyles } from '@mui/styles';
import React from 'react';

export const styles = makeStyles(() =>({
    dividerUp: {
        margin: '24px 24px 24px 24px'
    },
    dividerDown: {
        margin: '24px 24px 10px 24px'
    },
    tittle: {
        marginLeft: '8px',
        fontWeight: 400,
        fontSize: '18px',
        color: 'black'
    },
    menuItem: {
        marginLeft: '8px',
        color: 'black',
        '&:hover': {
            color: 'black',
        },
    },
    icon: {
        '&.MuiListItemIcon-root': {
            minWidth: '25px'
        }
    },
    link: {
        textDecoration: 'none',
    },
    button: {
        textTransform: 'none',
        backgroundColor: '#6F6CFF',
        float: 'right',
        margin: '10px 24px 24px 0px',
        color: 'white',
        '&:hover': {
            backgroundColor: '#6F6CFF',
            color: 'white'
        },
    }
}));

export const menuStyles = {
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    width: '300px',
    mt: 1.5,
    '& .MuiAvatar-root': {
        width: 32,
        height: 32,
        ml: -0.5,
        mr: 1,
    },

};

export const UserIcon = () => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.6 18.6C4 17.5 5.3 16.6 6.4 16.6H15.6C16.7 16.6 18 17.5 18.4 18.6L19.7 22H22L20.4 17.8C19.7 15.9 17.6 14.5 15.6 14.5H6.4C4.5 14.5 2.4 16 1.6 17.8L0 22H2.2L3.6 18.6Z" fill="#DCE1E6"/>
            <path d="M11 2.1C8.9 2.1 7.1 3.8 7.1 5.9C7.1 8 8.9 9.7 11 9.7C13.1 9.7 14.9 8 14.9 5.9C14.9 3.8 13.1 2.1 11 2.1ZM11 11.8C7.7 11.8 5 9.2 5 5.9C5 2.6 7.7 0 11 0C14.3 0 17 2.6 17 5.9C17 9.2 14.3 11.8 11 11.8Z" fill="#DCE1E6"/>
        </svg>
    );
};
