import React, { useEffect, useRef, useCallback, useState, useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Draggable from 'react-draggable';
import { makeStyles } from '@mui/styles';
//import { getTreeNodeAttrs, getSelectedMutationClasses } from '../../../../selectors/treeDataSelector';
import { styles } from './styles';
//import { select } from 'd3-selection';

import { treeD3 } from '../../d3/TreeD3';
import { useEventListener } from 'usehooks-ts';
import { selectNodeData } from '../../../../redux/actions/nodeActions';
import { setLabelMovement } from '../../../../redux/actions/renderActions';

// import { getScaledValue } from '../../../../functions/scales';
// import { getSymbol } from '../../d3/symbolSigns';
import { getMutClassId, getSymbolPosition } from './MutationsClassesLabelSymbols';
import { merge } from 'lodash';
import { RENDER_STATUS } from '../../../../config/consts';

const useStyles = makeStyles(styles);


export const getX = id => treeD3.x(+id);
export const getXBranch = id => treeD3.xBranch(+id)
export const getTransformBranchNodes = id => treeD3.transformBranchNodes(+id);
export const getY = id => treeD3.y(+id);
export const getYOrder = val => treeD3.yOrder(val);

//export const r = 3;
export const rMargin = 3;

const defaultXYPositions = (id, index, labelWidth, labelHeight, startPos, symbolsNumber, number) => {
    const symbolPosition = getSymbolPosition(id, startPos, symbolsNumber, number);
    const x = symbolPosition.xAnchor;
    const y = symbolPosition.yAnchor;
    return { x: x - (labelWidth || 0) / 2, y: y + (labelHeight / 2) + rMargin + ((index + 1) * (labelHeight || 0)) };
}

const DraggableLabel = props => {
    const { draggable, handleDrag, xMod, yMod, children } = props;
    return draggable 
        ? (<Draggable onDrag={handleDrag} position={{ x: xMod, y: yMod }}>{children}</Draggable>)
        : (<>{children}</>);
}

const MutationsClassesLabelText = (props) => {
    const w = 8;
    const h = 4;
    const _textRef = useRef();
    const _element = useRef();

    //console.log('MutationLabelText changed', props)
    const { id, classNamePrefix, mutClass, mutations, xAnchor, yAnchor, /*xPos,*/ x,/* yPos,*/ y, labelHeight, labelWidth, minOrder, maxOrder, renderStatus,
    setDimensions} = props;

    const classes = useStyles();
   
    useEffect(() => {
        const bBox = _textRef.current.getBBox();
        const labelWidth = bBox.width + w;
        const labelHeight = bBox.height + h;
        // const x = getXBranch(id);
        // const y = getY(id);
        //textHeight.current = labelHeight;
        if (id === 9724) console.log('[MutationsClassesLabelText].useEffect, setDimensions')
        setDimensions({ 
            labelWidth,
            labelHeight,
            minY: getYOrder(minOrder),
            maxY: getYOrder(maxOrder)
        }, mutClass);

    }, []);
    
    const xMove = (x || xAnchor) - xAnchor;
    const yMove = (y || yAnchor) - yAnchor;

   // console.log(id,-labelHeight + yMove);

    return (
        <g id={`${classNamePrefix}_${id}`} className="label" ref={_element} transform={`translate(${xMove}, ${-labelHeight + yMove})`} style={{ opacity: renderStatus === RENDER_STATUS.DONE ? 1 : 0 }}>
            <rect className={classes[`${classNamePrefix}Border`]}
                style={{
                    fill: '#ffffff',
                    stroke: '#4F4F4F',
                    opacity: 0.5,
                    strokeWidth: 1,
                    cursor: 'pointer'
                }}
                rx={4} ry={4} height={labelHeight} width={labelWidth}></rect>
            <text className={classes[`${classNamePrefix}Text`]}
                style={{
                    fontFamily: 'Source Sans Pro',
                    fontSize: '8px',
                    cursor: 'pointer',
                }}
                transform={`translate(${w / 2}, ${h / 2 + labelHeight / 2})`} ref={_textRef}>
                <tspan textAnchor="start">{mutations}</tspan>
            </text>
        </g>
    );
};



const MutationsClassesLabel = (props) => {
    const { id, classNamePrefix, mutClass, index, xMod, yMod, strainTreeWidth, strainTreeHeight, setLabelMovement, //labelWidth, labelHeight,
        xAnchor, yAnchor, initialized, startPos, symbolsNumber, number, exportMode, editMode ,
        setPosition, setDimensions} = props;
    //console.log(props);
    //if (id == 79781) console.log(id, { strainTreeWidth, strainTreeHeight, initialized});
    const [ labelWidth, setLabelWidth ] = useState(0);
    const [ labelHeight, setLabelHeight ] = useState(0) 
    const _element = useRef();

    const handleDrag = (event, data) => {
        const { x, y } = data;

        setLabelMovement({ id, type: classNamePrefix, subType: mutClass, xMod: x, yMod: y });
    };

    const clearSelectedNode = () => {
        if (!treeD3.selectedNode) props.selectNodeData();
    };
    const selectedNode = () => {
        if (!treeD3.selectedNode) props.selectNodeData({ nodeId: +id });
    };

    useEventListener('mouseover', selectedNode, _element);
    useEventListener('mouseleave', clearSelectedNode, _element);

    const setDefaultPosition = () => {
        //const x = getXBranch(id);
        //const x = xAnchor;
        //const y = yAnchor;
        const defaultPos = defaultXYPositions(id, index, labelWidth, labelHeight, startPos, symbolsNumber, number);
        const xPos = defaultPos.x;
        const yPos = defaultPos.y;
        //const defaultPos = defaultXYPositions(id, index, labelWidth, labelHeight, xAnchor, yAnchor)
        //console.log('setDeafultPosition', id, classNamePrefix, mutClass, 'x = ', xPos, 'y = ', yPos, 'index =', index)

        setPosition({ x: xPos, y: yPos}, mutClass);
    }



    useEffect(() => {
        //if (id == 9724) console.log(id, '[MutationsClassesLabel].useEffect labelWidth', labelWidth, initialized);
        if (!labelWidth) return;
        if (!initialized) {
            setDefaultPosition()
        }
    }, [strainTreeWidth, strainTreeHeight, labelWidth, initialized])

    const handleSetLabelDimensions = useCallback((dim) => {
        //if (id == 9724) console.log('1.1 [MutationsClassesLabel].handleSetLabelDimensions', getMutClassId(id, mutClass), dim);
        setLabelHeight(dim.labelHeight);
        setLabelWidth(dim.labelWidth);
        setDimensions(dim, mutClass);
     },[])

    return (

        <g ref={_element} transform={`translate(${xAnchor}, ${yAnchor})`}>
    
           <DraggableLabel draggable={!exportMode || editMode} handleDrag={handleDrag} xMod={xMod} yMod={yMod}>
                <g>
                    <MutationsClassesLabelText {...props} setDimensions={handleSetLabelDimensions}/>
                </g>
           </DraggableLabel>
        
        </g>

    );
};

const mapStateToPropsLabel = (state, ownProps) => {
    //const treeAttrs = getTreeNodeAttrs(state);
    const { id, mutClass, classNamePrefix } = ownProps;
    const labelPos = state.render.labels?.[classNamePrefix]?.[`${id}_${mutClass}`] || {};
    const renderStatus = state.render.viewToRender?.components?.mutationsClasses || RENDER_STATUS.NONE;
  
    //const xPos = getXBranch(ownProps.id);
    //const yPos = getY(ownProps.id);

    const { x, y, xMod, yMod, labelWidth, labelHeight, xAnchor, yAnchor, initialized } = labelPos;
    //const { index } = ownProps;

    return {
        strainTreeWidth: state.render.strainTreeWidth,
        strainTreeHeight: state.render.strainTreeHeight,
        //showCladeLabels: state.parameters.showCladeLabels,
        x,
        y,
        xMod: xMod || 0,
        yMod: yMod || 0,
        labelWidth: labelWidth || 0,
        labelHeight: labelHeight || 0,
        xAnchor: xAnchor || 0,
        yAnchor: yAnchor || 0,
        initialized,
        exportMode: state.parameters.exportMode,
        editMode: state.parameters.editMode,
        renderStatus
    };

};

const mapDispatchToPropsLabel = (dispatch) =>
    bindActionCreators(
        {
            selectNodeData,
            setLabelMovement
        },
        dispatch,
    );

const _MutationClassesLabel = connect(mapStateToPropsLabel, mapDispatchToPropsLabel)(MutationsClassesLabel);


const MutationsClassesLabels = (props) => {
    const { id, muts, onElementRendered, rerenderLabels, ...otherProps } = props;
    

    const positions = useRef({});
    const dimensions = useRef({});
    const [positionsCnt, setPositionsCnt] = useState(0);
    const [dimensionsCnt, setDimensionCnt] = useState(0);

    const mutsCnt = useMemo(() => { return muts.filter(({ showLabel }) => showLabel).length; }, [muts]);
    const positionsSet = positionsCnt === mutsCnt;
    const dimensionsSet = dimensionsCnt === mutsCnt;

    useEffect(() => {      
        if (rerenderLabels && positionsSet && dimensionsSet) {
            const labels = merge(dimensions.current, positions.current);
            onElementRendered(labels);
        }
    }, [onElementRendered, positionsSet, dimensionsSet]);
    

    const handleSetPositions = useCallback((pos, subType) => {
        if (!rerenderLabels) return;
        positions.current[getMutClassId(id, subType)] = pos;
        setPositionsCnt((positionsCnt) => positionsCnt+1);
     }, []);

     const handleSetDimensions = useCallback((dim, subType) => {
        if (!rerenderLabels) return;
         dimensions.current[getMutClassId(id, subType)] = dim;
         setDimensionCnt((dimensionsCnt) => dimensionsCnt+1);
     },[])

    return (
        <g>
            {muts.filter(({ showLabel }) => showLabel)
                .map(({ mutClass, mutations, startPos, number }, index) => (
                    <_MutationClassesLabel
                        key={`text_${mutClass}_${id}`}
                        id={id}
                        mutClass={mutClass}
                        mutations={mutations}
                        index={index}
                        startPos={startPos}
                        number={number}
                        setPosition={handleSetPositions}
                        setDimensions={handleSetDimensions}
                        {...otherProps}
                    />
                ))}

        </g>
    );
};

export default MutationsClassesLabels;
