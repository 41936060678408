import { select } from 'd3-selection';

export const handleMouseEnter = (_id, chartLayout) => {
    const id = _id.replace(/\./g, '\\.');
    if (chartLayout === 'stacked') {
        select(`#freqStacked_${id}`).style('opacity', 0.8);
        select(`#predStacked_${id}`).style('opacity', 0.6);
    } else {
        select(`#freq_${id}`).style('stroke-width', 6);
        select(`#pred_${id}`).style('stroke-width', 6);
    }
};

export const handleMouseLeave = (_id, chartLayout) => {
    const id = _id.replace(/\./g, '\\.');
    if (chartLayout === 'stacked') {
        select(`#freqStacked_${id}`).style('opacity', 0.9);
        select(`#predStacked_${id}`).style('opacity', 0.7);
    } else {
        select(`#freq_${id}`).style('stroke-width', 3);
        select(`#pred_${id}`).style('stroke-width', 3);
    }
};

export const splitDataToChunks = (data, collSize) => {
    Object.defineProperty(Array.prototype, 'chunk', {
        writable: false,
        configurable: true,
        value: function(chunkSize) {
          let R = [];
          for (let i = 0; i < this.length; i += chunkSize)
            R.push(this.slice(i, i + chunkSize));
          return R;
        }
    });

    const chunkSize = Math.ceil(data.length / collSize);
    return data.chunk(chunkSize);
};
