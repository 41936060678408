import config from '../config/envConfig';
const auth = {
    isAuthenticated() {
        if (typeof window === 'undefined') return false;
        if (sessionStorage.getItem(`jwt${config.frontendPrefix}`)) {
            return true;
        }
        return false;
    },
    authenticationOn() {
        return true
    },
    authenticate(jwt, cb) {
        // console.log(`[authenticate]: ${JSON.stringify(jwt)}`);
        if (typeof window !== 'undefined') sessionStorage.setItem(`jwt${config.frontendPrefix}`, JSON.stringify(jwt));
        cb();
        // window.location.reload(false);

    },
    signout(cb) {
        if (typeof window !== 'undefined') sessionStorage.removeItem(`jwt${config.frontendPrefix}`);
        if (cb) cb();
        // optional
        // signout().then((data) => {
        // document.cookie = 't=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        // });
    },
};

export default auth;
