import {
    FETCH_FREQUENCIES_REQUEST,
    FETCH_FREQUENCIES_SUCCESS,
    FETCH_FREQUENCIES_ERROR,
    RESET_FREQUENCIES,
    RESET_SESSION,
    SIGNOUT_REQUEST,
} from '../actions/actionTypes';

let frequenciesInitialState = { };
export const setFrequenciesInitialState = (state) => {
    frequenciesInitialState = state;
};

const frequenciesData = (state = frequenciesInitialState, action) => {
    switch (action.type) {
        case RESET_SESSION:
        case SIGNOUT_REQUEST: {
            return frequenciesInitialState;
        }

        case RESET_FREQUENCIES: {
            return frequenciesInitialState;
        }

        case FETCH_FREQUENCIES_REQUEST: {
            const { subsetId, regions } = action.payload;
            const frequencies = { ...state.frequencies };
            const frequenciesStatus = { ...state.frequenciesStatus };
            const seqCaseCounts = { ...state.seqCaseCounts };
            const selectedBins = { ...state.selectedBins };
            const _regions = regions || { [subsetId]: true };
            //console.log(subsetId, _regions);
            Object.keys(_regions).forEach(regionId => {
                frequenciesStatus[regionId] = 'loading';
                frequencies[regionId] = [];
                seqCaseCounts[regionId] = [];
                selectedBins[regionId] = {};
            })
            return {
                ...state,
                frequenciesStatus,
                selectedBins,
                frequencies,
                seqCaseCounts
            };
        }
        case FETCH_FREQUENCIES_SUCCESS: {
            const { frequencies, seqCaseCounts, selectedBins, dataPoints, parameters } = action.payload;
            const { freqCategory } = parameters;
            const _frequencies = { ...state.frequencies, ...frequencies };
            const _seqCaseCounts = { ...state.seqCaseCounts, ...seqCaseCounts };
            const _selectedBins = { ...state.selectedBins, ...selectedBins };
            const frequenciesStatus = { ...state.frequenciesStatus };

            Object.keys(frequencies).forEach(regionId => {
                frequenciesStatus[regionId] = 'loaded';
            });
            //console.log(seqCaseCounts);
            return {
                ...state,
                frequencies: _frequencies,
                seqCaseCounts: _seqCaseCounts,
                selectedBins: _selectedBins,
                freqCategory,
                frequenciesStatus,
                dataPoints
            };
        }
        case FETCH_FREQUENCIES_ERROR: {
            const { regionId, regions } = action.payload;
            const frequencies = { ...state.frequencies };
            const seqCaseCounts = { ...state.seqCaseCounts };
            const frequenciesStatus = { ...state.frequenciesStatus };
            const _regions = regions || { [regionId]: true };
            Object.keys(_regions).forEach(regionId => {
                frequenciesStatus[regionId] = 'error';
                frequencies[regionId] = [];
                seqCaseCounts[regionId] = [];
            })
            return {
                ...state,
                frequencies,
                seqCaseCounts,
                frequenciesStatus
            };
        }

        default:
            return state || frequenciesInitialState;
    }
};

export default frequenciesData;
