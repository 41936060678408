import { createSelector } from 'reselect';
import { daysToDate, isNull } from '../../functions/functions';
import { getAntigenicData, getAntigenicDataType, antigenicTiterTypeSelector } from './antigenicDataSelector';
import { getTreeNodeAttrs } from './treeDataSelector';
import { prepareMutationsInfo } from '../../components/Tree/d3/displayValue';

const getNodeType = ({ nodeData }) => nodeData.nodeType;
const getNodeId = ({ nodeData }) => nodeData.nodeId;
// const getModel = ({ modelData }) => modelData.model;
const getCladeType = ({ parameters }) => parameters.cladeType;
const getTreeDataStatus = ({ treeData }) => treeData.treeDataStatus;
const getNodeData = ({ nodeData }) => nodeData;
const getCladeData = ({ cladeData }) => cladeData;

// const getModelAttrs = createSelector([getNodeId, getModel], (nodeId, model) =>
//     model
//         ? Object.keys(model).reduce((attrs, k) => {
//               attrs[k] = model[k][nodeId];
//               return attrs;
//           }, {})
//         : undefined,
// );

// const getModelStatus = ({ modelData }) => modelData.modelStatus;

// const getNodeFreq = ({ treeData, nodeData }) => (nodeData.nodeId ? treeData.treeFreqs[nodeData.nodeId] : undefined);

// const getTreeAttrs = ({ treeData }) => treeData.treeAttrs;

const getNodeTreeAttrs = createSelector([getNodeId, getTreeNodeAttrs], (nodeId, treeAttrs) => {
    return treeAttrs && treeAttrs[nodeId];
});

//Are mutations present in input data
// const mutationsInTreeAttrs = createSelector(getTreeAttrsProperties, properties => properties.hasOwnProperty('mutations')); //(state) => Object.values(state.treeData.treeAttrs).findIndex((val) => val.mutations) > 0;

// const getCustomTreeAttrs = ({ customTreeData }) => customTreeData.customTreeAttrs;
// const getNodeCustomCustomTreeAttrs = createSelector([getNodeId, getCustomTreeAttrs], (nodeId, customTreeAttrs) =>
//     customTreeAttrs
//         ? Object.keys(customTreeAttrs).reduce((_customNodeAttrs, k) => {
//               if (customTreeAttrs[k][nodeId] !== undefined) _customNodeAttrs[k] = customTreeAttrs[k][nodeId];
//               return _customNodeAttrs;
//           }, {})
//         : undefined,
// );

// const getNodeTCellAntigenicityScore = createSelector(
//     [getNodeId, getTcellAntigenicityScores],
//     (nodeId, tcellAntigenicityScores) => (tcellAntigenicityScores ? tcellAntigenicityScores[nodeId] : undefined),
// );

const getRootNodeAdvance = ({ treeData, modelData }) => {
    const { rootNodeId, treeDataStatus } = treeData;
    const { model, modelStatus } = modelData;
    return treeDataStatus === 'loaded' &&
        modelStatus === 'loaded' &&
        rootNodeId &&
        model[rootNodeId] &&
        model[rootNodeId].advance
        ? model[rootNodeId].advance
        : 0;
};

const getCladeByType = (cladeId, cladeType, clades) => {
    let clade = clades[cladeId];
    if (cladeType !== 'clade') {
        clade = clades[cladeId]?.cladeMapping?.[cladeType];
    }
    return clade;
};

const getCladeIdByType = (id, cladeType, clades) => getCladeByType(id, cladeType, clades)?.alpha;

const getNodeClade = createSelector([getTreeDataStatus, getTreeNodeAttrs, getNodeData, getCladeData, getCladeType], (treeDataStatus, treeAttrs, nodeData, cladeData, cladeType) => {
    try {
        const { clades, cladesStatus } = cladeData;
        const { nodeId } = nodeData;

        if (treeDataStatus !== 'loaded' || cladesStatus !== 'loaded' || !treeAttrs || !clades || isNull(nodeId))
            return undefined;
        const clade = treeAttrs[nodeId]?.clade;
        const id = clade ? clades[clade].id : null;

        if (!id)
            return null;

        const cladeId = getCladeIdByType(id, cladeType, clades);
        const res = clades[cladeId]

        return res;

    } catch (e) {
        console.log(e);
        return null;
    }
});


const getNodeSigAlphaCladeId = ({ treeData, nodeData, cladeData }) => {
    const { treeAttrs, treeDataStatus } = treeData;
    const { clades, cladesStatus } = cladeData;
    const { nodeId } = nodeData;
    if (treeDataStatus !== 'loaded' || cladesStatus !== 'loaded' || !treeAttrs || !clades || isNull(nodeId))
        return undefined;
    //return treeAttrs[nodeId].cladeAlpha || treeAttrs[nodeId].cladeSigAlpha;


    const nodeAlphaClade = treeAttrs && treeAttrs[nodeId] ? treeAttrs[nodeId].cladeSigAlpha : null;
    const nodeAlpha = nodeAlphaClade && clades[nodeAlphaClade] ? clades[nodeAlphaClade].antigenicAlpha : null;
    //console.log('[getNodeSigAlphaCladeId] nodeAlpha = ',nodeAlpha);
    //const retValue = emptyObject(nodeAlpha) ? null : antigenicModel?.[nodeAlpha];
    //console.log('[getNodeSigAlphaCladeId] retValue = ',retValue);
    return nodeAlpha;
};

const getNodeAntigenic = createSelector(
    [getNodeId, getNodeSigAlphaCladeId, getAntigenicData, getAntigenicDataType, antigenicTiterTypeSelector],
    (nodeId, sigAlphaCladeId, antigenicData, antigenicDataType, antigenicTiterType) => {
        const strainCladeId =
            antigenicDataType === 'epitope_clades' || antigenicDataType === 'raw_strain' ? nodeId : sigAlphaCladeId;
        const paramName = `antigenic_titer_${antigenicTiterType}`;
        const res =
            antigenicData && strainCladeId
                ? { [paramName]: antigenicData[strainCladeId], antigenic: antigenicData[strainCladeId] }
                : undefined;
        return res;
    },
);


const nodeTypeAttrs = {
    mutations: { id: true, mutations: true, NSMutations: true, SMutations: true, ALLMutations: true },
};
const nodeTypeAttrFilter = (nodeType, name) => !nodeTypeAttrs[nodeType] || nodeTypeAttrs[nodeType][name];

// const getSelectedNodeData = ({ nodeData }) => nodeData;
// export const getNodeMutations = createSelector(getSelectedNodeData, (mutations) => prepareMutationsInfo(mutations));

const getNodeMutations = ({ nodeData }) => nodeData.nodeData;

const getNodeDataById = createSelector(
    [
        getNodeId,
        getNodeType,
        getNodeTreeAttrs,
        getNodeClade,
        getNodeAntigenic,
        getRootNodeAdvance,
        getNodeMutations,
    ],
    (
        nodeId,
        nodeType,
        nodeTreeAttrs,
        clade,
        antigenic,
        rootNodeAdvance,
        //tcellAntigenicity,
        nodeMutations,
    ) => {
        try {
            const attrData = (attrs, name, valueGetter, allowNull) => {
                if (!allowNull && !nodeTypeAttrFilter(nodeType, name)) return null;
                const _valueGetter = valueGetter || ((val) => val);
                return _valueGetter(attrs[name]);
            };

            if (!nodeTreeAttrs) return {};
            const mutations = nodeMutations.length > 0 ? nodeMutations : attrData(nodeTreeAttrs, 'mutations');
            const { nucMut, aminoMut } = prepareMutationsInfo(mutations);

            return {
                id: nodeId,
                ...nodeTreeAttrs,
                name: attrData(nodeTreeAttrs, 'name', (v) => (v ? v.split('_')[0] : null)),
                EPI: attrData(nodeTreeAttrs, 'name', (v) => (v ? v.split('_').slice(1).join('_') : null)),
                date: attrData(nodeTreeAttrs, 'time', (v) => (v ? daysToDate(v).toLocaleDateString() : null)),
                advance: attrData(nodeTreeAttrs, 'advance', (v) => (v || 0) - rootNodeAdvance, true), //nodeTreeAttrs ? (nodeTreeAttrs.advance || 0) - rootNodeAdvance : null,
                nucMut: nucMut && nucMut.length > 0 ? nucMut : null,
                aminoMut: aminoMut && aminoMut.length > 0 ? aminoMut : null,
                frequency: nodeTreeAttrs.frequency || 0,
                clade: clade ? clade.id : nodeTreeAttrs.calde,
                cladeLabel: clade ? `${clade.label}` : null,
                ...antigenic,
                //tcellAntigenicity
            };
        }
        catch (e) {
            console.log(e);
            return {};
        }
    },
);


export { getNodeDataById };
