import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { makeStyles } from '@mui/styles';
import { FormControl, InputLabel, MenuItem, Tooltip  } from '@mui/material';
import { setParameters, setMeasureScale } from '../../redux/actions/parametersActions';
import CustomSelect from '../../assets/GlobalStyles/CustomSelect';
import { getComplexDomainParameterValues, getMetadataMeasuresWithScales } from '../../redux/selectors/rangeDataSelector';
import { numFormat } from '../../functions/formats';
import { daysToDate, getMeasureScaleParamName } from '../../functions/functions';
import { discreteMeasureScalesDomainsSelector } from '../../redux/selectors/metadataSelector';
import { useMemo } from 'react';

const styles = makeStyles(() =>({
    formControl: {
        minWidth: 120,
        margin: '8px 0px 8px 0px',
    },
    input: {
        height: '35px',
        lineHeight: '35px',
        boxSizing: 'border-box'
    },
    tooltip: {
        margin: 0,
        padding: 0
    },
    dot: {
        height: '10px',
        width: '10px',
        borderRadius: '50%',
        display: 'inline-block',
        marginRight: '5px'
    }
}));

const ColorScaleSelector = (props) => {
    const {measureDomains, measureName, colorScale, scales, params,
        setMeasureScale, defaultScaleName, discrete, isTimeScale, paramValue, paramsScaleName } = props;
    const classes = styles();

    const scalesOptions = useMemo(() => { 
        return Object.keys(measureDomains).filter(k => !paramValue || k.match(`^${measureName}\.${paramValue}`)); 
    }, [measureDomains, measureName, paramValue]);

    const scalesOptionsLabels = scalesOptions.map((scaleName) => scaleName.split('.').at(-1));
    const scalesLength = scalesOptions.length;

    const formatLabel = (value, precision) => {
        if (isTimeScale)
            return daysToDate(value).toLocaleDateString();

        return numFormat(value, precision);
    }


    useEffect(() => {
        !discrete && defaultScaleName && paramsScaleName !== defaultScaleName && setMeasureScale( { measure: measureName, scaleName: defaultScaleName, parameters: params })
        //setParameters( {colorScale:  measureDomains && defaultScaleName } );
    }, [measureName]);

    useEffect(() => {
       
        if (!discrete && paramsScaleName !== defaultScaleName) {
            setMeasureScale( { measure: measureName, scaleName: defaultScaleName, parameters: params })
        }
    }, [defaultScaleName]);


    const handleChange = (event) => {
        const colorScale = event.target.value;
        setMeasureScale( { measure: measureName, scaleName: colorScale, parameters: params });
        
    }
    //console.log('measureName = ', measureName);

    //console.log('scalesOptions = ',measureDomains, scalesOptions, defaultScaleName);
    return (
        <>
        {
            scalesLength > 0 && scalesOptions.includes(defaultScaleName) && !discrete &&
            <FormControl className={classes.formControl} fullWidth>
                <InputLabel htmlFor="scale">
                    Scale
                </InputLabel>

                <CustomSelect
                    disabled={scalesOptions.length < 2}
                    value={defaultScaleName}
                    onChange={handleChange}
                    inputProps={{
                        name: 'scale',
                        id: 'scale',
                    }}
                >
                    {scalesOptions.map((scale, index) => {
                        const discrete = scales[scale].discrete;
                        const domain = measureDomains[scale];
                        //console.log(discrete, domain)
                        const colors = scales[scale].range;
                        let gradientStyle = 'linear-gradient(0.25turn, ';
                        if (!discrete) {
                            colors.forEach((color, index) => {
                                gradientStyle += index === colors.length - 1 ? `${color})` :`${color}, `
                            });
                        }
                        const text = <div style={{whiteSpace: 'pre-line'}}>
                            { discrete ?
                                <div>
                                    <div key={`tooltipItem-first`}>
                                        <span style={{ backgroundColor: colors[0]}} className={classes.dot}></span>
                                        -&infin; &le; {formatLabel(domain[0], 3)}
                                    </div>
                                    {

                                    domain.map((el, index) => {
                                        return index !== domain.length - 1 ? (
                                            <div key={`tooltipItem-${index}`}>

                                                <span style={{ backgroundColor: colors[index + 1]}} className={classes.dot}></span>
                                                {el} &le; {formatLabel(domain[index + 1], 3)}
                                            </div>)
                                            : (
                                            <div key={`tooltipItem-${index}`}>
                                                <span style={{ backgroundColor: colors[index + 1]}} className={classes.dot}></span>
                                                {el} &le; &infin;
                                            </div>)
                                    })
                                }</div>
                                :
                                <div key={`tooltipItem-${index}`}>
                                    <div style={{ height: '15px', width: '120px', background: gradientStyle }} />
                                    <div style={{ display: 'flex'}}>
                                        <div>{formatLabel(domain[0],3) }</div>
                                        <div style={{ marginLeft: 'auto'}}>{formatLabel(domain[domain.length - 1], 3)}</div>
                                    </div>
                                </div>
                            }
                        </div>;

                        return (
                            <Tooltip key={`tooltipItem-${index}`} followCursor value={scale} placement="bottom-start" title={text} className={classes.tooltip} >
                                <MenuItem
                                    key={`tooltipItem-${index}`}
                                    className={classes.tooltip}
                                >
                                    {scalesOptionsLabels[index]}
                                </MenuItem>
                           </Tooltip>
                        )}
                    )}
                </CustomSelect>
            </FormControl>
        }
        </>
    );
}

const mapStateToProps = (state, ownProps) => {

    const { colorBy, exportMode } = state.parameters
    const measureName = ownProps.colorBy || colorBy;
    const onlyDiscrete = ownProps.onlyDiscrete || false;
  
    const measureScalesDomains = onlyDiscrete ? discreteMeasureScalesDomainsSelector(state) : state.metadata.measureScalesDomains;
    const scales = state.metadata.scales;



    const measures = getMetadataMeasuresWithScales(state); //state.metadata.measures;

    //const colorScale = getColorScaleForColorBy(state); // colorScales[colorBy] || measuresWithScales[colorBy].scale.scaleName;
    const params = getComplexDomainParameterValues(state);

    const selectedMeasure = measures[measureName] || {};
    const paramName = getMeasureScaleParamName(state.metadata.measures[measureName]); 
    const paramValue = paramName ? params[paramName] : undefined;

    const measureDomains = measureScalesDomains[measureName] || {};

    
    const defaultScaleName = measureDomains[selectedMeasure.scale.scaleName] ? selectedMeasure.scale.scaleName : Object.keys(measureDomains)[0];
 
//   console.log(selectedMeasure.scale.scaleName, Object.keys(measureDomains)[0], measureScalesDomains[measureName]);
    const discrete = selectedMeasure.discrete || false;
    const isTimeScale = selectedMeasure.time || false;
    const paramsScaleName = state.parameters.colorScales[measureName];

    return {
        measureName,
        //colorScale,
        scales,
        measureScalesDomains,
        measureDomains,
        params,
        defaultScaleName,
        discrete,
        isTimeScale,
        paramValue,
        exportMode,
        paramsScaleName
    }
};

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        setParameters,
        setMeasureScale,
    },dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(ColorScaleSelector);
