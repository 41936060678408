import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Card, CardContent, Dialog, DialogActions,
    DialogContent, DialogContentText, DialogTitle,
    IconButton, InputAdornment, Button, Typography
} from '@mui/material';
import { VisibilityOutlined, VisibilityOffOutlined } from '@mui/icons-material';
import { styles } from './styles';
import { StyledTextField } from '../../../assets/GlobalStyles/TextField';
import config from '../../../config/envConfig';
import { fetchAxios } from '../../../functions/axiosRequests';

const NewPassword = (props) => {
    const classes = styles();
    const queryParams = new URLSearchParams(window.location.search);
    const email = queryParams.get('email');
    const resetPasswordToken = queryParams.get('resetPasswordToken');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [openAlert, setOpenAlert] = useState(false);
    const [done, setDone] = useState(false);
    const [error, setError] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [confirmed, setConfirmed] = useState(false);

    const handleChange = (event) => {
        setNewPassword(event.target.value);
    };

    const handleChangeConfirm = (event) => {
        setConfirmPassword(event.target.value);
    };

    const validatePasswords = () => {
        const match = newPassword === confirmPassword;
        if (!match)
            setError(true);
        else
            setError(false);

        return match;
    };

    const handleSubmit = () => {
        const validation = validatePasswords();
        if (!validation) return;

        const url = `${config.serverLink}/api/auth/setNewPassword?email=${email}&resetPasswordToken=${resetPasswordToken}&newPassword=${newPassword}`;

        fetchAxios(url).then(function (response) {
            if (response.data.isSuccess){
                setDone(true);
                setOpenAlert(true);
            }
            else {
                setDone(false);
                setOpenAlert(true);
            }
        }).catch(function (error) {
            console.log(error)
        })

    };

    const handleClose = () => {
        setOpenAlert(false);
    };

    const redirect = () => {
        setConfirmed(true);
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <>
            <Card className={classes.card} elevation={0}>
                <CardContent className={classes.cardContent}>
                    Resseting password for: {email}
                    <StyledTextField
                        id="new-password"
                        type={showPassword ? "text" : "password"}
                        label="New password"
                        className={classes.textField}
                        value={newPassword}
                        onChange={handleChange}
                        InputProps={{ endAdornment:
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                              >
                                {showPassword ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                              </IconButton>
                            </InputAdornment>
                        }}
                    />
                    <StyledTextField
                        id="confirm-new-password"
                        type={showPassword ? "text" : "password"}
                        label="Confirm password"
                        className={classes.textField}
                        value={confirmPassword}
                        onChange={handleChangeConfirm}
                        InputProps={{ endAdornment:
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                              >
                                {showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                              </IconButton>
                            </InputAdornment>
                        }}
                    />
                    {error && (
                        <Typography component="p" color="error" className={classes.error}>
                           Passwords do not match.
                        </Typography>
                    )}
                    <Button onClick={handleSubmit} className={classes.submit} >
                        Confirm
                    </Button>
                </CardContent>
            </Card>

            <Dialog
                open={openAlert}
                onClose={handleClose}
                aria-labelledby="session-timeout-title"
                aria-describedby="session-timeout-description"
            >
                <DialogTitle id="session-timeout">
                    {done ? 'Password changed successfully' : 'Your link expired'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="session-timeout-description">
                        {done ? 'Please go to sign in page.' : 'Please generate new one and try again'}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className={classes.confirm} onClick={() => {redirect(); handleClose();}}>Go to login page</Button>
                </DialogActions>
            </Dialog>

            {confirmed && <Navigate to="/signin" replace/>}
        </>
    );
}

NewPassword.propTypes = {
    classes: PropTypes.shape({ submit: PropTypes.string, error: PropTypes.string, textField: PropTypes.string, title: PropTypes.string, card: PropTypes.string })
};

export default NewPassword;
