import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import PropTypes from "prop-types";
import { Grid, MenuItem, InputLabel, FormControl, FormHelperText } from "@mui/material";
import { fetchModels, fetchModelTypes } from "../../redux/actions/modelActions";
import { setModelRegionId, setModelType } from "../../redux/actions/parametersActions";
import CustomSelect from "../../assets/GlobalStyles/CustomSelect";
import { getRegionsWithLabels } from "../../redux/selectors/parametersSelector";
import { isFetchNeeded, isLoadedOrNA } from '../../functions/functions';

import { styles } from './styles';
import AntigenicModelIdSelector from './AntigenicModelIdSelector';
import { showSelect } from './functions';
import ModelIdSelector from './ModelIdSelector';
import { getModelLoadingSelector } from '../../redux/selectors/statusSelector';
import { introModelTypesSelector } from '../../redux/selectors/modelsSelector';
import appConfig from '../../config/appConfig';


const ModelSelector = (props) => {
    const { modelContext, modelVariable, modelLevels, modelTypes, modelType,
        lineage, colorBy, regions, modelRegionId, intro,
        lineageStatus, modelsStatus, modelTypesStatus, modelsLoading,
        setModelRegionId, setModelType,
        fetchModels, fetchModelTypes,
    } = props;

    const classes = styles();

    useEffect(() => {
        if (lineageStatus !== 'loaded') return;
        if (modelContext === 'antigenic') {
            if (isFetchNeeded(modelsStatus))
                fetchModels({ lineage, colorBy: 'antigenic' });
        }
        else {
            if (isFetchNeeded(modelTypesStatus)) fetchModelTypes({ lineage, modelRegionId, colorBy });
            if (isLoadedOrNA(modelTypesStatus) && isFetchNeeded(modelsStatus))
                fetchModels({ lineage, modelRegionId, colorBy, modelType });
        }
    });


    const handleModelRegionChange = (event) => {
        const modelRegionId = event.target.value;
        setModelRegionId(modelRegionId, modelVariable)
    };

    const handleModelTypeChange = async (event) => {
        const modelType = event.target.value;
        setModelType(modelType, modelVariable);
    };

    const levelsConfig = {
        modelRegionId: {
            onChangeHandler: handleModelRegionChange,
            label: 'Model region',
            options: { array: regions, value: elem => elem.key, label: elem => elem.label },
            value: modelRegionId
        },
        modelType: {
            onChangeHandler: handleModelTypeChange,
            label: 'Model type',
            options: { array: modelTypes, value: elem => elem, label: elem => appConfig.modelTypesLabels[elem] || elem },
            value: modelType
        }
    }

    
    return (
        <>
            <Grid container spacing={2} >
                {modelLevels.filter(modelLevel => !intro || appConfig.introModelSelectorInputs.includes(modelLevel)).map((modelLevel, index) => {
                    const { onChangeHandler, label, value, options } = levelsConfig[modelLevel];
                    const showInput = showSelect(value, options.array.map(elem => options.value(elem)));

                    // console.log(index, '. modelLevel = ',modelLevel, value, options.array, 'show', showInput);
                    // if (!showInput ) return (
                    //     <Grid item sm={12} md={6} key={`${modelLevel}_grid`}><FormHelperText error={true}>No models for {colorBy}</FormHelperText>
                    // </Grid>);
                    const previousLevels = modelLevels.filter((_, mindex) => mindex < index);
                    const previousLevelsTxt = previousLevels.map(l => `, ${levelsConfig[l].label}: ${levelsConfig[l].value}`).join();
                    //console.log(previousLevelsTxt);
                    return (
                        <Grid item sm={12} md={6} key={`${modelLevel}_grid`}>
                            {!showInput && !modelsLoading &&
                                <FormHelperText error={true}>No models for {colorBy} {previousLevelsTxt}</FormHelperText>
                            }
                            {showInput &&
                                <FormControl className={classes.formControl} fullWidth>
                                    <InputLabel htmlFor={modelLevel}>
                                        {label}
                                    </InputLabel>
                                    <CustomSelect
                                        value={value}
                                        onChange={onChangeHandler}
                                        inputProps={{ name: modelLevel, id: modelLevel }}
                                    >
                                        {options.array.map((elem) => (
                                            <MenuItem key={`${modelLevel}_${options.value(elem)}`} value={options.value(elem)}>
                                                {options.label(elem)}
                                            </MenuItem>
                                        ))}
                                    </CustomSelect>
                                </FormControl>
                            }
                        </Grid>
                    );
                })}
            </Grid>
            {!intro && <ModelIdSelector modelContext={modelContext} />}
            {!intro && <AntigenicModelIdSelector modelContext={modelContext} />}

        </>
    );
}


ModelSelector.propTypes = {
    modelId: PropTypes.string,
    modelType: PropTypes.string,
    fixedModelType: PropTypes.string,
    modelContext: PropTypes.string,
    lineage: PropTypes.string,
    modelTypes: PropTypes.arrayOf(PropTypes.string),
    modelsStatus: PropTypes.string,

    colorBy: PropTypes.string,
    setModel: PropTypes.func,
    fetchModel: PropTypes.func,
    // fetchAntigenicClades: PropTypes.func,
    setModelType: PropTypes.func,
    resetAntigenicModel: PropTypes.func,
    // fetchAntigenicModelsList: PropTypes.func,
    classes: PropTypes.shape({ formControl: PropTypes.string }),
    regions: PropTypes.arrayOf(PropTypes.shape({ key: PropTypes.string, label: PropTypes.string })),
    modelRegionId: PropTypes.string,

    lineageStatus: PropTypes.string,
    modelTypesStatus: PropTypes.string,
    resetModelData: PropTypes.func,
    resetPredictions: PropTypes.func,
    setModelRegionId: PropTypes.func,
    setParameters: PropTypes.func,
    fetchModels: PropTypes.func,
    fetchModelTypes: PropTypes.func

};



const mapStateToProps = (state, ownProps) => {
    //const { modelContext } = ownProps;
    const { lineage, colorBy, intro } = state.parameters;


    const modelContext = (ownProps.modelContext === "strainTree" && colorBy === "antigenic") ? 'antigenic' : ownProps.modelContext;
    const modelVariable = modelContext === 'antigenic'
        ? 'antigenic'
        : ((modelContext === 'frequencies') ? 'fitness' : colorBy);

    const modelId = ownProps.modelId || modelContext === "antigenic" ? state.parameters.antigenicModelId : state.parameters.modelId;
    const modelRegionId = ownProps.modelRegionId || state.parameters.modelRegionId;
    const modelType = ownProps.modelType || state.parameters.modelType;
    const modelsStatus = state.models.modelsStatus[modelVariable];
    const modelsLoading = getModelLoadingSelector(state);
    return {
        modelType: ownProps.fixedModelType || modelType,
        intro,
        lineage,
        modelId,
        colorBy,
        modelTypes: intro ? introModelTypesSelector(state) : state.models.modelTypes[modelVariable],
        modelsStatus,
        modelTypesStatus: state.models.modelTypesStatus[modelVariable],
        modelsLoading,
        regions: getRegionsWithLabels(state),
        lineageStatus: state.lineages.lineageStatus,
        modelRegionId,
        modelContext,
        modelVariable,
        modelLevels: state.metadata.modelsConfig.modelLevels[modelVariable]
    };
};


const mapDispatchToProps = dispatch => bindActionCreators({
    setModelType,
    setModelRegionId,
    fetchModelTypes,
    fetchModels,

}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ModelSelector);
