import React, { useEffect, useState } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Paper, TablePagination, Button, InputAdornment, TableSortLabel, IconButton
} from '@mui/material';
import { fetchAllScales } from '../../../redux/actions/settingsActions';
import { styles, StyledTableCell } from './styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { StyledTextField } from '../../../assets/GlobalStyles/TextField';
import { HideIcon } from '../../../components/Alerts/styles';
import DeleteMeasure from './Actions/DeleteMeasure';
import EditMeasure from './Actions/EditMeasure';
import Info from './Info';
import { emptyObject, getBiggerForSortTable } from '../../../functions/functions';

const orderInitState = {
    name: 'none',
    label: 'none',
    element: 'none',
    scale: 'none',
    lineage: 'none',
    measure: 'none',
    custom: 'none',
}

const searchState = {
    name: '',
    lineage: '',
    element: ''
}

const MeasuresTable = ({ allMeasures, colorScales, fetchMeasures, fetchAllScales, lineages }) => {
    const [tableData, setTableData] = useState([]);
    const [displayedTableData, setDisplayedTableData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [searched, setSearched] = useState(searchState);
    const [infoDialog, setInfoDialog] = useState(false);
    const [info, setInfo] = useState('');
    const [action, setAction] = useState('');
    const [orderBy, setOrderBy] = useState({ ...orderInitState, name: 'asc' });
    const classes = styles();

    useEffect(() => {
        fetchAllScales();
        setTableMeasures();
    }, []);

    useEffect(() => {
        setTableMeasures();
    }, [allMeasures]);

    const setTableMeasures = () => {
        const rows = allMeasures
            .filter(el => el.scale) //typeof el.scale === 'string')
            .map(el => {
                return {
                    name: el.measure,
                    label: el.label,
                    element: el.element || 'node (standard)',
                    scale: el.scale,
                    lineage: el?.lineage || '(all)',
                    measure: el,
                    custom: !emptyObject(el.element) || el.lineage
                }
            });

        setTableData(rows);
        const sorted = sortData([...rows], 'name', 'asc');
        setDisplayedTableData(sorted);
    }

    const sortData = (data, type, order) => {
        if (order === 'none')
            return [...tableData];

        const asc = order === 'asc';
        return data.sort((a, b) => getBiggerForSortTable(a, b, type, asc));
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const requestSearch = (e, type) => {
        const search = e.target.value;
        setSearched({ ...searched, [type]: search });
        // filterafterSearchClicked()
    };

    const filterafterSearchClicked = () => {
        let filteredRows = [ ...tableData ];

        if (searched.name !== ''){
            filteredRows = filteredRows.filter((row) => {
                return row.label.toLowerCase().includes(searched.name.toLowerCase()) || row.name.toLowerCase().includes(searched.name.toLowerCase())
            });
        }

        if (searched.element !== ''){
            filteredRows = filteredRows.filter((row) => {
                return row.element.toLowerCase().includes(searched.element.toLowerCase());
            });
        }

        if (searched.lineage !== ''){
            filteredRows = filteredRows.filter((row) => {
                return row.lineage.toLowerCase().includes(searched.lineage.toLowerCase());
            });
        }

        setDisplayedTableData(filteredRows);
        setPage(0);
    }

    const clearField = (type) => {
        setSearched({ ...searched, [type]: '' });
    }

    const cancelSearch = () => {
        setSearched({ ...searchState });
        setDisplayedTableData(tableData);
    };

    const handleCloseInfo = () => {
        setInfoDialog(false);
    };

    const handleSort = (type) => {
        let val = '';

        if (orderBy[type] === 'none')
            val = 'asc';
        else if (orderBy[type] === 'asc')
            val = 'desc';
        else
            val = 'asc'

        const newOrderBy = {
            ...orderInitState,
            [type]: val
        };

        setOrderBy(newOrderBy);
        const sorted = sortData([...displayedTableData], type, val);
        setDisplayedTableData(sorted)
    }

    return (
        <>
            <Paper sx={{ marginRight: '15px', marginLeft: '15px' }}>
                <div>
                    <StyledTextField
                        id="name"
                        label="Name / Label"
                        className={classes.search}
                        value={searched.name}
                        onChange={(searchVal) => requestSearch(searchVal, 'name')}
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end">
                                    <IconButton onClick={() => clearField('name')}>
                                        <HideIcon />
                                    </IconButton>
                                </InputAdornment>
                        }}
                    />
                    <StyledTextField
                        id="lineage"
                        label="Lineage"
                        className={classes.search}
                        value={searched.lineage}
                        onChange={(searchVal) => requestSearch(searchVal, 'lineage')}
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end">
                                    <IconButton onClick={() => clearField('lineage')}>
                                        <HideIcon />
                                    </IconButton>
                                </InputAdornment>
                        }}
                    />
                    <StyledTextField
                        id="element"
                        label="Element"
                        className={classes.search}
                        value={searched.element}
                        onChange={(searchVal) => requestSearch(searchVal, 'element')}
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end">
                                    <IconButton onClick={() => clearField('element')}>
                                        <HideIcon />
                                    </IconButton>
                                </InputAdornment>
                        }}
                    />
                </div>
                <Button className={classes.saveButton} onClick={filterafterSearchClicked}>
                    Search
                </Button>
                <Button className={classes.cancelButton} onClick={cancelSearch}>
                    Cancel
                </Button>
                <TableContainer >
                    <Table size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={orderBy.name !== 'none'}
                                        direction={orderBy.name !== 'none' ? orderBy.name : 'asc'}
                                        onClick={(e) => handleSort('name')}
                                    >
                                        Name
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={orderBy.label !== 'none'}
                                        direction={orderBy.label !== 'none' ? orderBy.label : 'asc'}
                                        onClick={(e) => handleSort('label')}
                                    >
                                        Label
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={orderBy.lineage !== 'none'}
                                        direction={orderBy.lineage !== 'none' ? orderBy.lineage : 'asc'}
                                        onClick={(e) => handleSort('lineage')}
                                    >
                                        Lineage
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={orderBy.element !== 'none'}
                                        direction={orderBy.element !== 'none' ? orderBy.element : 'asc'}
                                        onClick={(e) => handleSort('element')}
                                    >
                                        Element
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={orderBy.scale !== 'none'}
                                        direction={orderBy.scale !== 'none' ? orderBy.scale : 'asc'}
                                        onClick={(e) => handleSort('scale')}
                                    >
                                        Default scale
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell align="left">Actions</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                (rowsPerPage > 0
                                    ? displayedTableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : displayedTableData
                                ).map((row, index) => (
                                    <TableRow
                                        key={`${row.name}_${index}`} >
                                        <TableCell component="th" scope="row">
                                            {row.name}
                                        </TableCell>
                                        <TableCell align="left">
                                            {row.label}
                                        </TableCell>

                                        <TableCell align="left">
                                            {row.lineage}
                                        </TableCell>
                                        <TableCell align="left">
                                            {row.element}
                                        </TableCell>
                                        <TableCell align="left">
                                            {(typeof row.scale === 'string') ? row.scale : '(parameterized)'}
                                        </TableCell>
                                        <TableCell align="left">
                                            <EditMeasure
                                                scales={colorScales}
                                                measure={row.measure}
                                                lineages={lineages}
                                                setInfo={setInfo}
                                                setInfoDialog={setInfoDialog}
                                                setAction={setAction}
                                                fetchMeasures={fetchMeasures}
                                            />
                                            {row.custom && (
                                                <DeleteMeasure
                                                    measure={row.measure}
                                                    setInfoDialog={setInfoDialog}
                                                    setInfo={setInfo}
                                                    setAction={setAction}
                                                    fetchMeasures={fetchMeasures}
                                                />)}
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[15, 25, 100]}
                    component="div"
                    count={displayedTableData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            <Info openDialog={infoDialog} handleCloseDialog={handleCloseInfo} info={info} action={action} />
        </>
    )

}

const mapStateToProps = (state) => {
    const { colorScales, scalesPalette } = state.settings;

    const scales = colorScales.map(scale => {
        const colors = typeof scale.range === 'string' ?
            scalesPalette[scale.range] : scale.range;
        return { id: scale.scaleId, lineage: scale.lineage, colors }
    });

    const { lineages } = state.lineages;
    return {
        colorScales: scales,
        lineages,
        colorScalesLength: colorScales.length
    }
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchAllScales,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(MeasuresTable);
