import React from 'react';
import { connect } from 'react-redux';
import { Grid } from '@mui/material';
import { bindActionCreators } from 'redux';
// import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { InputLabel, MenuItem, FormControl } from '@mui/material/';
import { setLayoutParameters, setParameters } from '../../redux/actions/parametersActions';
import { getTreeScaleXOptions, getTreeScaleYOptions, measuresSelector } from '../../redux/selectors/metadataSelector';
import { fetchCustomTreeAttrs } from '../../redux/actions/treeDataActions';
import { resetComponentsStatus } from '../../redux/actions/renderActions';
import { isFetchNeeded } from '../../functions/functions';
import CustomSelect from '../../assets/GlobalStyles/CustomSelect';

// const styles = () => ({
//     formControlHidden: {
//         minWidth: 120,
//         margin: '8px 0px',
//         display: 'none'
//     },
//     grid: {
//         width: 'calc(100% + 16px)',
//     }
// });


const TreeScaleTypeSelector = props => {

    const { measures, customDataStatus, fetchCustomTreeAttrs, lineage, showCladeBar,  
        treeScaleTypeX, treeScaleTypeY, treeScaleXOptions, treeScaleYOptions, setLayoutParameters } = props;

    const handleChange = async (event) => {

        const { name, value } = event.target;
        //console.log(`name =`, name, 'value =', value, measures);
        //const treeScaleType = event.target.value;

        if (value !== 'order' && name === 'treeScaleTypeY' && showCladeBar) {
            setLayoutParameters({ showCladeBar: false, showCladeBarLabels: false });
        }

        //await resetComponentsStatus('strainTree', RENDER_STATUS.START);

        // console.log(`name = `, name, measures[treeScaleType].custom);

        await setLayoutParameters({ [name]: value });
        if (measures[value].custom && isFetchNeeded(customDataStatus[value]))
            await fetchCustomTreeAttrs({ lineage, customTreeAttrId: value });

    };



    return (
        <Grid container spacing={2} style={{ paddingTop: '8px' }}>
            <Grid item sm={12} md={6}>
                <FormControl fullWidth>
                    <InputLabel htmlFor="treeScaleTypeX">
                        X axis
                    </InputLabel>
                    <CustomSelect
                        value={treeScaleTypeX}
                        onChange={handleChange}
                        inputProps={{
                            name: 'treeScaleTypeX',
                            id: 'treeScaleTypeX',
                        }}
                    >
                        {treeScaleXOptions.map((t) => (
                            <MenuItem key={t.key} value={t.key}>{t.label}</MenuItem>
                        ))}
                    </CustomSelect>
                </FormControl>
            </Grid>
            <Grid item sm={12} md={6}>
                <FormControl fullWidth>
                    <InputLabel htmlFor="treeScaleTypeY">
                        Y axis
                    </InputLabel>
                    <CustomSelect
                        value={treeScaleTypeY}
                        onChange={handleChange}
                        inputProps={{
                            name: 'treeScaleTypeY',
                            id: 'treeScaleTypeY',
                        }}
                    >
                        {treeScaleYOptions.map((t) => (
                            <MenuItem key={t.key} value={t.key}>{t.label}</MenuItem>
                        ))}
                    </CustomSelect>
                </FormControl>
            </Grid>
        </Grid>
    );

}

TreeScaleTypeSelector.propTypes = {
    lineage: PropTypes.string,
    treeScaleTypeX: PropTypes.string,
    treeScaleTypeY: PropTypes.string,
    customDataStatus: PropTypes.shape({}),
    showCladeBar: PropTypes.bool,
    treeScaleXOptions: PropTypes.arrayOf(PropTypes.shape({ key: PropTypes.string, label: PropTypes.string })),
    treeScaleYOptions: PropTypes.arrayOf(PropTypes.shape({ key: PropTypes.string, label: PropTypes.string })),
    measures:  PropTypes.shape({}),
    classes: PropTypes.shape({ formControl: PropTypes.string }),
    setParameters: PropTypes.func,
    fetchCustomTreeAttrs: PropTypes.func,
    setLayoutParameters: PropTypes.func
};


const mapStateToProps = (state, { intro }) => {
    const Xoptions = getTreeScaleXOptions(state);
    const Yoptions = getTreeScaleYOptions(state);
    const introOptions = ["AADivergence", "NLDivergence", "order", "time"];

    const treeScaleXOptions = intro ?
        Xoptions.filter(el => introOptions.includes(el.key)) : Xoptions;
    const treeScaleYOptions = intro ?
        Yoptions.filter(el => introOptions.includes(el.key)) : Yoptions;

    return ({
        treeScaleTypeX: state.parameters.treeScaleTypeX,
        treeScaleTypeY: state.parameters.treeScaleTypeY,
        showCladeBar: state.parameters.showCladeBar,
        treeScaleXOptions,
        treeScaleYOptions,
        measures: measuresSelector(state),
        lineage: state.parameters.lineage,
        customDataStatus: state.customTreeData.status
    });
};

const mapDispatchToProps = dispatch => bindActionCreators({
    setParameters,
    setLayoutParameters,
    fetchCustomTreeAttrs,
    resetComponentsStatus
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TreeScaleTypeSelector);
// (withStyles(styles)(TreeScaleTypeSelector));
