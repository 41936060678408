/* eslint-disable prefer-destructuring */
/* eslint-disable no-return-assign */
import { createSelector } from 'reselect';
import { getSegments } from '../../functions/functions';
import appConfig from '../../config/appConfig';


const getModelIds = state => state.models.models[state.parameters.colorBy];
const getAntigenicModelIds = state => state.models.models.antigenic;
const getAntigenicModelIdComponents = ({ metadata}) => metadata?.modelsConfig.antigenicModelIdComponents || {};
const getColorBy = ({parameters}) => parameters.colorBy;  

export const getModelSegmentsOptions = createSelector([getModelIds, getAntigenicModelIdComponents, getColorBy], 
    (modelIds, antigenicModelIdComponents, colorBy) => getSegments(modelIds, antigenicModelIdComponents[colorBy]));

export const getAntigenicModelSegmentsOptions = createSelector([getAntigenicModelIds, getAntigenicModelIdComponents], 
    (antigenicModelIds, antigenicModelIdComponents) => getSegments(antigenicModelIds, antigenicModelIdComponents.antigenic));

const getFitnessModelTypes = ({models}) => models.modelTypes?.fitness;

export const introModelTypesSelector = createSelector(getFitnessModelTypes, 
    modelTypes => appConfig.introModelTypes
        .filter(modelType => modelTypes && modelTypes.length && modelTypes.includes(modelType)))
       



