/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const LogoIcon = (props) => (
    <SvgIcon {...props} width="137" height="32" viewBox="0 0 137 32" style={{ width: 120 }}>
        <path d="M46.5327 1.32971C44.9308 0.43662 42.9773 0 40.6917 0H28.8339V30.5634H35.2023V20.66H40.555C42.8601 20.66 44.8331 20.2234 46.4741 19.37C48.0955 18.4968 49.3458 17.2862 50.2053 15.7382C51.0649 14.1901 51.4946 12.404 51.4946 10.3598C51.4946 8.33547 51.0649 6.52945 50.2248 4.98143C49.3653 3.41357 48.1346 2.20294 46.5327 1.32971ZM44.3448 13.0192C43.9541 13.7932 43.3485 14.3886 42.5475 14.8252C41.7466 15.2618 40.7308 15.4802 39.5196 15.4802H35.2023V5.27913H39.5001C40.7308 5.27913 41.7466 5.49744 42.5475 5.91421C43.3485 6.33099 43.9541 6.92638 44.3448 7.68054C44.7355 8.4347 44.9308 9.32778 44.9308 10.3399C44.9308 11.3521 44.7355 12.2452 44.3448 13.0192Z" fill="white" />
        <path d="M65.4037 7.30347C64.1339 7.30347 63.0204 7.6607 62.0632 8.39502C61.0864 9.12933 60.4027 10.201 59.9925 11.63H59.7581V7.64085H53.6826V30.5634H59.9339V17.5839C59.9339 16.6511 60.1488 15.8176 60.559 15.1031C60.9692 14.3886 61.5358 13.8329 62.2586 13.4161C62.9814 13.0192 63.8018 12.8208 64.72 12.8208C65.1498 12.8208 65.6186 12.8604 66.146 12.92C66.6735 12.9994 67.0642 13.0788 67.3572 13.1581V7.54162C67.0837 7.46224 66.7712 7.42255 66.4 7.38285C66.0484 7.32331 65.7163 7.30347 65.4037 7.30347Z" fill="white" />
        <path d="M86.3454 10.2408C85.3881 9.26829 84.2551 8.55382 82.9658 8.07751C81.6765 7.60119 80.2895 7.36304 78.8048 7.36304C76.5973 7.36304 74.6633 7.8592 73.0224 8.85151C71.3814 9.84383 70.1117 11.2331 69.213 13.0192C68.3144 14.8054 67.8651 16.8694 67.8651 19.2312C67.8651 21.6524 68.3144 23.7363 69.213 25.5026C70.1117 27.2689 71.401 28.6383 73.0615 29.591C74.722 30.5436 76.7341 31.0397 79.0392 31.0397C80.8951 31.0397 82.536 30.742 83.9621 30.1665C85.3881 29.591 86.5603 28.7772 87.4589 27.7254C88.3575 26.6735 88.9631 25.4431 89.2561 24.034L83.4737 23.637C83.2588 24.2126 82.9462 24.7087 82.536 25.1057C82.1258 25.5026 81.6374 25.8003 81.0513 25.9988C80.4653 26.1972 79.8402 26.2965 79.1369 26.2965C78.082 26.2965 77.1834 26.0781 76.4215 25.6217C75.6596 25.1652 75.0736 24.5301 74.6438 23.7164C74.2336 22.8829 74.0187 21.9104 74.0187 20.7792V20.7593H89.3733V19.0128C89.3733 17.0679 89.0998 15.3611 88.5724 13.8925C88.0645 12.404 87.3026 11.1934 86.3454 10.2408ZM74.0578 16.7901C74.0968 15.9764 74.2922 15.2222 74.6829 14.5276C75.0931 13.7933 75.6596 13.1979 76.402 12.7612C77.1248 12.3246 77.9648 12.1063 78.922 12.1063C79.8402 12.1063 80.6411 12.3048 81.3248 12.7017C82.0086 13.0986 82.5555 13.6543 82.9462 14.3688C83.337 15.0833 83.5323 15.8771 83.5323 16.8099H74.0578V16.7901Z" fill="white" />
        <path d="M101.016 24.034H100.782L96.0934 7.64087H89.4905L97.3827 30.5634H104.415L112.308 7.64087H105.685L101.016 24.034Z" fill="white" />
        <path d="M120.141 7.64087H113.89V30.5634H120.141V7.64087Z" fill="white" />
        <path d="M136.043 7.36301C135.672 7.32331 135.34 7.30347 135.027 7.30347C133.757 7.30347 132.644 7.6607 131.686 8.39502C130.71 9.12933 130.026 10.201 129.616 11.63H129.381V7.64085H123.306V30.5634H129.557V17.5839C129.557 16.6511 129.772 15.8176 130.182 15.1031C130.592 14.3886 131.159 13.8329 131.882 13.4161C132.605 13.0192 133.425 12.8208 134.343 12.8208C134.773 12.8208 135.242 12.8604 135.769 12.92C136.297 12.9994 136.687 13.0788 136.98 13.1581V7.54162C136.726 7.46224 136.414 7.4027 136.043 7.36301Z" fill="white" />
        <path d="M14.9835 18.4371C15.5695 18.8738 16.2923 19.1119 17.0737 19.1119C19.0468 19.1119 20.6486 17.4845 20.6486 15.48C20.6486 13.4756 19.0468 11.8482 17.0737 11.8482C15.0811 11.8482 13.4793 13.4756 13.4793 15.48C13.4793 15.8373 13.5379 16.1747 13.616 16.4922L9.06431 19.8859L3.14516 15.48L14.9639 6.68811C15.55 7.12473 16.2728 7.36288 17.0542 7.36288C19.0272 7.36288 20.6291 5.73548 20.6291 3.731C20.6291 1.72652 19.0272 0.0991211 17.0542 0.0991211C15.0811 0.0991211 13.4793 1.72652 13.4793 3.731C13.4793 4.08824 13.5379 4.42563 13.616 4.74317L0.488379 14.5274C0.175816 14.7457 0 15.103 0 15.48C0 15.8571 0.175816 16.2144 0.488379 16.4327L13.6355 26.2169C13.5379 26.5345 13.4988 26.8718 13.4988 27.2291C13.4988 29.2336 15.1007 30.861 17.0737 30.861C19.0468 30.861 20.6486 29.2336 20.6486 27.2291C20.6486 25.2246 19.0468 23.5972 17.0737 23.5972C16.2923 23.5972 15.5695 23.8552 14.9835 24.272L11.0569 21.3546L14.9835 18.4371Z" fill="white" />
    </SvgIcon>
);

export default LogoIcon;