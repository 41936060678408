import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import config from '../../config/envConfig';
import { fetchAxios } from '../../functions/axiosRequests';
import { initStrainTreeError, initStrainTreeSuccess } from '../actions/sessionActions';
import { INIT_STRAIN_TREE_REQUEST, } from '../actions/actionTypes';
import { prepareUrl } from '../../functions/functions';
import { sessionTimeOut } from './helpers/functions';

export const initStrainTreeEpic = (action$, store) => action$.pipe(
    ofType(INIT_STRAIN_TREE_REQUEST),
    mergeMap(action => {
        const url = prepareUrl(`${config.serverLink}/api/initStrainTree`, action.payload);
        // console.log(`initStrainTree => url = ${url}`);

        return fetchAxios(url).then(function (response) {

            return initStrainTreeSuccess(response.data)
        }).catch(function (error) {
            const { status, data } = error.response;
            if (status === 408)
                return sessionTimeOut(error, store);
            return initStrainTreeError({ error: data.error, errorWebsiteText: 'An error has occured during initializing strain tree.', errorWebsiteType: 'fetch'});
        })
    })
);
