import { makeStyles } from '@mui/styles';

export const styles = makeStyles(theme =>
    ({
    root: {
        flexGrow: 1,
        height: 'calc(100vh - 60px)',
        maxHeight: 'calc(100vh - 60px)',
    },
    container: {
        height: '100%',
        maxHeight: '100%',
    },
    item: {
        position: 'relative',
        height: '100%',
    },
    confirm: {
        backgroundColor: '#6F6CFF',
        color: 'white',
        marginBottom: '16px',
        textTransform: 'none',
        marginRight: '16px',
        '&:hover': {
            backgroundColor: '#6F6CFF',
            color: 'white',
        },
    },
    cancel: {
        marginRight: '6px',
        marginBottom: '16px',
        backgroundColor: '#e0e0e0',
        color: '#000000',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#e0e0e0',
        },
    },
}));

export const dialog = {
    '& .MuiDialog-container': {
      '& .MuiPaper-root': {
        width: '100%',
        maxWidth: '300px',
        borderRadius: '12px'
      },
    },
};
