import { withStyles } from '@mui/styles';
import { RegionalFreqButton } from './RegionalFreqButton';
const styles = theme => ({

    formControl: {
        minWidth: 120,
        margin: '8px 0px',
        display: 'block',
        width: '100%',
        marginBottom: '70px'
    },
    formControlHidden: {
        minWidth: 120,
        margin: '8px 0px',
        display: 'none'
    },
    reportButton: {
        padding: '8px 16px',
        fontSize: '16px',
        lineHeight: '21px',
        color: theme.palette.secondary.light,
        boxShadow: 'none',
        backgroundColor: theme.palette.secondary.main,
    },
    button: {
        backgroundColor: '#e0e0e0', 
        color: '#000000', 
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#e0e0e0',
        }
    }
});

export const StyledRegionalFreqButton = withStyles(styles)(RegionalFreqButton);