import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { MenuItem } from '@mui/material';
import { fetchSubsetTree } from '../../redux/actions/treeDataActions';
import { setParameters } from '../../redux/actions/parametersActions';
import { resetVisibleBins } from '../../redux/actions/frequenciesActions';
import { emptyObject } from '../../functions/functions';
import { getPredictionsStatus } from '../../redux/selectors/statusSelector';
import CustomSelect from '../../assets/GlobalStyles/CustomSelect';

const styles = () => ({
    formControl: {
        // minWidth: 120,
        marginTop: '6px'
    },
});


class StrainsSubsetSelector extends PureComponent {
    handleChange = async event => {
        const { setParameters, fetchSubsetTree,
            zoomNodeId, strainHighlight, lineage, context,
        } = this.props;

        const strainSubset = event.target.value;
        console.log('[handleChange]', strainSubset);
        if (context === 'strainTree') {
            setParameters({ strainSubset });
            fetchSubsetTree({ strainSubset, zoomNodeId, lineage, strainHighlight });
        }
        else if (context === 'clade') {
            setParameters({ strainSubset });
        }
    };

    render = () => {
        const { classes, strainSubset, strainSubsetOptions, intro } = this.props
        return (
            <>
                {!emptyObject(strainSubsetOptions) && (
                    <FormControl className={classes.formControl} style={{ marginBottom: intro ? 0 : '8px' }} fullWidth id="strainSubsetSelector" >
                        <InputLabel htmlFor="strainSubset">
                            Subset strains
                        </InputLabel>
                        <CustomSelect
                            value={strainSubset}
                            onChange={this.handleChange}
                            // id='strainSubset'
                            inputProps={{
                                name: 'strainSubset',
                                id: 'strainSubset'
                            }}
                        >
                            {strainSubsetOptions.map(({ key, label }) => (
                                <MenuItem key={key || '_ss'} id='strainSubset' value={key}>{label}</MenuItem>
                            ))}
                        </CustomSelect>
                    </FormControl>
                )}
            </>
        );
    }
}

StrainsSubsetSelector.propTypes = {
    context: PropTypes.string,
    classes: PropTypes.shape({ formControl: PropTypes.string }),
    strainSubsetOptions: PropTypes.arrayOf(PropTypes.shape({})),
    lineage: PropTypes.string,
    strainSubset: PropTypes.string,
    strainHighlight: PropTypes.string,
    zoomNodeId: PropTypes.number,
    //selectedBins: PropTypes.shape({}),
    setParameters: PropTypes.func,
    fetchSubsetTree: PropTypes.func
};


const mapStateToProps = (state) => {
    const { parameters, treeData, metadata } = state;
    //console.log('strainSubset', parameters.strainSubset);
    return ({
        lineage: parameters.lineage,
        strainSubset: parameters.strainSubset,
        strainHighlight: parameters.strainHighlight,
        strainSubsetOptions: metadata.strainSubsetOptions,
        zoomNodeId: parameters.zoomNodeId,
        subsetTreeStatus: treeData.subsetTreeStatus,
        //frequenciesStatus: frequenciesData.frequenciesStatus,
        freqCategory: parameters.freqCategory,
        trackingFrom: parameters.trackingFrom,
        predictionBaseline: parameters.predictionBaseline,
        gene: parameters.gene,
        hla: parameters.hla,
        // binMethod: parameters.binMethod,
        // binCnt: parameters.binCnt,
        predictionsStatus: getPredictionsStatus(state),
        //selectedBins: frequenciesData.selectedBins,
    })
};

const mapDispatchToProps = dispatch => bindActionCreators({
    setParameters,
    fetchSubsetTree,
    resetVisibleBins,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(StrainsSubsetSelector));
