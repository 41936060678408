import {
    SET_COLOR_BY,
    SET_MODEL_TYPE,
    SET_REFERENCE_CLADE,
    SET_REFERENCE_STRAIN,
    SET_PARAMETERS,
    SET_LAYOUT_PARAMETERS,
    SET_MEASURE_DOMAIN,
    SET_MEASURE_SCALE,
    TOGGLE_MUTATION_CLASS_LABEL_VISIBILITY,
    SET_MODEL_REGION_ID,
    SET_SELECTED_MODELS,
    SET_SHOW_MUTATIONS_GROUPS,
    SET_SHOW_REFERENCE_STRAINS,
    SET_SHOW_CLADE_LABELS,
} from './actionTypes';

// Base Actions
import { received } from '../baseActions';

const setColorBy = (payload) => received(SET_COLOR_BY, payload)

const setModelRegionId = (modelRegionId, colorBy) => received(SET_MODEL_REGION_ID, { modelRegionId, colorBy })

const setModelType = (modelType, colorBy) => received(SET_MODEL_TYPE, { modelType, colorBy })

const setMeasureDomain = (parameters) => received(SET_MEASURE_DOMAIN, parameters);

const setReferenceClade = refClade => received(SET_REFERENCE_CLADE, { refClade })

const setReferenceStrain = (refStrain, searchId) => received(SET_REFERENCE_STRAIN, { refStrain, searchId });

const toggleMutationClassLabelVisibility = (mutOptionId) => received(TOGGLE_MUTATION_CLASS_LABEL_VISIBILITY, { id: mutOptionId });

const setParameters = parameters => received(SET_PARAMETERS, { parameters });

const setLayoutParameters = parameters => received(SET_LAYOUT_PARAMETERS, { parameters });

const setShowMutationsGroups = showMutationsGroups => received(SET_SHOW_MUTATIONS_GROUPS, { showMutationsGroups });

const setShowReferenceStrains = showReferenceStrains => received(SET_SHOW_REFERENCE_STRAINS, { showReferenceStrains });

const setShowCladeLabels = showCladeLabels => received(SET_SHOW_CLADE_LABELS, { showCladeLabels});

const setMeasureScale = (payload) => received(SET_MEASURE_SCALE, payload);

const setSelectedModels = (payload) => received(SET_SELECTED_MODELS, payload);

export {
    setColorBy,
    setModelRegionId,
    setModelType,
    setReferenceClade,
    setReferenceStrain,
    toggleMutationClassLabelVisibility,
    setParameters,
    setLayoutParameters,
    setMeasureDomain,
    setMeasureScale,
    setSelectedModels,
    setShowMutationsGroups,
    setShowCladeLabels,
    setShowReferenceStrains
};
