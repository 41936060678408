import React, { useState } from "react";
import { withStyles } from "@mui/styles";
import { Button, CardActions, Typography } from '@mui/material';
import { signInStyles } from "../styles/signInStyles";
import { prepareUrl} from '../../../functions/functions';
import { StyledTextField } from '../../../assets/GlobalStyles/TextField';
import { postAxios } from "../../../functions/axiosRequests";
import config from "../../../config/envConfig";

const initState = {
    username: '',
    email: '',
    password: '',
    secondPassword: '',
};

const errorInitState = {
    username: {
        isError: false,
        text: ''
    },
    email: {
        isError: false,
        text: ''
    },
    password: {
        isError: false,
        text: ''
    },
    secondPassword: {
        isError: false,
        text: '' 
    }
}

const Register = (props) => {
    const { classes, changeView } = props;
    const [ user, setUser ] = useState(initState);
    const [ error, setError ] = useState(errorInitState);
    const [ internalError, setInternalError ] = useState(false);
    
    const handleChange = (e, name ) => {
        setUser( {...user, [name]: e.target.value});
        setError( { ...error, [name]: { isError: false, text: '' }})
    };

    const isValidEmail = () => {
        const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
        const test = emailPattern.test(user.email);
        return !test;
    };

    const validate = () => {
        let isError = false;
        const newErrorState = { ...error };
        
        if (!user.username){
            isError = true;
            newErrorState.username = {
                isError: true,
                text: 'Username cannot be empty.' 
            }
        }
            
        
        if (!user.email){
            isError = true;
            newErrorState.email = {
                isError: true,
                text: 'Email cannot be empty.'
            }
        } else {
            const invalidEmail = isValidEmail();
            if ( invalidEmail ){
                isError = true;
                newErrorState.email = {
                    isError: true,
                    text: 'Email is invalid.'
                }
            }
    
        }

        if (!user.password){
            isError = true;
            console.log('zjebane');
            newErrorState.password = {
                isError: true,
                text: 'Password cannot be empty.' 
            }
        }
        if (!user.secondPassword){
            isError = true;
            newErrorState.secondPassword = {
                isError: true,
                text: 'Confirm Password cannot be empty.' 
            }
        }
        const bothNotEmpty = user.password && user.secondPassword;

        if (bothNotEmpty && (user.password !== user.secondPassword)){
            isError = true;
            newErrorState.password = {
                isError: true,
                text: 'Passwords do not match.'
            };
            newErrorState.secondPassword = {
                isError: true,
                text: 'Passwords do not match.'
            };
        }

        setError(newErrorState);
        return isError;
    };

    const register = () => {
        const isError = validate();

        if (isError)
            return;

        const url = prepareUrl(`${config.serverLink}/api/auth/register`);
        postAxios(url, {user}).then(function (response) {
            const { success } = response.data;
            if (success) {
                changeView('login');
            }  else 
                setInternalError(true);

        }).catch(function (error) {
            console.log(error)
        })
    }
    return (
        <>
            <StyledTextField
                id="username"
                type="text"
                label="Username"
                className={classes.textField}
                value={user.username}
                onChange={(e) => handleChange(e, 'username')}
                error={error.username.isError}
                helperText={error.username.text}
            />
            <StyledTextField
                id="email"
                type="email"
                label="Email"
                className={classes.textField}
                value={user.email}
                onChange={(e) => handleChange(e, 'email')}
                error={error.email.isError}
                helperText={error.email.text}
            />     
            <StyledTextField
                id="password"
                type="password"
                label="Password"
                className={classes.textField}
                value={user.password}
                onChange={(e) => handleChange(e, 'password')}
                error={error.password.isError}
                helperText={error.password.text}
            />
            <StyledTextField
                id="confirmPass"
                type="password"
                label="Confirm password"
                className={classes.textField}
                value={user.secondPassword}
                onChange={(e) => handleChange(e, 'secondPassword')}
                error={error.secondPassword.isError}
                helperText={error.secondPassword.text}
            />
            <CardActions className={classes.cardActions}>
                <Button onClick={register} className={classes.submit}>
                    Register
                </Button>
                <div className={classes.link} onClick={() => changeView('login')}>Back</div>
            </CardActions>
            {   internalError &&
                <Typography component="p" color="error" className={classes.error}>
                    Something went wrong during registering.
                </Typography>
            }
            
        </>
    )
}

export default withStyles(signInStyles)(Register);
