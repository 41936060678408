import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@mui/styles';
import { IconButton } from '@mui/material';
//import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider , DesktopDatePicker } from '@mui/x-date-pickers';
import { StyledTextField } from '../../assets/GlobalStyles/TextField';
import { initStrainTree } from '../../redux/actions/sessionActions';
import { setParameters } from '../../redux/actions/parametersActions';
import { styles } from '../Tree/styles'
import { dateFormat } from '../../functions/checkBrowser';
import { daysToDate, dateToDays } from '../../functions/functions';
import {HideIcon } from '../Alerts/styles'

const StrainCutoffDatePicker = (props) => {
    const { strainCutOffDate, strainCutoffDateMinMax, setParameters, initStrainTree,
            lineage, modelId, colorBy, zoomNodeId, strainSubset, showRule, vpMethod } = props;
    const [strainCutOffDateHolder, setStrainCutOffDateHolder] = useState(strainCutOffDate);
    // const treeAttrsParams = ['p', colorBy, 'time'].join(',');

    useEffect(() => {
        // console.log('useEffect', strainCutOffDate, strainCutOffDateHolder, ' !==', strainCutOffDate !== strainCutOffDateHolder)
        // if (strainCutOffDate !== strainCutOffDateHolder && !strainCutOffDateHolder){
            setStrainCutOffDateHolder(strainCutOffDate);
        // }
    }, [strainCutOffDate]);

    const handleStrainCutOffDateChange = strainCutOffDateEvent => {
        if (!strainCutOffDateEvent){
            setParameters({ strainCutOffDate: null });
            setStrainCutOffDateHolder(null);
            initStrainTree({ lineage, modelId, colorBy, zoomNodeId, strainSubset, showRule, strainCutOffDate: strainCutOffDateEvent, vpMethod /*, treeAttrsParams*/ });
        }

        if (strainCutOffDateEvent && !isNaN(strainCutOffDateEvent.getTime()) && strainCutOffDateEvent !== strainCutOffDate)
            setStrainCutOffDateHolder(strainCutOffDateEvent);
    };

    const handleAccept = strainCutOffDateEvent => {
        setStrainCutOffDateHolder(strainCutOffDateEvent);
        setParameters({ strainCutOffDate: strainCutOffDateEvent });

        if (strainCutOffDateEvent !== strainCutOffDate)
            initStrainTree({ lineage, modelId, colorBy, zoomNodeId, strainSubset, showRule, strainCutOffDate: strainCutOffDateEvent, vpMethod /*, treeAttrsParams*/ });
    }

    const handleKeyPress = event => {
        if(event.key === 'Enter'){
            const dateInt = dateToDays(strainCutOffDateHolder)

            if (dateInt > strainCutoffDateMinMax.max || dateInt < strainCutoffDateMinMax.min){
                setStrainCutOffDateHolder({strainCutOffDateHolder: strainCutOffDate});
            } else {
                setParameters({ strainCutOffDate: strainCutOffDateHolder });

                if (strainCutOffDateHolder !== strainCutOffDate)
                    initStrainTree({ lineage, modelId, colorBy, zoomNodeId, strainSubset, showRule, strainCutOffDate: strainCutOffDateHolder, vpMethod, treeAttrsParams });
            }
        }
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
                clearable
                minDate = { strainCutoffDateMinMax &&
                    daysToDate(strainCutoffDateMinMax.min)
                }
                maxDate = { strainCutoffDateMinMax &&
                    daysToDate(strainCutoffDateMinMax.max)
                }
                variant="inline"
                format={dateFormat}
                allowSameDateSelection
                id="strainCutOffDate"
                label="Show strains from"
                value={strainCutOffDateHolder}
                onChange={handleStrainCutOffDateChange}
                onAccept={handleAccept}
                slotProps={{
                    textField: { 
                        variant: 'standard',
                        onKeyDown: handleKeyPress,
                        InputProps: {
                            endAdornment: (
                              <IconButton style={{padding: 0}} onClick={() => handleStrainCutOffDateChange(null)}>
                                <HideIcon />
                              </IconButton>
                            )
                        },
                        style: { 
                            margin: 0
                        }
                    },
                    inputAdornment: { position: 'start'},
                    
                }}
                />
        </LocalizationProvider>
    );
}

StrainCutoffDatePicker.propTypes = {
    lineage: PropTypes.string,
    strainCutOffDate: PropTypes.instanceOf(Date),
    colorBy: PropTypes.string,
    modelId: PropTypes.string,
    zoomNodeId: PropTypes.number,
    strainSubset: PropTypes.string,
    showRule: PropTypes.string,
    vpMethod: PropTypes.string,
    setParameters: PropTypes.func,
    initStrainTree: PropTypes.func,
};

const mapStateToProps = ({ parameters, treeData }) => ({
    colorBy: parameters.colorBy,
    strainCutoffDateMinMax: treeData.strainCutoffDateMinMax,
    strainCutOffDate: parameters.strainCutOffDate,
    lineage: parameters.lineage,
    modelId: parameters.modelId,
    zoomNodeId: parameters.zoomNodeId,
    strainSubset: parameters.strainSubset,
    showRule: parameters.showRule,
    vpMethod: parameters.vpMethod,
});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        setParameters,
        initStrainTree,
    },
    dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(StrainCutoffDatePicker));
