import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Grid } from '@mui/material';
import { fetchLineage, fetchLineages } from '../../redux/actions/lineagesActions';
import { initStrainTree } from '../../redux/actions/sessionActions';
import { isFetchNeeded, isColorByModel, isLoadedOrNA } from '../../functions/functions';
import { fetchModel, fetchModels } from '../../redux/actions/modelActions';
import { getShowRuleForColorBy, getCustomMeasures, getIgnoreStrainCutOffDateForColorBy, } from '../../redux/selectors/metadataSelector';
import { fetchTCellAntigenicityOptions, fetchCustomTreeAttrs, fetchMutationClasses, fetchVpValues } from '../../redux/actions/treeDataActions';
import { fetchGenotypeData, fetchMutationGroupValues, fetchMutationsPositionsDictionary } from '../../redux/actions/genotypeActions';
import { getStrainTreeRenderStatus, getIsMobile, shouldFetchModelsSelector } from '../../redux/selectors/statusSelector';
import { RENDER_STATUS } from '../../config/consts';
import TreeGraph from '../../components/Tree/TreeGraph';
import TreeGraphOptions from '../../components/Tree/options/TreeGraphOptions';
// import TreeGraphOptions
import NodeInfo from '../../components/Tree/NodeInfo';
import ErrorAlert from '../ErrorAlert/ErrorAlert';
import { styles } from './styles';
import { setParameters } from '../../redux/actions/parametersActions';
import { fetchClades } from '../../redux/actions/cladeActions';
import ExportableComponent from '../Export/ExportableComponent';
import MetaInformations from '../../components/MetaInformations/MetaInformations';

const Intro = (props) => {
    const {
        lineage, regionId, modelId, colorBy, antigenicTiterType, antigenicDataType, tcellAntigenicityOptionsStatus, exportMode, zoomNodeId, strainSubset, exportParams, showRule, customTreeAttrId,
        gene, hla, strainCutOffDate, ignoreStrainCutOffDate, vpMethod, mutgene, mutposition, humanPool, humanSerologyDataType, strainId, modelRegionId, modelType, antigenicModelId, mutationsGroup,
        showCladeBar, showMutationsGroups, models,
        editMode, menuRight, hiddenMenuMobile, isMobile, renderStatus, hiddenMenu,

        shouldFetchModels,

        treeDataStatus, cladesStatus, modelsStatus, lineageStatus, lineagesStatus, antigenicModelStatus, antigenicObservedDataStatus, antigenicRawModelStatus, mutationsPositionsDictStatus, genotypeDataStatus,
        customMeasures, customTreeDataStatus, tcellStatus, vpMethodsStatus, mutationClassesStatus, vpValuesStatus, humanPoolsStatus, humanSerologyDataStatus, strainSearchLoading,
        modelStatus, modelTypesStatus, antigenicModelsStatus, mutationGroupValuesStatus,

        fetchModel, fetchLineages, fetchClades, initStrainTree, fetchAntigenicModel, fetchTCellAntigenicityOptions, fetchCustomTreeAttrs, fetchTCellAntigenicityScores, fetchVpMethods, fetchMutationClasses, fetchVpValues,
        fetchAntigenicObservedData, fetchGenotypeData, fetchMutationsPositionsDictionary, fetchMutationGroupValues,
        fetchAntigenicRawModel,
        fetchHumanPools, fetchHumanSerologyData, fetchModelTypes, 

        // lineage, regionId, modelId, colorBy, modelType,
        // tcellAntigenicityOptionsStatus, exportMode, zoomNodeId, strainSubset,
        // showRule, customTreeAttrId, strainCutOffDate, renderStatus,
        // ignoreStrainCutOffDate, vpMethod, treeDataStatus, cladesStatus, modelsStatus,
        // lineageStatus, mutationsPositionsDictStatus, fetchClades, strainId,
        // genotypeDataStatus, customMeasures, customTreeDataStatus, hiddenMenuMobile,
        // vpMethodsStatus, mutationClassesStatus, vpValuesStatus, modelRegionId,
        // mutgene, mutposition, initStrainTree, humanPool, humanSerologyDataType,
        // models,
        // fetchTCellAntigenicityOptions, fetchCustomTreeAttrs, fetchVpMethods, fetchMutationClasses,
        // fetchVpValues, fetchGenotypeData, fetchMutationsPositionsDictionary, hiddenMenu, isMobile, modelTypesStatus
    } = props;
    const classes = styles();

    const canRefetchData = treeDataStatus === 'loaded' && !strainSearchLoading && vpValuesStatus === 'loaded';


    const _fetchModel = async () => {
        // console.log(`
        // isColorByModel(${colorBy}) = ${isColorByModel(colorBy)}  
        // modelId = ${modelId} 
        // models.includes(modelId) = ${(models||[]).includes(modelId)}
        // modelType = ${modelType} 
        // modelRegionId = ${modelRegionId} 
        // exportMode = ${exportMode}
        // isLoadedOrNA(modelTypesStatus) = ${isLoadedOrNA(modelTypesStatus)}
        // modelsStatus = ${modelsStatus} 
        // canRefetchData = ${canRefetchData} 
        // isFetchNeeded(modelStatus) = ${isFetchNeeded(modelStatus)}
        // `)

        if (isColorByModel(colorBy)
            && modelId
            && (!shouldFetchModels || models.includes(modelId))
            && modelType
            && modelRegionId
            && (!shouldFetchModels || (isLoadedOrNA(modelTypesStatus) && modelsStatus === 'loaded'))
            && canRefetchData
            && isFetchNeeded(modelStatus)
        ) return fetchModel({ lineage, modelRegionId, strainSubset, modelId, zoomNodeId, modelType, colorBy });


        return null;
    }

    const _fetchTree = async () => {
        const genotypeParams = colorBy === 'genotype' ? { mutgene, mutposition } : {};
        const humanSerologyParams = colorBy === 'humanSerology' ? { humanPool, humanSerologyDataType } : {};
        if (isFetchNeeded(treeDataStatus)) {
            return initStrainTree({ lineage, /*modelId,*/ colorBy, zoomNodeId, strainSubset, showRule, strainCutOffDate, ignoreStrainCutOffDate, vpMethod, ...genotypeParams, strainId, ...humanSerologyParams, /*treeAttrsParams,*/ ...(exportParams || {}) })
         } 
         return null;
    }

    const _fetchClades = async () => {
        const cladesNeeded = colorBy === 'clade' || showCladeBar;
        if (isFetchNeeded(cladesStatus) && cladesNeeded) {
            return fetchClades({ lineage }) 
        };
        return null;
    }

    const _fetchVpValues = async () => {
        const initStrainTreeNeeded = isFetchNeeded(treeDataStatus) || isFetchNeeded(cladesStatus);
        const fetchVpValuesNeeeded = !initStrainTreeNeeded && isFetchNeeded(vpValuesStatus);
        if (fetchVpValuesNeeeded) 
            return fetchVpValues({ lineage, zoomNodeId, strainSubset, showRule, strainCutOffDate, vpMethod })
        return null;
    }

    const _fetchMutationGroupValues = async () => {
        if (showMutationsGroups && mutationsGroup && isFetchNeeded(mutationGroupValuesStatus) && treeDataStatus === 'loaded')
            return fetchMutationGroupValues({ lineage, mutationsGroup });
        return null;
    }

    const initComponentData = async () => {
        const initStrainTreeNeeded = isFetchNeeded(treeDataStatus) || isFetchNeeded(cladesStatus);
        const fetchVpValuesNeeeded = !initStrainTreeNeeded && isFetchNeeded(vpValuesStatus);
        // const treeAttrsParams = [].join(',');
        const canRefetchData = treeDataStatus === 'loaded' && vpValuesStatus === 'loaded';

        
        await Promise.all([
            // (isColorByModel(colorBy) && modelId && modelType && modelRegionId && isLoadedOrNA(modelTypesStatus) && modelsStatus === 'loaded' && canRefetchData && isFetchNeeded(modelStatus)) ? fetchModel({ lineage, modelRegionId, strainSubset, modelId, zoomNodeId, modelType, colorBy }) : null,
            _fetchModel(),
            _fetchTree(),
            // isFetchNeeded(treeDataStatus) ? initStrainTree({ lineage, modelId, colorBy, zoomNodeId, strainSubset, showRule, strainCutOffDate, ignoreStrainCutOffDate, vpMethod, mutgene, mutposition, strainId, humanPool, humanSerologyDataType, /*treeAttrsParams*/ }) : null,
            _fetchClades(),
            _fetchVpValues(),
            // isFetchNeeded(tcellAntigenicityOptionsStatus) && !exportMode ? fetchTCellAntigenicityOptions({ lineage }) : null,
            isFetchNeeded(mutationClassesStatus) ? fetchMutationClasses({ lineage }) : null,
            isFetchNeeded(customTreeDataStatus[colorBy]) && customMeasures[colorBy] && canRefetchData && !exportMode ? fetchCustomTreeAttrs({ lineage, colorBy, customTreeAttrId, strainSubset, showRule, zoomNodeId }) : null,
            // (colorBy === 'genotype' && isFetchNeeded(mutationsPositionsDictStatus)) ? fetchMutationsPositionsDictionary({ lineage }) : null,
            (colorBy === 'genotype' && isFetchNeeded(genotypeDataStatus) && mutgene && mutposition) ? fetchGenotypeData({ lineage, mutgene, mutposition, zoomNodeId }) : null,
            _fetchMutationGroupValues()
        ])
    }
    useEffect(() => {
        if (lineageStatus !== 'loaded') return;

        initComponentData();
    });


    return (
        <div className={isMobile ? classes.rootMobile : classes.root}>
            <ErrorAlert />
            <Grid container className={classes.container}>
                {isMobile ?
                    <>
                        {treeDataStatus &&
                            <TreeGraph />
                        }
                        {!hiddenMenuMobile &&
                            <TreeGraphOptions intro={true} />
                        }
                        <NodeInfo />
                    </>
                    :
                    <>
                        <Grid item xs className={classes.item}>
                            {lineageStatus === 'loaded' && (
                                <>
                                    <ExportableComponent filename="strainTree">
                                        <TreeGraph />
                                    </ExportableComponent>
                                    <MetaInformations />
                                </>
                            )
                            }
                        </Grid>
                        <Grid item className={`${classes.treeSidebar} ${hiddenMenu ? classes.hidden : ''}`}>
                            {!hiddenMenu && <NodeInfo />}
                            <TreeGraphOptions intro={true} />
                        </Grid>
                    </>
                }
            </Grid>
            {(renderStatus === RENDER_STATUS.DONE) && (
                <div id="exportDone" />
            )}
        </div>
    )
}

const mapStateToProps = (state) => {

    return ({
        treeDataStatus: state.treeData.treeDataStatus,
        cladeSchema: state.cladeData.cladeSchema,
        cladesStatus: state.cladeData.cladesStatus,
        modelStatus: state.modelData.modelStatus[state.parameters.colorBy],
        modelsStatus: state.models.modelsStatus[state.parameters.colorBy],
        modelTypesStatus: state.models.modelTypesStatus[state.parameters.colorBy] || 'NA',
        lineagesStatus: state.lineages.lineagesStatus,
        lineageStatus: state.lineages.lineageStatus,
        genotypeDataStatus: state.genotype.genotypeDataStatus,
        mutationsPositionsDictStatus: state.genotype.mutationsPositionsDictStatus,
        lineage: state.parameters.lineage,
        modelId: state.parameters.modelId,
        modelRegionId: state.parameters.modelRegionId,
        regionId: state.parameters.regionId,
        colorBy: state.parameters.colorBy,
        modelType: state.parameters.modelType,
        zoomNodeId: state.parameters.zoomNodeId,
        showCladeBar: state.parameters.showCladeBar,
        showMutationsGroups: state.parameters.showMutationsGroups,
        mutationsGroup: state.parameters.mutationsGroup,
        strainSubset: state.parameters.strainSubset,
        customTreeAttrId: state.parameters.customTreeAttrId,
        refClade: state.parameters.refClade,
        editMode: state.parameters.editMode,
        mutgene: state.parameters.mutgene,
        mutposition: state.parameters.mutposition,
        mutationGroup: state.parameters.mutationGroup,
        mutationGroupValuesStatus: state.genotype.mutationGroupValuesStatus,
        vpMethod: state.parameters.vpMethod,
        treeScaleTypeX: state.parameters.treeScaleTypeX,
        strainCutOffDate: state.parameters.strainCutOffDate,
        lineageStatus: state.lineages.lineageStatus,
        strainId: state.parameters.strainId,
        ignoreStrainCutOffDate: getIgnoreStrainCutOffDateForColorBy(state),
        clades: state.cladeData.clades,
        tcellAntigenicityOptionsStatus: state.metadata.tcellAntigenicityOptionsStatus,
        vpMethodsStatus: state.metadata.vpMethodsStatus,
        mutationClassesStatus: state.metadata.mutationClassesStatus,
        showRule: getShowRuleForColorBy(state),
        exportMode: state.parameters.exportMode,
        renderStatus: state.render.renderStatus, //getStrainTreeRenderStatus(state), //state.render.renderStatus,
        customTreeDataStatus: state.customTreeData.status,
        vpValuesStatus: state.treeData.vpValuesStatus,
        genotypeDataStatus: state.genotype.genotypeDataStatus,
        hiddenMenu: state.render.hiddenMenu,
        hiddenMenuMobile: state.render.hiddenMenuMobile,
        customMeasures: getCustomMeasures(state),
        isMobile: getIsMobile(),
        shouldFetchModels: shouldFetchModelsSelector(state), 
        lineages: state.lineages.lineages,
        humanPool: state.parameters.humanPool,
        humanSerologyDataType: state.parameters.humanSerologyDataType,
        models: state.models.models[state.parameters.colorBy],
    })
};

const mapDispatchToProps = dispatch => ({
    fetchModel: payload => {
        dispatch(setParameters(payload));
        dispatch(fetchModel(payload));
    },
    fetchModels: payload => dispatch(fetchModels(payload)),
    //fetchLineages: payload => dispatch(fetchLineages(payload)),
    //fetchLineage: payload => dispatch(fetchLineage(payload)),
    initStrainTree: payload => dispatch(initStrainTree(payload)),
    fetchCustomTreeAttrs: payload => dispatch(fetchCustomTreeAttrs(payload)),
    fetchVpValues: payload => dispatch(fetchVpValues(payload)),
    fetchMutationsPositionsDictionary: payload => dispatch(fetchMutationsPositionsDictionary(payload)),
    fetchGenotypeData: payload => dispatch(fetchGenotypeData(payload)),
    fetchMutationClasses: payload => dispatch(fetchMutationClasses(payload)),
    setParameters: payload => dispatch(setParameters(payload)),
    fetchClades: payload => dispatch(fetchClades(payload)),
    fetchMutationGroupValues: payload => dispatch(fetchMutationGroupValues(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(Intro);
