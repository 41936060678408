import React from 'react';
import { BrowserRouter, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import StrainTree from '../pages//StrainTree/StrainTree';
import Intro from '../pages//Intro/Intro';
import IntroFreq from '../pages//Intro/IntroFreq';
import Error404 from '../pages//Error404/Error404';
import Antigenic from '../pages//Antigenic/Antigenic';
import GeoMap from '../pages//GeoMap/GeoMap';
import App from '../App';
import Signin from '../components/Auth/SignIn';
import Frequencies from '../pages//Frequencies/Frequencies';
import RegionalFrequenciesReport from '../pages//RegionalFrequenciesReport/connect';
import { AdminPanel } from '../pages//AdminPanel/AdminPanel';
import { SettingsPanel } from '../pages//SettingsPanel/SettingsPanel';
import NewPassword from '../components/Auth/NewPassword/NewPassword';
import { history } from '../redux/configureStore';
import { connect } from 'react-redux';
import auth from '../functions/auth-helper';
import CustomRouter from './CustomRouter';
import config from '../config/envConfig';
import LegalNotice from '../pages/LegalNotice/LegalNotice';

const MainRouter = ({isAuthenticated, role}) => {
    const Redirecor = (path, location) => <Navigate to={{ pathname: `${path}`, state: { from: location } }} />;

    //console.log('isAuthenticated', isAuthenticated);
    return (
        <>
        <CustomRouter history={history}>
            <App>
                <Routes>
                    <Route   path={`${config.frontendPrefix}/signin`}      Component={(props) => !isAuthenticated ? <Signin/> : Redirecor(`${config.frontendPrefix}/`, props.location)} exact />
                    <Route   path={`${config.frontendPrefix}/intro`}       Component={Intro}         exact />
                    <Route   path={`${config.frontendPrefix}/intro-freq`}  Component={IntroFreq}     exact />
                    <Route   path={`${config.frontendPrefix}/newPassword`} Component={NewPassword}   exact/>
                    <Route   path={`${config.frontendPrefix}/intro-map`}   Component={GeoMap}        exact />
                    <Route   path={`${config.frontendPrefix}/notice`}      Component={LegalNotice}   exact />
                    <Route   path={`${config.frontendPrefix}/`}            Component={(props) => isAuthenticated ? <StrainTree/> : Redirecor(`${config.frontendPrefix}/intro`, props.location)}  exact />
                    <Route   path={`${config.frontendPrefix}/antigenic`}   Component={(props) => isAuthenticated ? <Antigenic/> : Redirecor(`${config.frontendPrefix}/intro`, props.location)}   exact />
                    <Route   path={`${config.frontendPrefix}/geomap`}      Component={(props) => isAuthenticated ? <GeoMap/> : Redirecor(`${config.frontendPrefix}/intro`, props.location)}      exact />
                    <Route   path={`${config.frontendPrefix}/frequencies`} Component={(props) => isAuthenticated ? <Frequencies/> : Redirecor(`${config.frontendPrefix}/intro`, props.location)} exact />
                    <Route   path={`${config.frontendPrefix}/settings`}    Component={(props) => isAuthenticated ? ( true /*role === 'admin' */ ? <SettingsPanel/> : Redirecor(`${config.frontendPrefix}/`, props.location)) : Redirecor('/intro', props.location)} exact />
                    <Route   path={`${config.frontendPrefix}/manage`}      Component={(props) => isAuthenticated && role === 'admin' ? <AdminPanel/> : Redirecor(`${config.frontendPrefix}/intro`, props.location)} exact />
                    <Route
                        path={`${config.frontendPrefix}/export/frequencies/`} 
                        Component={Frequencies}
                        exact
                    />
                    <Route
                        path={`${config.frontendPrefix}/export/strainTree/`} 
                        Component={StrainTree}
                        exact
                    />
                    <Route
                        path={`${config.frontendPrefix}/export/Antigenic/`}     
                        Component={Antigenic}
                        exact
                    />
                    <Route
                        path={`${config.frontendPrefix}/export/regionalFrequenciesReport/`}  
                        Component={RegionalFrequenciesReport}
                        exact
                    />
                     <Route
                        path={`${config.frontendPrefix}/export/geomap/`}  
                        Component={GeoMap}
                        exact
                    />
                    <Route path='/*' Component={Error404} />
                </Routes>
            </App>
        </CustomRouter>
        </>
    )
};

const mapStateToProps = ({ user }) => {
    return ({
        isAuthenticated: auth.isAuthenticated(),
        role: user.role,
    });
};

export default connect(mapStateToProps)(MainRouter);
