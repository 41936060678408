import React from 'react';
import { Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, Button } from '@mui/material';
import { styles, dialog } from './styles';


const Info = (props) => {
    const { handleCloseDialog, openDialog, info, action } = props;
    const classes = styles();

    return (
        <Dialog open={openDialog} onClose={handleCloseDialog} sx={dialog}>
            <DialogTitle id="confirm-dialog-title">
                {action}
            </DialogTitle>

            <DialogContent>
                <DialogContentText>
                    {info}
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button className={classes.confirm} onClick={handleCloseDialog}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>);
};

export default Info;
