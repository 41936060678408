import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import { prepareUrl } from '../../functions/functions';
import {
    FETCH_ANTIGENIC_MODEL_REQUEST,
    // FETCH_ANTIGENIC_MODELS_LIST_REQUEST,
    FETCH_ANTIGENIC_RAW_MODEL_REQUEST,
    FETCH_ANTIGENIC_OBSERVED_DATA_REQUEST
} from '../actions/actionTypes';
import {
    fetchAntigenicModelsListSuccess,
    fetchAntigenicModelSuccess,
    fetchAntigenicModelError,
    fetchAntigenicRawModelSuccess,
    fetchAntigenicRawModelError,
    fetchAntigenicObservedDataSuccess,
    fetchAntigenicObservedDataError,
    fetchAntigenicModelsListError
} from '../actions/antigenicActions';
import config from '../../config/envConfig';
import { fetchAxios } from '../../functions/axiosRequests';
import { sessionTimeOut } from './helpers/functions';

export const fetchAntigenicModelEpic = action$ => action$.pipe(
    ofType(FETCH_ANTIGENIC_MODEL_REQUEST),
    mergeMap(action => {
        const {
            lineage,
            antigenicModelId,
            antigenicDataType,
            antigenicTiterType,
        } = action.payload;
        const url = prepareUrl(`${config.serverLink}/api/getAntigenicModel`, {
            lineage,
            antigenicModelId,
            antigenicDataType,
            antigenicTiterType,
        });
        //console.log(`[fetchAntigenicModelEpic]: url = ${url}, antigenicDataType = ${antigenicDataType}`);

        return fetchAxios(url).then(function (response) {
            return fetchAntigenicModelSuccess(response.data)
        }).catch(function (error) {
            const { status, data } = error.response;

            if (status === 408)
                return sessionTimeOut(error);

            return fetchAntigenicModelError({ error: data.error, errorWebsiteText: 'An error has occured during downloading models.', errorWebsiteType: 'fetch'});
        })
    })
);

// export const fetchAntigenicModelsListEpic = action$ => action$.pipe(
//     ofType(FETCH_ANTIGENIC_MODELS_LIST_REQUEST),
//     mergeMap(action => {
//         const {
//             lineage,
//             settings
//         } = action.payload;
//         const url = prepareUrl(`${config.serverLink}/api/antigenicModels`, { lineage });

//         return fetchAxios(url).then(function (response) {
//             return fetchAntigenicModelsListSuccess({...response.data, settings })
//         }).catch(function (error) {
//             const { status, data } = error.response;
//             if (status === 408)
//                 return sessionTimeOut(error);

//             return fetchAntigenicModelsListError({ error: data.error, errorWebsiteText: 'An error has occured during downloading models list.', errorWebsiteType: 'fetch'});
//         })
//     })
// );

export const fetchAntigenicRawModelEpic = action$ => action$.pipe(
    ofType(FETCH_ANTIGENIC_RAW_MODEL_REQUEST),
    mergeMap(action => {
        const {
            lineage,
            antigenicModelId,
            antigenicDataType,
            antigenicTiterType,
        } = action.payload;
        const url = prepareUrl(`${config.serverLink}/api/getAntigenicRawModel`, {
            lineage,
            antigenicModelId,
            antigenicDataType,
            antigenicTiterType,
        });

        return fetchAxios(url).then(function (response) {
            return fetchAntigenicRawModelSuccess(response.data)
        }).catch(function (error) {
            const { status, data } = error.response;
            if (status === 408)
                return sessionTimeOut(error);

            return fetchAntigenicRawModelError({ error: data.error, errorWebsiteText: 'An error has occured during downloading raw models.', errorWebsiteType: 'fetch'});
        })
    })
);


export const fetchAntigenicObservedDataEpic = action$ => action$.pipe(
    ofType(FETCH_ANTIGENIC_OBSERVED_DATA_REQUEST),
    mergeMap(action => {
        const {
            lineage,
            antigenicModelId
        } = action.payload;
        const url = prepareUrl(`${config.serverLink}/api/getAntigenicObservedData`, {
            lineage,
            antigenicModelId
        });
       // console.log(`[fetchAntigenicObservedDataEpic]: url = ${url}`);

        return fetchAxios(url).then(function (response) {
            return fetchAntigenicObservedDataSuccess(response.data)
        }).catch(function (error) {
            const { status, data } = error.response;
            if (status === 408) return
                sessionTimeOut(error);

            return fetchAntigenicObservedDataError({ error: data.error, errorWebsiteText: 'An error has occured during downloading observed data.', errorWebsiteType: 'fetch'});
        })
    }),
);
