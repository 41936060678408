import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import { FormControl, InputLabel, MenuItem } from '@mui/material';
import { bindActionCreators } from 'redux';
import CustomSelect from '../../../assets/GlobalStyles/CustomSelect';
import {setColoring} from '../../../redux/actions/geomapActions';

const styles = () => ({
    formControl: {
        minWidth: 120,
        margin: '8px 0px',
    },
});

class GeoFreqCategorySelector extends PureComponent {

    handleColoringChange = async event => {
        const { setColoring } = this.props;
        setColoring( {coloring: event.target.value})
    }

    render = () => {
        const { coloring, classes } = this.props;
        const coloringOptions = [
            {
                key: 'count-key',
                value: 'count',
                label: 'Sequences'
            },
            {
                key: 'multiplicity-key',
                value: 'multiplicity',
                label: 'Cases'
            }
        ];


        return (
            <FormControl className={classes.formControl} fullWidth>
                    <InputLabel htmlFor="frequenciesCategory">
                        Frequencies category
                    </InputLabel>
                    <CustomSelect
                        value={coloring}
                        onChange={this.handleColoringChange}
                        inputProps={{
                            name: 'frequenciesCategory',
                            id: 'frequenciesCategory',
                        }}
                    >
                        {coloringOptions.map((cB) => (
                                <MenuItem key={cB.key} value={cB.value}> {cB.label}</MenuItem>
                        ))}
                    </CustomSelect>
                </FormControl>
        );
    }
}

GeoFreqCategorySelector.propTypes = {
    coloring: PropTypes.string,
    setColoring: PropTypes.func,
    classes: PropTypes.shape({ formControl: PropTypes.string })

}
const mapStateToProps = ({ geomap }) => ({
    coloring: geomap.coloring
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setColoring
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GeoFreqCategorySelector));
