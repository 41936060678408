const checkIfValuesGrowing = (domain) => {
    let isDomainValid = true;
    let prevValue = 0;
    let lastIndex = domain.length - 1;

    for (const [index, element] of domain.entries()){
        if ( index === 0 || lastIndex === 0){
            prevValue = element.value;
            continue;
        };

        if (element.value <= prevValue)
            isDomainValid = false;
    };

    return isDomainValid;
}


const checkDomainDiscrete = (domain) => {
    const normalDomain = [];
    const quantileDomain = [];
    const lastIndex = domain.length - 1;

    for (const [index, element] of domain.entries()){
        if ( !element.value )
            return false;


        if ( index === lastIndex)
            continue;

        element.value = parseFloat(element.value);

        if ( element.quantile )
            quantileDomain.push(element);
        else
            normalDomain.push(element);
    };

    const normalDomainValid = checkIfValuesGrowing(normalDomain);
    const quantileDomainValid = checkIfValuesGrowing(quantileDomain);

    return normalDomainValid && quantileDomainValid;
}

const checkDomainLinear = (domain) => {
    const lastIndex = domain.length - 1;
    const ableTocheck = domain[0].quantile === domain[lastIndex].quantile;

    if ( !domain[0].value || !domain[lastIndex].value )
        return false;

    if (!ableTocheck)
        return true;

    const first = parseFloat(domain[0].value);
    const last = parseFloat(domain[domain.length - 1].value);

    if ( first >= last)
        return false;
    else
        return true;
}

const checkId = (scaleId, errorInitState) => {
    const elements = scaleId.split('.')
    const length = elements.length;
    const newErrorState = { ...errorInitState };
    let valid = true;

    if ( length === 3 ){
        if ( !elements[0]){
            newErrorState.scaleType = { status: true, message: 'It cannot be empty' };
            valid = false;
        }
        if ( !elements[1]){
            newErrorState.antigenicScaleType = { status: true, message: 'It cannot be empty' };
            valid = false;
        }
        if ( !elements[2]){
            newErrorState.id = { status: true, message: 'It cannot be empty' };
            valid = false;
        }
    } else {
        if ( !elements[0]){
            newErrorState.scaleType = { status: true, message: 'It cannot be empty' };
            valid = false;
        }
        if ( !elements[1]){
            newErrorState.id = { status: true, message: 'It cannot be empty' };
            valid = false;
        }
    }

    return { validId: valid, newErrorState };
}

const spliceScaleId = (scaleId) => {
    const elements = scaleId.split('.')
    const length = elements.length;

    if ( length === 3 ) {
        return {
            measure: elements[0],
            additionalMeasure: elements[1],
            idString: elements[2]
        }
    } else {
        return {
            measure: elements[0],
            idString: elements[1]
        }
    }
}

export { checkDomainDiscrete, checkDomainLinear, checkId, spliceScaleId }
