import { hierarchy, tree } from 'd3-hierarchy';
import { select, selectAll } from 'd3-selection';
import { color } from 'd3-color';
import { path } from 'd3-path';
import { pointRadial, symbol, symbols } from 'd3-shape';
// eslint-disable-next-line no-unused-vars
import { transition } from 'd3-transition';
import { drawDateAxis, hideAxesLayer } from './dateAxis';
import { vaccineIcon, reassortmentIcon, referenceStrainIcon, referenceStrainsIcon } from './svgshapes/icons';
import d3Tip from 'd3-tip';
import {
    initTreeScales,
    getScaledValue,
    resetScales,
} from '../../../functions/scales';
import { emptyObject, isNumeric, getTrimmedText, degreesToRadians } from '../../../functions/functions';
import { ZOOM_TYPES } from '../../../config/consts';
import appConfig from '../../../config/appConfig';
import D3Component from '../../D3Component/D3Component';
import { LAYOUT } from '../../../config/dictionaries';
//import LabelsD3 from './LabelsD3';
import { getNodeHeight, getNodeWidth } from '../../../functions/cssHelpers';
import { getSymbolPathForNode } from './symbolSigns';
import { isNumber } from 'lodash';

const duration = 1000;
const missingDataColor = '#dee0e2';
const rootCladeColor = '#a8a8a8';
//let globalCladesPadding = 0;

class TreeD3 extends D3Component {
    vaccineSvg = '';
    // cladeBarPadding = 200;
    margin = { top: 0, bottom: 0, left: 0, right: 0 };
    defaultPadding = { top: 0, bottom: 32, left: 0, right: 0 };
    padding = { ...this.defaultPadding };
    defaultPaddingExport = { top: 0, bottom: 18, left: 0, right: 10 };
    paddingExport = { ...this.defaultPaddingExport };
    root = null;
    fontSizeClades = 12;
    cladeSectionMarginLeft = 30;
    cladeSectionPlacing = 7;
    strainNamesPadding = 0;
    treeWidth = this.width;
    visibleTree = null;

    componentName = 'tree';

    //labels = new LabelsD3();

    setProps = (props) => {
        this.props = props; //{ ...props };
        // console.log(props)
        //this.labels.setTree(this);
    };

    setProp = (key, value) => {
        this.props[key] = value;
        // this.labels.setTree(this);
    };

    _xScale = () => `x${this.props.treeScaleTypeX}Scale`;

    xScale = () => this._xScale();

    _yScale = () => (emptyObject(this.props) ? 'yorderScale' : `y${this.props.treeScaleTypeY}Scale`);

    yScale = () => this._yScale();

    // eslint-disable-next-line no-unused-vars
    highlightStrain = (d) => {
        const { highlightedStrainIds } = this.props;
        return highlightedStrainIds[d.data.id];
    };

    nodeRadius = 4;

    rootRadius = (d) => {
        const ret = this.props.exportMode
            ? this.highlightStrain(d)
                ? 6
                : this.nodeRadius //Export settings [highlight : normal]
            : this.highlightStrain(d)
                ? 8
                : this.nodeRadius; //Website settings [highlight : normal]
        // if (this.highlightStrain(d)) console.log(`this.props.exportMode = ${this.props.exportMode}, ${d.data.id} => ${ret}`);
        return ret;
    };

    x = (d) => {
        const { treeAttrs, treeScaleTypeX, ranges } = this.props;
        const _id = isNumber(d) ? d : d.data.id;
        if (!treeAttrs[_id]) return null;
        const val =
            Math.max(
                Math.min(treeAttrs[_id][treeScaleTypeX], ranges.max[treeScaleTypeX]),
                ranges.min[treeScaleTypeX],
            );
        return this._getXValue(val);
    };

    y = (d) => {
        const { treeAttrs, treeScaleTypeY, ranges } = this.props;
        const _id = isNumber(d) ? d : d.data.id;
        // console.log('treeScaleTypeY', treeScaleTypeY, 'max = ', ranges.max[treeScaleTypeY] );
        if (!treeAttrs[_id]) return null;
        const val =
            //treeScaleTypeY === 'time'
            //? treeAttrs[d.data.id].time
            //:
            Math.max(
                Math.min(treeAttrs[_id][treeScaleTypeY], ranges.max[treeScaleTypeY]),
                ranges.min[treeScaleTypeY],
            );

        return this._getYValue(val || 0);
    };

    yOrder = (val) => {
        return getScaledValue('yorderScale', val);
    };

    radius = (d) => {
        const { treeAttrs, ranges, treeScaleTypeX } = this.props;
        const _id = isNumber(d) ? d : d.data.id;
        const xScaleName =`x${treeScaleTypeX}RadialScale`;

        const val =
            Math.max(
                Math.min(treeAttrs[_id][treeScaleTypeX], ranges.max[treeScaleTypeX]),
                ranges.min[treeScaleTypeX],
            );
        return getScaledValue(xScaleName, val);
    }

   


    angle = (d) => {
        const { treeAttrs, ranges, treeScaleTypeY } = this.props;
        const _id = isNumber(d) ? d : d.data.id;
        const yScaleName = `y${treeScaleTypeY}RadialScale`;
        const val =
            Math.max(
                Math.min(treeAttrs[_id][treeScaleTypeY], ranges.max[treeScaleTypeY]),
                ranges.min[treeScaleTypeY],
            );
        // console.log('angle', yScaleName, _id, val, getScaledValue(yScaleName, val), getScale(yScaleName).range())
        return getScaledValue(yScaleName, val);
    }





    transitionGrayOut = (nodeType) => (selection) => {
        const minTime = this.props.ranges.min.time;
        const { treeAttrs, exportMode } = this.props;
        console.log(`[transitionGrayOut]: nodeType = ${nodeType}, exportMode = ${exportMode}`);
        return new Promise((resolve) => {
            selection
                .transition()
                .delay(
                    (d) =>
                        (treeAttrs[d.data.id].time - minTime) * appConfig.animationDelay +
                        (365 / 2) * appConfig.animationDelay,
                )
                .style('fill', nodeType === 'leaf' ? missingDataColor : 'none')
                .style('stroke', nodeType === 'leaf' ? color(missingDataColor).darker().formatHex() : missingDataColor)
                .on('end', resolve)
                .end()
                .then(() => {
                    selection
                        .transition()
                        .delay(exportMode ? 0 : duration)
                        .style('fill', nodeType === 'leaf' ? this.nodeColor() : 'none')
                        .style('stroke', (d) =>
                            nodeType === 'leaf' ? color(this.nodeColor()(d)).darker().formatHex() : this.nodeColor()(d),
                        );
                    // if (nodeType === 'leaf') {
                    //     setTimeout(() => this.drawCladeLabels(select('#cladeLabels')), duration);
                    // }
                });
        });
    };

    translate = (layoutDependent) => {
        if (!this.props) return `translate(${0},${0})`;
        const { layout } = this.props;
        const curX =
            !layout || layout === LAYOUT.TREE.value || !layoutDependent
                ? this.getPadding().left
                : this.getPadding().left + this.fanLayoutRadius();
        const curY =
            !layout || layout === LAYOUT.TREE.value || !layoutDependent
                ? this.getPadding().top
                : this.getPadding().top + this.fanLayoutRadius();

        return `translate(${curX},${curY})`;
    };

    prepareGraphElements = () => {
        //console.log('[treeD3.prepareGraphElements]');
        const svg = select(this.mountNode);

        // const axesSvg = graphSvg.append('g').attr('id', 'axes');
        const treeAxis = svg.select('#treeAxis rect'); //axesSvg.append('g').attr('id', 'treeAxis');

        treeAxis
            // .append('rect')
            // .attr('id', 'treeAxis_background')
            // .attr('pointer-events', 'all')
            //.style('fill', '#D9D9D9')
            .style('fill', '#FFFFFF')
            //.style('stroke', '#000000')
            //.style('stroke-width', 0.5)
            //.style('stroke-opacity', 0.3)
            .on('dblclick', this.processZoom(ZOOM_TYPES.RESET_LAYOUT))
            // .on('click', this.processZoom(ZOOM_TYPES.ZOOM_OUT));
            .on('click', () => {
                // console.log(`rect click, this.props.nodeId = ${this.props.nodeId}`);
                this.props.setSearchStrainMode(false);
                select('#selectedNode').remove();
                this.selectedNode = null;
                if (this.props.nodeId) {
                    this.props.selectNodeData();
                } else this.processZoom(ZOOM_TYPES.ZOOM_OUT)();
                //this.selectedNode = null;
            });

        const fanAxis = svg.select('#fanAxis rect'); //axesSvg.append('g').attr('id', 'fanAxis');
        fanAxis
            // .append('rect')
            // .attr('id', 'fanAxis_background')
            // .attr('pointer-events', 'all')
            .style('fill', '#FFFFFF')
            .style('stroke', '#000000')
            .style('stroke-width', 0.5)
            .style('stroke-opacity', 0.3)
            .on('dblclick', this.processZoom(ZOOM_TYPES.RESET_LAYOUT))
            .on('click', () => {
                // console.log(`[click node]`, selectableNode(nodeType), this.selectedNode);
                this.props.setSearchStrainMode(false);
                select('#selectedNode').remove();
                this.selectedNode = null;
                if (this.props.nodeId) {
                    this.props.selectNodeData();
                } else this.processZoom(ZOOM_TYPES.ZOOM_OUT)();
            });
    };

    transformStrains = (d) => {
        const { layout } = this.props;

        //if (d.data.id === 39042) console.log(`[transformStrains]: ${d.data.id}`);
        if (layout === LAYOUT.TREE.value) {
            const xPos = this.x(d);
            const yPos = this.y(d);
            if (xPos && yPos) {
                const point = `${this.x(d)},${this.y(d)}`;
                return `translate(${point})`;
            }
            return `translate(0,0)`;
        }
        const fanPoint = pointRadial(this.angle(d), this.radius(d)).map((p) => p + this.fanLayoutRadius());
        // console.log(fanPoint);
        return `translate(${fanPoint})`;
    };

    xBranch = (d) => {
        const { treeAttrs } = this.props;
        const _id = isNumber(d) ? d : d.data.id;

        const parent = treeAttrs[_id].p || _id; //d.parent || d;
        // if (!parent) return 'translate(0,0)';
        const xM = (this.x(_id) + this.x(parent)) / 2;
        return xM;
    };

    transformBranchNodes = (d) => {
        const { layout } = this.props;

        const xM = this.xBranch(d);
        if (layout === LAYOUT.TREE.value) {
            return `translate(${xM},${this.y(d)})`;
        }
        const fanPoint = pointRadial(this.angle(d), this.radius(d)).map((p) => p + this.fanLayoutRadius());
        // console.log(fanPoint);
        const angle = this.angle(d) * (180 / Math.PI);
        return `translate(${fanPoint}) rotate(${angle})`;
    };

    transformMutationsLabels = (d) => {
        const { parent } = d;
        if (!parent) return `translate(${this.x(d)},${this.y(d) - 5})`;
        const xM = (this.x(d) + this.x(parent)) / 2;
        return `translate(${xM},${this.y(d) - 5})`;
    };

    transformNodes = (nodeType) => (d) => {
        // if (!this.props.measuresLoaded) return;
        if (nodeType === 'branch' || nodeType === 'mutation') return this.transformBranchNodes(d);
        // if (nodeType === 'internal') return this.transformInternalNodes(d);
        if (nodeType === 'epitopeMutationGroup') return this.transformMutationsLabels(d);
        return this.transformStrains(d);
    };

    transformNodeSymbols = (nodeType) => (d) => {
        const { layout } = this.props;
        if (nodeType === 'internal') {
            if (layout === LAYOUT.FAN.value) {
                const angle = this.angle(d) * (180 / Math.PI);
                return `translate(0, 0) rotate(${angle - 180})`;
            }
            return `translate(-${3}, 0) rotate(-90)`;
        }
        if (nodeType === 'branch' || nodeType === 'mutation' || nodeType === 'mutationClass')
            return this.transformBranchNodes(d);
        if (nodeType === 'epitopeMutationGroup') return this.transformMutationsLabels(d);
        return '';
    };

    diagonal = (d) => {
        const { treeAttrs, layout, treeScaleTypeY } = this.props;

        const getNodeFreqMid = (node) =>
            (node && treeAttrs[node.data.id].frequency
                ? getScaledValue('freqScale', treeAttrs[node.data.id].frequency)
                : 1) * 0.5;


        const treeDiagonal = (parent, source, firstChild, lastChild) => {
            const parentMid = getNodeFreqMid(parent);
            const firstChildMid = getNodeFreqMid(firstChild);
            const lastChildMid = getNodeFreqMid(lastChild);
            const xP = parent ? this.x(parent) - parentMid : null; // - mid : null;
            const xS = this.x(source);
            const yS = this.y(source);
            if ((parent && !xP) || !xS || !yS) return null;

            const yFC = firstChild ? this.y(firstChild) - firstChildMid : null;
            const yLC = lastChild ? this.y(lastChild) + lastChildMid : null;

            const parentPath = parent ? `M ${xP} ${yS} ${xS} ${yS}` : '';
            const childrenPath = firstChild && lastChild ? `M ${xS} ${yFC} ${xS} ${yLC}` : '';
            return `${parentPath} ${childrenPath}`; // ${postPath}`;
        };

        const fanDiagonal = (parent, source, firstChild, lastChild) => {
            // console.log(source.data.id, layout, parent ? this.radius(parent) : 0)
            
            const radVal90 = degreesToRadians(90);

            const cP = pointRadial(0, 0);
            const mid = getNodeFreqMid(source);
            const pp = parent ? pointRadial(this.angle(source), this.radius(parent) - mid) : null;
            const ps = pointRadial(this.angle(source), this.radius(source));

            const linkPath = path();
            if (parent) {
                linkPath.moveTo(pp[0], pp[1]);
                linkPath.lineTo(ps[0], ps[1]);
                // linkPath.arc(cP[0], cP[1], mid, this.angle(source) - radVal90, this.angle(source) - radVal90);
            }
            if (firstChild && lastChild) {
                const radius = Math.max(this.radius(source), 0);
                const sAngle = this.angle(source);
                const fAngle = this.angle(firstChild);
                const lAngle = this.angle(lastChild);

                const fromAngle = Math.min(sAngle, fAngle, lAngle);
                const toAngle = Math.max(sAngle, lAngle, lAngle);
                const pFC = firstChild ? pointRadial(fromAngle, this.radius(source)) : null;
                linkPath.moveTo(pFC[0], pFC[1]);
                linkPath.arc(cP[0], cP[1], radius, fromAngle - radVal90, toAngle - radVal90);
            }

            return linkPath.toString();
        };

        const linkDiagonal = (parent, source) => {
            const getMidPoint = (p, s) => {
                const x1 = this.x(p);
                const y1 = this.y(p);
                const x2 = this.x(s);
                const y2 = this.y(s);
                return { x: x1 + (x2 - x1) / 2, y: y1 + (y2 - y1) / 2 };
            };

            const xS = this.x(source);
            const yS = this.y(source);
            let parentPath = '';
            if (parent) {
                const parentMidPoint = getMidPoint(parent, source);
                parentPath = `M${parentMidPoint.x},${parentMidPoint.y}L${xS},${yS}`;
            }
            const childrenPath = source.children
                ? source.children
                    .filter((child) => this.props.treeAttrs[child.data.id])
                    .map((child) => {
                        const midPoint = getMidPoint(source, child);
                        return `M${xS},${yS}L${midPoint.x},${midPoint.y}`;
                    })
                    .join()
                : '';
            return `${parentPath}${childrenPath}`;
        };

        const s = d.parent;

        if (treeScaleTypeY !== 'order' && layout === LAYOUT.TREE.value)
            return linkDiagonal(s, d);

        const children = d.children ? d.children.filter((c) => treeAttrs[c.data.id]) : null;
        //if (d.children && d.children.length !== children.length) console.log(`!!!!! dif, ${node.data.id}`)
        const { firstChild, lastChild } = (children || [])
            .reduce((_children, child) => {
                const childVal = this.y(child);
                if (!emptyObject(childVal)) {
                    if (!_children.firstChild || childVal < this.y(_children.firstChild)) _children.firstChild = child;
                    if (!_children.lastChild || childVal > this.y(_children.lastChild)) _children.lastChild = child;
                }
                return _children;
            }, { firstChild: null, lastChild: null });

        // if (firstChild && lastChild && firstChild !== lastChild)
        // console.log('first last children => ', this.y(firstChild), this.y(lastChild));
        // if (d?.data?.id == 44267) // || s?.data?.id == 44267) 
        //     console.log(s, d, firstChild, lastChild)
        return layout === LAYOUT.TREE.value ?
            treeDiagonal(s, d, firstChild, lastChild)
            : fanDiagonal(s, d, firstChild, lastChild);
    };


    advanceValue = (node, defaultValue) => {
        // eslint-disable-next-line no-shadow
        const { treeAttrs } = this.props;
        if (treeAttrs[node.data.id]) return treeAttrs[node.data.id].advance || 0; // - (treeAttrs[tree.id].advance || 0);
        return defaultValue;
    };

    cladeColor = (node) => {
        const { treeAttrs, clades, cladeType } = this.props;
        const clade = treeAttrs[node.data.id]?.clade;
        const cladeId = cladeType === 'clade' ? clade : clades[clade]?.cladeMapping?.[cladeType]?.alpha;
        return cladeId && clades[cladeId] ? clades[cladeId].color : missingDataColor;
    };

    locColor = (node) => {
        const { treeAttrs, regions } = this.props;
        const loc = treeAttrs[node.data.id]?.loc;
        return loc && regions[loc] ? regions[loc].color || color(missingDataColor).darker(1).hex() : missingDataColor;
    };

    genotypeColor = (node) => {
        const { treeAttrs, genotypeLegend } = this.props;
        const value = treeAttrs[node.data.id].genotype;
        return value && genotypeLegend[value]
            ? genotypeLegend[value].color || color(missingDataColor).darker(1).hex()
            : missingDataColor;
    };

    antigenicValue = (node) => {
        const { clades, antigenicModel, antigenicDataType, treeAttrs, cladesStatus } = this.props;

        if (antigenicDataType === 'epitope_clades' || antigenicDataType === 'raw_strain' /*|| antigenicDataType === 'observed_strain'*/) {
            return antigenicModel[node.data.id];
        }

        //if (emptyObject(clades)) return null;
        if (cladesStatus !== 'loaded') return null;
        const nodeClade = treeAttrs && treeAttrs[node.data.id] ? treeAttrs[node.data.id].clade : null;
        const nodeAlpha = nodeClade && clades[nodeClade] ? clades[nodeClade].cladeMapping.antigenic_clade.alpha : null;
        //console.log('nodeAlpha = ',nodeAlpha, cladesStatus);
        const retValue = //emptyObject(nodeAlpha) ? null :
             antigenicModel?.[nodeAlpha];

        //    const retValue = //emptyObject(nodeAlpha) ? null :
          //  antigenicModel?.[nodeClade];
        return retValue;
    };

    mutationsCount = (node) => {
        const { treeAttrs, mutationsType } = this.props;
        const NS = treeAttrs[node.data.id].NS;
        const S = treeAttrs[node.data.id].S;
        const _NS = NS || 0;
        const _S = S || 0;
        const val = mutationsType === 'NS' ? _NS : mutationsType === 'S' ? _S : _S + _NS;
        return val;
    }
    mutationColor = (node) => {
        const { mutationsType } = this.props;
        const val = this.mutationsCount(node);

        const scaleName = `${mutationsType}MutationsColorScale`;
        //console.log(node.data.id, val, getScaledValue(scaleName, val) );
        return val > 0 ? getScaledValue(scaleName, val) || '#2f2f2f' : missingDataColor;
    };

    getValue = (node, attrId) => {
        const { treeAttrs, colorBy, measures } = this.props;
        const _colorBy = attrId || measures[colorBy].value || colorBy;
        //console.log('getValue', node.data.id, )
        if (_colorBy === 'advance') return this.advanceValue(node, measures[colorBy].defaultValue);
        if (_colorBy === 'antigenic') return this.antigenicValue(node);
        if (_colorBy instanceof Array) {
            return _colorBy.reduce((acc, k) => {
                if (treeAttrs[node.data.id][k]) acc = treeAttrs[node.data.id][k] + (acc || 0);
                return acc;
            }, undefined);
        } else if (!treeAttrs[node.data.id] || treeAttrs[node.data.id][_colorBy] === undefined)
            return measures[_colorBy] ? measures[_colorBy].defaultValue : null;
        else return treeAttrs[node.data.id][_colorBy];
    };

    _nodeColor = (node, attrId) => {
        const { colorBy } = this.props;

        let nColor = missingDataColor;
        const _colorBy = attrId || colorBy;

        if (_colorBy === 'clade') nColor = this.cladeColor(node);
        else if (_colorBy === 'loc') nColor = this.locColor(node);
        else {
            const val = this.getValue(node, attrId);
            const scaleName = `${_colorBy}ColorScale`;
            const valColor = getScaledValue(scaleName, val) || missingDataColor;
            nColor = color(val !== undefined && valColor !== '#FFFFFF' ? valColor : missingDataColor).formatHex();
        }

        return nColor;
    };

    nodeColor = (nodeType, attrId) => (node) => {
        if (nodeType !== 'selectedNode' && this.props.searchStrainMode) {
            return color(missingDataColor).formatHex();
        }
        if (nodeType === 'mutation') return this.mutationColor(node);

        const nColor = this._nodeColor(node, attrId);
        const ret = color(nColor).formatHex();
        //console.log(node.data.id, ret)
        return ret;
    };

    strokeColor = (nodeType, attrId) => (node) => {
        if (this.highlightStrain(node)) return '#FFFFFF';
        const nodeColor = this.nodeColor(nodeType, attrId)(node);
        if (!nodeColor) {
            console.log(`${node.data.id}, ${nodeColor}`);
            return '#000000';
        }
        return color(nodeColor).darker().formatHex();
    };

    linkColor = (node) => {
        const { colorBy } = this.props;
        if (colorBy === 'source') return color(missingDataColor).darker(0.5).formatHex();
        return this.nodeColor()(node);
    };


    nodeFrequency = (node) => this.props.treeAttrs[isNumeric(node) ? node : node.data.id]?.frequency;

    processZoom = (zoomType) => async (d) => {
        const { zoomNodeStack, treeAttrs, selectZoomNode, lineage, modelId, modelRegionId, strainSubset, colorBy, strainHighlight } = this.props;
        // Set styles
        // already zoomed-out to root
        if ((zoomType === ZOOM_TYPES.RESET_LAYOUT || zoomType === ZOOM_TYPES.ZOOM_OUT) && zoomNodeStack.length <= 1)
            return;
        if (zoomType === ZOOM_TYPES.ZOOM_IN) {
            const zoomNode = zoomNodeStack[zoomNodeStack.length - 1];
            const zoomNodeTime = zoomNode ? treeAttrs[this.props.zoomNodeId] : 0;
            if (!d.children || zoomNodeTime >= treeAttrs[d.data.id].time) return;
        }
        const prevNodeId = zoomNodeStack[Math.max(zoomNodeStack.length - 2, 0)];
        const zoomNodeId = zoomType === ZOOM_TYPES.ZOOM_IN ? d.data.id : prevNodeId;


        await selectZoomNode({ colorBy, zoomType, zoomNodeId, lineage, modelRegionId, modelId, strainSubset, strainHighlight });
    };

    renderFullTree = () => !this.props.zoomNodeStack || this.props.zoomNodeStack.length <= 1;

    nodesFilter = (node) => {
        const res =
            this.props.treeAttrs[node.data.id]?.order // && //treeAttrs casted to TreeGraph contain only
        //if (node.data.id == 153966) console.log('nodesFilter', 153966, res);
        return res;
    };

    coloredNodesFilter = (node) => !this.props.showColoredNodesOnly || this._nodeColor(node) !== missingDataColor;

    strainsFilter = node => !node.children && this.nodesFilter(node);

    highlightedStrainsFilter = (node) =>
        !node.children && this.highlightStrain(node) && this.nodesFilter(node);

    internalNodesFilter = (node) => node.children && this.nodesFilter(node);

    linksFilter = (node) => /*this.isLinkInZoomArea(zoomArea, node) && */ this.props.treeAttrs[node.data.id].order;

    mutationsFilter = (node) => {
        const val = this.mutationsCount(node);
        return this.props.treeAttrs[node.data.id].order && val > 0;
    };

    epitopeMutationsGroupsFilter = (node) => {
        const { treeAttrs } = this.props;
        const value = treeAttrs[node.data.id].epitopeMutationsGroups;
        return /*this.isLinkInZoomArea(zoomArea, node) &&*/ this.props.treeAttrs[node.data.id].order && value;
    };

    mutationsClassesFilter = (node) => {
        const { mutationClassesData } = this.props;
        const value = mutationClassesData[node.data.id];
        return /*this.isLinkInZoomArea(zoomArea, node) && */ this.props.treeAttrs[node.data.id].order && value;
    };

    customBranchAttrsFilter = () => (node) => {
        const { treeAttrs, markBranches } = this.props;
        const val =
            treeAttrs && treeAttrs[node.data.id]
                ? markBranches.some((key) => !emptyObject(treeAttrs[node.data.id][key]))
                : null;

        return /*this.isLinkInZoomArea(zoomArea, node) && */ treeAttrs[node.data.id].order && val;
    };

    selectedNode = null;
    selectedNodes = [];

    // displaySelectedNode = (nodeType) => {
    //     console.log(`[displaySelectedNode]: `, this.selectedNode, nodeType);
    //     if (this.selectedNode) {
    //         const selectedNodeLayer = select('#selectedNodes');
    //         const nodeData = this.selectedNode; // .datum();
    //         const transformSelectedNode = this.transformNodes('leaf')(nodeData);
    //         const nodeColor = this.nodeColor('selectedNode')(nodeData);
    //         const strokeColor = nodeColor !== missingDataColor ? color(nodeColor).darker().formatHex() : '#fff';

    //         if (nodeType === 'leaf') {
    //             selectedNodeLayer
    //                 .append('circle')
    //                 .attr('id', 'selectedNode')
    //                 .attr('r', this.rootRadius(nodeData) * 1.6)
    //                 .attr('transform', transformSelectedNode)
    //                 .style('fill', nodeColor)
    //                 .style('stroke', strokeColor)
    //                 .style('stroke-width', 1.5);
    //         } else if (nodeType === 'internal') {
    //             //this.selectedNode
    //             selectedNodeLayer
    //                 .append('path.internal')
    //                 .attr('id', 'selectedNode')
    //                 .attr('d', symbol().type(symbols[5]).size(80))
    //                 .attr('transform', transformSelectedNode)
    //                 .style('fill', nodeColor)
    //                 .style('stroke', strokeColor)
    //                 .style('stroke-width', 1.5);
    //         }
    //     }
    // };

    displaySelectedNodes = (selectedNodes, multiple = false) => {
        const handleMouseLeave = () => {
            if (!this.selectedNode /*&& selectableNode(nodeType)*/) {
                setTimeout(() => {
                    this.props.selectNodeData();
                }, 0);
            }
        };

        const addSelectedNodes = (selectedNodes, multiple) => {
            if (selectedNodes.length > 0) {
                const selectedNodeLayer = select('#selectedNodes');
                const selector = multiple ? 'g.selectedNode' : '#selectedNode';
                const selectedNode = selectedNodeLayer.selectAll(selector).data(selectedNodes, (d) => d.data.id);
                selectedNode.exit().remove();

                const transformSelectedNode = this.transformNodes('leaf');

                const selectedNodeEnter = selectedNode
                    .enter()
                    .append('g')
                    .attr(multiple ? 'class' : 'id', 'selectedNode')
                    .attr('transform', transformSelectedNode)
                    .on('mouseleave', handleMouseLeave)
                    .on('click', (d) => {
                        //console.log(`[click node]`, selectableNode(nodeType), this.selectedNode);
                        //if (selectableNode(nodeType)) {
                        // previous node
                        if (this.selectedNode) {
                            this.props.setSearchStrainMode(false);
                        }
                        this.selectedNode = d; //select(_nodes[i]);

                        //console.log('this.selectedNode === ', this.selectedNode);
                        //this.props.selectNodeData({ nodeId: d.data.id });
                        //this.selectNode(d.data.id, nodeType);
                        //}
                    });
                const isLeaf = (d) => this.props.treeAttrs[d.data.id].name;

                selectedNodeEnter
                    .filter((d) => isLeaf(d))
                    .append('circle')
                    .attr('r', (d) => Math.min(this.rootRadius(d) * 1.6, 10))
                    .style('fill', this.nodeColor('selectedNode'))
                    .style('stroke', (d) => color(this.nodeColor()(d)).darker().formatHex())
                    .style('stroke-width', 1.5);

                selectedNodeEnter
                    .filter((d) => !isLeaf(d))
                    .append('path')
                    .attr('class', 'internal')
                    .attr('d', symbol().type(symbols[5]).size(80))
                    .style('fill', this.nodeColor('selectedNode'))
                    .style('stroke', (d) => color(this.nodeColor()(d)).darker().formatHex())
                    .style('stroke-width', 1.5)
                    .attr('transform', this.transformNodeSymbols('internal'));
            }
        };
        addSelectedNodes(selectedNodes, multiple);
    };

    selectNode = (nodeId, searchStrainMode) => {
        select('#selectedNode').remove();
        const dataNode = this.nodes.filter((d) => d.data.id == nodeId);
        const selectedNode = dataNode.length > 0 ? dataNode : null;
        if (searchStrainMode && dataNode.length > 0) this.selectedNode = selectedNode[0];
        this.displaySelectedNodes(selectedNode);
    };

    selectNodes = (nodesIds) => {
        selectAll('.selectedNode').remove();
        // console.log('[selectNodes]', nodesIds);
        if (!nodesIds) return;
        const nodesIdsDict = nodesIds.reduce((acc, id) => {
            acc[id] = true;
            return acc;
        }, {});

        if (nodesIds.length) {
            const dataNodes = this.nodes.filter((d) => nodesIdsDict[d.data.id]);
            this.selectedNodes = dataNodes.length > 0 ? dataNodes : [];
            this.displaySelectedNodes(this.selectedNodes, true);
        }
    };

    getLeavesNumber = () => {
        const treeNodes = (this.root.descendants() || []).filter((d) => this.strainsFilter(d));
        return treeNodes.length;
    };

    drawNodes = (svg, nodesFilter, nodeType, customAttrId) =>
        new Promise((resolve) => {
            const { treeAttrs, markBranches, mutationClasses, mutationClassesData, displayOrder, measures, showStrainNames } = this.props;
            //console.log(treeAttrs[3682]);
            const treeNodes = (this.root.descendants() || [])
                .filter((d) => nodesFilter(d))
                .sort((n1, n2) =>
                    displayOrder === 'time'
                        ? treeAttrs[n1.data.id].time - treeAttrs[n2.data.id].time
                        : treeAttrs[n2.data.id].time - treeAttrs[n1.data.id].time,
                );

            const nodes = treeNodes.filter(
                (d) => (nodeType !== 'leaf' && nodeType !== 'highlightedStrain' && nodeType !== 'mutation') || this.coloredNodesFilter(d),
            );


            const node = svg.selectAll(`g.${nodeType}`).data(nodes, (d) => `${d.data.id}${customAttrId}`);

            const drawBranchNodes = (valueGetter) => (selection) => {
                const branchNodes = selection.nodes();

                const applyShape = (n, valArr, index, scolor, xtrans) => {
                    const { key, symbol_sign } = valArr[index];
                    const shape = symbol_sign || (key ? measures[key].shape : 'circle');
                    const size = key ? measures[key].size : 'medium';
                    const nsymbol = getSymbolPathForNode(shape, false, size)(n);
                    nsymbol.attr('transform', `translate(${xtrans}, 0)`);
                    const _color = valArr[index].color || scolor;
                    if (_color) nsymbol.style('fill', _color).style('stroke', color(_color).darker().formatHex());

                    return n;
                };

                branchNodes.forEach((bnode) => {
                    const snode = select(bnode);
                    const d = snode.datum();
                    const valArr = valueGetter(d);
                    // console.log(valArr);
                    const number = valArr.reduce((_val, v) => _val += v.number, 0);

                    if (number > 0) {
                        const diff = (number - 1) / 2;
                        const m = 10;
                        let j = 0;
                        let k = 0;
                        // console.log(`in diff = ${diff}`);
                        for (let i = -diff; i <= diff; i += 1) {
                            // console.log(`in diff = ${diff}, i = ${i}`);
                            const { key } = valArr[j];
                            const scolor = key ? this.nodeColor('branch', key)(d) : null; //measures[key].color : null;
                            applyShape(snode, valArr, j, scolor, i * m);
                            k += 1;
                            if (k === valArr[j].number) {
                                j += 1;
                                k = 0;
                            }
                        }
                    }
                });
            };

            const drawBranchLabels = (valueGetter) => (selection) => {
                const { exportMode } = this.props;
                const branchNodes = selection.nodes();
                const addText = (n, value) => {
                    n.append('text')
                        .text(value)
                        .attr('font-size', exportMode ? 8 : 12)
                        .attr('stroke-width', 0.5)
                        .style('fill', '#4F4F4F')
                        .style('stroke', '#4F4F4F');
                };

                branchNodes.forEach((bnode) => {
                    const snode = select(bnode);
                    const d = snode.datum();
                    const value = valueGetter(d);
                    addText(snode, value.join(' '));
                });
            };

            const selectableNode = (_nodeType) => /*_nodeType !== 'mutation' &&*/ _nodeType !== 'branch';


            const handleMouseEnter = (_event, d) => {
                //console.log(d.data.id)
                if (!this.selectedNode && selectableNode(nodeType)) {
                    this.props.selectNodeData({ nodeId: d.data.id, nodeType });
                }
            };

            const handleMouseClick = (_event, d) => {
                if (selectableNode(nodeType)) {
                    if (this.selectedNode) {
                        this.props.setSearchStrainMode(false);
                    }
                    this.props.selectNodeData({ nodeId: d.data.id, nodeType });
                    this.selectedNode = d;
                }
            };

            const handleMouseLeave = () => {
                if (this.props.isMobile) return;

                if (!this.selectedNode) {
                    setTimeout(() => {
                        this.props.selectNodeData();
                    }, 0);
                }
            };

            const nodeEnter = node
                .enter()
                .append('g')
                .attr('class', nodeType)
                .on('mouseenter', handleMouseEnter)
                .on('click', handleMouseClick);

            node.exit().remove();
            const nodeUpdate = nodeEnter.merge(node);

            let nodeUpdateSymbol = nodeUpdate;

            switch (nodeType) {
                case 'leaf': {
                    // console.log('leaf', nodeType);
                    nodeEnter.append('circle');
                    selectAll('.strainName').remove();
                    nodeUpdate.attr('transform', this.transformNodes());
                    //this.resetPadding();
                    // console.log(`[drawNodes]: nodeType = ${nodeType}, nodeUpdate = ${nodeUpdate.size()}, nodes = ${nodes.length}, treeNodes = ${treeNodes.length}`)
                    // const isRightLeavesNo = treeNodes.length <= 40 && treeNodes.length > 0;
                    // const isRightLayout = this.props.layout === LAYOUT.TREE.value;
                    // if (isRightLeavesNo && isRightLayout) {
                    if (showStrainNames) {
                        this.showStrainNames(nodeUpdate, handleMouseLeave);
                    }
                    nodeUpdateSymbol = nodeUpdate.select('circle');
                    break;
                }
                case 'highlightedStrain': {
                    nodeEnter.append('circle');
                    nodeUpdate.attr('transform', this.transformNodes());
                    nodeUpdateSymbol = nodeUpdate.select('circle');
                    break;
                }
                case 'internal': {
                    nodeEnter
                        .append('path')
                        .attr('class', 'internal')
                        .attr('d', symbol().type(symbols[5]).size(40));
                    nodeUpdate.attr('transform', this.transformNodes());
                    nodeUpdateSymbol = nodeUpdate.select('path.internal');
                    break;
                }
                case 'mutation': {
                    nodeEnter.call(
                        drawBranchNodes((d) => [
                            { value: this.mutationsCount(d), number: Math.min(this.mutationsCount(d), 5) },
                        ]),
                    );
                    break;
                }
                case 'epitopeMutationGroup': {
                    nodeEnter.call(drawBranchLabels((d) => treeAttrs[d.data.id].epitopeMutationsGroups));
                    break;
                }

                case 'mutationClass': {
                    nodeEnter.call(
                        drawBranchNodes(
                            (d) =>
                                mutationClassesData &&
                                mutationClassesData[d.data.id] &&
                                mutationClassesData[d.data.id].map((mc) => ({
                                    ...mutationClasses[mc.mutClass],
                                    number: Math.min(mc.mutations.length, 10), // we may show a symbol for all individual mutations in a group, but there would be too many
                                    value: mc.mutClass,
                                })),
                        ),
                    );
                    break;
                }
                case 'branch': {
                    nodeEnter.call(
                        drawBranchNodes((d) => {
                            // console.log(d);
                            const attrs = treeAttrs
                                ? markBranches.filter((key) => treeAttrs[d.data.id] && treeAttrs[d.data.id][key])
                                : null;
                            // console.log(attrs);
                            // if (attrs && attrs.length > 0) console.log(`id = ${d.data.id}, attrs = ${JSON.stringify(attrs)}, markBranches = ${JSON.stringify(markBranches)}`);
                            // console.log(attrs);
                            const valArr = attrs
                                ? attrs.map((b) => ({
                                    key: b,
                                    value: treeAttrs[d.data.id][b],
                                    number: 1,
                                }))
                                : null;
                            //console.log(`valArr = ${JSON.stringify(valArr)}`);
                            return valArr;
                        }),
                    );
                    break;
                }
            }

          
            // add transform attribute only for symbols that need to be transformed
            nodeUpdateSymbol
                .filter((d) => this.transformNodeSymbols(nodeType)(d) !== '')
                .attr('transform', this.transformNodeSymbols(nodeType));
            nodeUpdateSymbol
                .style('fill', (d) => this.nodeColor(nodeType)(d))
                .style('stroke', (d) => this.strokeColor(nodeType)(d))
                .style('stroke-width', (d) => (this.props.exportMode ? 0.5 : this.highlightStrain(d) ? 1.5 : 1))
                .attr('r', this.rootRadius)
                .transition()
                .delay(0)
                .duration(0) //this.renderFullTree() || !withTransition ? 0 : duration)
                //.style('opacity', 1)
                .end()

                .then(() => {
                    node.exit().remove();
                    //   console.log(`[drawNodes] nodeType = ${nodeType}, width = ${this.getWidth()}`)
                    resolve();
                });
            if (nodeUpdate.size() === 0) {
                resolve();
            }
        });

    showStrainNames = (nodeUpdate, handleMouseLeave) => {
        const { treeAttrs, exportMode } = this.props;
        nodeUpdate
            .append('text')
            .text(d => treeAttrs[d.data.id].name.split('_')[0])
            .attr('class', 'strainName')
            .attr('transform', `translate(${exportMode ? '7,2' : '8,3'})`)
            .style('font-size', exportMode ? '8px' : '10px')
            .on('mouseleave', handleMouseLeave)

        // this.strainNamesPadding = this.checkNodeTextsRightEdge();

        // if (this.strainNamesPadding > 0) {
        //     this.updateLayoutPadding({ ...this.getPadding(), right: this.strainNamesPadding + 10 });
        // }
    };

    checkNodeTextsRightEdge = () => {
        const { treeAttrs } = this.props;
        let maxRightX = 0;
        const font = `10px 'Source Sans Pro', 'Verdana'`;
        const canvas = document.createElement('canvas');
        let longestText = '';
        let context = canvas.getContext('2d');
        context.font = font;

        selectAll('.strainName').each(function (strainName) {
            const strLength = treeAttrs[strainName.data.id].name.split('_')[0].length;
            if (maxRightX < strLength) {
                maxRightX = strLength;
                longestText = treeAttrs[strainName.data.id].name.split('_')[0];
            }
        });

        const width = context.measureText(longestText).width ? context.measureText(longestText).width + 20 : 0;
        return width;
    };

    _drawStrains = (svg) => this.drawNodes(svg, this.strainsFilter, 'leaf');

    _drawHighlightedStrains = (svg) =>
        this.drawNodes(svg, this.highlightedStrainsFilter, 'highlightedStrain');

    _drawInternalNodes = (svg) =>
        this.drawNodes(svg, this.internalNodesFilter, 'internal');

    _drawMutations = (svg) =>
        this.drawNodes(svg, this.mutationsFilter, 'mutation');

    _drawEpitopeMutationsGroups = (svg) =>
        this.drawNodes(svg, this.epitopeMutationsGroupsFilter, 'epitopeMutationGroup');

    _drawMutationsClasses = (svg) =>
        this.drawNodes(svg, this.mutationsClassesFilter, 'mutationClass');

    _drawBranchNodes = (svg) => {
        this.drawNodes(svg, this.customBranchAttrsFilter(), 'branch');
    };

    _drawLinks = (svg) =>
        new Promise((resolve) => {
            const { treeAttrs, displayOrder } = this.props;
            const links = this.nodes
                .filter((d) => this.props.treeAttrs[d.data.id]) //root.descendants()
                .filter((d) => this.linksFilter(d))
                .sort((n1, n2) =>
                    displayOrder === 'time'
                        ? treeAttrs[n1.data.id].time - treeAttrs[n2.data.id].time
                        : treeAttrs[n2.data.id].time - treeAttrs[n1.data.id].time,
                );

            const link = svg.selectAll('path.treeLink').data(links, (d) => d.data.id);
            // console.log(`links to remove: ${link.exit().size()}`);

            link.exit().remove();
           
            const linkEnter = link.enter().append('path'); //.attr('class', 'treeLink'); ///.style('opacity', 0);

            linkEnter
                .on('mouseover', (_event, d) => {
                    if (!this.selectedNode) this.props.selectNodeData({ nodeId: d.data.id });
                })
                .on('touchstart', (_event, d) => {
                    if (!this.selectedNode) this.props.selectNodeData({ nodeId: d.data.id });
                })
                .on('mouseout', () => {
                    if (!this.selectedNode) this.props.selectNodeData();
                })
                .on('touchend', () => {
                    if (!this.selectedNode) this.props.selectNodeData();
                })
                .on('click', (_event, d) => {
                    //if (!this.selectedNode)
                    this.processZoom(ZOOM_TYPES.ZOOM_IN)(d);
                });
            
            const linkUpdate = linkEnter.merge(link);

            const rootTime = treeAttrs[this.props.tree?.id]?.time;

            linkUpdate
                .style('stroke', this.linkColor)
                //.style('opacity', 1)
                .style('stroke-width', (d) => getScaledValue('freqScale', this.nodeFrequency(d)) || 2)
                .style('stroke-dasharray', (d) => this.isDashedLink(d) ? '3, 3' : null)
                .classed('no-zoom-in', (d) => !d.children || treeAttrs[d.data.id].time <= rootTime)
                .attr('class', 'treeLink')
                .attr('d', this.diagonal);
           
            resolve();
        });

    drawElements = (svg, drawFunc) => {
        return drawFunc(svg);
    };

    removeElements = (svg, className) =>
        new Promise((resolve) => {
            // console.log('[removeElements]', svg._groups[0]);
            const selectorSufix = className ? `.${className}` : '';
            svg.selectAll(`g${selectorSufix}`).remove();
            svg.selectAll(`path${selectorSufix}`).remove();
            svg.selectAll(`circle${selectorSufix}`).remove();
            return resolve();
        });

    removeElementsLayer = (mountNode) => {
        const svg = select(mountNode);
        return this.removeElements(svg);
    };

    removeAxesLayer = (mountNode) => {
        const svg = select(mountNode);
        hideAxesLayer(svg, this.props);
    };

    drawStrains = (svg) => {
        if (!this.props) return;
        // console.log('[drawStrains]')
        const { showLeaves } = this.props;
        if (showLeaves) return this.drawElements(svg, this._drawStrains);
        else return this.removeElements(svg);

        // console.log(`[drawStrains]: DONE`)
    };

    drawStrainsLayer = async (mountNode) => {
        // console.log(`[drawStrainsLayer] 
        // this.getTreeWidth() = ${this.getTreeWidth()}
        // getCladeBarWidth = ${this.getCladeBarWidth()}
        // strainsNamesPadding = ${this.strainNamesPadding}
        // measuresLoaded = ${this.props.measuresLoaded}
        // `);
        // console.log('[drawStrainsLayer], layout = ', this.props.layout);
        // console.log(`[treeD3.drawStrainsLayer]: start1: ${measureTimer.getLayerInterval('StrainsLayerRender')}`);
        const svg = select(mountNode);
        // console.log(`[treeD3.drawStrainsLayer]: start2: ${measureTimer.getLayerInterval('StrainsLayerRender')}`);
        return this.drawStrains(svg);
        //console.log('[drawStrainsLayer]: STOP')
    };

    drawHighlightedStrains = (svg) => {
        if (!this.props) return;
        //console.log('[drawHighlightedStrains]: START')
        const { showLeaves } = this.props;
        // console.log(svg);
        // console.log(this.props.highlightedStrainIds);
        if (showLeaves) return this.drawElements(svg, this._drawHighlightedStrains);
        else return this.removeElements(svg);
        // console.log('[drawHighlightedStrains]: STOP')
        //console.log('[drawHighlightedStrains] END')
    };

    drawHighlightedStrainsLayer = (mountNode) => {
        //console.log('[drawHighlightedStrainsLayer]: START')
        const svg = select(mountNode);
        // console.log(this.props.highlightedStrainIds);
        return this.drawHighlightedStrains(svg);
        //console.log('[drawHighlightedStrainsLayer]: STOP')
    };

    drawSelectedStrainsLayer = (mountNode, nodeId, searchStrainMode) => {
        if (!this.props) return;
        const svg = select(mountNode);
        // const { nodeId } = this.props;
        const { showInternalNodes, treeAttrs } = this.props;
        // console.log('[drawSelectedStrainsLayer] measuresLoaded= ', this.props.measuresLoaded)
        if (nodeId && treeAttrs[nodeId]?.order) {
            if (showInternalNodes || treeAttrs[nodeId].name) return treeD3.selectNode(nodeId, searchStrainMode);
        } else return this.removeElements(svg);
    };
    drawInternalNodes = (svg) => {
        // console.log('[drawInternalNodes]: START')
        const { showInternalNodes } = this.props;
        //console.log(`showInternalNodes = ${showInternalNodes}`);
        if (showInternalNodes) return this.drawElements(svg, this._drawInternalNodes);
        else return this.removeElements(svg);
        //console.log('[drawInternalNodes]: STOP') 
    };

    drawInternalNodesLayer = (mountNode) => {
        //console.log('[drawInternalNodesLayer]: START')
        //   console.log('[drawInternalNodes], layout = ', this.props.layout);
        const svg = select(mountNode);
        return this.drawInternalNodes(svg);
        // console.log('[drawInternalNodesLayer]: STOP')
    };

    drawMutations = (svg) => {
        // console.log('[drawMutations]: START')
        const { showMutations } = this.props;
        if (showMutations) return this.drawElements(svg, this._drawMutations);
        else return this.removeElements(svg);
        // console.log('[drawMutations]: STOP')
    };

    drawMutationsLayer = (mountNode) => {
        const svg = select(mountNode);
        return this.drawMutations(svg);
    };

    drawEpitopeMutationsGroups = (svg) => {
        const { showMutationsGroups } = this.props;
        // console.log(`[drawMutationsGroups]: showMutationsGroups = ${showMutationsGroups}`)
        if (showMutationsGroups) return this.drawElements(svg, this._drawEpitopeMutationsGroups);
        else return this.removeElements(svg);
        //console.log('[drawEpitopeMutationsGroups]: STOP')
    };

    drawEpitopeMutationsGroupsLayer = (mountNode) => {
        // console.log('[drawEpitopeMutationsGroupsLayer]: START')
        const svg = select(mountNode);
        return this.drawEpitopeMutationsGroups(svg);
        // console.log('[drawEpitopeMutationsGroupsLayer]: STOP')
    };

    // drawMutationsClasses = (svg) => {
    //     // console.log('[drawMutationsClasses]: START')
    //     //const { showMutationsGroups } = this.props;
    //     //svg.selectAll('g').remove();
    //     // console.log(`[drawMutationsGroups]: showMutationsGroups = ${showMutationsGroups}`)
    //     //if (showMutationsGroups)
    //     return this.drawElements(svg, this._drawMutationsClasses);
    //     //else return this.removeElements(svg, 'mutationClass');
    //     // console.log('[drawMutationsClasses]: STOP')
    //     // else svg.selectAll('g').remove();
    // };

    // drawMutationsClassesLayer = (mountNode) => {
    //     // console.log('[drawMutationsClassesLayer]: START')
    //     const svg = select(mountNode);
    //     return this.drawMutationsClasses(svg);
    //     //console.log('[drawMutationsClassesLayer]: STOP')
    // };

    drawBranchNodes = (svg) => {
        const { markBranches } = this.props;
        // console.log('[drawBranchNodesLayer]', markBranches)
        svg.selectAll('g').remove();
        if (markBranches.length > 0) return this.drawElements(svg, this._drawBranchNodes);
        else return this.removeElements(svg);
    };

    drawBranchNodesLayer = async (mountNode) => {
        //console.log('[drawBranchNodesLayer]: START')
        const svg = select(mountNode);
        return this.drawBranchNodes(svg);
        //console.log('[drawBranchNodesLayer]: STOP')
    };

    drawLinks = async (svg) => {
        // console.log('[drawLinks]') 
        // if (!this.props.measuresLoaded) return;
        svg.attr('transform', this.translate(true));
        return this.drawElements(svg, this._drawLinks);
        // console.log('[drawLinks]: STOP') 
    };

    drawLinksLayer = async (mountNode) => {
        // console.log('[drawLinksLayer]')
        // console.log('[drawLinksLayer], layout = ', this.props.layout);
        const svg = select(mountNode);
        return this.drawLinks(svg);
        // console.log('[drawLinksLayer]: STOP')
    };

    drawReassortments = async (svg) => {
        //console.log('[drawReassortments]: START')
        const { showVaccineAndReassortment } = this.props;
        if (showVaccineAndReassortment)
            await this.markStrains(
                (d) =>
                    this.props.treeAttrs[d.data.id] && this.props.treeAttrs[d.data.id].R && showVaccineAndReassortment,
                'reassortment',
                reassortmentIcon,
            )(svg);
        else svg.selectAll('g').remove();
        // console.log('[drawReassortments]: STOP')
    };

    drawReassortmentsLayer = async (mountNode) => {
        const svg = select(mountNode);
        await this.drawReassortments(svg);
    };

    // await
    // this.markStrains(
    //     (d) => this.props.treeAttrs[d.data.id] && this.props.treeAttrs[d.data.id].vaccine && showVaccineAndReassortment,
    //     'vaccine',
    //     vaccineIcon,
    // )(svg.select('#vaccines'));

    drawVaccines = (svg) => {
        // console.log('[drawVaccines]: START')
        const { showVaccineAndReassortment } = this.props;
        if (showVaccineAndReassortment)
            return this.markStrains(
                (d) =>
                    this.props.treeAttrs[d.data.id] &&
                    this.props.treeAttrs[d.data.id].vaccine &&
                    showVaccineAndReassortment,
                'vaccine',
                vaccineIcon,
            )(svg);
        else return this.removeElements(svg); // .selectAll('g').remove();
        //console.log('[drawVaccines]: STOP')
    };

    drawVaccinesLayer = async (mountNode) => {
        //console.log('[drawVaccinesLayer]: START')
        const svg = select(mountNode);
        return this.drawVaccines(svg);
        // console.log('[drawVaccinesLayer]: STOP')
    };

    drawReferenceStrains = (svg) => {
        // console.log('[drawVaccines]: START')
        const { showReferenceStrains, strainCutOffDate } = this.props;

        if (showReferenceStrains)
            return this.markStrains(
                (d) =>
                    this.props.treeAttrs[d.data.id] &&
                    this.props.treeAttrs[d.data.id].time > strainCutOffDate &&
                    this.props.treeAttrs[d.data.id].referenceStrain &&
                    showReferenceStrains,
                'referenceSrain',
                referenceStrainsIcon,
            )(svg);
        else return this.removeElements(svg); // .selectAll('g').remove();
        //console.log('[drawVaccines]: STOP')
    };

    drawReferenceStrainsLayer = async (mountNode) => {
        const svg = select(mountNode);
        return this.drawReferenceStrains(svg);
    };

    drawRefStrains = (svg) => {
       
        const refStrain = `${this.props.refStrain || ''}`;
        const humanSerologyRefStrains = this.props.humanSerologyRefStrains;
        const refStrainsArr = refStrain ? `${refStrain}`.split(',') : [];
        const strainsArr = refStrainsArr.concat(humanSerologyRefStrains || []).map(el => parseInt(el));
        //const strains = `${refStrain||''}`.split(',').concat(humanSerologyRefStrains);
        if (strainsArr.length > 0) {
            return this.markStrains(
                (d) => strainsArr.includes(+d.data.id),
                'refStrain',
                referenceStrainIcon,
            )(svg);
        }

        else
            return this.removeElements(svg);
    };

    drawRefStrainsLayer = (mountNode) => {
        //console.log('[drawRefStrainsLayer]: START')
        const svg = select(mountNode);
        return this.drawRefStrains(svg);
        //console.log('[drawRefStrainsLayer]: STOP')
    };

    drawCladeBarLayer = async (mountNode, showCladeBarLabels, highlightLegend) => {
        const svg = select(mountNode);
        await this.drawCladeBar(svg, showCladeBarLabels, highlightLegend);
    };

    updateCladeBar = (pCSectionUpdate, showCladeBarLabels) => {
        // console.log('[updateCladeBar]');
        const w = this.getTreeWidth();
        // select('#cladeBar').attr(
        //     'transform',
        //     `translate(${w}, 0)`,
        // );

        const svg = select('#cladeBar');

        svg.attr('transform', `translate(${w}, 0)`);
        pCSectionUpdate.select('path').attr('d', (d) => {
            let marginStart = 4;
            let marginEnd = 4;
            if (d.labels.length > 0 && d.startOrder === d.endOrder) {
                marginStart = d.minHeight / 2;
                marginEnd = d.minHeight / 2;
            }
            return `M0,${this.yOrder(d.startOrder) - marginStart}L0,${this.yOrder(d.endOrder) + marginEnd}`;
        });
        if (showCladeBarLabels)
            pCSectionUpdate
                .select('text')
                .attr('transform',
                    (d) => `translate(${this.cladeSectionPlacing},${this.yOrder(d.startOrder + (d.endOrder - d.startOrder) / 2)})`
                );
    }

    highlightCladeBar = (mountNode, clade, show) => {
        const svg = select(mountNode);

        if (isNaN(clade))
            return;
        
        if (show)
            svg.selectAll(`g.clade_${clade}`)
                .select('text')
                .attr('text-decoration', 'underline');
        else
            svg.selectAll(`g.clade_${clade}`)
                .select('text')
                .attr('text-decoration', 'none');
    }

    styles = {
        tooltipContainer: {
            position: 'absolute',
            background: '#fff',
            border: '2px solid #000',
            padding: '10px',
            color: '#000',
            borderRadius: '5px',
            textAlign: 'center',
            fontSize: '14px',
        },
    };

    initializeTooltip = () => {
        const tip = d3Tip()
          .attr('class', 'd3-tip')
          .offset([10, 10])
          .html(d => {
            const text = d.replace(' ', '&nbsp;');
            return `<div class="tooltip-container">
                <span>${text}</span>
            </div>`
          });
        return tip;
    };

    getLabel = (fullLabel, width) => {
        const maxWidth = this.props?.longestCladeLabel;
        const tooLong = maxWidth - width <= 0;
        if (tooLong) {
            const slicedText = getTrimmedText(fullLabel, `12px 'Source Sans Pro', 'Verdana'`, maxWidth - 10)
            return slicedText;
        } else
            return fullLabel;
    };

    drawCladeBar = async (svg, showCladeBarLabels, highlightLegend) => {
        const { cladeBarData, clades } = this.props;
        //this.strainNamesPadding = this.checkNodeTextsRightEdge();

        const pCSection = svg
            .selectAll('g.cladeBar')
            .data(cladeBarData, (d) => `${d.clade}_${d.startOrder}`);

        const tip = this.initializeTooltip();
        svg.call(tip);

        function handleMouseOver(clade) {
            highlightLegend(clade, true);
            svg.selectAll(`g.clade_${clade}`)
                .select('text')
                .attr('text-decoration', 'underline');
        }

        function handleMouseOut(clade) {
            highlightLegend(clade, false);
            svg.selectAll(`g.clade_${clade}`)
                .select('text')
                .attr('text-decoration', 'none');
        }

        if (!pCSection._enter) return;

        const pCSectionEnter = pCSection.enter().append('g')
            .attr('class', (d) => `clade_${d.clade} cladeBar`)
            .style('cursor', 'pointer')
            .attr('transform', `translate(${this.cladeSectionMarginLeft + this.strainNamesPadding},0)`)
            .on("mouseover", (e, d) => handleMouseOver(d.clade))
            .on("mouseout", (e, d) => handleMouseOut(d.clade));

        pCSectionEnter
            .append('path')
            .attr('stroke', (d) => { if (!clades[d.clade]) console.log(d); return clades[d.clade]?.color })
            .attr('class', (d) => d.clade)
            .attr('stroke-width', 10)


        if (showCladeBarLabels) {
            const labelTextEnter = pCSectionEnter.append('text')
                .attr('id', (d) => `m1_${d.clade}_${d.startOrder}`)
                .attr('class', (d) => d.clade);

            labelTextEnter
                .append('tspan')
                .style('text-anchor', 'start')
                .style('alignment-baseline', 'central')
                .style('font-family', "'Source Sans Pro'")
                .style('font-size', `${this.fontSizeClades}px`)
                .text((d) => d.showLabel ? this.getLabel(d.label, d.width) : '')
                .attr('class', (d) => d.clade)
                .on("mouseover", (e, d) => { d.width > this.props?.longestCladeLabel ? tip.show(d.label, e.currentTarget) : {} })
                .on('mouseout', tip.hide);
        }

        const pCSectionUpdate = pCSectionEnter.merge(pCSection);

        this.updateCladeBar(pCSectionUpdate, showCladeBarLabels);
        //pCSectionUpdate.select('path')
        //    .attr('d', (d) => `M30,${this.yOrder(d.startOrder)-4}L30,${this.yOrder(d.endOrder)+4}`);
        pCSectionUpdate.select('path').attr('d', (d) => {
            let marginStart = 4;
            let marginEnd = 4;
            if (d.labels.length > 0 && d.startOrder === d.endOrder) {
                marginStart = d.minHeight / 2;
                marginEnd = d.minHeight / 2;
            }
            return `M0,${this.yOrder(d.startOrder) - marginStart}L0,${this.yOrder(d.endOrder) + marginEnd}`;
        });
        if (showCladeBarLabels)
            pCSectionUpdate
                .select('text')
                .attr('transform',
                    (d) => `translate(${this.cladeSectionPlacing},${this.yOrder(d.startOrder + (d.endOrder - d.startOrder) / 2)})`
                );

        pCSection.exit().remove();
    };


    // getLongestCladeLabel = () => {
    //     const { cladeBarData, clades, showCladeBar, showCladeBarLabels } = this.props;
    //     if (!showCladeBarLabels || !showCladeBar) return 0;
    //     const font = `${this.fontSizeClades} 'Source Sans Pro', sans-serif`;
    //     const canvas = document.createElement('canvas');
    //     let maxLength = 0;
    //     let longestText = '';
    //     let context = canvas.getContext('2d');
    //     context.font = font;

    //     cladeBarData.forEach((clade) => {
    //         const strLength = clades[clade.clade].label.length;
    //         if (clade.endOrder - clade.startOrder > this.fontSizeClades + 1 && strLength > maxLength) {
    //             maxLength = strLength;
    //             longestText = clades[clade.clade].label;
    //         }
    //     });

    //     const width = context.measureText(longestText).width;
    //     const formattedWidth = Math.ceil(width) + 90;

    //     return formattedWidth;
    // };

    markStrains = (nodeSelector, name, markSymbol) => (svg) =>
        new Promise((resolve) => {
            // Mutation and Reassortment texts
            // const { ranges, treeAttrs, exportMode, zoomNodeStack, refStrain } = this.props;

            
            const nodes = this.root.descendants().filter((d) => nodeSelector(d));
    
            const rNode = svg.selectAll(`g.${name}`).data(nodes, (d) => d.data.id);
            const rNodeEnter = rNode
                .enter()
                .append('g')
                .attr('class', name)
                .on('mouseover', (_event, d) => {
                    if (!this.selectedNode) this.props.selectNodeData({ nodeId: d.data.id });
                })
                .on('click', (_event, d) => {
                    if (this.selectedNode) {
                        this.props.setSearchStrainMode(false);
                    }
                    this.props.selectNodeData({ nodeId: d.data.id, nodeType: 'vaccine' });
                    this.selectedNode = d;
                })
                .on('mouseout', () => {
                    //console.log('nodeEnter: mouseOut', this.props.nodeId, this.selectedNode);
                    if (!this.selectedNode) this.props.selectNodeData();
                });

            if (name !== 'reassortment') {
                rNodeEnter
                    .append('path')
                    .attr('d', markSymbol.path.d)
                    .attr('transform', () => 'translate(-8, 8)')
                    .style('fill', markSymbol.path.fill) //scolor)
                    .style('stroke', markSymbol.path.stroke)
                    .style('stroke-width', markSymbol.path['stroke-width'])
                    .style('opacity', '0.9');
                rNodeEnter
                    .append('path')
                    .attr('d', markSymbol.symbol.d)
                    .attr('transform', () => 'translate(-8, 8)')
                    .style('fill', markSymbol.symbol.fill); //scolor)
            } else {
                rNodeEnter
                    .append('rect')
                    .attr('x', 0)
                    .attr('y', 0)
                    .attr('width', 15)
                    .attr('height', 15)
                    .attr('transform', () => 'translate(-16, -10)rotate(45)')
                    .style('fill', markSymbol.path.fill)
                    .style('stroke', markSymbol.path.stroke)
                    .style('stroke-width', markSymbol.path['stroke-width']);
                rNodeEnter
                    .append('path')
                    .attr('d', markSymbol.symbol.d)
                    .attr('transform', () => 'translate(-19, -3)')
                    .style('fill', markSymbol.symbol.fill); //scolor)
            }

            const rNodeUpdate = rNodeEnter.merge(rNode);

            rNodeUpdate
                .transition()
                .delay(0)
                .duration(0) //this.renderFullTree() ? 0 : duration)
                .attr('transform', this.transformNodes())
                .selectAll('circle')
                .style('opacity', 1);

            rNode.exit().remove();
            return resolve();
        });

    initLayout = () => {
        // if(this.props.tree){
        const treeHierarchy = hierarchy(this.props.tree, (d) => d.children); // ? d.children.filter(c => this.props.treeAttrs[c.id].order) : null)
        this.root = tree()(treeHierarchy);
        this.nodes = this.root.descendants(); //.slice(1);
        // }
    };

    changeNodesColor = async (mountNode, nodeType) => {
        const svg = select(mountNode);
        svg.selectAll('g *').style('fill', this.nodeColor(nodeType)).style('stroke', this.strokeColor(nodeType));
    };

    changeLinksColor = async (mountNode) => {
        const svg = select(mountNode);
        svg.selectAll('.treeLink').style('stroke', this.linkColor);
    };

    cladeLabelsDisplayed = false;

    drawDateAxisLayer = async (mountNode) => {
        const svg = select(mountNode);
        svg.attr('transform', this.translate(false));
        // console.log('[drawDateAxisLayer] width = ', this.getTreeWidth());
        drawDateAxis(svg, this.props, this.getTreeWidth(), this.height, this.getPadding());
    };

    initScales = () => {
        // console.log(`[initScales]`);// BEFORE width = ${this.width}, treeWidth = ${this.getTreeWidth()}`);
        const treeWidth = initTreeScales(this.props, this.width, this._getCladeBarWidth(), this.height, this.nodeRadius);
        this.strainNamesPadding = this.width - this.getCladeBarWidth() - treeWidth;
        // console.log(`[initScales] AFTER 
        //     width = ${this.width}, 
        //     treeWidth = ${this.getTreeWidth()}, 
        //     correctedTreeWidth = ${treeWidth},
        //     strainNamesPadding = ${this.strainNamesPadding}, 
        //     cladeBarPadding = ${this._getCladeBarWidth()}`);

    };

    resetScales = () => {
        resetScales();
    };
    renderD3Component = async () => { };

    resizeComponent = (width, height) => {
        this.setWidth(width);
        this.setHeight(height);
        // console.log('resizeComponent', this.componentName);
        this.initScales();
        this.setGraphAreaSize();
        //this.translateGraphElements();
    };

    clearTree = async () => {
        // console.log(`[clearTree]`)
        const svg = select(this.mountNode).select('g.graph');
        await Promise.all([
            svg.select('#nodes').selectAll('g').remove(),
            svg.select('#highlightedStrains').selectAll('g').remove(),
            svg.select('#links').selectAll('path').remove(),
            svg.select('#internalNodes').selectAll('g').remove(),
            svg.select('#mutations').selectAll('g').remove(),
            svg.select('#epitopeMutationsGroups').selectAll('g').remove(),
            //svg.select('#mutationsClasses').selectAll('g').remove(),
            svg.select('#branchNodes').selectAll('g').remove(),
            //svg.select('#cladeLabels').selectAll('g').remove(),
            svg.select('#reassortments').selectAll('g').remove(),
            svg.select('#vaccines').selectAll('g').remove(),
            svg.select('#refStrains').selectAll('g').remove(),
        ]);
    };

    fanLayoutRadius = () => Math.min(this.getTreeWidth() / 2, this.height);

    isDashedLink = d => {
        const { treeAttrs, ranges, treeScaleTypeX, treeScaleTypeY } = this.props;
        return treeAttrs[d.data.id][treeScaleTypeX] > ranges.max[treeScaleTypeX] ||
            treeAttrs[d.data.id][treeScaleTypeY] > ranges.max[treeScaleTypeY] ||
            treeAttrs[d.data.id][treeScaleTypeX] < ranges.min[treeScaleTypeX] ||
            treeAttrs[d.data.id][treeScaleTypeY] < ranges.min[treeScaleTypeY];
    }
    translateLinks = async () => {
        const svg = select(this.mountNode).select('g.graph');
        svg.select('#links').attr('transform', this.translate(true));
        // console.log('TRANSLATE LINKS!', this.translate(true));
        const linkUpdate = select('#links').selectAll('path');
        linkUpdate.attr('d', this.diagonal)
            .style('stroke-dasharray', (d) => this.isDashedLink(d) ? '3, 3' : null);
    };

    translateInternalNodes = async () => {
        const svg = select(this.mountNode).select('g.graph');
        svg.select('#internalNodes').attr('transform', this.translate(false));
        const internalNodeUpdate = select('#internalNodes').selectAll('g'); // .select('path.internal');
        internalNodeUpdate.attr('transform', this.transformNodes());
    };

    translateStrains = async () => {
        // console.log('translateStrains');
        const svg = select(this.mountNode).select('g.graph');
        svg.select('#nodes').attr('transform', this.translate(false));
        const nodeUpdate = select('#nodes').selectAll('g');
        nodeUpdate.attr('transform', this.transformNodes());
    };

    translateMutations = async () => {
        const mutations = select('#mutations').selectAll('g');
        mutations.attr('transform', this.transformNodes('mutation'));
    };

    translateEpitopeMutationsGroups = async () => {
        const epitopeMutationsGroups = select('#epitopeMutationsGroups').selectAll('g');
        epitopeMutationsGroups.attr('transform', this.transformNodes('epitopeMutationGroup'));
    };

    translateMutationsClasses = async () => {
        const mutationsClasses = select('#mutationsClasses').selectAll('g');
        mutationsClasses.attr('transform', this.transformNodes('mutationClass'));
    };

    translateReassortments = async () => {
        const reassortments = select('#reassortments').selectAll('g.reassortment');
        reassortments.attr('transform', this.transformNodes());
    };

    translateVaccines = async () => {
        const reassortments = select('#vaccines').selectAll('g.vaccine');
        reassortments.attr('transform', this.transformNodes());
    }; 

    translateRefStrains = async () => {
        const refStrains = select('#refStrains').selectAll('g.refStrain');
        refStrains.attr('transform', this.transformNodes());
    };

    translateReferenceStrains = async () => {
        const referenceStrains = select('#referenceStrains').selectAll('g.referenceSrain');
        referenceStrains.attr('transform', this.transformNodes());
    }; 

    translateHighlightedStrains = async () => {
        const highligtedStrainUpdate = select('#highlightedStrains').selectAll('g');
        highligtedStrainUpdate.attr('transform', this.transformNodes());
    };

    translateBranchNodes = async () => {
        const branchNodes = select('#branchNodes').selectAll('g');
        branchNodes.attr('transform', this.transformNodes('branch'));
    };

    translateSelectedNodes = () => {
        if (this.selectedNode)
            select('#selectedNodes')
                .select('#selectedNode')
                .attr('transform', this.transformNodes('leaf')(this.selectedNode)); //(this.selectedNode.datum()));
    };


    _getCladeBarWidth = () => this.cladeSectionMarginLeft + this.cladeSectionPlacing + (this.props?.showCladeBarLabels ? (this.props?.longestCladeLabel || 0) : 0);

    getCladeBarWidth = () => {
        // console.log('[getCladeBarWidth]');
        if (!this.props || this.props.layout !== LAYOUT.TREE.value) return 0;

        return this._getCladeBarWidth();
    }

    getTreeWidth = () => {
        //console.log('[getTreeWidth] strainNamesPadding = ', this.strainNamesPadding, 'cladeBarPadding =', this.getCladeBarWidth());
        const strainNamesPadding = (!this.props || this.props.layout !== LAYOUT.TREE.value) ? 0 : this.strainNamesPadding;
        return this.width - this.getCladeBarWidth() - strainNamesPadding;
    }

    translateCladeBarLayer = async () => {
     
        const svg = select('#cladeBar');
        const pCSection = svg.selectAll('g.cladeBar');
        this.updateCladeBar(pCSection, this.props.showCladeBarLabels)
    };

    translateGraphElements = () => {
        //console.log(`[TreeD3.translateGraphElements]`);
        this.translateLinks();
        this.translateInternalNodes();
        this.translateStrains();
        this.translateHighlightedStrains();
        this.translateReassortments();
        this.translateVaccines();
        this.translateRefStrains();
        this.translateMutations();
        this.translateEpitopeMutationsGroups();
        //this.translateMutationsClasses();
        this.translateBranchNodes();
        this.translateSelectedNodes();
        //this.translateCladeLabels();
        // console.log('translateGraphElements');
        this.drawDateAxisLayer('#axes');
        this.translateCladeBarLayer();
    };
}

export default TreeD3;

const treeD3 = new TreeD3();
export { treeD3 };
