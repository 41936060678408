import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import Autocomplete from '@mui/material/Autocomplete';
import { StyledTextField } from '../../assets/GlobalStyles/TextField';
import { setParameters } from '../../redux/actions/parametersActions';
import { fetchCustomTreeAttrs } from '../../redux/actions/treeDataActions';
import { getCustomTreeBranchOptions, markBranchesSelector } from '../../redux/selectors/metadataSelector';

const styles = theme => ({
    // root: {
    //     display: 'flex',
    //     flexWrap: 'wrap',
    //     textAlign: 'left',
    //     padding: '22px 12px 12px 12px'
    // },
    formControl: {
        // margin: theme.spacing(1),
        minWidth: 120,
        width: '100%',
        margin: '8px 0px'
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    // selectEmpty: {
    //     marginTop: theme.spacing(2)
    // },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


const BranchColorBySelector = props => {
    const { lineage, customTreeAttrs, status, branchColorOptions, markBranches, strainSubset, classes,
        fetchCustomTreeAttrs, setParameters } = props;

    const handleSelectedOptions = (event, _markBranches) => {

        const mB = _markBranches.reduce((acc, { key }) => { acc[key] = true; return acc; }, {});
        setParameters({ markBranches: mB });

        const fetchMB = _markBranches
           // .filter(({ key }) => !customTreeAttrs[key] || status[key] !== 'loaded')
            .reduce((acc, { key }) => { acc[key] = true; return acc; }, {});
        /*if (Object.keys(fetchMB).length > 0) */ fetchCustomTreeAttrs({ lineage, markBranches: fetchMB, strainSubset, element: 'branchNodes' });
        
    }


    return (
        <>
            {branchColorOptions.length > 0 && (
                <div className={classes.formControl}>
                    <Autocomplete
                        multiple
                        id="markBranches"
                        size="small"
                        options={branchColorOptions}
                        getOptionLabel={(option) => option.label}
                        isOptionEqualToValue={(option, value) => option.key === value.key}
                        value={markBranches}
                        onChange={handleSelectedOptions}
                        renderInput={(params) => (
                            <StyledTextField
                                {...params}
                                label="Mark branches with"
                                placeholder={""}
                            />
                        )}
                    />
                </div>
            )}
        </>
    );

}

BranchColorBySelector.propTypes = {
    lineage: PropTypes.string,
    branchColorOptions: PropTypes.arrayOf(PropTypes.shape({ key: PropTypes.string, label: PropTypes.string })),
    markBranches: PropTypes.arrayOf(PropTypes.shape({ key: PropTypes.string, label: PropTypes.string })),
    customTreeAttrs: PropTypes.shape({}),
    classes: PropTypes.shape({ formControl: PropTypes.string }),
    fetchCustomTreeAttrs: PropTypes.func,
    setParameters: PropTypes.func
};


const mapStateToProps = (state) => {
    const branchColorOptions = getCustomTreeBranchOptions(state);
    const markBranches = markBranchesSelector(state);

    return ({
        lineage: state.parameters.lineage,
        strainSubset: state.parameters.strainSubset,
        branchColorOptions,
        markBranches,
        customTreeAttrs: state.customTreeData.customTreeAttrs,
        status: state.customTreeData.status
    });
};

const mapDispatchToProps = dispatch => ({
    fetchCustomTreeAttrs: (payload) => dispatch(fetchCustomTreeAttrs(payload)),
    setParameters: payload => dispatch(setParameters(payload))

});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(BranchColorBySelector));
