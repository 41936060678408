import { THEME } from '../../../config/consts';
import removeCursor from '../../../assets/images/cursorRemove.svg'

const border = `2px solid ${THEME.color.lightGray}`;
const cursor = 'url(/images/erase.png) 10 10, auto';

const WEB_CELL = '18px';
const EXPORT_CELL = '12px';

const webStyles = {
    name: {
        cursor: "url(" + removeCursor + "), auto",
        borderBottom: border,
        fontSize: THEME.fontSize.tableWeb,
        backgroundColor: THEME.color.white,
        overflowWrap: 'break-word',
        padding: '2px 6px',
        marginLeft: '2px',
        position: 'sticky',
        left: 0,
    },
    nameEx: {
        border
    },
    wrappedName: {
        maxWidth: '25vw'
    },
    val: {
        boxShadow: `inset 0 0 0 1px ${THEME.color.lightGray}`,
        padding: '1px',
        width: WEB_CELL,
        maxWidth: WEB_CELL,
        '&:hover': {
            boxShadow: 'inset 0 0 0 2px #bdbdbd',
            cursor: 'pointer',
        },
    },
    emptyHeader: {
        display: 'table-cell',
        backgroundColor: '#e2e2e2',
        zIndex: 10,
        position: 'sticky',
        top: 0,
        left: 0 
    },
    emptyHeaderEx: {
        backgroundColor: 'white', 
        borderBottom: border,
        borderRight: border
    },
    headerTh: {
        cursor: "url(" + removeCursor + "), auto",
        borderRight: border,
        backgroundColor: THEME.color.white,
        width: WEB_CELL,
        verticalAlign: 'bottom',
        padding: '9px 2px',
        display: 'table-cell',
        height: 'fit-content'
    },
    headerThEx: {
        border
    },
    stickyHeader: {
        position: 'sticky',
        top: 0,
    },
    wrapped: {
        maxHeight: '30vh',
        wordWrap: 'break-word'
    },
    tr: {
        overflow: 'overlay'
    },
    headerDiv: {
        fontSize: THEME.fontSize.tableWeb,
        fontWeight: 'normal',
        maxHeight: '100%',
        // wordWrap: 'break-word',
        writingMode: 'vertical-rl',
        textAlign: 'left',
        transform: 'rotate(180deg)',
        height: 'fit-content'
    },
};

const exportStyles = {
    name: {
        ...webStyles.name,
        fontSize: THEME.fontSize.tableExport,
    },
    val: {
        width: EXPORT_CELL,
        maxWidth: EXPORT_CELL,
    },
    headerTh: {
        ...webStyles.headerTh,
        width: EXPORT_CELL,
        border: 'none',
    },
    headerDiv: {
        ...webStyles.headerDiv,
        fontSize: THEME.fontSize.tableExport,
    },
};

export const antigenicStyles = {
    rootData: {
        margin: '0px 0px 50px 30px',
        maxWidth: 'calc(100% - 20px)',
        maxHeight: '100%',
        overflow: 'overlay',
        minHeight: '100%',
    },
    rootEx: {
        marginTop: '20px'
    },
    table: {
        boxSizing: 'border-box',
        borderCollapse: 'collapse',
        border: '0px',
        maxHeight: '100%',
        padding: '0px',
        maxWidth: 'calc(100% - 20px)',
        overflow: 'overlay',
    },
    tableDetails: {
        borderCollapse: 'collapse',
        border: '0px',
        marginTop: '60px',
        padding: '0px',
    },
    ...webStyles,
    export: {
        ...exportStyles,
    },
};
