import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

export const styles = makeStyles(() =>({
    root: {
        backgroundColor: '#e2e2e2',
        height: 'calc(100vh - 184px)',
        maxHeight: 'calc(100vh - 184px)',
        overflow: 'overlay'
    },
    content: {
        margin: '0px 15px 15px 15px',
        backgroundColor: 'white',
        height: 'calc(100% - 100px)',
    },
    container: {
        height: '100%',
        maxHeight: '100%'
    },
    item: {
        position: 'relative',
        height: '100%',
        overflowY: 'overlay',
        maxHeight: '80vh',
    },
    search: {
        width: 350,
        marginLeft: '15px'
    },
    formControl: {
        width: 350,
        margin: '15px 0px 8px 15px'
    },
    saveButton: {
        margin: '15px',
        backgroundColor: '#6F6CFF',
        color: 'white',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#6F6CFF',
            color: 'white',
        },
    },
    cancelButton: {
        backgroundColor: '#e2e2e2',
        color: 'black',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#e2e2e2',
            color: 'black',
        },
    },
    searchSelect: {
        width: '350px',
        marginTop: '15px',
        display: 'block',
        backgroundColor: 'white !important',
        '& > div': {
            width: '100%',
        },
        "& input:-internal-autofill-selected": {
            WebkitBoxShadow: '0 0 0 1000px white inset',
            fontFamily: 'Source Sans Pro, Verdana',
            fontSize: '16px'
        }
    },
}));

export const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: 'grey',
      color: 'white',
    },
  }));
