import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Dialog, DialogTitle, DialogActions, Autocomplete, DialogContent, InputLabel, FormControl, MenuItem, Button } from '@mui/material';
import { styles } from '../styles';
import CustomSelect from '../../../../assets/GlobalStyles/CustomSelect';
import { StyledTextField } from '../../../../assets/GlobalStyles/TextField';
import { fetchLineages } from '../../../../redux/actions/lineagesActions';
import appConfig from '../../../../config/appConfig';

const errorState = {
    email: {
        status: false,
        message: ''
    },
    username: {
        status: false,
        message: ''
    },
    password: {
        status: false,
        message: ''
    },
    role: {
        status: false,
        message: ''
    }
};
const initialStateUser = {
    email: '',
    username: '',
    password: '',
    role: 'user',
    lineages: [],
    defaultLineage: ''
};
const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const AddUserDialog = (props) => {
    const { handleCloseDialog, openDialog, lineages, fetchLineages, addUser, roles } = props;
    const [user, setUser] = useState(initialStateUser);
    const [error, setError] = useState(errorState);
    const classes = styles();
    const defaultLin = `Aplication default (${appConfig.default.lineage})`;

    useEffect(() => {
        if (lineages.length === 0)
            fetchLineages();
    }, []);

    const handleChange = (e, type) => {
        switch(type) {
            case 'email': {
                setUser({...user, email: e.target.value});
                if (error.email.status && e.target.value)
                    setError({...error, email: {status: false, message: ''}});
                return;
            }
            case 'password': {
                setUser({...user, password: e.target.value});
                if (error.password.status && e.target.value)
                    setError({...error, password: {status: false, message: ''}});
                return;
            }
            case 'username': {
                setUser({...user, username: e.target.value});
                if (error.username.status && e.target.value)
                    setError({...error, username: {status: false, message: ''}});
                return;
            }
            case 'role': {
                setUser({...user, role: e.target.value});
                if (error.role.status && e.target.value)
                    setError({...error, role: {status: false, message: ''}});
                return;
            }
            case 'defaultLineage': {
                setUser({...user, defaultLineage: e.target.value});
                return;
            }
        }
    };

    const validate = () => {
        const matchEmail = user.email.match(emailRegex);
        setError({
            username: !user.username ? {status: true, message: 'Username cannot be empty'} : errorState.username,
            email: !user.email ? {status: true, message: 'Email cannot be empty'} :
                !matchEmail ? {status: true, message: 'Email is not valid'} : errorState.email,
            password: !user.password ? {status: true, message: 'Password cannot be empty'} : errorState.password,
            role: !user.role ? {status: true, message: 'Role cannot be empty'} : errorState.role
        });
        const state = !user.username || !user.email || !user.password || !user.role || !matchEmail;
        return state;
    };

    const handleSubmit = () => {
        if (!validate()){
            const lineagesDict = lineages.reduce((acc, l)=> { acc[l] = true; return acc;},{});
            if (!lineages.includes(user.defaultLineage)) {
                const newUser = {...user, defaultLineage: ''};
                addUser(newUser);
                setUser(initialStateUser);
                return;
            }
            addUser(user);
            setUser(initialStateUser);
        }
    };

    return (
        <Dialog open={openDialog} onClose={handleCloseDialog} sx={{'& .MuiDialog-container': {
            '& .MuiPaper-root': {
              borderRadius: '12px'
            },
          }}}>
            <DialogTitle id="confirm-dialog-title">
                Add user
            </DialogTitle>

            <DialogContent>
                <StyledTextField
                    id="username"
                    type="text"
                    label="Username"
                    required
                    autoComplete='off'
                    className={classes.textField}
                    value={user.username}
                    onChange={(e) => handleChange(e, 'username')}
                    error={error.username.status}
                    helperText={error.username.message}
                />
                <StyledTextField
                    id="email"
                    type="email"
                    label="Email"
                    required
                    className={classes.textField}
                    value={user.email || ''}
                    onChange={(e) => handleChange(e, 'email')}
                    error={error.email.status}
                    helperText={error.email.message}
                />
                <StyledTextField
                    id="password"
                    type="password"
                    label="Password"
                    autoComplete="new-password"
                    required
                    className={classes.textField}
                    value={user.password}
                    onChange={(e) => handleChange(e, 'password')}
                    error={error.password.status}
                    helperText={error.password.message}
                />
                <FormControl className={classes.formControl} fullWidth>
                    <InputLabel required htmlFor="plotType">
                        Permissions
                    </InputLabel>
                    <CustomSelect
                        value={user.role}
                        defaultValue={roles[0]}
                        onChange={(e) => handleChange(e, 'role')}
                        className={classes.textField}
                        inputProps={{
                            name: 'role',
                            id: 'role',
                        }}
                    >
                        {roles.map((t) => (
                            <MenuItem key={t.name} value={t.name}>{t.name[0].toUpperCase() + t.name.slice(1)}</MenuItem>
                        ))}
                    </CustomSelect>
                        {error.role.status && <FormHelperText>Role cannot be empty</FormHelperText>}
                </FormControl>
                <Autocomplete
                    multiple
                    id="lineages"
                    options={lineages}
                    getOptionLabel={(option) => option}
                    value={user.lineages}
                    onChange={(e,v) => setUser({...user, lineages: v})}
                    className={classes.autoComplete}
                    disableCloseOnSelect
                    renderInput={(params) => (
                    <StyledTextField
                        {...params}
                        label="Available lineages"
                        placeholder={user.lineages.length > 0 ? '' : 'All lineages'}
                    />
                    )}
                />
                <FormControl className={classes.formControl} fullWidth>
                    <InputLabel htmlFor="plotType">
                        Default lineage
                    </InputLabel>
                    <CustomSelect
                        value={user.defaultLineage || defaultLin}
                        onChange={(e) => handleChange(e, 'defaultLineage')}
                        className={classes.textField}
                        inputProps={{
                            name: 'defaultLineage',
                            id: 'defaultLineage',
                        }}
                    >
                        <MenuItem key='default' value={defaultLin}>{defaultLin}</MenuItem>
                        {user.lineages.length > 0 ?
                            user.lineages.map((lineage) => (
                                <MenuItem key={lineage} value={lineage}>{lineage}</MenuItem> )) :
                            lineages.map((lineage) => (
                                <MenuItem key={lineage} value={lineage}>{lineage}</MenuItem> ))
                        }
                    </CustomSelect>
                </FormControl>
            </DialogContent>

            <DialogActions>
                <Button className={classes.cancel}
                    onClick={handleCloseDialog}>Cancel</Button>
                <Button className={classes.confirm} onClick={handleSubmit}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>);
};

const mapStateToProps = state => {
    const lineages = state.lineages.lineages;

    return {
        lineages
    };
};

const mapDispatchToProps = {
    fetchLineages
}

export default connect(mapStateToProps, mapDispatchToProps)(AddUserDialog);
