
import React, { PureComponent } from 'react';
import { InputAdornment, IconButton, FormControl } from '@mui/material';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { bindActionCreators } from 'redux';
import { StyledTextField } from '../../assets/GlobalStyles/TextField';
import { setParameters } from '../../redux/actions/parametersActions';
import { fetchRecalculatedTree } from '../../redux/actions/treeDataActions';
import { MinusIcon, PlusIcon, ValidateIcon } from '../SvgIcons/SequencesInputIcons';
import { getDisplayedSequencesNumber } from '../../redux/selectors/treeDataSelector';
import { setAlertStatus } from '../../redux/actions/alertActions';

const styles = {
    formControl: {
        margin: '16px 0px 0px 0px'
    },
    text: {
        textTransform: 'capitalize'
    },
    icon: {
        padding: 0,
        marginRight: '3px',
        '&:hover': {
            color: '#000000',
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
            color: '#000000',
          },
    },
    iconEnd : {
        padding: 0,
        margin: 0,
    }
};

class ShowLeafNumberSelector extends PureComponent {
    state = {
        showLeafNumberValue: 0,
        canSubmit: false,
        difference: 0,
        initialValue: 0
    }

    componentDidMount() {
        const { showLeafNumber } = this.props;

        if (!this.state.showLeafNumberValue)
            this.setState({
                showLeafNumberValue: showLeafNumber,
                initialValue: showLeafNumber
            })
    }

    componentDidUpdate() {
        const { showLeafNumber, displayedSequencesNumber, setAlertStatus } = this.props;
        if (!this.state.showLeafNumberValue)
            this.setState({
                showLeafNumberValue: showLeafNumber,
                initialValue: showLeafNumber
            })

        const intValue = parseInt(this.state.showLeafNumberValue)
        const difference = Math.abs(displayedSequencesNumber - intValue);

        if (difference !== this.state.difference && !this.state.canSubmit && intValue !== 0 && difference !== intValue) {
            this.setState({ difference: difference })
            difference > 50 ?
                setAlertStatus({id: 'fetchSequences', status: true, model: 'strainTree', messageVar: displayedSequencesNumber.toString()}) :
                setAlertStatus({id: 'fetchSequences', status: false, model: 'strainTree'});
        } else if (displayedSequencesNumber === 0) setAlertStatus({id: 'fetchSequences', status: false, model: 'strainTree'});
    }

    handleChange = (e) => {
        let input = e.target.value;

        if (input == ''){
            this.setState({
                showLeafNumberValue: ' ',
                canSubmit: false,
            })
            return;
        }
        else {
            this.setState({
                showLeafNumberValue: input,
                canSubmit: true,
            })
        }
    }

    handleClick = (type) => {
        const inputInt = parseInt(this.state.showLeafNumberValue);
        const newValue = type === 'up' ? inputInt + 100 : inputInt - 100;

        this.setState({
            showLeafNumberValue: newValue.toString(),
            canSubmit: true
        })
    }

    handleSubmit = (e) => {
        const { setParameters, fetchRecalculatedTree, lineage, colorBy, refStrain, ignoreStrainCutOffDate, showRule } = this.props;
        const intValue = this.state.showLeafNumberValue;
        const params = {
            showLeafNumber: intValue,
            lineage,
            colorBy,
            refStrain,
            ignoreStrainCutOffDate,
            showRule
        }

        setParameters({
            showLeafNumber: intValue
        })

        fetchRecalculatedTree(params);
        this.setState({
            canSubmit: false
        })

        e.preventDefault();
    }

    render = () => {
        const { classes } = this.props;

        return (
            <FormControl className={classes.formControl} fullWidth>
                <StyledTextField
                    id="show-leaf-number"
                    label="Displayed sequences"
                    type='number'
                    value={this.state.showLeafNumberValue || ''}
                    onChange={this.handleChange}
                    style={{marginTop: '2px'}}
                    InputProps={{
                        endAdornment:
                        <InputAdornment position="end" >
                            <IconButton className={classes.icon} onClick={() => this.handleClick('down')}>
                                <MinusIcon/>
                            </IconButton>
                            <IconButton className={classes.icon} onClick={() => this.handleClick('up')}>
                                <PlusIcon/>
                            </IconButton>
                            <IconButton type='submit' className={classes.iconEnd} disabled={!this.state.canSubmit} onClick={this.handleSubmit}>
                                <ValidateIcon color={this.state.canSubmit ? '#0000FF' : '#888888'}/>
                            </IconButton>
                        </InputAdornment>
                    }}
                />
            </FormControl>
        )
    }
}

ShowLeafNumberSelector.propTypes = {
    showLeafNumber: PropTypes.number,
    classes: PropTypes.shape({ formControl: PropTypes.string, root: PropTypes.string }),
    setParameters: PropTypes.func,
    fetchRecalculatedTree: PropTypes.func,
    displayedSequencesNumber: PropTypes.number,
};

const mapStateToProps = (state) => ({
    showLeafNumber: state.parameters.showLeafNumber,
    lineage: state.parameters.lineage,
    treeDataStatus: state.treeData.treeDataStatus,
    colorBy: state.parameters.colorBy,
    showRule: state.parameters.showRule,
    refStrain: state.parameters.refStrain,
    ignoreStrainCutOffDate: state.parameters.ignoreStrainCutOffDate,
    displayedSequencesNumber: getDisplayedSequencesNumber(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setParameters,
    fetchRecalculatedTree,
    setAlertStatus
    }, dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ShowLeafNumberSelector));
