import React from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { getColorBins, getColorByMeasure, getMeasure, getSelectedBins } from '../../../redux/selectors/metadataSelector';
import { numFormat } from '../../../functions/formats';
import { daysToDate } from '../../../functions/functions';
import { getMetadataMeasuresWithScales } from '../../../redux/selectors/rangeDataSelector';
import { getCurrentColorScalesForMeasures } from '../../../redux/selectors/parametersSelector';
import LegendElement from '../Elements/LegendElement';
import ContinousScale from '../Elements/ContinousScale';

const ScalesLegend = (props) => {
    const { scaleId, title, inContainer, precision, ticks, isTimeScale,
        measureName, valueGetter, discrete, colors, domain, mode, colorBins, chartLayout, visibleBins, exportMode
    } = props;
    
    const formatLabel = (value, precision) => {
        if (isTimeScale)
            return daysToDate(value).toLocaleDateString();

        return numFormat(value, precision);
    }

    const _domain = (Object.values(colorBins||{})).filter(el=> mode === 'frequencies' || el.bin !== -1);
    return (
        <>
            {discrete && domain && colors && 
            <div>
                {_domain.map((el, index) => {
                    const { bin, v } = el;
                    const v0 = (bin !== -1 && index > 0) ? formatLabel(_domain[index-1].v, precision) : undefined;
                    const v1 = (bin !== -1 && v !== undefined) ? formatLabel(v, precision) : undefined;
                    const visible = mode !== 'frequencies' || chartLayout === 'stacked' || visibleBins[bin];
                    const label = (bin === -1) ? 
                        'Missing value' : 
                        v0 && v1 ?
                            `${v0} < x < ${v1}` :
                            !v0 && v1 ?
                                `x < ${v1}` :
                                `x > ${v0}`;
            
                    return < LegendElement
                        key={`elem-${scaleId}_${index}`}
                        keyId={`${scaleId}_${index}`} 
                        color={_domain[index].color} 
                        fontSize={measureName === 'loc' ? '14px' : '12px'}
                        visible={visible}
                        label={label}
                        border={`1px solid ${_domain[index].color}`}
                        size={exportMode ? 12 : 17}
                    />
                })}
            </div>}
            {!discrete && domain && 
                <ContinousScale 
                    measureName={measureName}
                    precision={precision}
                    formatLabel={formatLabel}
                    title={title}
                    inContainer={inContainer}
                    ticks={ticks}
                    valueGetter={valueGetter}
                    scaleId={scaleId}
                />
            }
        </>
    );
}

ScalesLegend.propTypes = {
    value: PropTypes.number,
    scaleId: PropTypes.string,
    measureName: PropTypes.string.isRequired,
    title: PropTypes.string,
    inContainer: PropTypes.bool,
    precision: PropTypes.number,
    ticks: PropTypes.number,
};


const mapStateToProps = (state, ownProps) => {
    const { colorBy, chartLayout, exportMode } = state.parameters
    const measureName = ownProps.measureName || colorBy;
    const { scaleId } = ownProps;
    const measuresWithScales = getMetadataMeasuresWithScales(state);
    const measuresScales = getCurrentColorScalesForMeasures(state);
    const scales = state.metadata.scales;
    const discrete = measuresWithScales[measureName]?.scale.discrete;
    const measure = getMeasure(state, ownProps.measureName) || getColorByMeasure(state);
    const modelType = scaleId !== 'strainTree' ? 'antigenic' : 'strainTree';
    const colorScale = measuresScales[measureName]; 
    const domain = measuresWithScales[measureName]?.scale.domain;
    const colors = scales[colorScale]?.range;
    const _colorBy = (ownProps.mode === 'frequencies') ? 'freqCategory' : 'colorBy';
    const { visibleBins } = state.parameters;
    const colorBins = getColorBins(_colorBy, modelType)(state);
    const selectedBins = getSelectedBins(state);

    return ({
        chartLayout,
        modelType,
        measureName,
        discrete,
        colors,
        domain,
        exportMode,
        isTimeScale: measure && measure.time,
        visibleBins: ownProps.mode === 'frequencies' && visibleBins,
        colorBins: /*ownProps.mode === 'frequencies' &&*/ colorBins,
        selectedBins: ownProps.mode === 'frequencies' && selectedBins,
    });
};

export default connect(mapStateToProps)(ScalesLegend);
