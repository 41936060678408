import { createSelectorCreator, defaultMemoize, createSelector } from 'reselect';
import { isEqual } from 'lodash';
import { getMetadataMeasuresWithScales } from './rangeDataSelector';

// create a "selector creator" that uses lodash.isequal instead of ===
const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

const getTrackingTo = ({ parameters }) => parameters.trackingTo;
const getTrackingFrom = ({ parameters }) => parameters.trackingFrom;
const getPredictionBaseline = ({ parameters }) => parameters.predictionBaseline;
const getColorBy = ({parameters}) => parameters.colorBy;
const getRegions = ({parameters}) => parameters.regions;
const getColorScales = ({parameters}) => parameters.colorScales;
const getFreqCategory = ({ parameters }) => parameters.freqCategory;
const getGeoMapColorBy = ({ parameters }) => parameters.geoMapColorBy;

export const trackingToSelector = createDeepEqualSelector(
    getTrackingTo,
    (trackingTo) => trackingTo,
);

export const trackingFromSelector = createDeepEqualSelector(
    getTrackingFrom,
    (trackingFrom) => trackingFrom,
);

export const predictionBaselineSelector = createDeepEqualSelector(
    getPredictionBaseline,
    (predictionBaseline) => predictionBaseline,
);

export const getStrainSubset = ({ parameters }) => parameters.strainSubset;

export const getStrainSubsetsOptions = ({ metadata }) => metadata.strainSubsetOptions;

export const strainSubsetsOptionsDictSelector = createSelector(getStrainSubsetsOptions, strainSubsetOptions => {
    const res = strainSubsetOptions.reduce((acc, option) => { acc[option.key] = option; return acc }, {});
    //console.log(res);
    return res;
})

const getRegionsArr = createSelector(getRegions, regions => {
    const res = regions ? Object.keys(regions) : [];
    return res;
})

export const getRegionsArrWithAll = createSelector([getRegionsArr, getRegions], (regionsArr, regions) => {
    const res = ['ALL', ...regionsArr];
    return res;
})

export const getRegionsLabelsArr = createSelector([getRegionsArr, getRegions], (regionsArr, regions) => {
    const res = regionsArr.map(region => regions[region].label);
    return res;
})


export const getRegionsWithLabels = createSelector([getRegionsArr, getRegions], (regionsArr, regions) => {
    const res = ['ALL', ...regionsArr].map(region => ({ key: region, label:  region === 'ALL' ? 'All' : regions[region].label }));
    return res;
})

export const getIgnoreStrainCutOffDate = createSelector(
    [strainSubsetsOptionsDictSelector, getStrainSubset],
    (strainSubsetOptionsDict, strainSubset) => {

        const res = strainSubsetOptionsDict?.[strainSubset]?.ignoreStrainCutOffDate || false;
        //console.log(res);
        return res;
    });


export const getColorScaleForColorBy = createSelector([getColorBy, getColorScales, getMetadataMeasuresWithScales], (colorBy, colorScales, measures) => colorScales[colorBy] || measures[colorBy]?.scale?.scaleName || `${colorBy}.default`);

export const getCurrentColorScalesForMeasures = createSelector([getColorScales, getMetadataMeasuresWithScales], (colorScales, measures) => {
    const res = Object.keys(measures).reduce((acc, m) => {
        acc[m] = colorScales[m] || measures[m]?.scale?.scaleName || `${m}.default`;
        return acc;
    },{});
   // console.log('[getCurrentColorScalesForMeasures]',res);
    return res;
}) 

export const getScaleNameForColorBy = createSelector([getCurrentColorScalesForMeasures, getColorBy], (measuresScales, colorBy) => measuresScales[colorBy]);

export const getScaleNameForFreqCategory = createSelector([getCurrentColorScalesForMeasures, getFreqCategory], (measuresScales, freqCategory) => measuresScales[freqCategory]);
    // const selectedMeasure = measures[freqCategory] || {};
    // return getScaleNameForMeasure(selectedMeasure, parameters);

export const getScaleNameForGeoMapColorBy = createSelector([getCurrentColorScalesForMeasures, getGeoMapColorBy], (measuresScales, geoMapColorBy) => measuresScales[geoMapColorBy]);


