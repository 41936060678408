import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    Checkbox, FormGroup, Divider, FormControlLabel, FormControl, InputLabel,
    MenuItem, Grid, Button, Typography
} from '@mui/material';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import { StyledTextField } from '../../../assets/GlobalStyles/TextField';
import CustomSelect from '../../../assets/GlobalStyles/CustomSelect';
import { fetchLineage } from '../../../redux/actions/lineagesActions';
import { fetchMutationClasses, fetchVpMethods } from '../../../redux/actions/treeDataActions';
import { fetchMutationsPositionsDictionary } from '../../../redux/actions/genotypeActions';
import { fetchModels, fetchModelTypes } from '../../../redux/actions/modelActions';
// import { fetchAntigenicModelsList } from '../../../redux/actions/antigenicActions';
import { setSettings, setSettingsParameters, setSettingsMetadata } from '../../../redux/actions/settingsActions';
import { emptyObject, daysToDate, dateToDays, isFetchNeeded } from '../../../functions/functions';
// import appConfig from '../../../config/appConfig';
// import { getTreeScaleXOptions, getTreeScaleYOptions } from '../../../redux/selectors/metadataSelector';
import { Select } from './utils/Select';
import { styles } from './styles';
import { dateFormat } from '../../../functions/checkBrowser';
import Alert from './utils/Alert';
import { parametersToEdit } from './parametersToEdit';
import ClearCache from './utils/ClearCache';
import { getEditableParameters, getSelectSettingsOptions } from '../../../redux/selectors/settingsSelector';
// import { getAntigenicModelOptionsSettings } from '../../../redux/selectors/modelsSelector';
import FloatedInput from '../../../components/Common/FloatedInput';

const parametersToEditArray = Object.keys(parametersToEdit).sort();
const alertState = { open: false, type: '' };

const tabs = {
    strainTree: 'Strain Tree',
    clades: 'Frequencies',
    antigenic: 'Antigenic',
    geomap: 'Geomap'
};

const ParametersPanel = ({
    parameters, lineages, modules,
    lineage, fetchLineage, options, fetchVpMethods, setSettings,
    fetchMutationsPositionsDictionary, fetchModels, fetchAntigenicModelsList, fetchMutationClasses,
    lineageStatus, modelTypesStatus,
    fetchModelTypes, setSettingsParameters, setSettingsMetadata
}) => {
    const [openAlert, setOpenAlert] = useState(alertState);
    const classes = styles();

    //console.log('lineageStatus = ',lineageStatus)


    useEffect(() => {
        if (lineageStatus !== 'loaded') return;

        // if (lineage && isFetchNeeded(lineageStatus)) {
        //     fetchLineage({lineage, settings: true});
        //     return;
        // }
        fetchMutationsPositionsDictionary({ lineage, settings: true });
        fetchVpMethods({ lineage, settings: true });
        fetchModels({lineage, colorBy: 'antigenic', settings: true})
        fetchModels({lineage, colorBy: 'fitness', modelRegionId: parameters.modelRegionId, modelType: parameters.modelType, settings: true})
        // fetchAntigenicModelsList({ lineage, settings: true });
        fetchMutationClasses({ lineage, settings: true });
    }, [lineageStatus]);

    useEffect(() => {
        if (lineageStatus !== 'loaded' || !lineage) return;
        // fetchModelTypes({ lineage, modelRegionId: parameters.modelRegionId, colorBy: 'antigenic', settings: true });
        fetchModelTypes({ lineage, modelRegionId: parameters.modelRegionId, colorBy: 'fitness', settings: true });
    }, [parameters.modelRegionId, lineageStatus])

    useEffect(() => {
        const modelType = parameters.modelType || parameters.modelType;
        if (modelTypesStatus === 'loaded' && modelType)
            fetchModels({ lineage, colorBy: 'fitness', modelRegionId: parameters.modelRegionId, modelType, settings: true });
    }, [modelTypesStatus])




    // useEffect(() => {
    //     if (!antigenicModelIdItems) return;

    //     setAntigenicStateModelIdItems(antigenicModelIdItems);
    // }, [antigenicModelIdItems]);

    // useEffect(() => {
    //     if (!modelIdItems) return;

    //     setStateModelIdItems(modelIdItems);
    // }, [modelIdItems]);

    // useEffect(() => {
    //     if (!modelIdItemsOptions) return;

    //     setStateModelIdItemsOptions(modelIdItemsOptions);
    // }, [modelIdItemsOptions]);

    // useEffect(() => {
    //     //console.log('RRRRRRR,', parameters.modelType)
    //     setStateParameters(parameters);
    //     if (!currentLineage)
    //         setCurrentLineage(lineage);
    // }, [parameters]);




    const handleOpenAlert = (type) => {
        setOpenAlert({
            open: true,
            type
        })
    };

    const handleCloseAlert = () => {
        window.history.back();
        setOpenAlert(alertState);
    };

    const handleChangeLineage = (e) => {
        //setCurrentLineage(e.target.value);
        //console.log('aaa', e.target.value)
        const lineage = e.target.value;
        setSettingsParameters({ lineage });
        fetchLineage({ lineage, settings: true });
    };

    const handleChangeModules = (e, type) => {
        const checked = e.target.checked;
        const _modules  = { ...modules };
        _modules[type] = checked;

        setSettingsMetadata({ modules: _modules })
        // setStateParameters({
        //     ...stateParameters,
        //     modules: modulesState
        // });
    }

    const handleChangeCheckbox = (e, type) => {
        const checked = e.target.checked;

        if (type === 'showCladeBar' && !checked) {
            // setStateParameters({
            //     ...stateParameters,
            //     [type]: checked,
            //     showCladeBarLabels: false
            // });
            setSettingsParameters({
                [type]: checked,
                showCladeBarLabels: false
            })
            return;
        }

        if (type === 'showCladeBarLabels' && checked) {
            // setStateParameters({
            //     ...stateParameters,
            //     [type]: checked,
            //     showCladeBar: true
            // });

            setSettingsParameters({
                [type]: checked,
                showCladeBar: true
            })
            return;
        }

        setSettingsParameters({
            [type]: checked,
        })

        // setStateParameters({ ...stateParameters, [type]: checked });
    };

    const handleChangeInput = (e, type) => {
        setSettingsParameters({ [type]: parseFloat(e.target.value) });
        // setStateParameters({ ...stateParameters, [type]: e.target.value });
    };

    const handleChangeDate = (e, type, isDateNumber) => {
        const value = isDateNumber ? dateToDays(e) : e;
        setSettingsParameters({ [type]: value });
        // setStateParameters({ ...stateParameters, [type]: value });
    };

    const handleChangeSelect = (e, type) => {
        const value = e.target.value;

        if (type === 'mutgene') {
            setSettingsParameters({ [type]: value, mutposition: options.mutposition[value][0] });
            //setStateParameters({ ...stateParameters, [type]: value, mutposition: options.mutposition[value][0] });
            return;
        }

        if (type === 'modelType') {
            fetchModels({ lineage, modelRegionId: parameters.modelRegionId, modelType: value, colorBy: 'fitness', settings: true  });
            //const modelIdItems = getModelIdItems(stateParameters.modelId, value);
            // const modelIdItemsOptions = getModelIdItemsOptions(value);
            //setStateModelIdItemsOptions(modelIdItemsOptions);
            //setStateModelIdItems(modelIdItems);
            setSettingsParameters({ [type]: value }); //, modelId: value  });
            //setStateParameters({ ...stateParameters, [type]: value, modelId: value });
            return;
        }
        // } else if (type === 'modelType' && value === 'Antigenic') {
        //     const modelIdItems = getModelIdItems(appConfig.default.antigenicModelId, value);
        //     const modelIdItemsOptions = getModelIdItemsOptions(value);
        //     setStateModelIdItemsOptions(modelIdItemsOptions);
        //     setStateModelIdItems(modelIdItems);
        //     setStateParameters({ ...stateParameters, [type]: value, modelId: appConfig.default.antigenicModelId });
        //     return;
        // }
        setSettingsParameters({ [type]: value });
        // setStateParameters({ ...stateParameters, [type]: value });
    };



    const handleSubmit = () => {
        const _parameters = Object.keys(parameters)
            .reduce((acc, k) => { 
                const p = parameters[k];
                if (p instanceof Date && k === 'strainCutOffDate')
                console.log(k, p instanceof Date, p, dateToDays(p), p.toLocaleDateString());
                acc[k] = (p instanceof Date) ? dateToDays(p) : p;
                return acc;
            }, {});
        setSettings({ parameters: _parameters, lineage, modules, handleOpenAlert });
    }

    const showSelect = (value, options) => options && options.length > 0 && options.includes(value);

    return (
        <div className={classes.root}>
            {lineageStatus === 'loaded' &&
                <div className={classes.content}>
                    <ClearCache />
                    <Grid container spacing={2} >
                        {lineage && lineages.includes(lineage) &&
                            <Grid item key={`lineage`} xs={4}>
                                <FormControl fullWidth className={classes.lineageSelect}>
                                    <InputLabel htmlFor="plotType">
                                        Current Lineage
                                    </InputLabel>
                                    <CustomSelect
                                        className={classes.textField}
                                        value={lineages.length > 0 ? lineage || '' : ''}
                                        onChange={handleChangeLineage}
                                        inputProps={{
                                            name: 'lineage',
                                            id: 'lineage',
                                        }}
                                    >
                                        {lineages.length > 0 &&
                                            lineages.map((lineage) => (
                                                <MenuItem key={lineage} value={lineage}>{lineage}</MenuItem>))
                                        }
                                    </CustomSelect>
                                </FormControl>
                            </Grid>
                        }
                        <Grid item key={`button`} xs={8}>
                            <Button className={`${classes.buttonUp} ${classes.button}`} onClick={handleSubmit}>
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                    <FormGroup className={classes.group}>
                         <Typography className={classes.title}>Tabs</Typography>
                        <Divider className={classes.divider} />

                        <Grid container spacing={2} className={classes.section}>
                            {modules && Object.keys(tabs).sort().map(((element, index) => {
                                return (
                                    <Grid item key={`item-${index}`} xs='auto' >
                                        <FormControlLabel
                                            key={`checkbox-${index}`}
                                            disabled={element === 'strainTree'}
                                            className={classes.checkbox}
                                            label={tabs[element]}
                                            control={
                                                <Checkbox checked={modules[element] || false}
                                                    onChange={(e) => handleChangeModules(e, element)}
                                                    name={element}
                                                    icon={<CircleUnchecked />}
                                                    checkedIcon={<RadioButtonCheckedIcon style={{ color: '#6F6CFF' }} />}
                                                />}
                                        />
                                    </Grid>
                                )
                            }))}
                        </Grid>


                        <Typography className={classes.title}>Options parameters</Typography>
                        <Divider className={classes.divider} />
                        <Grid container spacing={2} className={classes.section}>
                            {parametersToEditArray.filter(type => parametersToEdit[type] === 'select').map((element, index) => {
                                return (options[element] && options[element].length > 0 &&
                                    <Grid item key={`item-${index}-${element}`} xs='auto'>
                                        <Select
                                            options={options[element]}
                                            handleChangeSelect={handleChangeSelect}
                                            value={parameters[element]}
                                            key={`select-${index}`}
                                            id={element}
                                        />
                                    </Grid>
                                )
                            })}
                            {options.mutposition && options.mutposition[parameters.mutgene] &&
                                <Grid item key={`item-mutposition`} xs='auto' >
                                    <Select
                                        options={options['mutposition'][parameters.mutgene]}
                                        handleChangeSelect={handleChangeSelect}
                                        value={parameters['mutposition']}
                                        key={`select-mutposition`}
                                        id={'mutposition'}
                                    />
                                </Grid>
                            }
                        </Grid>

                        
                        <Typography className={classes.title}>Date parameters</Typography>
                        <Divider className={classes.divider} />
                        <Grid container spacing={2} className={classes.section}>
                            {parametersToEditArray.filter(type => parametersToEdit[type] === 'date').map((element, index) => {
                                const isDateNumber = typeof parameters[element] === 'number';
                                
                                const value = isDateNumber ? daysToDate(parameters[element]) : parameters[element];

                                //console.log(element, isDateNumber, value)
                                return (
                                    <Grid item xs='auto' className={classes.item} key={`datepicker-${index}`}>
                                        <LocalizationProvider key={`locProv-${index}`} dateAdapter={AdapterDateFns} >
                                            <DesktopDatePicker
                                                disableToolbar
                                                variant="inline"
                                                format={dateFormat}
                                                label={element}
                                                value={value}
                                                onChange={(e) => handleChangeDate(e, element, isDateNumber)}
                                                slotProps={{
                                                    textField: { 
                                                        variant: 'standard',
                                                        style: { width: '350px' },
                                                    },
                                                    inputAdornment: { position: 'start'}
                                                }}
                                                />
                                        </LocalizationProvider>
                                    </Grid>
                                );
                            })}
                        </Grid>

                        <Typography className={classes.title}>Boolean parameters</Typography>
                        <Divider className={classes.divider} />

                        <Grid container spacing={2} className={classes.section}>
                            {parametersToEditArray.filter(type => parametersToEdit[type] === 'boolean').map((element, index) => {
                                return (
                                    <Grid item key={`item-${index}`} xs='auto' >
                                        <FormControlLabel
                                            key={`checkbox-${index}`}
                                            className={classes.checkbox}
                                            control={
                                                <Checkbox checked={parameters[element] || false}
                                                    onChange={(e) => handleChangeCheckbox(e, element)}
                                                    name={element}
                                                    icon={<CircleUnchecked />}
                                                    checkedIcon={<RadioButtonCheckedIcon style={{ color: '#6F6CFF' }} />}
                                                />}
                                            label={element}
                                        />
                                    </Grid>
                                )
                            })}
                        </Grid>

                        <Typography className={classes.title}>Numeric parameters</Typography>
                        <Divider className={classes.divider} />
                        <Grid container spacing={2} className={classes.section}>
                            {parametersToEditArray.filter(type => parametersToEdit[type] === 'number').map((element, index) => {
                                return (
                                    <Grid item key={`item-${index}-${element}`} xs='auto' className={`${classes.item} ${classes.number}`}>
                                        <FloatedInput
                                            className={classes.textField}
                                            element={element}
                                            initValue={parameters[element]}
                                            setSettingsParameters={setSettingsParameters}
                                        />
                                    </Grid>
                                )
                            })}
                        </Grid>

                        <Typography className={classes.title}>Default model</Typography>
                        <Divider className={classes.divider} />

                        <Grid container spacing={2} className={classes.section}>
                            <Grid item container xs={12}>
                                {showSelect(parameters.modelType, options.modelType) &&
                                    <Grid item key={`item-modelType`} xs='auto' >
                                        <Select
                                            options={options.modelType}
                                            handleChangeSelect={handleChangeSelect}
                                            value={parameters.modelType}
                                            key={`select-modelType`}
                                            id={'modelType'}
                                        />
                                    </Grid>
                                }
                                {!showSelect(parameters.modelType, options.modelType) && 
                                    <Typography className={classes.text}>No models for this lineage</Typography>
                                }
                                <Grid item key={`item-modelId`} xs='auto' className={classes.modelId}>
                                    {showSelect(parameters.modelId, options.modelId) &&
                                        <Select
                                            options={options.modelId}
                                            id={'modelId'}
                                            key={`input-modelId`}
                                            label={'modelId'}
                                            handleChangeSelect={handleChangeSelect}
                                            // className={classes.textField}
                                            value={parameters.modelId || ''}
                                            error={!options.modelId.includes(parameters.modelId)}
                                        // helperText={!options.modelId.includes(parameters.modelId) && antigenicSegmentsCount > 0 ? 'Model is not valid, please change options' : ''}
                                        />
                                    }
                                </Grid>
                            </Grid>
                            {/* {antigenicSegmentsCount > 0 &&
                            <Grid item container className={classes.section} spacing={2}>
                                {!emptyObject(stateModelIdItems) && Object.keys(stateModelIdItems).map((id) => {
                                    return (stateModelIdItemsOptions[id].length > 0 &&
                                        <Grid item key={`modelId-items-${id}`} xs='auto'>
                                            <Select
                                                options={stateModelIdItemsOptions[id]}
                                                handleChangeSelect={handleChangeModelIdItem}
                                                value={stateModelIdItems[id]}
                                                key={`select-${id}`}
                                                id={id}
                                            />
                                        </Grid>
                                    )
                                })
                                }
                            </Grid>
                        } */}
                        </Grid>

                        <Typography className={classes.title}>Default antigenic model</Typography>
                        <Divider className={classes.divider} />

                        <Grid container spacing={2} className={classes.lastSection}>
                            <Grid item container xs={12}>

                                {showSelect(parameters.antigenicModelId, options.antigenicModelId) &&
                                    <Select
                                        options={options.antigenicModelId}
                                        handleChangeSelect={handleChangeSelect}
                                        id={'antigenicModelId'}
                                        key={`input-antigenicModelId`}
                                        label={'antigenicModelId'}
                                        className={classes.textField}
                                        value={parameters.antigenicModelId || ''}
                                        error={!options.modelId.includes(parameters.antigenicModelId)}
                                        helperText={!options.modelId.includes(parameters.antigenicModelId) ? 'Model is not valid, please change options' : ''}
                                    />}
                                {!showSelect(parameters.antigenicModelId, options.antigenicModelId) && <Typography className={classes.text}>No models for this lineage</Typography>}
                            </Grid>
                        </Grid>
                    </FormGroup>
                    <Grid container spacing={2}>
                        <Grid item key={`save`} xs={12}>
                            <Button className={`${classes.button} ${classes.buttonDown}`} onClick={handleSubmit}>
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                    <Alert openAlert={openAlert} handleCloseAlert={handleCloseAlert} />
                </div>}
        </div>
    );
};

const mapStateToProps = (state) => {
    //const options = {};
    const lineages = state.lineages.lineages;
    const metadata = state.settings.metadata;
    const parameters = getEditableParameters(state); //filterParameters(state.settings.parameters, parametersToEditArray);
    const options = getSelectSettingsOptions(state);

    return {
        lineages,
        // lineagesStatus: state.lineages.lineagesStatus,
        lineageStatus: state.settings.lineages?.lineageStatus,
        parameters,
        modules: metadata.modules,
        lineage: state.settings.parameters.lineage,
        options,
        // modelIdItems,
        // modelIdItemsOptions,
        // antigenicModelIdItems,
        // antigenicModelIdItemsOptions,
        modelTypesStatus: state.settings.models.modelTypesStatus,
        // antigenicSegmentsCount

    }
};


const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchVpMethods,
            fetchLineage,
            fetchMutationsPositionsDictionary,
            fetchModels,
            // fetchAntigenicModelsList,
            setSettings,
            fetchModelTypes,
            fetchMutationClasses,
            setSettingsParameters,
            setSettingsMetadata
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(ParametersPanel);
