import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { FormControl, InputLabel, Grid, MenuItem } from '@mui/material';
import CustomSelect from '../../assets/GlobalStyles/CustomSelect';
import { setParameters } from '../../redux/actions/parametersActions';
import { fetchMeasureScalesDomains } from '../../redux/actions/settingsActions';
import { resetHumanSerologyData } from '../../redux/actions/humanSerologyActions';


const useStyles = makeStyles({
    formControl: {
        margin: '8px 0px'
    },
    input: {
        height: '35px',
        lineHeight: '35px',
        boxSizing: 'border-box'
    }
});

const humanSerologyDataTypes = [
    { id: 'observed', label: 'Observed' },
    { id: 'inferred', label: 'Inferred' },
];

const HumanSerologySelector = props => {

    const { humanPool, humanPools, humanPoolsStatus, humanSerologyDataType, 
        setParameters, resetData, resetHumanSerologyData } = props;
    const classes = useStyles();


    const handleChange = name => event => {
        // console.log(name, event.target.value)
        const value = event.target.value;
        setParameters({[name]: value});
        resetHumanSerologyData();

        if (resetData) {
            resetData();
        }

    };
    

    return <Grid container spacing={1}>
        {humanPoolsStatus === 'loaded' && (
            <>
                <Grid item sm={12} md={6}>
                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel htmlFor="humanPool">
                            Human pool
                        </InputLabel>
                        <CustomSelect
                            value={humanPool}
                            onChange={handleChange('humanPool')}
                            inputProps={{ name: `${humanPool}`, id: `${humanPool}` }}
                        >
                            {humanPools.map(val => (
                                <MenuItem key={val} value={val}>{val}</MenuItem>
                            ))}
                        </CustomSelect>
                    </FormControl>
                </Grid>

                <Grid item sm={12} md={6}>
                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel htmlFor="humanSerologyDataType">
                            Data type
                        </InputLabel>
                        <CustomSelect
                            value={humanSerologyDataType}
                            onChange={handleChange('humanSerologyDataType')}
                            inputProps={{ name: 'humanSerologyDataType', id: 'humanSerologyDataType' }}
                        >
                            {humanSerologyDataTypes.map(({ id, label }) => (
                                <MenuItem key={id || '_'} value={id}>{label}</MenuItem>
                            ))}
                        </CustomSelect>
                    </FormControl>
                </Grid>
            </>)}
    </Grid>
}



HumanSerologySelector.propTypes = {
    colorBy: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => {
    const humanPools = state.humanSerology.humanPools;
    const humanPoolsStatus =state.humanSerology.humanPoolsStatus;
    const humanPool = state.parameters.humanPool; 
    return ({
        lineage: state.parameters.lineage,
        colorBy: ownProps.mapSelector
            ? state.parameters.geoMapColorBy
            : state.parameters.colorBy,
        humanPool: humanPool,
        humanPools: humanPools,
        humanPoolsStatus: humanPoolsStatus,
        humanSerologyDataType: state.parameters.humanSerologyDataType
    });
};

const mapDispatchToProps = dispatch => ({
    setParameters: payload => dispatch(setParameters(payload)),
    fetchMeasureScalesDomains: payload => dispatch(fetchMeasureScalesDomains(payload)),
    resetHumanSerologyData: () => dispatch(resetHumanSerologyData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(HumanSerologySelector);
