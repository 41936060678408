import {
    SELECT_NODE_DATA,
    RESET_SESSION,
    SIGNOUT_REQUEST,
    SET_ACTIVE_LEGEND_OPTION,
    FETCH_NODE_REQUEST,
    FETCH_NODE_SUCCESS,
    FETCH_NODE_ERROR,
    SET_PARAMETERS
} from '../actions/actionTypes';

let nodeDataInitialState = { };
export const setNodeDataInitialState = (state) => {
    nodeDataInitialState = state;
};

export default function nodeDataReducer(state = nodeDataInitialState, action) {
    switch (action.type) {
        case RESET_SESSION:
        case SIGNOUT_REQUEST: {
            return nodeDataInitialState;
        }
        case SET_PARAMETERS: {
            //console.log('SET_PARAMETERS nodeDataReducer')
            const { strainId, searchStrainMode } = action.payload.parameters;

            return {
                ...state,
                nodeId: searchStrainMode && strainId ? +strainId : null,
            };
        }
        case SELECT_NODE_DATA: {
            const nodeId = action.payload && action.payload.nodeId;
            const nodeType = action.payload && action.payload.nodeType;
            return {
                ...state,
                nodeId,
                nodeType
            };
        }
        case SET_ACTIVE_LEGEND_OPTION: {
            const activeLegendOption = action.payload || state.activeLegendOption;
            return {
                ...state,
                activeLegendOption
            }
        }
        case FETCH_NODE_REQUEST: {
            return {
                ...state,
                nodeData: [],
                loadingNode: true
            }
        }
        case FETCH_NODE_SUCCESS: {
            return {
                ...state,
                nodeData: action.payload.data || {},
                loadingNode: false
            }
        }
        case FETCH_NODE_ERROR: {
            return {
                ...state,
                error: action.payload.error,
                loadingNode: false
            }
        }
        default:
            return state || nodeDataInitialState;
    }
}
