import {
    SET_LINEAGE_SETTINGS_REQUEST,
    SET_LINEAGE_SETTINGS_SUCCESS,
    SET_LINEAGE_SETTINGS_ERROR,
    FETCH_ALL_SCALES_REQUEST,
    FETCH_ALL_SCALES_SUCCESS,
    FETCH_ALL_SCALES_ERROR,
    // FETCH_SCALE_DOMAIN_REQUEST,
    // FETCH_SCALE_DOMAIN_SUCCESS,
    // FETCH_SCALE_DOMAIN_ERROR,
    FETCH_MEASURE_SCALES_DOMAINS_REQUEST,
    FETCH_MEASURE_SCALES_DOMAINS_SUCCESS,
    FETCH_MEASURE_SCALES_DOMAINS_ERROR,
    SET_SETTINGS_PARAMETERS,
    SET_SETTINGS_METADATA
} from './actionTypes';

import { received } from '../baseActions';



export const setSettings = (payload) => received(SET_LINEAGE_SETTINGS_REQUEST, payload);
export const setSettingsSuccess = (payload) => received(SET_LINEAGE_SETTINGS_SUCCESS, payload);
export const setSettingsError = (payload) => received(SET_LINEAGE_SETTINGS_ERROR, payload);

export const fetchAllScales = (payload) => received(FETCH_ALL_SCALES_REQUEST, payload);
export const fetchAllScalesSuccess = payload => received(FETCH_ALL_SCALES_SUCCESS, payload);
export const fetchAllScalesError = payload => received(FETCH_ALL_SCALES_ERROR, payload);

// export const fetchScaleDomain = (payload) => received(FETCH_SCALE_DOMAIN_REQUEST, payload);
// export const fetchScaleDomainSuccess = res => received(FETCH_SCALE_DOMAIN_SUCCESS, res);
// export const fetchScaleDomainError = err => received(FETCH_SCALE_DOMAIN_ERROR, err);

export const fetchMeasureScalesDomains = payload => received(FETCH_MEASURE_SCALES_DOMAINS_REQUEST, payload);
export const fetchMeasureScalesDomainsSuccess = res => received(FETCH_MEASURE_SCALES_DOMAINS_SUCCESS, res);
export const fetchMeasureScalesDomainsError = err => received(FETCH_MEASURE_SCALES_DOMAINS_ERROR, err);

export const setSettingsParameters = payload => received(SET_SETTINGS_PARAMETERS, payload);

export const setSettingsMetadata = payload => received(SET_SETTINGS_METADATA, payload);

