import React, { useState, useEffect } from 'react';
import MainRouter from './router/MainRouter';
import { Provider } from 'react-redux';
import { emptyObject } from './functions/functions';
import { fetchAxios } from './functions/axiosRequests';
import { configureStore } from './redux/configureStore';
import config from './config/envConfig';
import { LAYOUT } from './config/dictionaries';
import { v4 as uuid } from 'uuid';
import appConfig from './config/appConfig';

const Main = () => {
    const [store, setStore] = useState({});

    useEffect(() => {
        const tabId = uuid();
        sessionStorage.setItem('tabId', tabId);
        initApp()
        // .then(() =>
        // setAppConfig().then(() => setInitialState())
        // )
        ;
    }, [])

    const setAppConfig = async () => {
        const defaultUrl = `${config.serverLink}/api/getDefaults`;
        await fetchAxios(defaultUrl)
        .then(function (response) {
            const { defaults, colorScaleRange, measures, regions, regionOptions, strainSubsetOptions, strainHighlightOptions, missingDataColor } = response.data;
            appConfig.setDefault(defaults);
            appConfig.setColorScaleRange(colorScaleRange);
            appConfig.setMeasures(measures);
            appConfig.setRegions(regions);
            appConfig.setRegionOptions(regionOptions);
            appConfig.setStrainSubsetOptions(strainSubsetOptions);
            appConfig.setStrainHighlightOptions(strainHighlightOptions);
            appConfig.setMissingDataColor(missingDataColor);
        })
        .catch(function (error) {
            console.log(error)
        });
    };

    const setInitialState = async () => {
        let initState = {};
        const url = `${config.serverLink}/api/getInitState`;

        await fetchAxios(url)
            .then(function (response) {
                initState = response.data.initState;

                const predictionBaseline = new Date(initState.parameters.predictionBaseline);

                initState.parameters.layout = LAYOUT.TREE.value;
                initState.parameters.predictionBaseline = predictionBaseline;
                initState.settings.parameters.predictionBaseline = predictionBaseline;
            })
            .catch(function (error) {
                console.log(error)
            });

        const configuredStore = configureStore(initState);
        setStore(configuredStore);
    };


    const initApp = async () => {
        let initState = {};
        const url = `${config.serverLink}/api/init`;
        await fetchAxios(url)
        .then(function (response) {
            const { defaults, colorScaleRange, measures, regions, regionOptions, strainSubsetOptions, strainHighlightOptions, missingDataColor } = response.data;
            appConfig.setDefault(defaults);
            appConfig.setColorScaleRange(colorScaleRange);
            appConfig.setMeasures(measures);
            appConfig.setRegions(regions);
            appConfig.setRegionOptions(regionOptions);
            appConfig.setStrainSubsetOptions(strainSubsetOptions);
            appConfig.setStrainHighlightOptions(strainHighlightOptions);
            appConfig.setMissingDataColor(missingDataColor);

            initState = response.data.initState;
            const predictionBaseline = new Date(initState.parameters.predictionBaseline);
            initState.parameters.layout = LAYOUT.TREE.value;
            initState.parameters.predictionBaseline = predictionBaseline;
            initState.settings.parameters.predictionBaseline = predictionBaseline;
        })
        .catch(function (error) {
            console.log(error)
        });
        const configuredStore = configureStore(initState);
        setStore(configuredStore);
    }
    return (
        <>
        { !emptyObject(store) &&
            <Provider store={store}>
                <MainRouter />
            </Provider>
        }
        </>
    )
}

export default Main;
