const basicCell = {
    padding: '0',
    height: 20,
    borderBottom: 0,
    verticalAlign: 'top',
}

export const antigenicInfoStyles = (theme) => ({
    root: {
        backgroundColor: '#fff',
        height: '100%',
    },
    tableWrapper: {
        padding: '10px 15px',
    },
    table: {
        padding: '10px 15px',
        borderCollapse: 'collapse',
        height: '200px',
        '& tbody': {
            display: 'table',
        }
    },
    row: {
        fontSize: 15,
        height: 'auto',
        minHeight: 26
    },
    cellTitle: {
        ...basicCell,
        wordBreak: 'break-all',
        width: 110,
        maxWidth: 110,
        minWidth: 110,
        border: 'none',
        whiteSpace: 'nowrap',
        verticalAlign: 'top',
        fontFamily: 'Source Sans Pro Bold',
        lineHeight: '19px',
    },
    cell: {
        ...basicCell,
        width: '100%',
        maxWidth: '100%',
        fontWeight: 'normal',
        border: 'none',
        lineHeight: '19px',
        width: '100%',
        verticalAlign: 'top',
        textAlign: 'left',
        overflowWrap: 'break-word',
        wordBreak: 'break-word',
    },
});
