import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { FormControl, InputLabel, MenuItem } from '@mui/material';
import CustomSelect from '../../assets/GlobalStyles/CustomSelect';
import { setParameters } from '../../redux/actions/parametersActions';
import appConfig from '../../config/appConfig';
import { resetActiveClades } from '../../redux/actions/cladeActions';
import { bindActionCreators } from 'redux';

const useStyles = makeStyles({
    formControl: {
        margin: '8px 0px'
    },
    input: {
        height: '35px',
        lineHeight: '35px',
        boxSizing: 'border-box'
    }
});

// const cladeTypes = appConfig.cladeTypes;

const CladeTypeSelector = props => {

    const { cladeType, setParameters, resetData, resetActiveClades, cladeTypes } = props;
    const classes = useStyles();

    const handleValueChange = event => {
        const cladeType = event.target.value;
        setParameters({ cladeType });
        resetActiveClades();
        if (resetData) {
            resetData();
        }
    }

    // useEffect(() => {
    //     console.log('mutgene =',mutgene, 'mutposition =', mutposition, genotypeDomainStatus);
    //     if (isFetchNeeded(genotypeDomainStatus))
    //         fetchMeasureScalesDomains( {lineage, colorBy: 'genotype', mutgene, mutposition})
    // }, [mutgene, mutposition])

    return <FormControl className={classes.formControl} fullWidth>
                <InputLabel htmlFor={`${cladeType}`}>
                    Clade type
                </InputLabel>
                <CustomSelect
                    value={cladeType}
                    onChange={handleValueChange}
                    inputProps={{ name: `${cladeType}`, id: `${cladeType}` }}
                >
                    {cladeTypes.map(c => (
                        <MenuItem key={c.key} value={c.key}>{c.label}</MenuItem>
                    ))}
                </CustomSelect>
            </FormControl>;
}



CladeTypeSelector.propTypes = {
    cladeType: PropTypes.string,
    setParameters: PropTypes.func,
    resetData: PropTypes.func,
    resetActiveClades: PropTypes.func,
    cladeTypes: PropTypes.arrayOf(PropTypes.shape({key: PropTypes.string, label: PropTypes.string})) 
};

const mapStateToProps = (state) => {
    return ({
        //  lineage: state.parameters.lineage,
        cladeType: state.parameters.cladeType,
        cladeTypes: state.metadata.cladeTypes || appConfig.cladeTypes
    });
};


const mapDispatchToProps = dispatch => bindActionCreators({
    setParameters,
    resetActiveClades
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CladeTypeSelector);
