import React, { useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { useStyles } from './styles';
import Modal from '@mui/material/Modal';
import { Box, LinearProgress } from '@mui/material';
import {
    getStrainTreeRenderStatus,
    getStrainTreeStatus, getChartDataStatus, antigenicModelStatusSelector, antigenicRawModelStatusSelector,
    getStrainTreeErrorStatus
} from '../../redux/selectors/statusSelector';
import { RENDER_STATUS } from '../../config/consts';
import config from '../../config/envConfig';

const LoadProgress = props => {
    const { loadingStrainTree, loadingChartStatus, loadingAntigenicModelStatus, loadingRawAntigenicModelStatus,
        detailsOpen, geoLoading, loadingTreeRenderStatus, strainTreeErrorStatus, lineageCnt, renderStatus } = props;
    const location = useLocation();
    const { pathname } = location;
        
    const startTime = useRef();
    const endTime = useRef();

    const classes = useStyles();

    const isStrainTreeTab = (pathname === `${config.frontendPrefix}/strainTree` || pathname === `${config.frontendPrefix}/` || pathname === `${config.frontendPrefix}/intro`);
    const strainTreeLoading = (!strainTreeErrorStatus && isStrainTreeTab)
            ? (loadingStrainTree || renderStatus !== RENDER_STATUS.DONE) 
            : false;

    // console.log(`loadingStrainTree = ${loadingStrainTree}`)
    const frequenciesLoading = (pathname === `${config.frontendPrefix}/frequencies` || pathname ===  `${config.frontendPrefix}/intro-freq` || pathname === `${config.frontendPrefix}/prediction`)
        ? loadingChartStatus
        : false;

    const antigenicLoading = (pathname === `${config.frontendPrefix}/antigenic`)
            ? (loadingAntigenicModelStatus || (loadingRawAntigenicModelStatus && detailsOpen))
            : false;

    const geomapLoading = (pathname === `${config.frontendPrefix}/geomap`)
        ? (geoLoading) // || loadingStrainTree)
        : false;

    const loading = strainTreeLoading || frequenciesLoading || antigenicLoading || geomapLoading;

  
    const showLoadProgress = useMemo(() => { return loading && lineageCnt > 0}, [loading, lineageCnt]);

    // console.log(`[LoadProgress] showLoadProgress = ${`${showLoadProgress}`.toUpperCase()}
    // lineageCnt = ${lineageCnt}
    // strainTreeLoading = ${strainTreeLoading}
    // strainTreeErrorStatus = ${strainTreeErrorStatus}
    // isStrainTreeTab = ${isStrainTreeTab}
    // loadingStrainTree = ${loadingStrainTree} 
    // renderStatus = ${renderStatus}`)

    useEffect(() => {
        if (loading) startTime.current = performance.now();
        else if (startTime.current) {
            endTime.current = performance.now();
            // console.log('renderTime', endTime.current - startTime.current);
        }
        // setShowLoadProgress(showLoadProgr);

    }, [loading]);

    //console.log('!!!!!!!![LoadProgress] LOAD_PROGRESS, LoadProgress: showLoadProgress', showLoadProgress, 'loading = ', loading);
    // useEffect(() => {
    //     console.log('LOAD_PROGRESS, LoadProgress: showLoadProgress', showLoadProgress, classes.root);

    // }, [showLoadProgress]);

    return (
        showLoadProgress && (
            <div className={classes.root} style={{ zIndex: 99, position: 'absolute', overflow: 'auto', width: '100%' }} id='loader'>
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
                <Modal open >
                    <span></span>
                </Modal>
            </div>
        )
    );
};

LoadProgress.propTypes = {
    loadingStrainTree: PropTypes.bool,
    loadingChartStatus: PropTypes.bool,
    loadingAntigenicModelStatus: PropTypes.bool,
    firstRenderStatus: PropTypes.bool,
    location: PropTypes.shape({ pathname: PropTypes.string })
};

const mapStateToProps = (state) => {
    const loadingStrainTree = getStrainTreeStatus(state);
    const strainTreeErrorStatus = getStrainTreeErrorStatus(state);
    //const loadingTreeRenderStatus = getStrainTreeRenderStatus(state);
    //console.log('mstp', loadingTreeRenderStatus)
    const loadingChartStatus = getChartDataStatus(state);
    const loadingAntigenicModelStatus = antigenicModelStatusSelector(state);
    const detailsOpen = state.antigenic.detailsOpen;
    const loadingRawAntigenicModelStatus = antigenicRawModelStatusSelector(state);
    //const firstRenderStatus = getFirstRenderStatus(state);
    const geoLoading = state.geomap.geoLoading;
    const lineages = state.lineages.lineages;
    return {
        loadingStrainTree,
        strainTreeErrorStatus,
        loadingChartStatus,
        loadingAntigenicModelStatus,
        //firstRenderStatus,
        loadingRawAntigenicModelStatus,
        // loadingTreeRenderStatus,
        detailsOpen,
        geoLoading,
        lineageCnt: (lineages || []).length,
        renderStatus: state.render.renderStatus

    };
};

export default connect(mapStateToProps, null)(LoadProgress);
