
import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import { postAxios, fetchAxios } from '../../functions/axiosRequests';
import {
    FETCH_MEASURE_SCALES_DOMAINS_REQUEST,
    SET_LINEAGE_SETTINGS_REQUEST,
    FETCH_ALL_SCALES_REQUEST,
} from '../actions/actionTypes';
import {
    setSettingsSuccess,
    setSettingsError,
    fetchMeasureScalesDomainsSuccess,
    fetchMeasureScalesDomainsError,
    fetchAllScalesSuccess,
    fetchAllScalesError
    // fetchConfigError,
} from '../actions/settingsActions';
//import { fetchAxios } from '../../functions/axiosRequests';
import config from '../../config/envConfig';
import { prepareUrl } from '../../functions/functions';
import { sessionTimeOut } from './helpers/functions';

export const setSettings = action$ => action$.pipe(
    ofType(SET_LINEAGE_SETTINGS_REQUEST),
    mergeMap(action => {
        const url = prepareUrl(`${config.serverLink}/api/admin/setNewSettings`);
        const { handleOpenAlert, ...payload } = action.payload;
        console.log(payload);
        
        return postAxios(url, payload)
            .then(response => {
                handleOpenAlert(response.status, response.data);
                return setSettingsSuccess({ ...response.data, parameters: payload.parameters });
            })
            .catch(error => {
                return setSettingsError({ error, errorWebsiteText: 'Saving settings failed.', errorWebsiteType: 'fetch'});
            });
    })
);

export const fetchAllScalesEpic = action$ => action$.pipe(
    ofType(FETCH_ALL_SCALES_REQUEST),
    mergeMap(_ => {
        const url = prepareUrl(`${config.serverLink}/api/admin/getAllScales`);
        return fetchAxios(url)
            .then(response => {
                return fetchAllScalesSuccess({ ...response.data });
            })
            .catch(error => {
                const { data } = error.response;

                return fetchAllScalesError({ error: data.error });
            });
    })
);


export const fetchMeasureScalesDomainsEpic = (action$, store) => action$.pipe(
    ofType(FETCH_MEASURE_SCALES_DOMAINS_REQUEST),
    mergeMap(action => {
        //console.log(action.payload);
        const { colorBy } = action.payload;
        const url = prepareUrl(`${config.serverLink}/api/getMeasureScalesDomains`, action.payload);
        return fetchAxios(url).then(function (response) {
            //console.log(response);
            return fetchMeasureScalesDomainsSuccess({...response.data, measure: colorBy});
        }).catch(function (error) {
            const { status, data } = error.response;
            if (status === 408) return sessionTimeOut(error, store);
            return fetchMeasureScalesDomainsError({ error: data.error, errorWebsiteText: 'An error has occured when loading measure scales domains.', errorWebsiteType: 'fetch'});
        });
    }),
);



