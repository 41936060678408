import { createStore, applyMiddleware, compose } from 'redux';
import { createBrowserHistory } from 'history';
import thunk from 'redux-thunk';
import rootReducer from './reducers/_rootReducer';
import { rootEpic } from './epics/_rootEpic';
import { createEpicMiddleware } from 'redux-observable';
import {
    SET_COMPONENT_STATUS,
    SET_LABEL_MOVEMENT,
    SET_RENDER_STATUS,
    // SET_SYMBOL_DIMENSIONS,
    // SET_SYMBOL_POSITION,
    TOGGLE_ANTIGENIC_DETAILS,
    SET_STRAINS_TREE_DIMENSIONS,
    FETCH_NODE_REQUEST,
    FETCH_NODE_SUCCESS,
    SET_ALERT_STATUS,
    CORRECT_LABEL_POSITIONS,
    SET_ACTIVE_LEGEND_OPTION,
    SELECT_NODE_DATA
} from './actions/actionTypes';
import { setAntigenicDataInitalState } from './reducers/antigenicReducer';
import { setAlertInitialState } from './reducers/alertReducer';
import { setCladesInitialState } from './reducers/cladesReducer';
import { setCustomTreeDataInitialState } from './reducers/customTreeDataReducer';
import { setFrequenciesInitialState } from './reducers/frequenciesReducer';
import { setGenotypeInitialState } from './reducers/genotypeReducer';
import { setGeomapInitialState } from './reducers/geoMapReducer';
import { setLineagesInitialState } from './reducers/lineagesReducer';
import { setModelDataInitalState, setModelsInitialState } from './reducers/modelsReducer';
import { setMetadataInitialState } from './reducers/metadataReducer';
import { setNodeDataInitialState } from './reducers/nodeDataReducer';
import { setParametersInitialState } from './reducers/parametersReducer';
import { setPredictionsInitialState } from './reducers/predictionsReducer';
import { setRenderInitialState } from './reducers/renderStatusReducer';
import { setSettingsInitialState } from './reducers/settingsReducer';
import { setTreeDataInitialState } from './reducers/treeDataReducer';
import { setUserInitialState } from './reducers/userReducer';

export const history = createBrowserHistory();

export function configureStore(initState) {
    const epicMiddleware = createEpicMiddleware({
        dependencies: {
            history: history,
        },
    });

    // const priorityMiddleware = store => next => action => {
    //     if (action.type === SET_PARAMETERS && !action.priority) {
    //       // Add the priority property to the action object
    //       const priorityAction = { ...action, priority: 'high' };
    //       // Process the action with the modified action object
    //       next(priorityAction);
    //     } else {
    //       next(action);
    //     }
    //   };

    const middleware = [thunk, epicMiddleware]; // , createLogger()];
    const actionSanitizer = (action) =>
        action.type === 'INIT_STRAIN_TREE_SUCCCESS' && action.payload
            ? { ...action, payload: '<<LONG_BLOB>>' }
            : action;

    const composeEnhancers =
        process.env.REACT_APP_SHOW_REDUX == 'true' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__?.({
            actionSanitizer,
            stateSanitizer: (state) => (state.data ? { ...state, data: '<<LONG_BLOB>>' } : state),
            actionsDenylist: [
                SET_ACTIVE_LEGEND_OPTION,
                SET_COMPONENT_STATUS,
                SET_RENDER_STATUS,
                TOGGLE_ANTIGENIC_DETAILS,
                SET_LABEL_MOVEMENT,
                SELECT_NODE_DATA,
                FETCH_NODE_REQUEST,
                FETCH_NODE_SUCCESS,
                SET_STRAINS_TREE_DIMENSIONS,
                SET_ALERT_STATUS,
                CORRECT_LABEL_POSITIONS
            ],
        }) ?? compose : compose;

    const enhancer = composeEnhancers(
        applyMiddleware(...middleware),
    );

    setAlertInitialState(initState.alert);
    setAntigenicDataInitalState(initState.antigenic);
    setCladesInitialState(initState.cladeData);
    setCustomTreeDataInitialState(initState.customTreeData);
    setFrequenciesInitialState(initState.frequenciesData);
    setGenotypeInitialState(initState.genotype);
    setGeomapInitialState(initState.geomap);
    setLineagesInitialState(initState.lineages);
    setModelDataInitalState(initState.modelData);
    setModelsInitialState(initState.models);
    setMetadataInitialState(initState.metadata);
    setNodeDataInitialState(initState.nodeData);
    setParametersInitialState(initState.parameters);
    setPredictionsInitialState(initState.predictionsData);
    setRenderInitialState(initState.render);
    setSettingsInitialState(initState.settings);
    setTreeDataInitialState(initState.treeData);
    setUserInitialState(initState.user);

    const store = createStore(
        rootReducer,
        initState,
        enhancer,
    );

    epicMiddleware.run(rootEpic);
    return store;
}
