import {
    FETCH_GEO_DATA_REQUEST,
    FETCH_GEO_DATA_SUCCESS,
    FETCH_GEO_DATA_ERROR,
    RESET_SESSION,
    SIGNOUT_REQUEST,
    SET_COLORING,
    SET_PARAMETERS,
    SET_LAYOUT_PARAMETERS,
    SET_GEO_LOADING
} from "../actions/actionTypes";

let geomapInitialState = { };
export const setGeomapInitialState = (state) => {
    geomapInitialState = state;
};

export const geoMapReducer = (state = geomapInitialState, action) => {
    switch (action.type) {
        case FETCH_GEO_DATA_REQUEST:
            return {
                ...state,
                geoLoading: true,
                geoMapStatus: 'loading'
            }
        case FETCH_GEO_DATA_SUCCESS:
            return {
                ...state,
                geoLoading: false,
                geoMapStatus: 'loaded',
                geojson: action.payload.geojson
            }
        case FETCH_GEO_DATA_ERROR:
            return {
                ...state,
                geoLoading: false,
                geoMapStatus: 'error',
                error: action.payload
            }
        case SET_COLORING: {
            const { coloring } = action.payload;
            return {
                ...state,
                coloring
            }
        }
        case RESET_SESSION:
        case SIGNOUT_REQUEST: {
            return geomapInitialState
        }

        case SET_PARAMETERS: 
        case SET_LAYOUT_PARAMETERS: {
            //console.log('SET_PARAMETERS geomap reducer')
            return {
                ...state,
                geoMapStatus: 'none',
            }
        }
        case SET_GEO_LOADING: {
            const { geoLoading } = action.payload;
            return {
                ...state,
                geoLoading
            }
        }
        default:
            return state || geomapInitialState;
    }
}
