import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import { antigenicDetailStyles } from '../styles/antigenicDetailsStyles';
import ExportableComponent from '../../../pages//Export/ExportableComponent';
import AntigenicDetailsData from './AntigenicDetailsData';
import AntigenicDetailsInfo from './AntigenicDetailsInfo';
import { toggleAntigenicDetails } from '../../../redux/actions/antigenicActions';
import { getMeasures } from '../../../redux/selectors/metadataSelector';
import { getRanges } from '../../../redux/selectors/rangeDataSelector';

class AntigenicDetails extends Component {

    render() {
        const { classes, toggleAntigenicDetails } = this.props;
        return (
            <div className={classes.root}>
                <Grid container className={classes.container}>

                    <Grid item xs={9} className={classes.dataContainer}>
                        <div className={classes.closeBtn} onClick={() => toggleAntigenicDetails(false)} />

                        <ExportableComponent filename="antigenicDetails">
                            <AntigenicDetailsData />
                        </ExportableComponent>
                    </Grid>
                    <Grid item xs={3} className={classes.legend}>
                        <AntigenicDetailsInfo />
                    </Grid>
                </Grid>
            </div>
        );
    }
}

AntigenicDetails.propTypes = {
    exportMode: PropTypes.bool,
    classes: PropTypes.shape({
        root: PropTypes.string,
    }),
    rho: PropTypes.number,
    alpha: PropTypes.number,
};

const mapStateToProps = (state) => {
    const { exportMode, antigenicTiterType } = state.parameters;
    const { rawAntigenicCladeStrains, alpha, rho } = state.antigenic;
    const measures = getMeasures(state);
    const ranges = getRanges(state);

    return {
        measures,
        exportMode,
        antigenicTiterType,
        rawAntigenicCladeStrains,
        alpha,
        rho,
        ranges
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ toggleAntigenicDetails }, dispatch);

const AntigenicWithStyles = withStyles(antigenicDetailStyles)(AntigenicDetails);

export default connect(mapStateToProps, mapDispatchToProps)(AntigenicWithStyles);
// export { AntigenicExport };
