import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { IconButton } from '@mui/material';
import { withStyles } from '@mui/styles';
import Alerts from '../Alerts/Alerts'
import { setParameters } from '../../redux/actions/parametersActions';
import { getNodeDataById } from '../../redux/selectors/nodeDataSelector';
import AdditionalColorBySelector from '../OptionsSelector/AdditionalColorBySelector';
import LineageSelector from '../OptionsSelector/LineageSelector';
import ColorBySelector from '../OptionsSelector/ColorBySelector';
import TreeLegend from '../ColorLegend/Legends/TreeLegend';
import ScalesLegend from '../ColorLegend/Legends/ScalesLegend';
import GeoFreqCategorySelector from './helpers/GeoFreqCategorySelector';
import PredictionBaselinePicker from '../DatePickers/PredictionBaselinePicker';
import GeoFreqSpanSelector from '../OptionsSelector/GeoFreqSpanSelector';
import HamburgerIcon from '../SvgIcons/HamburgerIcon';
import { setHiddenMenu } from '../../redux/actions/renderActions';
import { HideIcon } from '../Alerts/styles';
import { getIsMobile } from '../../redux/selectors/statusSelector';

const styles = theme => (
    {
        root: {
            height: '100%',
            flexWrap: 'wrap',
            textAlign: 'left',
            padding: '10px 15px',
            backgroundColor: '#fff',
        },

        formControl: {
            minWidth: 120,
            margin: '8px 0px'
        },
        colFormControl: {
            width: '49%',
            margin: '8px 4px 8px 0px',
        },
        lastColFormControl: {
            minWidth: 120,
            width: '50%',
            margin: '8px 0px 8px 0px',
        },
        selectEmpty: {
            marginTop: theme.spacing(2)
        },
        button: {
            width: '100%'
        },
        deletednfo: {
            margin: '24px 0 8px'
        },
        buttonWrapper: {
            width: '100%',
            height: '30px'
        },
        hideButton: {
            padding: '0px',
            float: 'right'
        },
        expandButton: {
            marginTop: '7px'
        },
        mapBarMobile: {
            backgroundColor: 'white',
            position: 'absolute'
        }
    });

class GeoMapOptions extends PureComponent {
    handleHideClick = () => {
        const { hiddenMenu, setHiddenMenu } = this.props;
        setHiddenMenu({ hiddenMenu: !hiddenMenu });
    }

    render = () => {
        const { classes, colorBy, permissions, hiddenMenu, isMobile, intro } = this.props;

        return (
            <div className={isMobile ? classes.mapBarMobile : {}}>
                {!hiddenMenu ?
                    <form className={classes.root} autoComplete="off">

                        {!isMobile &&
                            <div className={classes.buttonWrapper}>
                                <IconButton className={classes.hideButton} onClick={this.handleHideClick}>
                                    <HideIcon />
                                </IconButton>
                            </div>

                        }
                        <Alerts module="geomap" />
                        {<LineageSelector mapSelector />}
                        {!intro && <ColorBySelector mapSelector />}
                        {<AdditionalColorBySelector mapSelector />}
                        {!intro && <GeoFreqCategorySelector mapSelector />}
                        <GeoFreqSpanSelector />
                        <PredictionBaselinePicker type={'geoMap'} />
                        <TreeLegend mapSelector />
                        <ScalesLegend mapSelector scaleId="strainTree" valueGetter={(state) => getNodeDataById(state)[colorBy]} ticks={3} inContainer precision={3} />
                    </form>

                    :
                    <IconButton className={classes.expandButton} onClick={this.handleHideClick}>
                        <HamburgerIcon />
                    </IconButton>
                }
            </div>
        );
    };
}

GeoMapOptions.propTypes = {
    classes: PropTypes.shape({ root: PropTypes.string, button: PropTypes.string, formControl: PropTypes.string }),
    setParameters: PropTypes.func,
};

const mapStateToProps = ({ parameters, user, render }) => ({
    colorBy: parameters.geoMapColorBy,
    permissions: user.permissions.mapPermissions,
    hiddenMenu: render.hiddenMenu,
    hiddenMenuMobile: render.hiddenMenuMobile,
    intro: parameters.intro,
    isMobile: getIsMobile()
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setParameters,
    setHiddenMenu
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GeoMapOptions));
