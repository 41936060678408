import { combineReducers } from 'redux';
import { treeDataReducer as treeData } from './treeDataReducer';
import { customTreeDataReducer as customTreeData } from './customTreeDataReducer';
import nodeData from './nodeDataReducer';
import cladeData from './cladesReducer';
import frequenciesData from './frequenciesReducer';
import { modelData, modelsData as models } from './modelsReducer';
import { lineagesData as lineages } from './lineagesReducer';
import { antigenicModelData as antigenic } from './antigenicReducer';
import { alertReducer as alert } from './alertReducer';
import render from './renderStatusReducer';
import parameters from './parametersReducer';
import metadata from './metadataReducer';
import { predictionsData } from './predictionsReducer';
import user from './userReducer';
import { settingsReducer as settings } from './settingsReducer';
import { genotypeReducer as genotype } from './genotypeReducer';
import { geoMapReducer as geomap } from './geoMapReducer';
import { humanSerology } from './humanSerologyReducer';

const rootReducer = combineReducers({
    alert,
    antigenic,
    cladeData,
    customTreeData,
    frequenciesData,
    genotype,
    geomap,
    humanSerology,
    lineages,
    metadata,
    modelData,
    models,
    nodeData,
    parameters,
    predictionsData,
    render,
    settings,
    treeData,
    user
});

export default rootReducer;
