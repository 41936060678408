import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@mui/styles';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import { isEqual } from 'lodash';
import { fetchTreeFreqs } from '../../redux/actions/treeDataActions';
import { setParameters } from '../../redux/actions/parametersActions';
import { setAlertStatus } from '../../redux/actions/alertActions';
import { styles } from '../Tree/styles';
import { StyledTextField } from '../../assets/GlobalStyles/TextField';
import { dateFormat } from '../../functions/checkBrowser';
import { fetchGeoData } from '../../redux/actions/geomapActions';
import { resetFrequencies } from '../../redux/actions/frequenciesActions';
import { resetPredictions } from '../../redux/actions/predictionsActions';
import { resetActiveClades } from '../../redux/actions/cladeActions';

const PredictionBaselinePicker = (props) => {
    const {
        predictionBaseline, lineage, type, caseSpan, cladeType, geoMapColorBy,
        setParameters, fetchTreeFreqs, resetActiveClades, resetFrequencies, resetPredictions, fetchGeoData
    } = props;

    //    const [predictionBaselineHolder, setPredictionBaselineHolder] = useState(predictionBaseline);

    // useEffect(() => {
    //     console.log('something has changed111111')
    //     setPredictionBaselineHolder(predictionBaseline);
    // }, [predictionBaseline]);
    let predictionBaselineHolder = predictionBaseline;

    //console.log('something has changed222222')
    const handlePredictionBaselineChange = value => {
        if (value && !isNaN(value.getTime()))
            predictionBaselineHolder = value
        // setPredictionBaselineHolder(predictionBaseline);
    };

    const handleAccept = value => {
        //console.log('[PredictionBaselinePicker.handleAccept]: value = ',value, 'predictionBaseline = ',predictionBaseline);
        if (isEqual(value, predictionBaseline)) return;

        setParameters({ predictionBaseline: value });

        // if (predictionBaselineArg !== predictionBaseline){
        switch (type) {
            case 'predictionBaseline': {
                fetchTreeFreqs({ lineage, predictionBaseline: value });
                resetActiveClades();
                break;
            }
            case 'geoMap': {
                fetchGeoData({ lineage, colorBy: geoMapColorBy, predictionBaseline: value, caseSpan, cladeType });
                break;
            }
            case 'frequencies': {
                resetFrequencies();
                resetPredictions();
                break;
            }
            default:
                return;
        }
        //}
    }

    const handleKeyPress = event => {
        if (event.key === 'Enter' && !isEqual(predictionBaselineHolder, predictionBaseline)) {
            setParameters({ predictionBaseline: predictionBaselineHolder });

            //  if (predictionBaselineHolder !== predictionBaseline){
            switch (type) {
                case 'predictionBaseline':
                    fetchTreeFreqs({ lineage, predictionBaseline: predictionBaselineHolder });
                    resetActiveClades();
                case 'geoMap':
                    fetchGeoData({ lineage, colorBy: geoMapColorBy, predictionBaseline: predictionBaselineHolder, cladeType });
                case 'frequencies':
                    resetFrequencies();
                    resetPredictions();
                default:
                    return;
            }
            //   }
        }
    }

    return <LocalizationProvider dateAdapter={AdapterDateFns} >
        <DesktopDatePicker
            allowSameDateSelection
            disableToolbar
            clearable
            variant="inline"
            format={dateFormat}
            id="prediction-baseline"
            label={type === 'geoMap' ? `Date +/- ${caseSpan} days` : 'Prediction baseline'}
            value={predictionBaselineHolder}
            onChange={handlePredictionBaselineChange}
            onAccept={handleAccept}
            slotProps={{
                textField: {
                    onKeyDown: handleKeyPress,
                    variant: 'standard',
                    style: ['predictionBaseline', 'geoMap'].includes(type) ? { margin: 0 } : { margin: '8px 0px 0px 0px' }
                },
                inputAdornment: { position: 'start' }
            }}
        />
    </LocalizationProvider>

        ;

}

PredictionBaselinePicker.propTypes = {
    lineage: PropTypes.string,
    geoMapColorBy: PropTypes.string,
    predictionBaseline: PropTypes.instanceOf(Date),
    setParameters: PropTypes.func,
    setAlertStatus: PropTypes.func,
    fetchTreeFreqs: PropTypes.func,
    fetchGeoData: PropTypes.func,
    resetFrequencies: PropTypes.func,
    resetPredictions: PropTypes.func,
};


const mapStateToProps = (state) => ({
    predictionBaseline: state.parameters.predictionBaseline,
    lineage: state.parameters.lineage,
    geoMapColorBy: state.parameters.geoMapColorBy,
    caseSpan: state.parameters.caseSpan,
    cladeType: state.parameters.cladeType
});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        setParameters,
        fetchTreeFreqs,
        setAlertStatus,
        fetchGeoData,
        resetFrequencies,
        resetPredictions,
        resetActiveClades
    },
    dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PredictionBaselinePicker));
