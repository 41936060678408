import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormControl, InputLabel, MenuItem } from '@mui/material';
import appConfig from '../../config/appConfig';
import { setParameters } from '../../redux/actions/parametersActions';
import CustomSelect from '../../assets/GlobalStyles/CustomSelect';

const chartLayoutOptions = [
    { key: 'nonstacked', label: 'Non-stacked' },
    { key: 'stacked', label: 'Stacked' }
];

const styles = theme => ({

    formControl: {

        margin: '8px 0px 8px 0px'
    },
    layoutIntro:{
        margin: '8px 0px 0px 0px',
        minWidth: 120,
    },
});

class ChartLayoutSelector extends PureComponent {

    handleChartLayoutChange = event => {
        const { setParameters } = this.props;
        const chartLayout = event.target.value;
        setParameters({ chartLayout, plotType: 'frequencies' });
    };

    render = () => {
        const { chartLayout, classes, intro } = this.props;
        return (
            <FormControl className={intro ? classes.layoutIntro : classes.formControl} fullWidth>
                <InputLabel htmlFor="chartLayout">
                    Layout
                </InputLabel>
                <CustomSelect
                    value={chartLayout}
                    onChange={this.handleChartLayoutChange}
                    inputProps={{
                        name: 'chartLayout',
                        id: 'chartLayout',
                    }}
                >
                    {chartLayoutOptions.map((t) => (
                        <MenuItem key={t.key} value={t.key}>{t.label}</MenuItem>
                        // <MenuItem key={t.key} value={t.key}>{t.label}</MenuItem>
                    ))}
                </CustomSelect>
            </FormControl>
        );
    }
}

ChartLayoutSelector.propTypes = {
    chartLayout: PropTypes.string,
    setParameters: PropTypes.func
};


const mapStateToProps = ({ parameters }) => {
    return ({
        chartLayout: parameters.chartLayout || appConfig.default.chartLayout,
    });
};

const mapDispatchToProps = dispatch => bindActionCreators({
    setParameters,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ChartLayoutSelector));
