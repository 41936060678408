import { createSelector } from 'reselect';
import { RENDER_STATUS } from '../../config/consts';
import { measuresSelector } from './metadataSelector';
import { treeD3 } from '../../components/Tree/d3/TreeD3';
import { emptyObject, isColorByModel } from '../../functions/functions';

const loaded = (status) => status === 'loaded' || status === 'nodata' || status === 'error'; // || status === 'refetchNeeded';
const loadedOrNone = (status) => loaded(status) || status === 'none' || status === 'refetchNeeded';
const tcellAntigenicityLoadingSelector = (state) => {
    const { gene, hla, colorBy } = state.parameters;
    const { tcellStatus } = state.customTreeData;
    const status = colorBy === 'tcellAntigenicity' && tcellStatus && tcellStatus[`${gene}_${hla}`] !== 'loaded';
    return status;
};

const getIntro = ({ parameters }) => parameters.intro;
const getTreeScaleTypeX = ({ parameters }) => parameters.treeScaleTypeX;
const getTreeScaleTypeY = ({ parameters }) => parameters.treeScaleTypeY;
const getCustomDataStatus = ({ customTreeData }) => customTreeData.status;

const coordinateScalesLoadingSelector = createSelector([getTreeScaleTypeX, getTreeScaleTypeY, getCustomDataStatus, measuresSelector],
    (treeScaleTypeX, treeScaleTypeY, status, measures) => {
        if (measures[treeScaleTypeX].custom && status[treeScaleTypeX] !== 'loaded') return true;
        if (measures[treeScaleTypeY].custom && status[treeScaleTypeY] !== 'loaded') return true;
        return false;
    });

const getStrainSearchStatus = (state) => {
    const { strainSearchStatuses, strainSearchStatus } = state.treeData;
    const strainsSearchLoading = strainSearchStatus === 'loading'|| Object.values(strainSearchStatuses || {}).some((s) => s === 'loading') || false;
    return strainsSearchLoading;
}

const getShowCladeBar = ({parameters}) => parameters.showCladeBar;
const getColorBy = ({parameters}) => parameters.colorBy;
const getExportMode = ({ parameters }) => parameters.exportMode;
const getTreeDataStatus = ({treeData}) => treeData.treeDataStatus;
const getVpValuesStatus = ({treeData}) => treeData.vpValuesStatus;
const getAdditionalNodesStatus = ({treeData}) => treeData.additionalNodesStatus;
const getReferenceStrainNodesStatus = ({treeData}) => treeData.referenceStrainNodesStatus;

const getModelStatus = ({modelData}) => modelData.modelStatus;
const getModelsStatus = ({models}) => models.modelsStatus;
const getModelTypesStatus = ({ models }) => models.modelTypesStatus;
const getCladesStatus = ({ cladeData}) => cladeData.cladesStatus;

const getAntigenicModelStatus = ({antigenic}) => antigenic.antigenicModelStatus;
const getAntigenicRawModelStatus = ({antigenic}) => antigenic.antigenicRawModelStatus;

const getHumanSerologyStatus = ({humanSerology}) => humanSerology.humanSerologyDataStatus;

const getGenotypeDataStatus = ({genotype}) => genotype.genotypeDataStatus;

const getIsMobile = () => {
    return window.innerWidth < 786;
};

// const getExportMode = ({parameters}) => parameters.exportMode;
const getHiddenMenu = ({ render }) => render.hiddenMenu;
const getHiddenMenuMobile = ({ render }) => render.hiddenMenuMobile;

const shouldFetchModelsSelector = createSelector([getExportMode, getIsMobile, getHiddenMenu, getHiddenMenuMobile], 
    (exportMode, isMobile, hiddenMenu, hiddenMenuMobile) => {
    const hiddenModelsSelector = (isMobile && hiddenMenuMobile) || (!isMobile && hiddenMenu);
    const shouldFetchModels = !exportMode && !hiddenModelsSelector;
    return shouldFetchModels;
});

const additionaNodesLoadingSelector = createSelector(getAdditionalNodesStatus, 
    additionalNodesStatus => {
        // console.log(Object.values(additionalNodesStatus ||{}).some(s => s === 'loading'));
        return Object.values(additionalNodesStatus ||{}).some(s => s === 'loading') || false
});

const strainTreeLoadingSelector = createSelector(
    [getTreeDataStatus, getVpValuesStatus, getStrainSearchStatus, additionaNodesLoadingSelector, getReferenceStrainNodesStatus],
    (treeDataStatus, vpValuesStatus, strainSearchStatus, additionalNodesLoading, referenceStrainNodesStatus) => {
        const loading =
            !loaded(treeDataStatus) ||
            !loaded(vpValuesStatus) ||
            strainSearchStatus ||
            additionalNodesLoading || 
            (!loadedOrNone(referenceStrainNodesStatus));

        // console.log(`[strainTreeLoadingSelector]
        // treeDataStatus = ${treeDataStatus},
        // vpValuesStatus = ${vpValuesStatus},
        // strainSearchStatus = ${strainSearchStatus},
        // additionalNodesLoading = ${additionalNodesLoading},
        // referenceStrainNodesStatus = ${referenceStrainNodesStatus}
        // `)
        return loading;
    });


export const getModelLoadingSelector = createSelector([getModelStatus, getModelTypesStatus, getModelsStatus, getColorBy, shouldFetchModelsSelector], 
    (modelStatus, modelTypesStatus, modelsStatus, colorBy, shouldFetchModels) => {
    const res = isColorByModel(colorBy) && (
        (shouldFetchModels && !loaded(modelTypesStatus[colorBy])) || 
        (shouldFetchModels && (!loaded(modelsStatus[colorBy]) && modelTypesStatus[colorBy] !== 'nodata')) ||
        (!loaded(modelStatus[colorBy]) && modelTypesStatus[colorBy] !== 'nodata')
        );
    return res;
});

const getCladesLoadingSelector = createSelector([getCladesStatus, getShowCladeBar, getColorBy], (cladesStatus, showCladeBar, colorBy) => 
    (!loaded(cladesStatus) && (colorBy === 'clade' || showCladeBar))
);

const getAntigenicDataLoadingSelector = createSelector([getAntigenicModelStatus, getAntigenicRawModelStatus], (antigenicModelStatus, antigenicRawModelStatus) => {
    return (!loadedOrNone(antigenicModelStatus)  || !loadedOrNone(antigenicRawModelStatus)) 
}
);

const getHumanSerologyLoadingSelector = createSelector(getHumanSerologyStatus, humanSerologyDataStatus => humanSerologyDataStatus === 'loading');

const getGenotypeDataLoadingSelector = createSelector(getGenotypeDataStatus, genotypeDataStatus => genotypeDataStatus === 'loading');


const getCustomDataLoadingSelector = createSelector(getCustomDataStatus, customDataStatus => (customDataStatus && Object.values(customDataStatus).some((s) => s === 'loading')) || false);

const getPropsLoaded = () => !emptyObject(treeD3.props);



const getStrainTreeStatus = createSelector([
    strainTreeLoadingSelector, 
    getModelLoadingSelector, 
    getCladesLoadingSelector, 
    getAntigenicDataLoadingSelector,
    getHumanSerologyLoadingSelector,
    getGenotypeDataLoadingSelector,
    getCustomDataLoadingSelector,
    tcellAntigenicityLoadingSelector,
    coordinateScalesLoadingSelector,
    getPropsLoaded
    ], (
        strainTreeLoading, modelLoading, cladesLoading, antigenicDataLoading, humanSerologyDataLoading, genotypeDataLoading, 
        customDataLoading, tcellAntigenicityLoading, coordinateScalesLoading, propsLoaded) => {
       
    
        const loading = strainTreeLoading
            || modelLoading
            || cladesLoading
            || antigenicDataLoading
            || humanSerologyDataLoading
            || genotypeDataLoading
            || customDataLoading
            || tcellAntigenicityLoading
            || coordinateScalesLoading
            || !propsLoaded;
        // console.log(`[getStrainTreeStatus] loading = ${loading}`);

        // console.log(`[getStrainTreeStatus] loading = ${`${loading}`.toUpperCase()}
        // strainTreeLoading = ${strainTreeLoading},
        // modelLoading = ${modelLoading}, 
        // cladesLoading = ${cladesLoading}, 
        // antigenicDataLoading = ${antigenicDataLoading}, 
        // humanSerologyDataLoading = ${humanSerologyDataLoading}, 
        // genotypeDataLoading = ${genotypeDataLoading}, 
        // customDataLoading = ${customDataLoading}`)
        return loading;
    }
)
const _getStrainTreeStatus = (state) => {
    try {
        const { treeDataStatus, vpValuesStatus, strainSearchStatus, strainSearchStatuses, additionalNodesStatus, referenceStrainNodesStatus } = state.treeData;
        const { modelStatus } = state.modelData;
        const { cladesStatus } = state.cladeData;
        const { antigenicModelStatus, antigenicRawModelStatus } = state.antigenic;
        const { status } = state.customTreeData;
        const { colorBy, showCladeBar } = state.parameters;
        const { genotypeDataStatus } = state.genotype;
        const { humanSerologyDataStatus } = state.humanSerology;
        // console.log('humanSerologyDataStatus', humanSerologyDataStatus)

        const customTreeAttrsLoading = (status && Object.values(status).some((s) => s === 'loading')) || false;
        const strainsSearchLoading = Object.values(strainSearchStatuses || {}).some((s) => s === 'loading') || false;
        // console.log(`strainsSearchLoading = ${strainsSearchLoading}`);
        const loading =
            !loaded(treeDataStatus) ||
            !loaded(vpValuesStatus) ||
            (!loaded(modelStatus) && (colorBy === 'fitness' || colorBy === 'advance' || colorBy === 'flux')) ||
            (!loaded(cladesStatus) && (colorBy === 'clade' || showCladeBar)) ||
            (!loaded(antigenicModelStatus) && antigenicModelStatus !== 'none') ||
            (!loaded(antigenicRawModelStatus) && antigenicRawModelStatus !== 'none') ||
            (!loadedOrNone(additionalNodesStatus)) || (!loadedOrNone(referenceStrainNodesStatus)) ||
            // !treeOrderDict ||
            // Object.keys(treeOrderDict).length === 0 ||
            // !treeAttrs ||
            // Object.keys(treeAttrs).length === 0 ||
            customTreeAttrsLoading ||
            tcellAntigenicityLoadingSelector(state) ||
            strainSearchStatus === 'loading' ||
            strainsSearchLoading ||
            genotypeDataStatus === 'loading' ||
            humanSerologyDataStatus === 'loading' ||
            coordinateScalesLoadingSelector(state) ||
            !treeD3.props; //props not loaded yet

        //console.log(`loading = ${loading},
        //referenceStrainNodesStatus = ${referenceStrainNodesStatus}`);
        // loadingtreeDataStatus = ${treeDataStatus},
        // vpValuesStatus = ${vpValuesStatus},
        // modelStatus = ${modelStatus},
        // cladesStatus = ${cladesStatus},
        // antigenicModelStatus = ${antigenicModelStatus},
        // antigenicRawModelStatus = ${antigenicRawModelStatus},
        // additionalNodesStatus = ${additionalNodesStatus},
        // customTreeAttrsLoading = ${customTreeAttrsLoading},
        // genotypeDataStatus = ${genotypeDataStatus},
        // humanSerologyDataStatus = ${humanSerologyDataStatus}`)

        return loading;
    }
    catch (error) {
        console.error('Error in getStrainTreeStatus selector:', error);
        return false; // Or return a default value if needed
    }
};

export const getStrainTreeErrorStatus = (state) => {
    const res = state.treeData.treeDataStatus === 'error' || state.treeData.vpValuesStatus === 'error' || state.cladeData.cladesStatus === 'error';
    return res;
}

export const getAntigenicDistanceStatus = (state) => {
    const { strainSearchStatuses } = state.treeData;
    const { antigenicRawModelStatus, antigenicModelStatus } = state.antigenic;

    const { colorBy, antigenicDataType, refStrain, exportMode } = state.parameters;
    //console.log('refStrain', refStrain);
    // `${refStrain}`.split(',').forEach((rs) => {
    //     // console.log(rs, treeOrderDict[rs]);
    // });
    const nonRawModel = (antigenicDataType === 'inferred' || antigenicDataType === 'observed')
        && antigenicModelStatus !== 'loaded'
        ;
    const rawModel = (antigenicDataType === 'raw_strain' || antigenicDataType === 'epitope_clades')
        && antigenicRawModelStatus !== 'loaded'
        && (strainSearchStatuses && strainSearchStatuses.antigenic !== 'found') && !exportMode;

    return colorBy === 'antigenic'
        && refStrain !== null
        && (nonRawModel || rawModel);
    // console.log(res,  colorBy === 'antigenic', refStrain !== null, (nonRawModel || rawModel) );
    // return res;
};

const getFirstRenderStatus = (state) => state.render.renderStatus === RENDER_STATUS.NONE;

// const getLoadStrainTreeStatus = state => {
//     // const { colorBy } = state.parameters;
//     const { treeDataStatus } = state.treeData;
//     // const { modelStatus } = state.modelData;
//     const { cladesStatus } = state.cladeData;

//     // const { renderStatus } = state.render;
//     const fetchNeeded = isFetchNeeded(treeDataStatus) || isFetchNeeded(cladesStatus)  //|| ( colorBy === 'fitness' && isFetchNeeded(modelStatus));
//     // console.log(`getLoadStrainTreeStatus: ${fetchNeeded}, treeDataStatus = ${treeDataStatus}, modelStatus = ${modelStatus}, cladesStatus = ${cladesStatus}`);
//     return fetchNeeded;
// };

const getViewToRender = (state) => state.render.viewToRender;
export const getRenderStatus = ({render}) => render.renderStatus;

const getStrainTreeRenderStatus = createSelector([getViewToRender, getRenderStatus], (viewToRender, _renderStatus) => {
    return _renderStatus;
    // const renderStatus = 
    //     viewToRender.viewName !== 'strainTree' || emptyObject(viewToRender.components)
    //         ? 'NONE'
    //         : Object.values(viewToRender.components).some((v) => v !== 'DONE')
    //             ? 'loading'
    //             : 'DONE';
    // return renderStatus;
});



const getPlotFrequenciesStatus = (state) => {
    const subsetId = state.parameters.strainSubset;
    // console.log('[getPlotFrequenciesStatus]', subsetId);
    return state.frequenciesData.frequenciesStatus && subsetId
        ? (state.frequenciesData.frequenciesStatus[subsetId] || 'none')
        : 'none';
};


const getSelectedModels = ({parameters}) => parameters.selectedModels;
const getShowPredicton = ({parameters}) => parameters.showPrediction;
const getStrainSubset = ({parameters}) => parameters.strainSubset;
const getPredictionsDataStatus = ({predictionsData}) => predictionsData.predictionsStatus;



const getPredictionsStatuses = createSelector([getShowPredicton, getStrainSubset, getSelectedModels, getPredictionsDataStatus], 
    (showPrediction, strainSubset, models, predictionsStatus) => {
    try {
        // let status = 'none';
        const status = [];
        if (!showPrediction) return status;
        const _models = models.filter(({ idIncomplete }) => !idIncomplete);
        // console.log('[getPredictionsStatuses] models = ', models);
        for (const model of _models) {
            const { modelRegionId, modelId, modelType, invalid } = model;
            const modelStatus = invalid ? 'nodata' : (predictionsStatus?.[strainSubset]?.[modelRegionId]?.[modelType]?.[modelId] || 'none');
            status.push({modelRegionId, modelType, modelId, status: modelStatus});
        }
        //console.log('[getPredictionsStatuses]', status);
        return status;
    }
    catch (e) {
        console.log(e);
        return 'error';
    }
});


const getSelectedModelsToFetch = createSelector(getPredictionsStatuses, 
    predictionsStatuses => {
        // console.log('[getSelectedModelsToFetch] predictionsStatuses = ',predictionsStatuses)
        return predictionsStatuses.filter(({ status }) => status === 'none').map(({ status: _, ...model }) => model);
    });

const statusOrder = { 'none': 0, 'loading': 1, 'loaded': 3, 'nodata': 4};
const getPredictionsStatus = createSelector(getPredictionsStatuses, predictionsStatuses => 
    predictionsStatuses.reduce((acc, model) => (statusOrder[model.status] < statusOrder[acc]) ? model.status : acc, 'nodata')
);

const getCustomFreqCategoryStatus = (state) => {
    const { freqCategory, gene, hla } = state.parameters;
    if (freqCategory === 'tcellAntigenicity') {
        return state.customTreeData.tcellStatus && state.customTreeData.tcellStatus[`${gene}_${hla}`] === 'loaded';
    }
    const { status } = state.customTreeData;
    const res = !status[freqCategory] || status[freqCategory] === 'loaded';
    return res;
};

const getChartStatus = createSelector(
    [getCladesStatus, /*getPlotFrequenciesStatus, getPredictionsStatus,*/ getCustomFreqCategoryStatus],
    (cladesStatus, /*frequenciesStatus, predictionsStatus,*/ customFreqStatus) => {
        const loading =
            cladesStatus !== 'loaded' /*|| frequenciesStatus !== 'loaded' || predictionsStatus !== 'loaded' */ ||
            !customFreqStatus;
        return loading;
    },
);

const getChartDataStatus = createSelector(
    [getCladesStatus, getPlotFrequenciesStatus, getPredictionsStatus, getCustomFreqCategoryStatus, getIntro],
    (cladesStatus, frequenciesStatus, predictionsStatus, customFreqStatus, intro) => {

        const loading =
            (!loaded(cladesStatus) && cladesStatus !== 'none') ||
            (!loaded(frequenciesStatus) && frequenciesStatus !== 'none') ||
            (!loaded(predictionsStatus) && predictionsStatus !== 'nodata' && predictionsStatus !== 'none' && !intro) ||
            !customFreqStatus;
        // console.log(`[getChartDataStatus]: ${loading},
        // clades => ${(!loaded(cladesStatus) && cladesStatus !== 'none')},
        // freqs => ${(!loaded(frequenciesStatus) && frequenciesStatus !== 'none')},
        // preds => ${(!loaded(predictionsStatus) && predictionsStatus !== 'nodata' && !intro)}
        // custom => ${!customFreqStatus}

        // cladesStatus = ${cladesStatus}, 
        // frequenciesStatus = ${frequenciesStatus}, 
        // predictionsStatus = ${predictionsStatus}, 
        // customFreqStatus = ${customFreqStatus}`)
        return loading;
    },
);

const getFrequencyChartStatus = createSelector(
    [getCladesStatus, getCustomFreqCategoryStatus],
    (cladesStatus, customFreqStatus) => {
        const loading = cladesStatus !== 'loaded' || !customFreqStatus;
        return loading;
    },
);

const antigenicModelStatusSelector = (state) => {
    const { antigenicModelStatus } = state.antigenic;
    const loading = antigenicModelStatus !== 'loaded' && antigenicModelStatus !== 'nodata' && antigenicModelStatus !== 'error';
    //|| (renderStatus !== RENDER_STATUS.DONE && renderStatus !== RENDER_STATUS.NONE); //  || (renderStatus !== RENDER_STATUS.DONE && renderStatus !== RENDER_STATUS.NONE);
    return loading;
};

const antigenicRawModelStatusSelector = (state) => {
    const { antigenicRawModelStatus, antigenicCladesStatus } = state.antigenic;
    const { cladesStatus } = state.cladeData;

    const loading =
        (antigenicRawModelStatus !== 'loaded' && antigenicRawModelStatus !== 'nodata') ||
        antigenicCladesStatus !== 'loaded' ||
        cladesStatus !== 'loaded';
    return loading;
};



export {
    getStrainTreeStatus,
    getStrainSearchStatus,
    getStrainTreeRenderStatus,
    getChartStatus,
    getChartDataStatus,
    antigenicModelStatusSelector,
    antigenicRawModelStatusSelector,
    getFirstRenderStatus,
    getPredictionsStatus,
    getPredictionsStatuses,
    getFrequencyChartStatus,
    getIsMobile,
    getSelectedModelsToFetch,
    shouldFetchModelsSelector
};
