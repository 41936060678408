import { makeStyles } from '@mui/styles';

export const styles = makeStyles((theme) => ({
    card: {
        paddingBottom: theme.spacing(2),
        padding: '77px 138px'
    },
    cardContent: {
        padding: 0
    },
    cardActions: {
        padding: 0

    },
    titleWrapper: {
        marginBottom: theme.spacing(8)
    },
    error: {
        marginBottom: theme.spacing(2),
    },
    logo: {
        width: '400px',
    },
    title: {
        fontSize: '92px',
        fontFamily: 'Source Sans Pro Bold',
        marginBottom: theme.spacing(4),
        color: '#000000',
        letterSpacing: '-0.05em',
        fontFamily: "'IBM Plex Sans', sans-serif"
    },
    text: {
        fontWeight: 100,
        fontSize: '36px',
        marginBottom: theme.spacing(2),
        lineHeight: '45px',
        color: '#000000',
        letterSpacing: '-0.03em',
        fontFamily: 'Source Sans Pro Light, Verdana',
        maxWidth: '450px'
    },
    textField: {
        width: 350,
        display: 'block',
        backgroundColor: 'white !important',
        marginBottom: theme.spacing(3),
        '& > div': {
            width: '100%',
        },
        "& input:-internal-autofill-selected": {
            WebkitBoxShadow: '0 0 0 1000px white inset',
            fontFamily: 'Source Sans Pro, Verdana',
            fontSize: '16px'
        }
    },
    confirm: {
        backgroundColor: '#6F6CFF',
        color: 'white',
        marginBottom: '16px',
        textTransform: 'none',
        marginRight: '16px',
        '&:hover': {
            backgroundColor: '#6F6CFF',
            color: 'white',
        },
    },
    submit: {
        backgroundColor: theme.palette.secondary.main,
        border: `2px solid ${theme.palette.secondary.main}`,
        color: '#fff',
        boxShadow: 'none',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: theme.palette.secondary.main,
            border: `2px solid ${theme.palette.secondary.main}`,
            color: '#fff',
            boxShadow: 'none',
        },
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
        minWidth: '104px'
    },



}));
