/* eslint-disable react/no-unused-prop-types */
import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import { PropTypes } from 'prop-types';

import { getLegendSelectedNodes, getVisibleNodes } from '../../../redux/selectors/treeDataSelector';
import { treeD3 } from '../d3/TreeD3';

import { getStrainTreeStatus, getAntigenicDistanceStatus } from '../../../redux/selectors/statusSelector';


// import { printWhatChanged } from '../../../functions/utils';
// const viewToRender = 'strainTree';
// const epitopeMutationsGroups = 'cladeLabels';

const SelectedStrainsLayer = (props) => {
    const _element = useRef();

    // const prevProps = {
    //     nodeId: usePrevious(props.nodeId),
    //     searchStrainMode: usePrevious(props.searchStrainMode),
    //     activeLegendOption: usePrevious(props.activeLegendOption),
    // };

    const { nodeId, activeLegendOption, searchStrainMode, legendSelectedNodes, loading, renderStatus } = props;

    useEffect(() => {
        if (loading) return;
        if (activeLegendOption) treeD3.selectNodes(legendSelectedNodes);
    }, [activeLegendOption]);

    useEffect(() => {
        if (loading /*|| !searchStrainMode*/ ) return;

        treeD3.drawSelectedStrainsLayer(_element.current, nodeId, searchStrainMode);
    }, [nodeId, loading]);


    useEffect(() => {
        if (renderStatus !== 'loading') return;
        treeD3.drawSelectedStrainsLayer(_element.current, nodeId, searchStrainMode);
    }, [renderStatus]);


    return <g id="selectedNodes" transform={treeD3.translate(false)} ref={_element} />;
};

SelectedStrainsLayer.propTypes = {
    nodeId: PropTypes.number,
    layout: PropTypes.string,
    loading: PropTypes.bool,
    activeLegendOption: PropTypes.object,
    searchStrainMode: PropTypes.bool,
    legendSelectedNodes: PropTypes.arrayOf(PropTypes.string),
    renderStatus: PropTypes.string

};

const mapStateToProps = (state, ownProps) => {
    const loading = getStrainTreeStatus(state) || !ownProps.initialized || getAntigenicDistanceStatus(state);
    return {
        loading,
        renderStatus: state.render.renderStatus,
        nodeId: state.nodeData.nodeId,
        visibleNodes: getVisibleNodes(state),
        activeLegendOption: state.nodeData.activeLegendOption,
        searchStrainMode: state.parameters.searchStrainMode,
        legendSelectedNodes: getLegendSelectedNodes(state),
        //showInternalNodes: state.parameters.showInternalNodes
        // refStrain: state.parameters.refStrain,
    };
};
// const mapDispatchToProps = dispatch => bindActionCreators(
//     {
//         getLegendSelectedNodes
//     },
//     dispatch);

export default connect(mapStateToProps)(SelectedStrainsLayer);
