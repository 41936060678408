import { LAYOUT } from './dictionaries';

class AppConfig {
    setDefault = (defaults) => this.default = defaults;
    setColorScaleRange = (colorScaleRange) => this.colorScaleRange = colorScaleRange;
    setMeasures = (measures) => this.measures = measures;
    setRegions = (regions) => this.regions = regions;
    setRegionOptions = (regionOptions) => this.regionOptions = regionOptions;
    setStrainSubsetOptions = (strainSubsetOptions) => this.strainSubsetOptions = strainSubsetOptions;
    setStrainHighlightOptions = (strainHighlightOptions) => this.strainHighlightOptions = strainHighlightOptions;
    setMissingDataColor = (missingDataColor) => this.missingDataColor = missingDataColor;

    frequenciesGreyZoneThresholds = [
        { x0: 0, x1: 50, color: '#C9C9C9' },
        { x0: 50, x1: 100, color: '#E0E0E2' },
    ];

    titerTypes = [
        { id: 'absolute', label: 'Titer' },
        { id: 'drop', label: 'Titer drop' },
        { id: 'fold_reduction', label: 'Fold reduction' }
    ];

    displayOrderOptions = [
        { id: 'time', label: 'Time' },
        { id: 'reversedTime', label: 'Reversed time' },
    ];

    mutationsTypes = [
        { id: 'NS', label: 'Non-synonymous' },
        { id: 'S', label: 'Synonymous' },
        { id: 'NCL', label: 'Nucleotide' },
        { id: 'ALL', label: 'All' },
    ];

    layouts = [LAYOUT.TREE.value, LAYOUT.FAN.value];

    dataStores = ['mongo'];

    antigenicDataTypes = [
        { id: 'raw_strain', label: 'Raw measurements' },
        { id: 'observed_strain', label: 'Observed strain level'},
        { id: 'observed', label: 'Observed clade level' },
        { id: 'inferred', label: 'Inferred clade level' },
        { id: 'epitope_clades', label: 'Epitope clades' },
    ];

    // introPatogenes = [
    //     'SARS-CoV-2',
    //     'H3N2',
    //     'H1N1p',
    //     'BVic',
    //     'H1N1s',
    //     'H5NX',
    //     'H7NX',
    //     'H9NX'
    // ];

    // introBuilds = [
    //     'HA_NA',
    //     'HA',
    //     'NA',
    //     'Light'
    // ];


    introColorByOptions = [
        'clade', 
        'fitness',
        'genotype', 
        'loc',
        'host', 
        'pangolin'
    ];

    introModelTypes = [
        'SEQ',
        'CGR',
        'Antigenic'
    ]

    modelTypesLabels = {
        'CGR': 'Clade growth',
    }

    introModelSelectorInputs = [
        'modelType'
    ]

    collaboratingCenters = [
        { id: "ALL" },
        { id: "CDC" },
        { id: "CRICK" },
        { id: "NIID" },
        { id: "VIDRL" },
        { id: "CNIC" },
        { id: "EGG" },
        { id: "CDC_VIDRL" }
    ];

    assays = [
        { id: "ALL" },
        { id: "HI" },
        { id: "FRA_VNT" },
        { id: "HNT" },
        { id: "VIDRL" }
    ];

    strainPropagations = [
        { id: "ALL" },
        { id: "CELL" },
        { id: "EGG" }
    ];

    refStrainPropagations = [
        { id: "ALL" },
        { id: "CELL" },
        { id: "EGG" }
    ];

    binMethods = [
        { key: 'quantile', label: 'Quantile' },
        { key: 'uniform', label: 'Equal intervals' },
    ];

    animationDelay = 20;
    animationDuration = 10000;

    transmissions = {
        CHXEC: { label: 'CH x USA EC', color: '#FF4249' },
        CHXWC: { label: 'CH x USA WC', color: '#9B59B6' },
        EUXEC: { label: 'EU x USA EC', color: '#F4A460' },
        EUXWC: { label: 'EU x USA WC', color: '#DFDF1F' },
    };

    cladeTypes = [
        { key: 'clade', label: 'Clade' },
        { key: 'significant_clade', label: 'Significant clade'},
        { key: 'antigenic_clade', label: 'Antigenic clade'},
        { key: 'who_clade', label: 'WHO clade'},
    ]
    
    pathogensLabels = { 
        'BVic': 'Influenza B Victoria', 
        'H3N2': 'Influenza A(H3N2)',
        'H1N1p': 'Influenza A(H1N1)pdm09'
    }

    buildsLabels = { 
        'Light': 'Whole genome'
    }
}

const appConfig = new AppConfig();
export default appConfig;
