/* eslint-disable */
import { ofType } from 'redux-observable';
import { mergeMap, debounceTime } from 'rxjs/operators';
import { prepareUrl } from '../../functions/functions';
import config from '../../config/envConfig';
import {
    FETCH_MUTATION_CLASSES_REQUEST,
    FETCH_GENOTYPE_DATA_REQUEST,
    FETCH_MUT_POS_DICT_REQUEST,
    FETCH_MUTATION_GROUP_REQUEST
} from '../actions/actionTypes';
import {
    fetchMutationClassesSuccess,
    fetchMutationClassesError,
} from '../actions/treeDataActions';
import { fetchAxios } from '../../functions/axiosRequests';
import {
    fetchGenotypeDataSuccess,
    fetchGenotypeDataError,
    fetchMutationsPositionsDictionarySuccess,
    fetchMutationsPositionsDictionaryError,
    fetchMutationGroupValuesSuccess,
    fetchMutationGroupValuesError
} from '../actions/genotypeActions';

import { sessionTimeOut } from './helpers/functions';

export const fetchMutationClassesEpic = (action$, store)  => action$.pipe(
    ofType(FETCH_MUTATION_CLASSES_REQUEST),
    mergeMap(action => {

        const { settings, ...params } = action.payload;
        const url = prepareUrl(`${config.serverLink}/api/getMutationClasses/`, params);
       // console.log(`fetchMutationClasses => url = ${url}`);

        return fetchAxios(url).then(function (response) {
            return fetchMutationClassesSuccess({...response.data, settings});
        }).catch(function (error) {
            const { status, data } = error.response;
            if (status === 408) return sessionTimeOut(error, store);
            return fetchMutationClassesError({ error: data.error, errorWebsiteText: 'An error has occured during downloading mutation calsses.', errorWebsiteType: 'fetch'});
        });
    })
);

export const fetchGenotypeDataEpic = (action$, store) => action$.pipe(
    ofType(FETCH_GENOTYPE_DATA_REQUEST),
    debounceTime(250),
    mergeMap(action => {
        const { lineage, mutgene, mutposition, zoomNodeId } = action.payload
        const url = prepareUrl(`${config.serverLink}/api/getGenotypeData/`, { lineage, mutgene, mutposition, zoomNodeId });
        //console.log(`getGenotypeData => url = ${url}, mutgene: ${mutgene}`);

        return fetchAxios(url).then(function (response) {
            return fetchGenotypeDataSuccess(response.data);
        }).catch(function (error) {
            const { status, data } = error.response;
            if (status === 408) return sessionTimeOut(error, store);

            return fetchGenotypeDataError({ error: data.error, errorWebsiteText: 'An error has occured during downloading genotype data.', errorWebsiteType: 'fetch'});
        });
    })
);

export const fetchMutationsPositionsDictionaryEpic = (action$, store)  => action$.pipe(
    ofType(FETCH_MUT_POS_DICT_REQUEST),
    mergeMap(action => {
        const { lineage, settings } = action.payload
        const url = prepareUrl(`${config.serverLink}/api/getMutationsPositionsDictionary/`, { lineage });
        //console.log(`getMutationsPositionsDictionary => url = ${url}`);

        return fetchAxios(url).then(function (response) {
            return fetchMutationsPositionsDictionarySuccess({ ...response.data, settings });
        }).catch(function (error) {
            const { status, data } = error.response;
            if (status === 408) return sessionTimeOut(error);
            return fetchMutationsPositionsDictionaryError({ error: data.error, errorWebsiteText: 'An error has occured during downloading mutation positions.', errorWebsiteType: 'fetch'});
        });
    })
);

export const fetchMutationsPositionsGroupsValuesEpic = action$ => action$.pipe(
    ofType(FETCH_MUTATION_GROUP_REQUEST),
    mergeMap(action => {
        const url = prepareUrl(`${config.serverLink}/api/getMutationsGroups/`, action.payload);
        //console.log(`getMutationsGroups => url = ${url}`);

        return fetchAxios(url).then(function (response) {
            return fetchMutationGroupValuesSuccess(response.data);
        }).catch(function (error) {
            const { status, data } = error.response;
            if (status === 408) return sessionTimeOut(error);

            return fetchMutationGroupValuesError({ error: data.error, errorWebsiteText: 'An error has occured during downloading mutation positions.', errorWebsiteType: 'fetch'});
        });
    })
);
