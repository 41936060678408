import React from 'react';
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from '@mui/material/styles';
import { StyledEngineProvider } from '@mui/material';
import './assets/fonts/SourceSansPro-Regular.ttf';
import './assets/fonts/SourceSansPro-Light.ttf';
import './assets/fonts/IBMPlexSans-Regular.ttf';
import './assets/fonts/SourceSansPro-Bold.ttf';
import './assets/css/main.css';
import './assets/css/alert.css';
import theme from './theme';
import Main from './Main';
import { Helmet } from 'react-helmet';

if (process.env.NODE_ENV !== "development" && process.env.REACT_APP_SHOW_LOGS !== 'true')
  console.log = () => { };

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <ThemeProvider theme={theme}>
    <StyledEngineProvider injectFirst>
      <Helmet>
        <title>Previr app</title>
        {process.env.REACT_APP_HIDE_INDEXING === 'true' && <meta name="googlebot" content="noindex" />}
        <meta name="description" content="Tracking and evolutionary predictions for human influenza and SARS-CoV-2. Provided by the Center for Predictive Analysis of Viral Evolution." />
      </Helmet>
      <Main />
    </StyledEngineProvider>
  </ThemeProvider>
);
