import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import { FETCH_FREQUENCIES_REQUEST } from '../actions/actionTypes';
import { prepareUrl } from '../../functions/functions';
import { fetchFrequenciesSuccess, fetchFrequenciesError } from '../actions/frequenciesActions'
import config from '../../config/envConfig';
import { fetchAxios } from '../../functions/axiosRequests';
import { sessionTimeOut } from './helpers/functions';

export const fetchFrequencies = action$ => action$.pipe(
    ofType(FETCH_FREQUENCIES_REQUEST),
    mergeMap(action => {
        // console.log('frequencies epic, action.payload', action.payload);
        const { allRegions, } = action.payload;

        const subsetId = allRegions ? null : action.payload.subsetId;
        const url = prepareUrl(`${config.serverLink}/api/frequencies`, action.payload)         
        //console.log(`fetchFrequencies => url = ${url}`);

        return fetchAxios(url).then(function (response) {
            return fetchFrequenciesSuccess({ ...response.data, subsetId});
        }).catch(function (error) {
            const { status, data } = error.response;
            if (status === 408) return sessionTimeOut(error);

            return fetchFrequenciesError({ error: data.error, errorWebsiteText: 'An error has occured during downloading frequencies.', errorWebsiteType: 'fetch'});
        })
    }),
);

