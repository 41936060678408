/* eslint-disable react/no-unused-prop-types */
import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';

import { RENDER_STATUS } from '../../../config/consts';
import { setComponentStatus } from '../../../redux/actions/renderActions';
import { getStrainTreeStatus } from '../../../redux/selectors/statusSelector';
import { treeD3 } from '../d3/TreeD3';
// import { printWhatChanged } from '../../../functions/utils';
import { emptyObject } from '../../../functions/functions';

const viewToRender = 'strainTree';
const componentId = 'highlightedStrains';

const HighlightedStrainsLayer = (props) => {
    const _element = useRef();

    // const prevProps = {
    //     loading: usePrevious(props.loading),
    //     layout: usePrevious(props.layout),
    //     showLeaves: usePrevious(props.showLeaves),
    //     colorBy: usePrevious(props.colorBy),
    // };

    const {
        loading,
        modelId,
        colorBy,
        cladeType,
        showLeaves,
        showColoredNodesOnly,
        displayOrder,
        searchStrainMode,
        calculatedDomain,
        antigenicDataType,
        antigenicTiterType,
        renderStatus,
        setComponentStatus,
        treeAttrs
    } = props;

    const startRender = async () => {
        if (renderStatus !== RENDER_STATUS.START) setComponentStatus(viewToRender, componentId, RENDER_STATUS.START);
    }

    const stopRender = () => {
        //if (renderStatus !== RENDER_STATUS.DONE)
        setComponentStatus(viewToRender, componentId, RENDER_STATUS.DONE);
    }


    useEffect(() => {
        if (renderStatus !== RENDER_STATUS.NONE) return;
        // console.log('[HighlightedStrainsLayer] useEffect 0 [renderStatus]')
        startRender()
            .then(() => treeD3.translateHighlightedStrains())
            .then(() => stopRender()); 
    }, [renderStatus]);

    useEffect(() => {
        if (loading) return;
        // console.log('[HighlightedStrainsLayer] useEffect 1 [modelId, calculatedDomain, cladeType]')
        startRender()
            .then(() => treeD3.changeNodesColor(_element.current, 'highlightedStrain'))
            .then(() => stopRender());
    }, [modelId, calculatedDomain, cladeType]);

    useEffect(() => {
        if (loading) return;
        if (!loading && emptyObject(treeAttrs)) {
            stopRender();
            return;
        }
        // console.log(`[HighlightedStrainsLayer] useEffect 2 ${treeD3.getWidth()}`);
        startRender()
            .then(() => treeD3.drawHighlightedStrainsLayer(_element.current))
            .then(() => stopRender());
    }, [loading, showLeaves, showColoredNodesOnly, searchStrainMode, colorBy, antigenicDataType, antigenicTiterType]);

    useEffect(() => {
        if (loading) return;
        // console.log('[HighlightedStrainsLayer] useEffect 3 [displayOrder]');
        treeD3.removeElementsLayer(_element.current)
            .then(() => startRender())
            .then(() => treeD3.drawHighlightedStrainsLayer(_element.current))
            .then(() => stopRender());
    }, [displayOrder]);

    return <g id="highlightedStrains" transform={treeD3.translate(false)} ref={_element} />;
};

HighlightedStrainsLayer.propTypes = {
    layout: PropTypes.string,
    loading: PropTypes.bool,
    showLeaves: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => {
    const loading = getStrainTreeStatus(state) || !ownProps.initialized;

    return {
        loading,
        renderStatus: state.render.viewToRender ? state.render.viewToRender.components[componentId] : null,
        colorBy: state.parameters.colorBy,
        cladeType: state.parameters.cladeType,
        // layout: state.parameters.layout,
        showLeaves: state.parameters.showLeaves,
        showColoredNodesOnly: state.parameters.showColoredNodesOnly,

        searchStrainMode: state.parameters.searchStrainMode,
        highlightedStrainIds: state.treeData.highlightedStrainIds,
        calculatedDomain: state.parameters.calculatedDomain,
        displayOrder: state.parameters.displayOrder,
        modelId: state.parameters.modelId,
        antigenicDataType: state.parameters.antigenicDataType,
        antigenicTiterType: state.parameters.antigenicTiterType,
        treeAttrs: state.treeData.treeAttrs,
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setComponentStatus,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(HighlightedStrainsLayer);
