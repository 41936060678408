import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, IconButton } from '@mui/material';
import AntigenicOptions from '../../components/Antigenic/AntigenicOptions';
import AntigenicData from '../../components/Antigenic/AntigenicData';
import AntigenicDetails from '../../components/Antigenic/AntigenicDetails/AntigenicDetails';
import ParametersInfo from '../../components/ParametersInfo/ParametersInfo';
import ExportableComponent from '../Export/ExportableComponent';
import appConfig from '../../config/appConfig';
import HamburgerIcon from '../../components/SvgIcons/HamburgerIcon';
// import { initStrainTree } from '../../redux/actions/sessionActions';
import { fetchAntigenicModel, fetchAntigenicRawModel , setParameters, unhideAlphaClade, unhideRhoClade } from '../../redux/actions/antigenicActions';
import { fetchMeasureScalesDomains } from '../../redux/actions/settingsActions';
import { getMeasures } from '../../redux/selectors/metadataSelector';
import { fetchLineages } from '../../redux/actions/lineagesActions';
import { isFetchNeeded } from '../../functions/functions';
import { RENDER_STATUS } from '../../config/consts';
import { usePrevious } from '../../functions/customHooks';
import { setHiddenMenu } from '../../redux/actions/renderActions';
import { styles } from './styles';
import ScalesLegend from '../../components/ColorLegend/Legends/ScalesLegend';
import { fetchClades } from '../../redux/actions/cladeActions';

const Antigenic = (props) => {
    const { lineageStatus, lineage, antigenicModelId, exportMode,
        unhideAlphaClade, unhideRhoClade,
        antigenicDataType, antigenicTiterType, antigenicModelStatus, setHiddenMenu, antigenicModelsStatus,
        antigenicRawModelStatus,
        cladesStatus, //antigenicCladesStatus, //fetchAntigenicClades, 
        hiddenMenu,
        renderStatus, detailsOpen, menuRight, fetchMeasureScalesDomains, dataLoaded, treeDataLoaded, antigenicDataLoaded,

        fetchClades, fetchAntigenicModel, fetchDetailsModel, //fetchAntigenicModelsList
    } = props;
    const [dataInitialized, setDataInitialized] = useState(false);
    const [deleted, setDeleted] = useState([]);
    const classes = styles();

       // const prevAntigenicModelStatus = usePrevious(antigenicModelStatus);
    const prevAntigenicTiterType = usePrevious(antigenicTiterType);
    const prevDetailsOpen = usePrevious(detailsOpen);

    useEffect(() => {
        return () => {
            setDataInitialized(false);
            setDeleted([]);
        };
    }, []);

    useEffect(() => {
        if (lineageStatus !== 'loaded') return;
        fetchMeasureScalesDomains({lineage, colorBy: 'antigenic'})
    }, [lineageStatus])

    useEffect( () => {
        // console.log(`useEffect lineageStatus = ${lineageStatus}, antigenicModelsStatus = ${antigenicModelsStatus}`);
        if (lineageStatus !== 'loaded') return;
        if (!treeDataLoaded) initComponentData();
        if (!antigenicDataLoaded) initAntigenicData();
    });

    useEffect(() => {
        const titerChanged = prevAntigenicTiterType !== antigenicTiterType && prevAntigenicTiterType;
        const detailsOpenChanged = prevDetailsOpen !== detailsOpen && prevDetailsOpen;
        if (dataLoaded || titerChanged || detailsOpenChanged) setDataInitialized(true);
    }, [dataLoaded, antigenicModelStatus, detailsOpen]);

    const setDeletedClades = (clade) => {
        const newDeleted = {
            type: 'clade',
            id: clade
        };
        setDeleted([...deleted, newDeleted]);
    };

    const setDeletedRhos = (rho) => {
        const newDeleted = {
            type: 'rho',
            id: rho
        };
        setDeleted([...deleted, newDeleted]);
    };

    const undoDeleting = () => {
        if (deleted.length === 0)
            return;

        const lastDeleted = deleted[deleted.length - 1];

        if (lastDeleted.type === 'rho')
            unhideRhoClade(lastDeleted.id);
        else
            unhideAlphaClade(lastDeleted.id);


        setDeleted(current =>
            current.filter(element => {
                return element.id !== lastDeleted.id;
            })
        );

    };

    const initComponentData = async () => {
        if (isFetchNeeded(cladesStatus)) await fetchClades({lineage});
    };

    const initAntigenicData = () => {
        if (cladesStatus !== 'loaded' || (!exportMode && antigenicModelsStatus !== 'loaded'))
            return;

        if (isFetchNeeded(antigenicModelStatus))
            fetchAntigenicModel({ lineage, antigenicModelId, antigenicDataType, antigenicTiterType });
        
        if (isFetchNeeded(antigenicRawModelStatus))
            fetchDetailsModel({ lineage, antigenicModelId, antigenicDataType, antigenicTiterType });
    };

    const getParameters = () => {
        const pars = [
            { name: 'Lineage', value: lineage },
            { name: 'Model', value: antigenicModelId },
            { name: 'Titer', value: antigenicTiterType },
            { name: 'Data type', value: antigenicDataType },
        ];
        return pars;
    };

    const handleHideClick = () => {
        setHiddenMenu( {hiddenMenu: !hiddenMenu} );
    }

    const pars = getParameters();
    return (
        <>
            {!exportMode && (
                <div className={classes.root}>
                    {detailsOpen && <AntigenicDetails />}
                    <Grid container className={classes.container}>
                        <Grid item xs={hiddenMenu ? 0.3 : 3} className={menuRight ? classes.antigenicSidebarRight : classes.antigenicSidebarLeft}>
                            { !hiddenMenu ?
                                <AntigenicOptions undoDeleting={undoDeleting} handleHideClick={handleHideClick}/>
                                :
                                <IconButton className={classes.expandButton}  onClick={handleHideClick}>
                                    <HamburgerIcon/>
                                </IconButton>
                            }
                        </Grid>
                        
                        <Grid item xs={hiddenMenu ? 11.7 : 9} className={classes.item}>
                            <div className={classes.space}>
                                <ExportableComponent filename="antigenic">
                                    <AntigenicData setDeletedClades={setDeletedClades} setDeletedRhos={setDeletedRhos} dataInitialized={dataInitialized} />
                                </ExportableComponent>
                            </div>
                        </Grid>
                       
                    </Grid>
                </div>
            )}
            {exportMode && dataInitialized && (
                <Grid container className={classes.containerExport}>
                    <Grid item xs={8} className={classes.item}>
                        <AntigenicData dataInitialized={dataInitialized} />
                    </Grid>
                    <Grid item xs={4}>
                        <div className={classes.legend}> 
                            <ParametersInfo parameters={pars} />
                            <div className={classes.dataLegend}>
                                <ScalesLegend
                                    scaleId="antigenic"
                                    measureName="antigenic"
                                    ticks={3}
                                    title="Titer"
                                    precision={2}
                                />
                                <ScalesLegend
                                    scaleId="alphaY"
                                    measureName="alphaY"
                                    ticks={3}
                                    title="Clade frequency"
                                    precision={3}
                                />
                                 <ScalesLegend
                                    scaleId="rhoR"
                                    measureName="rhoR"
                                    ticks={3}
                                    title="Immune cohort weight"
                                    precision={2}
                                />
                            </div>
                        </div>
                    </Grid>
                    
                </Grid>
            )}
            {exportMode === true && renderStatus === RENDER_STATUS.DONE && <div id="exportDone" />}
        </>
    );
}


Antigenic.propTypes = {
    fetchLineage: PropTypes.func,
    fetchAntigenicModel: PropTypes.func,
    fetchDetailsModel: PropTypes.func,
    // fetchAntigenicModelsList: PropTypes.func,
    lineage: PropTypes.string,
    antigenicModelId: PropTypes.string,
    antigenicDataType: PropTypes.string,
    antigenicTiterType: PropTypes.string,
    lineageStatus: PropTypes.string,
    cladesStatus: PropTypes.string,
    // antigenicCladesStatus: PropTypes.string,
    antigenicModelStatus: PropTypes.string,
    exportMode: PropTypes.bool,
    renderStatus: PropTypes.string,
    classes: PropTypes.shape({
        root: PropTypes.string,
        container: PropTypes.string,
        item: PropTypes.string,
        containerExport: PropTypes.string,
        legend: PropTypes.string,
    }),
    detailsOpen: PropTypes.bool,
};

const mapStateToProps = (state) => {
    const {
        lineage,
        antigenicModelId,
        antigenicDataType,
        antigenicTiterType,
        exportMode,
        freqCategory,
        zoomNodeId,
        strainSubset,
        // binMethod,
        // binCnt,
    } = state.parameters;
    const { antigenicModelStatus, antigenicRawModelStatus, antigenicCladesStatus, detailsOpen } = state.antigenic;
    const { cladesStatus } = state.cladeData;

    const dataLoaded = (cladesStatus === 'loaded' && antigenicModelStatus === 'loaded' && antigenicRawModelStatus === 'loaded');
    const treeDataLoaded = cladesStatus === 'loaded';
    const antigenicDataLoaded = (antigenicModelStatus === 'loaded' && antigenicRawModelStatus === 'loaded');
    const measures = getMeasures(state);
  
    return {
        lineage: lineage || appConfig.default.lineage,
        antigenicModelId, //modelId.split('-').length === 4 ? modelId : appConfig.default.antigenicModelId,
        antigenicDataType: antigenicDataType || appConfig.default.antigenicDataType,
        antigenicTiterType: antigenicTiterType || appConfig.default.antigenicTiterType,
        freqCategory,
        zoomNodeId,
        strainSubset,
        // binMethod,
        // binCnt,
        dataLoaded,
        treeDataLoaded,
        antigenicDataLoaded,
        menuRight: state.user.menuRight,
        measures,
        lineageStatus: state.lineages.lineageStatus,
        lineagesStatus: state.lineages.lineagesStatus,
        antigenicModelsStatus: state.models.modelsStatus.antigenic,
        antigenicModelStatus,
        antigenicRawModelStatus,
        antigenicCladesStatus,
        cladesStatus,
        exportMode,
        renderStatus: state.render.renderStatus,
        hiddenMenu: state.render.hiddenMenu,
        detailsOpen,
    };
};

const mapDispatchToProps = (dispatch) => ({
    fetchAntigenicModel: (payload) => dispatch(fetchAntigenicModel(payload)),
    fetchDetailsModel: (payload) => {
        dispatch(setParameters(payload));
        dispatch(fetchAntigenicRawModel(payload));
    },
    // fetchLineages: (payload) => dispatch(fetchLineages(payload)),
    // fetchAntigenicModelsList: (payload) => dispatch(fetchAntigenicModelsList(payload)),
    unhideAlphaClade: (payload) => dispatch(unhideAlphaClade(payload)),
    unhideRhoClade: (payload) => dispatch(unhideRhoClade(payload)),
    setParameters: (payload) => dispatch(setParameters(payload)),
    fetchMeasureScalesDomains:(payload) => dispatch(fetchMeasureScalesDomains(payload)),
    setHiddenMenu:(payload) => dispatch(setHiddenMenu(payload)),
    fetchClades: payload => dispatch(fetchClades(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(Antigenic);
// export { AntigenicExport };
