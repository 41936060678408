import { createSelector } from 'reselect';
import { getMetadataMeasuresWithScales } from './rangeDataSelector';

const getGeojson = ({geomap}) => geomap.geojson;
const getGeoJsonFeatures = ({geomap}) => geomap.geojson.features;
const getGeoMapColorBy = ({ parameters }) => parameters.geoMapColorBy;

export const uniqueCladesSelector = createSelector(getGeoJsonFeatures, features => features ? [...new Set(features.map(el => el.properties.groupId).sort())] : []);

export const correctedColorsGeoJson = createSelector([getGeojson, getGeoMapColorBy, getMetadataMeasuresWithScales], (geojson, geoMapColorBy, measures) => {
    const selectedMeasure = measures[geoMapColorBy] || {};
    
    const colorData = selectedMeasure.scale.range?.data; //visibleRange;

    // console.log('colorData = ', colorData)

    // console.log('[correctedColorsGeoJson] geoMapColorBy = ',geoMapColorBy);
    const _geojson = { ...geojson };

    if (colorData) {
        _geojson.features = (geojson.features||[]).map(f => {
         return { ...f, properties: { ...f.properties, color: colorData[f.properties.groupId]?.color}};
         
        });
        // console.log(_geojson.features);
    }
    // console.log(_geojson.features);
    else _geojson.features = [];
    return _geojson;
})
