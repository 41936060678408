import React from 'react';
import { Dialog, DialogActions,
    DialogContent, DialogContentText, DialogTitle,
    Button
} from '@mui/material';
import { styles, dialog } from './styles';
import { connect } from 'react-redux';
import { setErrorWebsite } from '../../redux/actions/renderActions';
import { signOut } from '../../redux/actions/userActions';

const ErrorAlert = (props) => {
    const { errorWebsite, setErrorWebsite, errorWebsiteText, signOut, errorWebsiteType } = props;
    const classes = styles();

    //console.log(errorWebsite, errorWebsiteText, errorWebsiteType )
    const handleClose = () => {
        setErrorWebsite({errorWebsite: false});
        errorWebsiteType !== 'fetch' && signOut({timeout: true});
    };

    return (
        <Dialog
            open={errorWebsite}
            onClose={() => {}}
            aria-labelledby="session-timeout-title"
            aria-describedby="session-timeout-description"
            sx={dialog}
        >
            <DialogTitle id="session-timeout">
                Error
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="session-timeout-description">
                    {errorWebsiteText}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button className={classes.confirm} onClick={() => handleClose()}>
                    {errorWebsiteType === 'fetch' ? 'OK' : 'Sign in'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const mapStateToProps = (state) => {
    const {errorWebsite, errorWebsiteText, errorWebsiteType} = state.render;
    return {
        errorWebsite,
        errorWebsiteText,
        errorWebsiteType
    };

};

const mapDispatchToProps = (dispatch) => ({
    setErrorWebsite: (payload) => dispatch(setErrorWebsite(payload)),
    signOut: (payload) => dispatch(signOut(payload)),
});


export default connect(mapStateToProps, mapDispatchToProps)(ErrorAlert);
