import { ofType } from 'redux-observable';
import { mergeMap, debounceTime, switchMap, exhaustMap } from 'rxjs/operators';
import { fetchAxios } from '../../functions/axiosRequests';

import config from '../../config/envConfig';
import { prepareUrl, dateToDays } from '../../functions/functions';
import {
    FETCH_CUSTOM_TREE_ATTRS_REQUEST,
    FETCH_SELECTED_STRAIN_REQUEST,
    FETCH_SUBSET_TREE_REQUEST,
    FETCH_TREE_FREQS_REQUEST,
    FETCH_TCELL_ANTIGENICITY_OPTIONS_REQUEST,
    FETCH_TCELL_ANTIGENICITY_SCORES_REQUEST,
    FETCH_VISIBLE_NODES_REQUEST,
    FETCH_VACCINE_CANDIDATES_REQUEST,
    FETCH_VP_METHODS_REQUEST,
    FETCH_STRAINS_LIST_REQUEST,
    FETCH_VP_VALUES_REQUEST,
    FETCH_RECALCULATED_TREE_REQUEST,
    FETCH_NODE_REQUEST,
    FETCH_ANTIGENIC_REFERENCE_STRAIN_REQUEST,
    FETCH_REFERENCE_STRAINS_REQUEST
    // FETCH_COLOR_BY_RULE_STRAINS_REQUEST,
} from '../actions/actionTypes';
import {
    fetchVisibleNodesSuccess,
    fetchVisibleNodesError,
    fetchRecalculatedTreeSuccess,
    fetchRecalculatedTreeError,
    fetchSubsetTreeSuccess,
    fetchSubsetTreeError,
    fetchCustomTreeAttrsSuccess,
    fetchCustomTreeAttrsError,
    fetchTCellAntigenicityOptionsSuccess,
    fetchTCellAntigenicityOptionsError,
    fetchTCellAntigenicityScoresSuccess,
    fetchTCellAntigenicityScoresError,
    fetchSelectedStrainSuccess,
    fetchSelectedStrainError,
    fetchAntigenicReferenceStrainSuccess,
    fetchAntigenicReferenceStrainError,
    fetchTreeFreqsSuccess,
    fetchTreeFreqsError,
    fetchVaccineCandidatesSuccess,
    fetchVaccineCandidatesError,
    fetchVpMethodsSuccess,
    fetchVpMethodsError,
    fetchReferenceStrainsSuccess,
    fetchReferenceStrainsError,
    // fetchMutationClassesSuccess,
    // fetchMutationClassesError,
    fetchVpValuesSuccess,
    fetchVpValuesError,
    fetchStrainsListSuccess,
    fetchStrainsListError,
    fetchNodeSuccess,
    fetchNodeError,
    // fetchColorByRuleStrainsSuccess,
    // fetchColorByRuleStrainsError
} from '../actions/treeDataActions';

import { sessionTimeOut } from './helpers/functions';


export const fetchVisibleNodesEpic = (action$, store) => action$.pipe(
    ofType(FETCH_VISIBLE_NODES_REQUEST),
    mergeMap((action) => {
        const url = prepareUrl(`${config.serverLink}/api/getVisibleNodes`, action.payload);
        //console.log(`fetchVisibleNodes => url = ${url}`);

        return fetchAxios(url).then(response => fetchVisibleNodesSuccess(response.data))
            .catch(error => {
                const { status, data } = error.response;
                if (status === 408) return sessionTimeOut(error, store);
                if (status === 406) return fetchVisibleNodesError({ error: data.error, errorWebsiteText: 'An error has occured when downloading tree order.', errorWebsiteType: 'fetch'});

                return fetchVisibleNodesError({ error: data.error, errorWebsiteText: 'An error has occured when downloading tree order.', errorWebsiteType: 'fetch'});
            });
    }),
);

// Fetch tree after showLeafNumber has changed
export const fetchRecalculatedTree = (action$, store)=> action$.pipe(
    ofType(FETCH_RECALCULATED_TREE_REQUEST),
    mergeMap((action) => {
        const url = prepareUrl(`${config.serverLink}/api/getRecalculatedTree`, action.payload);
        //console.log(`fetchRecalculatedTree => url = ${url}`);

        return fetchAxios(url).then(function (response) {
            return fetchRecalculatedTreeSuccess(response.data);
        }).catch(function (error) {
            const { status, data } = error.response;
            if (status === 408) return sessionTimeOut(error, store);
            if (status === 406) return fetchRecalculatedTreeError({ error: data.error, errorWebsiteText: 'An error has occured during initialization of strain tree.', errorWebsiteType: 'fetch'});

            return fetchRecalculatedTreeError({ error: data.error, errorWebsiteText: 'An error has occured during initialization of strain tree.', errorWebsiteType: 'fetch'});
        });
    }),
);

export const fetchReferenceStrainsEpic = (action$, store)=> action$.pipe(
    ofType(FETCH_REFERENCE_STRAINS_REQUEST),
    mergeMap((action) => {
        const url = prepareUrl(`${config.serverLink}/api/getReferenceStrains`, action.payload);
        console.log(`getReferenceStrains => url = ${url}`);

        return fetchAxios(url).then(function (response) {
            return fetchReferenceStrainsSuccess(response.data);
        }).catch(function (error) {
            const { status, data } = error.response;
            if (status === 408) return sessionTimeOut(error, store);
            if (status === 406) return fetchReferenceStrainsError({ error: data.error, errorWebsiteText: 'An error has occured on fetching reference strain.', errorWebsiteType: 'fetch'});

            return fetchReferenceStrainsError({ error: data.error, errorWebsiteText: 'An error has occured on fetching reference strains', errorWebsiteType: 'fetch'});
        });
    }),
);


export const fetchSubsetTreeEpic = (action$, store) => action$.pipe(
    ofType(FETCH_SUBSET_TREE_REQUEST),
    mergeMap(action => {
        const { lineage, strainSubset, strainHighlight, zoomNodeId, ignoreStrainCutOffDate } = action.payload;
        const url = prepareUrl(`${config.serverLink}/api/subsetTree`, { lineage, strainSubset, strainHighlight, zoomNodeId, ignoreStrainCutOffDate });
        //console.log(`fetchSubsetTree => url = ${url}`);

        return fetchAxios(url).then(response => fetchSubsetTreeSuccess(response.data))
            .catch(error => {
                const { status, data } = error.response;
                if (status === 408) return sessionTimeOut(error, store);
                if (status === 406) return fetchSubsetTreeError({ error: data.error, errorWebsiteText: 'An error has occured during initialization of strain tree.', errorWebsiteType: 'fetch'});

                return fetchSubsetTreeError({ error: data.error, errorWebsiteText: 'An error has occured during initialization of strain tree.', errorWebsiteType: 'fetch'});
            });
    })
);

export const fetchTreeFreqsEpic = (action$, store) => action$.pipe(
    ofType(FETCH_TREE_FREQS_REQUEST),
    mergeMap(action => {
        const { lineage, predictionBaseline } = action.payload;
        const origPredictionBaseline = dateToDays(predictionBaseline);
        //console.log(`[fetchTreeFreqsEpic]: predictionBaseline = ${predictionBaseline} / ${origPredictionBaseline}`);
        const url = prepareUrl(`${config.serverLink}/api/recalculateTreeFrequencies`, { lineage, predictionBaseline });
        //console.log(`fetchTreeFreqs => url = ${url}`);

        return fetchAxios(url).then(response => fetchTreeFreqsSuccess({ ...response.data, origPredictionBaseline }))
            .catch(error => {
                const { status, data } = error.response;

                if (status === 408) return sessionTimeOut(error, store);
                if (status === 406) return fetchTreeFreqsError({ error: data.error, errorWebsiteText: 'An error has occured when downloading tree frequencies.', errorWebsiteType: 'fetch'});
                return fetchTreeFreqsError({ error: data.error, errorWebsiteText: 'An error has occured when downloading tree frequencies.', errorWebsiteType: 'fetch'});
            });
    })
);

export const fetchCustomTreeAttrsEpic = (action$, store) => action$.pipe(
    ofType(FETCH_CUSTOM_TREE_ATTRS_REQUEST),
    mergeMap(action => {
        const { element, ...payload } = action.payload;
        const { customTreeAttrId, markBranches } = action.payload;
        const url = prepareUrl(`${config.serverLink}/api/fetchCustomTreeAttrs`, payload);
       // console.log(`fetchCustomTreeAttrs => url = ${url}`);

        return fetchAxios(url).then(response => fetchCustomTreeAttrsSuccess({ ...response.data, element: element || 'colorBy', customTreeAttrId, markBranches }))
            .catch(error => {
                const { status, data } = error.response;
                if (status === 408) return sessionTimeOut(error, store);
                if (status === 406) return fetchCustomTreeAttrsError({ error: data.error, errorWebsiteText: 'An error has occured when downloading tree frequencies.', errorWebsiteType: 'fetch'});
                return fetchCustomTreeAttrsError({ error: data.error, errorWebsiteText: 'An error has occured when downloading tree frequencies.', errorWebsiteType: 'fetch', element: element || 'colorBy'});
            });
    })
);

export const fetchTCellAntigenicityOptionsEpic = (action$, store) => action$.pipe(
    ofType(FETCH_TCELL_ANTIGENICITY_OPTIONS_REQUEST),
    mergeMap(action => {
        const { lineage } = action.payload;
        const url = prepareUrl(`${config.serverLink}/api/getTCellAntigenicityOptions/`, { lineage });
        //`fetchTCellAntigenicityOptions => url = ${url}`);

        return fetchAxios(url).then(function (response) {
            return fetchTCellAntigenicityOptionsSuccess(response.data);
        }).catch(function (error) {
            const { status, data } = error.response;
            if (status === 408) return sessionTimeOut(error, store);

            return fetchTCellAntigenicityOptionsError({ error: data.error, errorWebsiteText: 'An error has occured during initialization of strain tree.', errorWebsiteType: 'fetch'});
        });
    })
);

export const fetchVpMethodsEpic = (action$, store) => action$.pipe(
    ofType(FETCH_VP_METHODS_REQUEST),
    mergeMap(action => {
        const url = prepareUrl(`${config.serverLink}/api/getVpMethods/`, action.payload);
        //console.log(`fetchVpMethods => url = ${url}`);

        const { settings } = action.payload;
        return fetchAxios(url).then(function (response) {
            return fetchVpMethodsSuccess({ ...response.data, settings });
        }).catch(function (error) {
            const { status, data } = error.response;
            if (status === 408) return sessionTimeOut(error, store);
            return fetchVpMethodsError({ error: data.error, errorWebsiteText: 'An error has occured when downloading VP methods.', errorWebsiteType: 'fetch'});
        });
    })
);

export const fetchVpValuesEpic = (action$, store) => action$.pipe(
    ofType(FETCH_VP_VALUES_REQUEST),
    mergeMap(action => {
        const url = prepareUrl(`${config.serverLink}/api/getVpValues`, action.payload);
       // console.log(`fetchVpValues => url = ${url}`);

        return fetchAxios(url).then(function (response) {
            return fetchVpValuesSuccess(response.data);
        }).catch(function (error) {
            const { status, data } = error.response;
            if (status === 408) return sessionTimeOut(error, store);
            if (status === 406) return fetchVpValuesError({ error: data.error, errorWebsiteText: 'An error has occured when downloading VP values.', errorWebsiteType: 'fetch'});
            return fetchVpValuesError({ error: data.error, errorWebsiteText: 'An error has occured when downloading VP values.', errorWebsiteType: 'fetch'});

        });
    })
);

export const fetchTCellAntigenicityScoresEpic = (action$, store) => action$.pipe(
    ofType(FETCH_TCELL_ANTIGENICITY_SCORES_REQUEST),
    mergeMap(action => {
        const url = prepareUrl(`${config.serverLink}/api/fetchTCellAntigenicityScores`, action.payload);
        //console.log(`fetchTCellAntigenicityScores => url = ${url}`);

        return fetchAxios(url).then(function (response) {
            return fetchTCellAntigenicityScoresSuccess(response.data);
        }).catch(function (error) {
            const { status, data } = error.response;
            if (status === 408) return sessionTimeOut(error, store);
            if (status === 406) return fetchTCellAntigenicityScoresError({ error: data.error, errorWebsiteText: 'An error has occured during downloading antigenicity scores.', errorWebsiteType: 'fetch'});

            return fetchTCellAntigenicityScoresError({ error: data.error, errorWebsiteText: 'An error has occured during downloading antigenicity scores.', errorWebsiteType: 'fetch'});
        });
    })
);



export const fetchSelectedStrainEpic = (action$, store) => action$.pipe(
    ofType(FETCH_SELECTED_STRAIN_REQUEST),
    mergeMap(action => {
        const { searchId, ...payload } = action.payload;
        const url = prepareUrl(`${config.serverLink}/api/fetchSelectedStrain`, payload);
        // console.log(`fetchSelectedStrain => url = ${url}`);

        return fetchAxios(url).then(function (response) {
            return fetchSelectedStrainSuccess({ ...response.data, searchId });
        }).catch(function (error) {
            const { status, data } = error.response;
            if (status === 408) return sessionTimeOut(error, store);
            if (status === 406) return fetchSelectedStrainError({ error: data.error, errorWebsiteText: 'An error has occured during downloading selected strain.', errorWebsiteType: 'fetch'});
            return fetchSelectedStrainError({ error: data.error, errorWebsiteText: 'An error has occured during downloading selected strain.', errorWebsiteType: 'fetch'});
        });
    })
);

export const fetchAntigenicReferenceStrainEpic = (action$, store) => action$.pipe(
    ofType(FETCH_ANTIGENIC_REFERENCE_STRAIN_REQUEST),
    mergeMap(action => {
        const { searchId, ...payload } = action.payload;
        const url = prepareUrl(`${config.serverLink}/api/fetchAntigenicReferenceStrain`, payload);
        //console.log(`fetchAntigenicReferenceStrain => url = ${url}`);

        return fetchAxios(url).then(function (response) {
            return fetchAntigenicReferenceStrainSuccess({ ...response.data, searchId });
        }).catch(function (error) {
            const { status, data } = error.response;
            if (status === 408) return sessionTimeOut(error, store);
            if (status === 406) return fetchAntigenicReferenceStrainError({ searchId, error: data.error, errorWebsiteText: 'An error has occured during downloading selected strain.', errorWebsiteType: 'fetch'});
            return fetchAntigenicReferenceStrainError({ searchId, error: data.error, errorWebsiteText: 'An error has occured during downloading selected strain.', errorWebsiteType: 'fetch'});
        });
    })
);

export const fetchVaccineCandidatesEpic = (action$, store) => action$.pipe(
    ofType(FETCH_VACCINE_CANDIDATES_REQUEST),
    mergeMap(action => {
        //console.log(action.payload)
        const { lineage } = action.payload
        const url = prepareUrl(`${config.serverLink}/api/getVaccineCandidates`, { lineage });
        // console.log(`lineage = ${lineage}`)
       // console.log(`getVaccineCandidates => url = ${url}`);

        return fetchAxios(url).then(function (response) {
            return fetchVaccineCandidatesSuccess(response.data);
        }).catch(function (error) {
            const { status, data } = error.response;
            if (status === 408) return sessionTimeOut(error, store);
            return fetchVaccineCandidatesError({ error: data.error, errorWebsiteText: 'An error has occured during downloading vaccine candidates.', errorWebsiteType: 'fetch'});
        });
    })
);

export const fetchStrainsListEpic = (action$, store) => action$.pipe(
    ofType(FETCH_STRAINS_LIST_REQUEST),
    debounceTime(50),
    switchMap(action => {
        const { lineage, strainsTxt, searchId } = action.payload
        const url = prepareUrl(`${config.serverLink}/api/searchStrains`, { lineage, strainsTxt });
        //console.log(`fetchStrainsList => url = ${url}, lineage: ${lineage}, strainsTxt: ${strainsTxt}`);

        return fetchAxios(url).then(function (response) {
            return fetchStrainsListSuccess({ strainsList: response.data.strainsList, searchId });
        }).catch(function (error) {
            const { status, data } = error.response;
            if (status === 408) return sessionTimeOut(error, store);
            return fetchStrainsListError({ error: data.error, errorWebsiteText: 'An error has occured when downloading strains list.', errorWebsiteType: 'fetch'});
        });
    })
);

export const fetchMutationsByNodeIdEpic = (action$, store) => action$.pipe(
    ofType(FETCH_NODE_REQUEST),
    exhaustMap(action => {
        const { nodeId, lineage } = action.payload
        const url = prepareUrl(`${config.serverLink}/api/mutations/${lineage}/node/${nodeId}`);
        // console.log(`fetchMutationsByNode => url = ${url}`);

        return fetchAxios(url).then(function (response) {
            return fetchNodeSuccess({ data: response.data, nodeId });
        }).catch(function (error) {
            const { status, data } = error.response;
            if (status === 408) return sessionTimeOut(error, store);
            return fetchNodeError({ error: data.error, errorWebsiteText: 'An error has occured when downloading node info.', errorWebsiteType: 'fetch'});
        });
    }),
);

// export const fetchColorByRuleStrainsEpic = (action$, store) => action$.pipe(
//     ofType(FETCH_COLOR_BY_RULE_STRAINS_REQUEST),
//     exhaustMap(action => {
//         const { lineage, colorBy, showRule, humanPool, humanSerologyDataType } = action.payload
//         const url = prepareUrl(`${config.serverLink}/api/fetchColorByRuleStrains/`,  { lineage, colorBy, showRule, humanPool, humanSerologyDataType });
//         // console.log(`fetchMutationsByNode => url = ${url}`);

//         return fetchAxios(url).then(function (response) {
//             return fetchColorByRuleStrainsSuccess(response.data);
//         }).catch(function (error) {
//             const { status, data } = error.response;
//             if (status === 408) return sessionTimeOut(error, store);
//             return fetchColorByRuleStrainsError({ error: data.error, errorWebsiteText: 'An error has occured when fetching nodes.', errorWebsiteType: 'fetch'});
//         });
//     }),
// );




