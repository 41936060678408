import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Hidden } from '@mui/material';
import ExportableComponent from '../Export/ExportableComponent';
import TreeGraph from '../../components/Tree/TreeGraph';
import { Edit } from '../Edit/Edit';
import NodeInfo from '../../components/Tree/NodeInfo';
import TreeGraphOptions from '../../components/Tree/options/TreeGraphOptions';
import CladeSchema from '../../components/CladeSchema/CladeSchema';
import MetaInformations from '../../components/MetaInformations/MetaInformations';
import ScalesLegend from '../../components/ColorLegend/Legends/ScalesLegend';
import { withStyles } from '@mui/styles';
import { fetchLineages } from '../../redux/actions/lineagesActions';
import { initStrainTree } from '../../redux/actions/sessionActions';
import { isFetchNeeded, isColorByModel, isLoadedOrNA, getMeasureShowRule } from '../../functions/functions';
import { fetchModels, fetchModel, fetchModelTypes } from '../../redux/actions/modelActions';
import { fetchAntigenicModel, fetchAntigenicObservedData, fetchAntigenicRawModel, setParameters } from '../../redux/actions/antigenicActions';
import { getShowRuleForColorBy, getCustomMeasures, getIgnoreStrainCutOffDateForColorBy, } from '../../redux/selectors/metadataSelector';
import { fetchTCellAntigenicityOptions, fetchTCellAntigenicityScores, fetchCustomTreeAttrs, fetchMutationClasses, fetchVpValues } from '../../redux/actions/treeDataActions';
import { fetchGenotypeData, fetchMutationGroupValues, fetchMutationsPositionsDictionary } from '../../redux/actions/genotypeActions';
import { getStrainTreeRenderStatus, getIsMobile, getStrainSearchStatus, shouldFetchModelsSelector } from '../../redux/selectors/statusSelector';
import { RENDER_STATUS } from '../../config/consts';
import { styles } from './styles';
import ErrorAlert from '../ErrorAlert/ErrorAlert';
import { fetchHumanPools, fetchHumanSerologyData } from '../../redux/actions/humanSerologyActions';
import { fetchClades } from '../../redux/actions/cladeActions';
import { schemaHeightMultiplierInit, schemaWidth } from '../../components/CladeSchema/helpers';
import TreeLegend from '../../components/ColorLegend/Legends/TreeLegend';
import { isNull } from 'lodash';


const StrainTree = props => {

    const {
        lineage, regionId, modelId, colorBy, antigenicTiterType, antigenicDataType, tcellAntigenicityOptionsStatus, exportMode, zoomNodeId, strainSubset, exportParams, showRule, customTreeAttrId,
        gene, hla, strainCutOffDate, ignoreStrainCutOffDate, vpMethod, mutgene, mutposition, humanPool, humanSerologyDataType, strainId, modelRegionId, modelType, antigenicModelId, mutationsGroup,
        showCladeBar, showMutationsGroups, models,

        shouldFetchModels,

        treeDataStatus, cladesStatus, modelsStatus, lineageStatus, lineagesStatus, antigenicModelStatus, antigenicObservedDataStatus, antigenicRawModelStatus, mutationsPositionsDictStatus, genotypeDataStatus,
        customMeasures, customTreeDataStatus, tcellStatus, vpMethodsStatus, mutationClassesStatus, vpValuesStatus, humanPoolsStatus, humanSerologyDataStatus, strainSearchLoading,
        modelStatus, modelTypesStatus, antigenicModelsStatus, mutationGroupValuesStatus,

        fetchModel, fetchLineages, fetchClades, initStrainTree, fetchAntigenicModel, fetchCustomTreeAttrs, fetchTCellAntigenicityScores, fetchVpMethods, fetchMutationClasses, fetchVpValues,
        fetchAntigenicObservedData, fetchGenotypeData, fetchMutationsPositionsDictionary,
        fetchAntigenicRawModel,
        fetchHumanPools, fetchHumanSerologyData, fetchModelTypes, fetchMutationGroupValues

    } = props;

    const { classes, editMode, menuRight, hiddenMenuMobile, isMobile, renderStatus, hiddenMenu } = props;


    useEffect(() => {
        initComponentData();
    })

    const canRefetchData = treeDataStatus === 'loaded' && !strainSearchLoading && vpValuesStatus === 'loaded';



    const _fetchModel = async () => {

        // const hiddenModelsSelector = (isMobile && hiddenMenuMobile) || (!isMobile && hiddenMenu);
        // const shouldFetchModels = !exportMode && !hiddenModelsSelector;

        // console.log(`
        // shouldFetchModels = ${shouldFetchModels},
        // isColorByModel(${colorBy}) = ${isColorByModel(colorBy)}  
        // modelId = ${modelId} 
        // models.includes(modelId) = ${(models||[]).includes(modelId)}
        // modelType = ${modelType} 
        // modelRegionId = ${modelRegionId} 
        // exportMode = ${exportMode}
        // isLoadedOrNA(modelTypesStatus) = ${isLoadedOrNA(modelTypesStatus)}
        // modelsStatus = ${modelsStatus} 
        // canRefetchData = ${canRefetchData} 
        // isFetchNeeded(modelStatus) = ${isFetchNeeded(modelStatus)}
        // modelStatus=${modelStatus}
        // `, models)
        if (isColorByModel(colorBy)
            && modelId
            && (!shouldFetchModels || models.includes(modelId))
            && modelType
            && modelRegionId
            && (!shouldFetchModels || (isLoadedOrNA(modelTypesStatus) && modelsStatus === 'loaded'))
            && canRefetchData
            && isFetchNeeded(modelStatus)
        ) return fetchModel({ lineage, modelRegionId, strainSubset, modelId, zoomNodeId, modelType, colorBy });
        return null;
    }

    const _fetchTree = async () => {
        const genotypeParams = colorBy === 'genotype' ? { mutgene, mutposition } : {};
        const humanSerologyParams = colorBy === 'humanSerology' ? { humanPool, humanSerologyDataType } : {};
        if (isFetchNeeded(treeDataStatus)) {
            return initStrainTree({ lineage, /*modelId,*/ colorBy, zoomNodeId, strainSubset, showRule, strainCutOffDate, ignoreStrainCutOffDate, vpMethod, ...genotypeParams, strainId, ...humanSerologyParams, /*treeAttrsParams,*/ ...(exportParams || {}) })
         } 
         return null;
    }

    const _fetchClades = async () => {
        const cladesNeeded = colorBy === 'clade' || showCladeBar;
        if (isFetchNeeded(cladesStatus) /*&& treeDataStatus === 'loaded'*/ && cladesNeeded) {
            return fetchClades({ lineage }) 
        };
        return null;
    }

    const _fetchVpValues = async () => {
        const initStrainTreeNeeded = isFetchNeeded(treeDataStatus) || isFetchNeeded(cladesStatus);
        const fetchVpValuesNeeeded = !initStrainTreeNeeded && isFetchNeeded(vpValuesStatus);
        if (fetchVpValuesNeeeded) 
            return fetchVpValues({ lineage, zoomNodeId, strainSubset, showRule, strainCutOffDate, vpMethod })
        return null;
    }

    const _fetchMutationGroupValues = async () => {
        if (showMutationsGroups && mutationsGroup && isFetchNeeded(mutationGroupValuesStatus) && treeDataStatus === 'loaded')
            return fetchMutationGroupValues({ lineage, mutationsGroup });
        return null;
    }

    const initComponentData = async () => {
        if (lineageStatus !== 'loaded') return;
        // console.log(`[initComponentData]
        //     colorBy = ${colorBy},
        //     modelId = ${modelId},
        //     modelStatus = ${modelStatus},
        //     showRule = ${showRule},
        //     gene = ${gene},
        //     hla = ${hla},
        //     tCellStatus = ${tcellStatus[`${gene}_${hla}`]},

        //     treeDataStatus = ${treeDataStatus},
        //     cladesStatus = ${cladesStatus}`);
        
      
        // const treeAttrsParams = [].join(',');

        const fetchColorByAntigenicModel = colorBy === 'antigenic' && modelId && antigenicTiterType && isFetchNeeded(antigenicModelStatus);
        const fetchColorByAntigenicInferredOrObserved = fetchColorByAntigenicModel && (antigenicDataType === 'inferred' || antigenicDataType === 'observed');
        const fetchColorByAntigenicEpitopeClades = colorBy === 'antigenic' && modelId && antigenicTiterType && antigenicDataType === 'epitope_clades' && isFetchNeeded(antigenicObservedDataStatus);
        const fetchColorByAntigenicRawStrains = colorBy === 'antigenic' && modelId
            && antigenicTiterType
            && (antigenicDataType === 'raw_strain' || antigenicDataType === 'observed_strain')
            && isFetchNeeded(antigenicRawModelStatus) && treeDataStatus === 'loaded' && cladesStatus === 'loaded';
        // console.log(`fetchColorByAntigenicRawStrains = ${fetchColorByAntigenicRawStrains}, antigenicRawModelStatus = ${antigenicRawModelStatus}`);
        // console.log('[StrainTree]', initStrainTreeNeeded);
        // console.log('lineagesStatus = ',lineagesStatus, isFetchNeeded(lineagesStatus) )
        // console.log('treeDataStatus', treeDataStatus, 'modelStatus = ', modelStatus, 'modelId = ', modelId, 'isFetchNeeded(modelStatus) = ', isFetchNeeded(modelStatus), 'willFetchModel => ', (modelId && treeDataStatus === 'loaded' && isFetchNeeded(modelStatus) && isColorByModel(colorBy)))
        //console.log(`vpMethod = ${vpMethod}, treeDataStatus = ${treeDataStatus}, strainSearchLoading = ${strainSearchLoading}, vpValuesStatus = ${vpValuesStatus}, modelStatus = ${modelStatus}, modelId = ${modelId}`);


       

        //console.log('[StrainTree] colorBy = ',colorBy, 'isColorByModel = ',isColorByModel(colorBy), 'modelTypesStatus = ',modelTypesStatus, 'modelsStatus = ',modelsStatus)
        // console.log(`
        //     isColorByModel(colorBy) = ${isColorByModel(colorBy)}
        //     modelId = ${modelId}
        //     modelType = ${modelType}
        //     modelRegionId = ${modelRegionId}
        //     exportMode = ${exportMode}
        //     isLoadedOrNA(modelTypesStatus) = ${isLoadedOrNA(modelTypesStatus)}
        //     modelsStatus = ${modelsStatus}
        //     canRefetchData = ${canRefetchData} 
        //     isFetchNeeded(modelStatus) = ${isFetchNeeded(modelStatus)}
        // `)

       
    
        // console.log(`exportMode = ${exportMode}, lineageStatus = ${lineageStatus}`);
        await Promise.all([
            _fetchModel(),
            _fetchTree(),
            _fetchClades(),
            _fetchVpValues(),

            // isFetchNeeded(tcellAntigenicityOptionsStatus) && !exportMode ? fetchTCellAntigenicityOptions({ lineage }) : null,
            isFetchNeeded(mutationClassesStatus) ? fetchMutationClasses({ lineage }) : null,
            isFetchNeeded(customTreeDataStatus[colorBy]) && customMeasures[colorBy] && canRefetchData && !exportMode ? fetchCustomTreeAttrs({ lineage, colorBy, customTreeAttrId, strainSubset, showRule, zoomNodeId }) : null,
            fetchColorByAntigenicInferredOrObserved && (exportMode || antigenicModelsStatus === 'loaded') ? fetchAntigenicModel({ lineage, antigenicModelId, antigenicDataType, antigenicTiterType }) : null,
            fetchColorByAntigenicEpitopeClades ? fetchAntigenicObservedData({ lineage, antigenicModelId }) : null,
            fetchColorByAntigenicRawStrains ? fetchAntigenicRawModel({ lineage, antigenicModelId, antigenicDataType, antigenicTiterType }) : null,
            // (colorBy === 'genotype' && isFetchNeeded(mutationsPositionsDictStatus)) ? fetchMutationsPositionsDictionary({ lineage }) : null,
            (colorBy === 'genotype' && isFetchNeeded(genotypeDataStatus) && !isNull(mutgene) && mutgene.length > 0 && !isNull(mutposition)) ? fetchGenotypeData({ lineage, mutgene, mutposition, zoomNodeId }) : null,
            (colorBy === 'tcellAntigenicity' && gene && hla && isFetchNeeded(tcellStatus[`${gene}_${hla}`]) && !exportMode) ? fetchTCellAntigenicityScores({ lineage, colorBy, gene, hla, showRule, strainSubset }) : null,
            (colorBy === 'humanSerology' && isFetchNeeded(humanPoolsStatus)) ? fetchHumanPools({ lineage }) : null,
            (colorBy === 'humanSerology' && canRefetchData && isFetchNeeded(humanSerologyDataStatus) && humanPool && humanSerologyDataType) ? fetchHumanSerologyData({ lineage, colorBy, humanPool, humanSerologyDataType }) : null,
            _fetchMutationGroupValues()
        ])
    }


 
    return (
        <>
            {!exportMode && (
                <div className={classes.root}>
                    <ErrorAlert />
                    <Grid container className={classes.container}>
                        {
                            isMobile ?
                                <>
                                    <TreeGraph />
                                    {!hiddenMenuMobile &&
                                        <TreeGraphOptions intro={false} />
                                    }
                                    <NodeInfo />
                                </>
                                :
                                <>
                                    {!menuRight &&
                                        <Grid item className={`${classes.treeSidebarLeft} ${hiddenMenu ? classes.hidden : ''}`}>
                                            {!hiddenMenu && <NodeInfo />}
                                            <TreeGraphOptions intro={false} />
                                        </Grid>}
                                    <Grid item xs className={classes.item}>
                                        <ExportableComponent filename="strainTree">
                                            <TreeGraph />
                                        </ExportableComponent>
                                        <CladeSchema cladeSchemaWidth={schemaWidth} />
                                        <MetaInformations />
                                    </Grid>
                                    {menuRight &&
                                        <Grid item className={`${classes.treeSidebarRight} ${hiddenMenu ? classes.hidden : ''}`}>
                                            {!hiddenMenu && <NodeInfo />}
                                            <TreeGraphOptions intro={false} />
                                        </Grid>
                                    }
                                </>

                        }



                    </Grid>

                </div>
            )}
            {exportMode && !editMode && (
                <div className={classes.rootExport}>
                    <div className={classes.containerExport}>
                        <div id='exportComponent' className={classes.itemExport}>
                            <TreeGraph />
                        </div>
                        <div className={classes.legendExport} >
                            <TreeLegend />
                            <ScalesLegend scaleId="strainTree" valueGetter={() => null} ticks={3} precision={3} />
                        </div>
                        <CladeSchema cladeSchemaWidth={schemaWidth} />
                    </div>
                </div>
            )}
            {exportMode && editMode && (
                <Edit />
            )}
            {(renderStatus === RENDER_STATUS.DONE) && (
                <div id="exportDone" />
            )}
        </>
    );

}

StrainTree.propTypes = {
    classes: PropTypes.shape({
        root: PropTypes.string,
        container: PropTypes.string,
        item: PropTypes.string,
        legend: PropTypes.string,
        rootExport: PropTypes.string,
        containerExport: PropTypes.string,
        itemExport: PropTypes.string
    }),
    //location: PropTypes.shape({ pathname: PropTypes.string }),
    exportMode: PropTypes.bool,
    treeDataStatus: PropTypes.string,
    cladesStatus: PropTypes.string,
    modelStatus: PropTypes.string,
    modelsStatus: PropTypes.string,
    lineageStatus: PropTypes.string,
    lineagesStatus: PropTypes.string,
    lineage: PropTypes.string,
    modelId: PropTypes.string,
    regionId: PropTypes.string,
    colorBy: PropTypes.string,
    zoomNodeId: PropTypes.number,
    antigenicTiterType: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number]),
    refClade: PropTypes.number,
    //refStrainName: PropTypes.string,
    strainSubset: PropTypes.string,
    renderStatus: PropTypes.string,
    clades: PropTypes.shape({ label: PropTypes.string }),
    exportParams: PropTypes.shape({ lineage: PropTypes.string, modelId: PropTypes.string, regionId: PropTypes.string }),
    tcellAntigenicityOptionsStatus: PropTypes.string,
    initStrainTree: PropTypes.func,
    fetchLineage: PropTypes.func,
    fetchLineages: PropTypes.func,
    fetchAntigenicModel: PropTypes.func,
    fetchGenotypeData: PropTypes.func,
    fetchTCellAntigenicityOptions: PropTypes.func
};

const mapStateToProps = (state) => ({
    treeDataStatus: state.treeData.treeDataStatus,
    cladeSchema: state.cladeData.cladeSchema,
    cladesStatus: state.cladeData.cladesStatus,
    modelStatus: state.modelData.modelStatus[state.parameters.colorBy],
    modelsStatus: state.models.modelsStatus[state.parameters.colorBy],
    modelTypesStatus: state.models.modelTypesStatus[state.parameters.colorBy] || 'NA',
    lineagesStatus: state.lineages.lineagesStatus,
    lineageStatus: state.lineages.lineageStatus,
    genotypeDataStatus: state.genotype.genotypeDataStatus,
    mutationsPositionsDictStatus: state.genotype.mutationsPositionsDictStatus,
    lineage: state.parameters.lineage,
    modelType: state.parameters.modelType,
    modelId: state.parameters.modelId,
    models: state.models.models[state.parameters.colorBy],
    antigenicModelId: state.parameters.antigenicModelId,
    regionId: state.parameters.regionId,
    modelRegionId: state.parameters.modelRegionId,
    colorBy: state.parameters.colorBy,
    zoomNodeId: state.parameters.zoomNodeId,
    showCladeBar: state.parameters.showCladeBar,
    showMutationsGroups: state.parameters.showMutationsGroups,
    antigenicTiterType: state.parameters.antigenicTiterType,
    antigenicDataType: state.parameters.antigenicDataType,
    antigenicModelStatus: state.antigenic.antigenicModelStatus,
    antigenicObservedDataStatus: state.antigenic.antigenicObservedDataStatus,
    antigenicRawModelStatus: state.antigenic.antigenicRawModelStatus,
    //refStrainName: state.parameters.refStrainName,
    strainSubset: state.parameters.strainSubset,
    customTreeAttrId: state.parameters.customTreeAttrId,
    refClade: state.parameters.refClade,
    gene: state.parameters.gene,
    editMode: state.parameters.editMode,
    mutgene: state.parameters.mutgene,
    mutposition: state.parameters.mutposition,
    mutationGroup: state.parameters.mutationGroup,
    mutationGroupValuesStatus: state.genotype.mutationGroupValuesStatus,
    strainId: state.parameters.strainId,
    hla: state.parameters.hla,
    vpMethod: state.parameters.vpMethod,
    mutationsGroup: state.parameters.mutationsGroup,
    strainCutOffDate: state.parameters.strainCutOffDate,
    ignoreStrainCutOffDate: getIgnoreStrainCutOffDateForColorBy(state),
    clades: state.cladeData.clades,
    tcellAntigenicityOptionsStatus: state.metadata.tcellAntigenicityOptionsStatus,
    vpMethodsStatus: state.metadata.vpMethodsStatus,
    mutationClassesStatus: state.metadata.mutationClassesStatus,
    showRule: getShowRuleForColorBy(state),
    exportMode: state.parameters.exportMode,
    renderStatus: state.render.renderStatus, //getStrainTreeRenderStatus(state), //

    customTreeDataStatus: state.customTreeData.status,
    tcellStatus: state.customTreeData.tcellStatus,
    vpValuesStatus: state.treeData.vpValuesStatus,
    genotypeDataStatus: state.genotype.genotypeDataStatus,
    customMeasures: getCustomMeasures(state),
    menuRight: state.user.menuRight,
    hiddenMenu: state.render.hiddenMenu,
    hiddenMenuMobile: state.render.hiddenMenuMobile,
    isMobile: getIsMobile(),
    shouldFetchModels: shouldFetchModelsSelector(state), 
    strainSearchLoading: getStrainSearchStatus(state),
    humanPoolsStatus: state.humanSerology.humanPoolsStatus,
    humanSerologyDataStatus: state.humanSerology.humanSerologyDataStatus,
    humanPool: state.parameters.humanPool,
    humanSerologyDataType: state.parameters.humanSerologyDataType,
    antigenicModelsStatus: state.models.modelsStatus.antigenic,



});


const mapDispatchToProps = dispatch => ({
    fetchModel: payload => {
        dispatch(setParameters(payload));
        dispatch(fetchModel(payload));
    },
    //fetchLineages: payload => dispatch(fetchLineages(payload)),
    fetchClades: payload => dispatch(fetchClades(payload)),
    initStrainTree: payload => dispatch(initStrainTree(payload)),
    fetchAntigenicModel: payload => dispatch(fetchAntigenicModel(payload)),
    fetchAntigenicObservedData: payload => dispatch(fetchAntigenicObservedData(payload)),
    fetchAntigenicRawModel: payload => dispatch(fetchAntigenicRawModel(payload)),
    // fetchTCellAntigenicityOptions: payload => dispatch(fetchTCellAntigenicityOptions(payload)),
    fetchTCellAntigenicityScores: payload => dispatch(fetchTCellAntigenicityScores(payload)),
    fetchCustomTreeAttrs: payload => dispatch(fetchCustomTreeAttrs(payload)),
    fetchVpValues: payload => dispatch(fetchVpValues(payload)),
    fetchMutationsPositionsDictionary: payload => dispatch(fetchMutationsPositionsDictionary(payload)),
    fetchGenotypeData: payload => dispatch(fetchGenotypeData(payload)),
    fetchMutationClasses: payload => dispatch(fetchMutationClasses(payload)),
    fetchHumanPools: payload => dispatch(fetchHumanPools(payload)),
    fetchHumanSerologyData: payload => dispatch(fetchHumanSerologyData(payload)),
    fetchMutationGroupValues: payload => dispatch(fetchMutationGroupValues(payload))
    //fetchColorByRuleStrains: payload => dispatch(fetchColorByRuleStrains(payload))
});

const StrainTreeWithStyles = withStyles(styles)(StrainTree);

export default connect(mapStateToProps, mapDispatchToProps)(StrainTreeWithStyles);

