import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@mui/styles';
import { InputLabel, FormControl, Grid, Button, MenuItem, IconButton } from '@mui/material';
import ScalesLegend from '../../ColorLegend/Legends/ScalesLegend';
import { setLayoutParameters, setParameters } from '../../../redux/actions/parametersActions';
import { getNodeDataById } from '../../../redux/selectors/nodeDataSelector';
import ModelSelector from '../../ModelSelector/ModelSelector';
import ColorBySelector from '../../OptionsSelector/ColorBySelector';
import BranchColorBySelector from '../../OptionsSelector/BranchColorBySelector';
import LineageSelector from '../../OptionsSelector/LineageSelector';
import TreeScaleTypeSelector from '../../OptionsSelector/TreeScaleTypeSelector';
import NodeVisibilitySelector from '../../OptionsSelector/NodeVisibilitySelector';
import StrainsSubsetSelector from '../../OptionsSelector/StrainsSubsetSelector';
import StrainsHighlightSelector from '../../OptionsSelector/StrainsHighlightSelector';
import VpMethodSelector from '../../OptionsSelector/VpMethodSelector';
import ShowLeafNumberSelector from '../../OptionsSelector/ShowLeafNumberSelector';
import DisplayOrderSelector from '../../OptionsSelector/DisplayOrderSelector';
import AdditionalColorBySelector from '../../OptionsSelector/AdditionalColorBySelector';
import PredictionBaselinePicker from '../../DatePickers/PredictionBaselinePicker';
import StrainCutoffDatePicker from '../../DatePickers/StrainCutoffDatePicker';
import ColorScaleSelector from '../../OptionsSelector/ColorScaleSelector';
import Alerts from '../../Alerts/Alerts';
import TreeStrainSearch from '../TreeStrainSearch';
import { emptyObject } from '../../../functions/functions';
import { LAYOUT } from '../../../config/dictionaries';
import TreeLegend from '../../ColorLegend/Legends/TreeLegend';
import CustomSelect from '../../../assets/GlobalStyles/CustomSelect';
import { styles } from './styles';
import HamburgerIcon from '../../SvgIcons/HamburgerIcon';
import { HideIcon } from '../../Alerts/styles';
import { setHiddenMenu } from '../../../redux/actions/renderActions';
import { getIsMobile } from '../../../redux/selectors/statusSelector';
import CladeBarTypeSelector from '../../OptionsSelector/CladeBarTypeSelector';

const TreeGraphOptions = props => {
    const { classes, modelParam, colorBy, layout, nodeId, permissions, showAlert, hiddenMenu, isMobile, showCladeBar,
        intro, setLayoutParameters, setHiddenMenu } = props;

    const handleLayoutChange = event => {
        const layout = event.target.value;
        setLayoutParameters({ layout });
    };

    const handleHideClick = () => {
        setHiddenMenu({ hiddenMenu: !hiddenMenu });
    }


    // console.log(( permissions.modelSelector || intro) && modelParam);

    return (
        <>
            {!hiddenMenu ?
                <div
                    style={nodeId ? { padding: 0, margin: 0 } : {}}
                    className={showAlert ?
                        isMobile ?
                            classes.rootWithAlertsMobile
                            : classes.rootWithAlerts
                        : isMobile ?
                            classes.rootWithoutAlertsMobile
                            : classes.rootWithoutAlerts
                    }>
                    <div style={{width: '100%'}}>
                        {emptyObject(nodeId) && (
                            <form autoComplete="off" style={{paddingBottom: '55px'}}>
                                {
                                    !isMobile && !nodeId &&
                                    <div className={classes.buttonWrapper}>
                                        <IconButton className={classes.hideButton} onClick={handleHideClick}>
                                            <HideIcon />
                                        </IconButton>
                                    </div>
                                }
                                <Alerts module="strainTree" />
                                {( permissions.lineageSelector || intro ) && <LineageSelector />}
                                {( permissions.colorBySelector || intro ) && <ColorBySelector intro={intro} />}
                                {( permissions.modelSelector || intro) && modelParam && <ModelSelector modelContext="strainTree" fixedModelType={colorBy === 'antigenic' ? 'Antigenic' : undefined}/>}
                                {( permissions.antigenicColorByOptionsSelector || intro ) && <AdditionalColorBySelector />}
                                {( permissions.cladeBarTypeSelector ) && showCladeBar && <CladeBarTypeSelector />}
                                <TreeLegend />
                                { !intro && <ColorScaleSelector /> }
                                <ScalesLegend
                                    scaleId="strainTree"
                                    valueGetter={(state) => getNodeDataById(state)[colorBy]}
                                    ticks={3}
                                    inContainer
                                    precision={3}
                                    mode="strainTree" />

                                {permissions.markBranchesSelector && <BranchColorBySelector />}
                                {permissions.vpMethodSelector && <VpMethodSelector />}
                                {( permissions.layoutSelector || intro ) &&
                                    <FormControl className={classes.formControl} fullWidth>
                                        <InputLabel htmlFor="layout">
                                            Layout
                                        </InputLabel>
                                        <CustomSelect
                                            value={layout}
                                            onChange={handleLayoutChange}
                                            inputProps={{
                                                name: 'layout',
                                                id: 'layout',
                                            }}
                                        >
                                            {Object.values(LAYOUT).map((l) => (
                                                <MenuItem key={l.value} value={l.value}>{l.label}</MenuItem>
                                            ))}
                                        </CustomSelect>
                                    </FormControl>
                                }
                                {( permissions.treeScaleTypeSelector || intro ) && <TreeScaleTypeSelector intro={intro} />}


                                <Grid container className={classes.datePickers} spacing={2}>
                                    {permissions.predictionBaselineSelector && <Grid item sm={12} md={6}><PredictionBaselinePicker type={'predictionBaseline'} /></Grid>}
                                    {( permissions.strainCutoffDateSelector || intro ) && <Grid item sm={12} md={intro ? 12 : 6}><StrainCutoffDatePicker /></Grid>}
                                </Grid>
                                <Grid container spacing={2}>
                                    {permissions.showLeafNumberSelector && <Grid item sm={12} md={6}><ShowLeafNumberSelector /></Grid>}
                                    {( permissions.displayOrderSelector) && <Grid item sm={12} md={intro ? 12 : 6}><DisplayOrderSelector /></Grid>}
                                </Grid>
                                {( permissions.nodeVisibilitySelector || intro ) && <NodeVisibilitySelector intro={intro} />}
                                {( permissions.strainsSubsetSelector ) && <StrainsSubsetSelector context="strainTree" />}
                                {( permissions.strainsHighlightSelector ) && <StrainsHighlightSelector />}
                                {( permissions.searchStrainSelector || intro) && <TreeStrainSearch label="Search strain" searchId="searchStrain" />}
                            </form>
                        )}
                    </div>
                </div>
                :
                <IconButton className={classes.expandButton} onClick={handleHideClick}>
                    <HamburgerIcon />
                </IconButton>

            }
        </>
    );

}
TreeGraphOptions.propTypes = {
    classes: PropTypes.shape({ formControl: PropTypes.string, root: PropTypes.string }),
    colorBy: PropTypes.string,
    layout: PropTypes.string,
    modelParam: PropTypes.bool,
    nodeId: PropTypes.number,
    setParameters: PropTypes.func,
    fetchTreeFreqs: PropTypes.func
};

const mapStateToProps = ({ metadata, models, lineages, parameters, nodeData, user, alert, render }) => ({
    ...models,
    ...lineages,
    colorBy: parameters.colorBy,
    layout: parameters.layout,
    lineage: parameters.lineage,
    showCladeBar: parameters.showCladeBar,
    modelParam: metadata.measures[parameters.colorBy] && metadata.measures[parameters.colorBy].model ? true : false,
    nodeId: nodeData.nodeId,
    permissions: user.permissions.strainTreePermissions,
    showAlert: alert.strainTree.show,
    hiddenMenu: render.hiddenMenu,
    isMobile: getIsMobile()
});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        setParameters,
        setLayoutParameters,
        setHiddenMenu
    },
    dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TreeGraphOptions));
