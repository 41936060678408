/* eslint-disable no-unused-vars */
// Module.exports = (labeler) => {
//     // labeler.js https://github.com/tinker10/D3-Labeler
//     (function() {

const debug_id =null;

export default function () {
    var lab = [],
        anc = [],
        w = 1, // box width
        h = 1, // box width
        labeler = {};

    var max_move = 5.0,
        max_angle = 0.5,
        acc = 0,
        rej = 0;

    // weights
    var w_move = 0.3, // move from original preferred position
        w_len = 0.2, // leader line length
        w_inter = 1.0, // leader line intersection
        w_lab2 = 30.0, // label-label overlap
        w_lab_anc = 1000.0, // label-anchor overlap
        w_orient = 0.3, // orientation bias
        w_excess = 2000.0,// sticking out outside the chart area
        w_branch_excess = 1000.0 //sticking out outside branch area
    ;
    // booleans for user defined functions
    var user_energy = false,
        user_schedule = false;

    var user_defined_energy, user_defined_schedule;

    const energy = function (index, before=false) {
        // energy function, tailored for label placement

        const getClosestCorner = (lab, anc) => {
            const x0 = lab.x; // /*- labelWidth - branchWidth*/ + r + rMargin; //branchWidth;
            const y0 = lab.y - lab.height; //- labelHeight/2; //+ branchWidth; 
            const corners = [
                { i: 0, x: x0, y: y0 },
                { i: 1, x: x0, y: y0 + lab.height / 2 },
                { i: 2, x: x0, y: y0 + lab.height },
                { i: 3, x: x0 + lab.width / 2, y: y0 },
                { i: 4, x: x0 + lab.width / 2, y: y0 + lab.height / 2 },
                { i: 5, x: x0 + lab.width / 2, y: y0 + lab.height },
                { i: 6, x: x0 + lab.width, y: y0 },
                { i: 7, x: x0 + lab.width, y: y0 + lab.height / 2 },
                { i: 8, x: x0 + lab.width, y: y0 + lab.height },
            ];

            const getDistance = (p1, p2) => Math.sqrt((p1.x - p2.x) ** 2 + (p1.y - p2.y) ** 2);
           
            const { selected } = corners.reduce((acc, c, index) => {
                const dist = getDistance(c, anc);
                if (!acc.dist || dist < acc.dist) {
                    acc.selected = index;
                    acc.dist = dist;
                }
                return acc;
            }, {});
            return corners[selected];
        };

        const p = getClosestCorner(lab[index], anc[index]);
       
        //console.log('p', lab[index], p);
        var m = lab.length,
            ener = 0,
            dx = p.x - anc[index].x, //lab[index].x - anc[index].x,
            dy = p.y - anc[index].y, //anc[index].y - lab[index].y + lab[index].height / 2,
            dist = Math.sqrt(dx * dx + dy * dy),
            overlap = true,
            amount = 0,
            theta = 0,
            _dx = lab[index].x - lab[index]._x,
            _dy = lab[index].y - lab[index]._y,
            move_dist = Math.sqrt(_dx * _dx + _dy * _dy);
        
   
        //const getLastEnerDiff = () => earr.length > 1 ? earr[earr.length-1] - earr[earr.length-2] : 0;

        //earr.push(ener)
        if (lab[index]?.id == debug_id) console.log('0. start', lab[index]?.id, ener)
        // penalty for move
        if (move_dist > 0) ener += move_dist * w_move; 
        if (lab[index]?.id == debug_id) console.log('1. move penalty ', lab[index]?.id, move_dist * w_move, '=>', ener)
        // penalty for length of leader line
        if (dist > 0) ener += dist * w_len;

        if (lab[index]?.id == debug_id) console.log('2. leader line penalty ', lab[index]?.id, dist * w_len, '=>', ener)
        
        //earr.push(ener)


        // if (lab[index].id == 79781) 
        //     console.log(`${before} dist diff: `, getLastEnerDiff());
        // label orientation bias
        dx /= dist;
        dy /= dist;

        
       
        //earr.push(ener);
      
        let orient_index = 0;
        if (dx > 0 && dy > 0) {
            orient_index = 0;
        } else if (dx < 0 && dy > 0) {
            orient_index = 1;
        } else if (dx < 0 && dy < 0) {
            orient_index = 2;
        } else {
            orient_index = 3;
        }
        ener += orient_index * w_orient;
        
        if (lab[index]?.id == debug_id) console.log('3. orientation penalty', lab[index]?.id, orient_index * w_orient, '=> ', ener, `orient_index = ${orient_index}`);
        // earr.push(ener)
        // if (lab[index].id == 80165) 
        // //     console.log(`${before} orientation diff: `, getLastEnerDiff());
        var x21 = lab[index].x,// - (lab[index].type === 'cladeLabel') ? 3 : 0,
            y21 = lab[index].y - lab[index].height,// + 2.0,
            x22 = lab[index].x + lab[index].width,
            y22 = lab[index].y; //+ 2.0;
        
        // var x21 = lab[index].x,
        //     y21 = lab[index].y + 8.0,
        //     x22 = lab[index].x + lab[index].width,
        //     y22 = lab[index].y + lab[index].height + 8.0;
        var x11, x12, y11, y12, x_overlap, y_overlap, overlap_area, excess_width, excess_height, excess_x, excess_y, excess_branch;

        for (var i = 0; i < m; i++) {
            if (i != index) {
                // penalty for intersection of leader lines
                overlap = intersect(
                    anc[index].x,
                    lab[index].x,
                    anc[i].x,
                    lab[i].x,
                    anc[index].y,
                    lab[index].y,
                    anc[i].y,
                    lab[i].y,
                );
                if (overlap) ener += w_inter;
                // penalty for label-label overlap
                x11 = lab[i].x - 3;
                y11 = lab[i].y - lab[i].height;// + 2.0;
                x12 = lab[i].x + lab[i].width;
                y12 = lab[i].y; // + 2.0;
                // x11 = lab[i].x;
                // y11 = lab[i].y + 8.0;
                // x12 = lab[i].x + lab[i].width;
                // y12 = lab[i].y + lab[i].height + 8.0;
                x_overlap = Math.max(0, Math.min(x12, x22) - Math.max(x11, x21));
                y_overlap = Math.max(0, Math.min(y12, y22) - Math.max(y11, y21));

                overlap_area = x_overlap * y_overlap;

                
                // if (lab[index].id == 79781/*&& lab[i].id == 126534*/ && (overlap_area > 0 || overlap )) 
                //     console.log(lab[index].id, `${index} / ${i} 
                //     overlap_area = ${overlap_area},
                //     leader_lines = ${overlap},
                //     (${x21}, ${y21}) / (${x11}, ${y11})`);
                if (overlap_area) {
                    ener += overlap_area * w_lab2;
                    if (lab[index]?.id == debug_id) console.log('4. label-label overlap penalty ', `${lab[index]?.id} / ${lab[i]?.id}` , overlap_area * w_lab2, '=>', ener, `overlap_area = ${overlap_area}`)
                }
                //if (lab[index]?.id == 80165) console.log('4.', lab[index]?.id, ener, i, lab[i].id)
            }
 
            // penalty for label-anchor overlap
            if (anc[i].symbolWidth && anc[i].symbolHeight) {
                x11 = anc[i].x - anc[i].symbolWidth/2;
                y11 = anc[i].y - anc[i].symbolHeight /2;// + 2.0;
                x12 = anc[i].x + anc[i].symbolWidth/2;
                y12 = anc[i].y + anc[i].symbolHeight /2;// + 2.0;          
            }
            else {
                x11 = anc[i].x - anc[i].r;
                y11 = anc[i].y - anc[i].r;
                x12 = anc[i].x + anc[i].r;
                y12 = anc[i].y + anc[i].r;
            }
            x_overlap = Math.max(0, Math.min(x12, x22) - Math.max(x11, x21));
            y_overlap = Math.max(0, Math.min(y12, y22) - Math.max(y11, y21));
            
            overlap_area = x_overlap * y_overlap;
            // if (lab[index]?.id == 79781 && lab[i]?.id == 79781 && overlap_area) console.log(lab[index].id, `/${anc[i].id} / anchor overlap_area = ${overlap_area}:
            // anc: ${anc[i].x}, ${anc[i].y}, ${anc[i].r}
            // p: ${lab[index].x}, ${lab[index].y},  ${lab[index].width},  ${lab[index].height}`);

            ener += overlap_area * w_lab_anc;

            if (lab[index]?.id == debug_id && overlap_area) console.log('5. label-anchor overlap penalty ', `${lab[index]?.id} / ${lab[i]?.id}` , overlap_area * w_lab_anc, '=>', ener, `overlap_area = ${overlap_area}`)

            //if (lab[index]?.id == 80165) console.log('6.', lab[index]?.id, ener, overlap_area, w_lab_anc)

            //earr.push(ener)
            //if (lab[index].id == 79781) 
            //console.log(`${before} label anchor overlap diff: `, getLastEnerDiff());
        }
        // Penalty for sticking out
        excess_width = Math.max(0, lab[index].x + lab[index].width - w);
        excess_height = Math.abs(Math.min(0, lab[index].y))
        excess_x = Math.abs(Math.min(0, lab[index].x))
        excess_y = Math.abs(Math.min(0, lab[index].y - lab[index].height))
       
       // if (lab[index]?.id == 80165) console.log('7.', lab[index]?.id, ener)
        //excess_hight = Math.max(0, lab[index].y)

        //if (lab[index].id == 79781/*&& lab[i].id == 126534*/ && (excess_width + excess_height) * w_excess != 0)  
       // if (excess_width || excess_height)
         //   console.log(before, 'sticking out', lab[index].id, `${index} / excess = ${(excess_width + excess_height) * w_excess}`);

        ener += (excess_width + excess_height + excess_x + excess_y) * w_excess;
        if (lab[index]?.id == debug_id) console.log('6. sticking out penalty ', `${lab[index]?.id}` , (excess_width + excess_height + excess_x + excess_y) * w_excess, '=>', ener)


       // if (lab[index].id == 80165) console.log(`3. excess_penalty: ${lab[index].id} x = ${lab[index].x} excess_width = ${excess_width} excess_height = ${excess_height}
      //  excess_x = ${excess_x} excess_y = ${excess_y}
      //  ener = ${(excess_width + excess_height + excess_x + excess_y) * w_excess}`);
       
        const excess_minY = lab[index]?.minY ? Math.abs(Math.min(0, (lab[index].y - lab[index].height) - (lab[index].minY - 4))) : 0;
        const excess_maxY = lab[index]?.maxY ? Math.abs(Math.min(0, (lab[index].maxY + 4) - lab[index].y)) : 0;
       
        excess_branch = excess_maxY + excess_minY;
        
        ener += excess_branch * w_branch_excess;
        // Penalty for sticking out of branch area


       
       
        //if (lab[index]?.id == 80165) console.log('8.', lab[index]?.id, ener, excess_maxY, excess_minY)
        //if (!lab[index]?.id) console.log('ERRROR', index, lab[index]);
        //console.log(`${before} sticking out diff: `, getLastEnerDiff());
        //console.log(index, lab[index]);
        return ener;
    };

    const mcmove = function (currT) {
        // Monte Carlo translation move

        // select a random label
        var i = Math.floor(Math.random() * lab.length);

        // save old coordinates
        var x_old = lab[i].x;
        var y_old = lab[i].y;

        // if (lab[i].id == 119181) console.log(`--------
        // before move:`)
        // old energy
        var old_energy;
        if (user_energy) {
            old_energy = user_defined_energy(i, lab, anc);
        } else {
            old_energy = energy(i, true);
        }

        // random translation
        const x_move = (Math.random() - 0.5) * max_move;
        const y_move = (Math.random() - 0.5) * max_move;
        lab[i].x += x_move; //(Math.random() - 0.5) * max_move;
        lab[i].y += y_move; //(Math.random() - 0.5) * max_move;

        // if (lab[i].id == 119181) console.log(`move: x_move = ${x_move}, y_move = ${y_move}`)
        
        // hard wall boundaries
        //if (lab[i].x +lab[i].width > w)
        //if (i === 3) console.log('>>> W', i, lab[i].x + lab[i].width, lab[i]._x, '=>', lab[i].x, w, lab[i].x > w);
        //const excess = lab[i].x + lab[i].width - w;

       

        if (lab[i].x > w) lab[i].x = x_old;
        
        if (lab[i].x < 0 && lab[i].x < x_old) lab[i].x = x_old;
        if (lab[i].y > h) lab[i].y = y_old;
        if (lab[i].y < 0 && lab[i].y < y_old) lab[i].y = y_old;

        // new energy
        var new_energy;
        if (user_energy) {
            new_energy = user_defined_energy(i, lab, anc);
        } else {
            new_energy = energy(i);
        }

        // delta E
        var delta_energy = new_energy - old_energy;
        // if (lab[i].id == 79781) 
        //     console.log(`step: new_energy = ${new_energy}, old_energy = ${old_energy}, delta_energy = ${delta_energy}, exp=${Math.exp(-delta_energy / currT)},
        //     move: x_move = ${x_move}, y_move = ${y_move}, w = ${w}`);
    
        if (Math.random() < Math.exp(-delta_energy / currT)) {

            //const excess_width = Math.max(0, lab[i].x + lab[i].width - w);
            //const excess_height = Math.abs(Math.min(0, lab[i].y - lab[i].height))
            //excess_hight = Math.max(0, lab[index].y)
    
            //if (lab[index].id == 79781/*&& lab[i].id == 126534*/ && (excess_width + excess_height) * w_excess != 0)  
            //if (excess_width || excess_height)
              //  console.log('sticking out', lab[i].id, `${i} / excess = ${excess_width}, ${excess_height}`);
            // if (lab[i].id == 79781) {
            //     console.log('=> move on')
            //     console.log(`step: new_energy = ${new_energy}, old_energy = ${old_energy}, delta_energy = ${delta_energy}, exp=${Math.exp(-delta_energy / currT)},
            //     move: x_move = ${x_move}, y_move = ${y_move}, w = ${w}`);
        
            // }
            acc += 1;
        } else {
            // move back to old coordinates
            //if (lab[i].id == 79781) console.log('=> move back')
            lab[i].x = x_old;
            lab[i].y = y_old;
            rej += 1;
            //if (i === 3) console.log('moving back');
        }
        // if (lab[i].y - lab[i].height < 0) console.log(lab[i], lab[i].y, lab[i].height, lab[i].y - lab[i].height)
        //if (i === 3) console.log('x = ', lab[i].x, 'excess = ', excess);
    };

    const mcrotate = function (currT) {
        // Monte Carlo rotation move

        // select a random label
        var i = Math.floor(Math.random() * lab.length);

        // save old coordinates
        var x_old = lab[i].x;
        var y_old = lab[i].y;

        // old energy
        var old_energy;
        if (user_energy) {
            old_energy = user_defined_energy(i, lab, anc);
        } else {
            old_energy = energy(i);
        }

        // random angle
        var angle = (Math.random() - 0.5) * max_angle;

        var s = Math.sin(angle);
        var c = Math.cos(angle);

        // translate label (relative to anchor at origin):
        lab[i].x -= anc[i].x;
        lab[i].y -= anc[i].y;

        // rotate label
        var x_new = lab[i].x * c - lab[i].y * s,
            y_new = lab[i].x * s + lab[i].y * c;

        // translate label back
        lab[i].x = x_new + anc[i].x;
        lab[i].y = y_new + anc[i].y;

        // hard wall boundaries
        if (lab[i].x > w) lab[i].x = x_old;
        if (lab[i].x < 0) lab[i].x = x_old;
        if (lab[i].y > h) lab[i].y = y_old;
        if (lab[i].y < 0) lab[i].y = y_old;

        // new energy
        var new_energy;
        if (user_energy) {
            new_energy = user_defined_energy(i, lab, anc);
        } else {
            new_energy = energy(i);
        }

        // delta E
        var delta_energy = new_energy - old_energy;

        if (Math.random() < Math.exp(-delta_energy / currT)) {
            acc += 1;
        } else {
            // move back to old coordinates
            lab[i].x = x_old;
            lab[i].y = y_old;
            rej += 1;
        }
    };

    const intersect = function (x1, x2, x3, x4, y1, y2, y3, y4) {
        // returns true if two lines intersect, else false
        // from http://paulbourke.net/geometry/lineline2d/

        var mua, mub;
        var denom, numera, numerb;

        denom = (y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1);
        numera = (x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3);
        numerb = (x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3);

        /* Is the intersection along the the segments */
        mua = numera / denom;
        mub = numerb / denom;
        if (!(mua < 0 || mua > 1 || mub < 0 || mub > 1)) {
            return true;
        }
        return false;
    };

    const cooling_schedule = function (currT, initialT, nsweeps) {
        // linear cooling
        return currT - initialT / nsweeps;
    };

    labeler.start = function (nsweeps) {
        // main simulated annealing function
        //console.log('START', nsweeps);
        var m = lab.length,
            currT = 1.0,
            initialT = 1.0;

        for (var i = 0; i < nsweeps; i++) {
           // console.log(i, '/', nsweeps);
            for (var j = 0; j < m; j++) {
               if (Math.random() < 0.5) {
                    mcmove(currT);
                } else {
                    mcrotate(currT);
                }
            }
            //console.log(i,'labeler', lab);
            currT = cooling_schedule(currT, initialT, nsweeps);
        }
    };

    labeler.width = function (x) {
        // users insert graph width

        if (!arguments.length) return w;
        w = x;
        return labeler;
    };

    labeler.height = function (x) {
        // users insert graph height

        if (!arguments.length) return h;
        h = x;
        return labeler;
    };

    labeler.label = function (x) {
        // users insert label positions
        if (!arguments.length) return lab;
        lab = x;
        return labeler;
    };

    labeler.anchor = function (x) {
        // users insert anchor positions
        if (!arguments.length) return anc;
        anc = x;
        return labeler;
    };

    labeler.alt_energy = function (x) {
        // user defined energy
        if (!arguments.length) return energy;
        user_defined_energy = x;
        user_energy = true;
        return labeler;
    };

    labeler.alt_schedule = function (x) {
        // user defined cooling_schedule
        if (!arguments.length) return cooling_schedule;
        user_defined_schedule = x;
        user_schedule = true;
        return labeler;
    };

    return labeler;
}
