import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

export const styles = makeStyles(_ => ({
    root: {
        backgroundColor: '#e2e2e2',
        height: 'calc(100vh - 184px)',
        maxHeight: 'calc(100vh - 184px)',
        overflow: 'overlay'
    },
    saveButton: {
        margin: '15px',
        backgroundColor: '#6F6CFF',
        color: 'white',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#6F6CFF',
            color: 'white',
        },
    },
    cancelButton: {
        backgroundColor: '#e2e2e2',
        color: 'black',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#e2e2e2',
            color: 'black',
        },
    },
    content: {
        margin: '0px 15px 15px 15px',
        backgroundColor: 'white',
        height: 'calc(100% - 100px)',
    },
    container: {
        height: '100%',
        maxHeight: '100%'
    },
    item: {
        position: 'relative',
        height: '100%',
        overflowY: 'overlay',
        maxHeight: '80vh',
    },
    search: {
        width: 350,
        marginLeft: '15px'
    },
    confirm: {
        backgroundColor: '#6F6CFF',
        color: 'white',
        marginBottom: '16px',
        textTransform: 'none',
        marginRight: '16px',
        '&:hover': {
            backgroundColor: '#6F6CFF',
            color: 'white',
        },
    },
}));

export const StyledTableCell = styled(TableCell)(() => ({
[`&.${tableCellClasses.head}`]: {
  backgroundColor: 'grey',
  color: 'white',
  cursor: 'pointer',
  '&:hover': {
    color: '#fff',
  },
},
}));

export const dialog = {
    '& .MuiDialog-container': {
      '& .MuiPaper-root': {
        width: '100%',
        maxWidth: '300px',
        borderRadius: '12px'
      },
    },
};