import * as React from "react";
import { withStyles } from "@mui/styles";
import { Button, Typography, CardActions  } from '@mui/material';
import { signInStyles } from "../styles/signInStyles";
import { prepareUrl} from '../../../functions/functions';
import { StyledTextField } from '../../../assets/GlobalStyles/TextField';
import { fetchAxios } from "../../../functions/axiosRequests";
import config from "../../../config/envConfig";

const ResetPasswordBase = (props) => {
    const {
        email,
        err,
        handleChange,
        changeView,
        classes
    } = props;
    const [paswordResetSent, setPaswordResetSent] = React.useState('');

    const resetPassword = (email) => {
        const url = prepareUrl(`${config.serverLink}/api/auth/resetPassword?email=${email}`);

        fetchAxios(url).then(function (response) {
            if (response.data.userExist)
                    setPaswordResetSent(`Email sent to: ${email}`);
            else
                setPaswordResetSent(`User doesn't exist`);
        }).catch(function (error) {
            console.log(error)
        })
    }

    console.log(email)
    return (
        <>
            <StyledTextField
                id="email"
                type="email"
                label="Email"
                className={classes.textField}
                value={email}
                onChange={(e) => handleChange(e, 'email')}
            />
            {err && (
                <Typography component="p" color="error" className={classes.error}>
                    {JSON.stringify(err)}
                </Typography>
            )}
            <CardActions className={classes.cardActions}>
                <Button onClick={() => resetPassword(email)} className={classes.submit}>
                    Reset Passsword
                </Button>
                <div className={classes.link} onClick={() => changeView('login')}>Back</div>

            </CardActions>

            {paswordResetSent && (
                <Typography component="p" color="secondary">{paswordResetSent}</Typography>
            )}

        </>
    )
}

export const ResetPasswordMongo = withStyles(signInStyles)(ResetPasswordBase);
