export const radioButtonsStyles = theme => ({
    rootRadio: {
        paddingTop: '12px',
        paddingBottom: '2px',
        paddingLeft: '2%'
    },
    formControl: {
        minWidth: 120,
        margin: '8px 0px'
    },
    option: {
        marginBottom: '2px',
        padding: '0',
    },
    optionRight: {
        '@media (min-width:1101px)': {
            marginLeft: '0px',
        }
    },
    checkbox: {
        padding: '0 4px',
        display:'table-cell',
        verticalAlign: 'top'
    },
    formControlLabel: {
        fontSize: '13px',
        marginTop: '5px',
        marginBottom: '5px',
        lineHeight: 1
    },
    formControl: {
        marginRight: 0,
        display: 'table',
        verticalAlign: 'top',
    }
});
