import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import EditMeasureDialog from '../Dialogs/EditMeasureDialog';
import { postAxios } from '../../../../functions/axiosRequests';
import { styles } from './styles';
import config from '../../../../config/envConfig';

const EditMeasure = (props) => {
    const [ openDialog, setOpenDialog ] = useState(false);
    const { scales, measure, lineages, setInfoDialog, setInfo, setAction, fetchMeasures } = props;
    const classes = styles();

    const handleClick = async () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };


    const updateMeasure = (body) => {
        const url = `${config.serverLink}/api/admin/updateMeasure`;

        postAxios(url, body)
            .then((response) => {
                if (response.data.status === 'done'){
                    handleCloseDialog();
                    setInfoDialog(true);
                    setAction('Update measure');
                    fetchMeasures();
                    setInfo(`Measure ${measure.measure} was updated sucessfully`);
                } else {
                    handleCloseDialog();
                    setInfoDialog(true);
                    setAction('Update measure');
                    fetchMeasures();
                    setInfo(`Measure ${measure.measure} wasn't updated`);
                }
        });
    };

    // console.log(measure.measure, openDialog, scales);
    return (
        <>
            <IconButton onClick={handleClick} className={classes.button}>
                <EditIcon />
            </IconButton>
            { scales.length > 0 && openDialog &&
                <EditMeasureDialog openDialog={openDialog} handleCloseDialog={handleCloseDialog} updateMeasure={updateMeasure} scales={scales} measure={measure} lineages={lineages}/>
            }
        </>
    );
};

export default EditMeasure;
