import {
    FETCH_ANTIGENIC_MODEL_REQUEST,
    FETCH_ANTIGENIC_MODEL_SUCCESS,
    FETCH_ANTIGENIC_MODEL_ERROR,
    FETCH_ANTIGENIC_RAW_MODEL_REQUEST,
    FETCH_ANTIGENIC_RAW_MODEL_SUCCESS,
    FETCH_ANTIGENIC_RAW_MODEL_ERROR,
    FETCH_ANTIGENIC_OBSERVED_DATA_REQUEST,
    FETCH_ANTIGENIC_OBSERVED_DATA_SUCCESS,
    FETCH_ANTIGENIC_OBSERVED_DATA_ERROR,
    SELECT_ANTIGENIC_VALUE,
    SELECT_ANTIGENIC_VALUES,
    SELECT_ANTIGENIC_DETAILS_VALUES,
    RESET_ANTIGENIC_MODEL,
    RESET_ANTIGENIC_CLADES,
    TOGGLE_ANTIGENIC_DETAILS,
    RESET_SESSION,
    SIGNOUT_REQUEST,
    FETCH_CLADES_SUCCESS,
} from '../actions/actionTypes';
import { emptyObject } from '../../functions/functions';

let antigenicDataInitalState = { };
export const setAntigenicDataInitalState = (state) => {
    antigenicDataInitalState = state;
};

const antigenicModelData = (state = antigenicDataInitalState, action) => {
    switch (action.type) {
        case RESET_SESSION:
        case SIGNOUT_REQUEST: {
            return antigenicDataInitalState;
        }

        // case FETCH_ANTIGENIC_CLADES_REQUEST: {
        //     return {
        //         ...state,
        //         // antigenicClades: {},
        //         antigenicCladesStatus: 'loading',
        //     };
        // }
        // case FETCH_ANTIGENIC_CLADES_SUCCESS:
        case FETCH_CLADES_SUCCESS: {
            const { antigenicClades } = action.payload;
            return {
                ...state,
                antigenicClades,
                antigenicCladesStatus: 'loaded',
            };
        }
        // case FETCH_ANTIGENIC_CLADES_ERROR: {
        //     return {
        //         ...state,
        //         antigenicCladesStatus: 'error',
        //     };
        // }
        case FETCH_ANTIGENIC_MODEL_REQUEST: {
            // console.log(`FETCH_ANTIGENIC_MODEL_REQUEST`);
            return {
                ...state,
                // antigenicModel: {},
                antigenicModelStatus: 'loading',
            };
        }
        case FETCH_ANTIGENIC_MODEL_SUCCESS: {
            const { antigenicModel } = action.payload;
            //console.log('epic reducer success', action)
            return {
                ...state,
                antigenicModel,
                antigenicModelStatus: antigenicModel === null ? 'nodata' : 'loaded',
            };
        }
        case FETCH_ANTIGENIC_MODEL_ERROR: {
            // console.log(`FETCH_MODEL_DATA_ERROR: action.payload = ${JSON.stringify(action.payload)}`);
            return {
                ...state,
                antigenicModelStatus: 'error',
            };
        }

        case FETCH_ANTIGENIC_OBSERVED_DATA_REQUEST: {
            // console.log(`FETCH_ANTIGENIC_MODEL_REQUEST`);
            return {
                ...state,
                // antigenicModel: {},
                antigenicObservedDataStatus: 'loading',
            };
        }
        case FETCH_ANTIGENIC_OBSERVED_DATA_SUCCESS: {
            const { antigenicObservedData, antigenicObservedRefStrains } = action.payload;
            //console.log('epic reducer success', action)
            return {
                ...state,
                antigenicObservedData,
                antigenicObservedDataStatus: antigenicObservedData === null ? 'nodata' : 'loaded',
                antigenicObservedRefStrains
            };
        }
        case FETCH_ANTIGENIC_OBSERVED_DATA_ERROR: {
            // console.log(`FETCH_MODEL_DATA_ERROR: action.payload = ${JSON.stringify(action.payload)}`);
            return {
                ...state,
                antigenicObservedDataStatus: 'error',
            };
        }

        case FETCH_ANTIGENIC_RAW_MODEL_REQUEST: {
            //console.log('antigenic reducer fetch raw model')
            return {
                ...state,
                rawAntigenicCladeStrains: {},
                //rawStrainNames: {},
                rawStrains: {},
                rawAntigenicModel: {},
                antigenicRawModelStatus: 'loading'
            };
        }
        case FETCH_ANTIGENIC_RAW_MODEL_SUCCESS: {
            const { rawAntigenicCladeStrains, rawAntigenicModel, /*rawStrainNames,*/ rawStrains } = action.payload;
            return {
                ...state,
                rawAntigenicModel,
                //rawStrainNames,
                rawStrains,
                rawAntigenicCladeStrains,
                antigenicRawModelStatus: rawAntigenicModel === null ? 'nodata' : 'loaded',
            };
        }
        case FETCH_ANTIGENIC_RAW_MODEL_ERROR: {
            return {
                ...state,
                antigenicRawModelStatus: 'error',
            };
        }

        case RESET_ANTIGENIC_MODEL: {
            const nodata = action.payload?.nodata;
            return {
                ...state,
                antigenicModel: {},
                rawAntigenicCladeStrains: {},
                rawAntigenicModel: {},
                //rawStrainNames: {},
                rawStrains: {},
                antigenicModelStatus: nodata ? 'nodata' : 'none',
                antigenicRawModelStatus: nodata ? 'nodata': 'none',
                antigenicObservedDataStatus: nodata ? 'nodata': 'none'
            };
        }
        case RESET_ANTIGENIC_CLADES: {
            return {
                ...state,
                antigenicClades: {},
                antigenicCladesStatus: 'none',
            };
        }
        case TOGGLE_ANTIGENIC_DETAILS: {
            return {
                ...state,
                detailsOpen: action.payload.isOpen,
            };
        }

        case SELECT_ANTIGENIC_VALUE: {
            const { titerValue, cladeIndex, refCladeIndex } = action.payload;
            const { antigenicData } = state;
            const clades = antigenicData ? antigenicData.clades : [];
            const clade = cladeIndex < clades.length ? clades[cladeIndex] : null;
            const refClades = antigenicData ? antigenicData.refClades : [];
            const refClade = refCladeIndex < refClades.length ? refClades[refCladeIndex].clade : null;
            const refStrains = refCladeIndex < refClades.length ? refClades[refCladeIndex].strains : null;

            return {
                ...state,
                titerValue,
                clade,
                refClade,
                refStrains,
            };
        }

        case SELECT_ANTIGENIC_VALUES: {
            const { alpha, rho, titer } = action.payload;

            // console.log(`SELECT_ANTIGENIC_VALUES: alpha = ${alpha}, rho = ${rho}`);
            // const clades = antigenicData ? antigenicData.clades : [];
            // const clade = (cladeIndex < clades.length) ? clades[cladeIndex] : null;
            // const refClades = antigenicData ? antigenicData.refClades : [];
            // const refClade = (refCladeIndex < refClades.length) ? refClades[refCladeIndex].clade : null;
            // const refStrains = (refCladeIndex < refClades.length) ? refClades[refCladeIndex].strains : null;

            return {
                ...state,
                titer,
                alphaY:
                    emptyObject(state.antigenicClades) && emptyObject(state.antigenicClades.alphas)
                        ? null
                        : state.antigenicClades.alphas[alpha],
                rhoR:
                    emptyObject(state.antigenicClades) && emptyObject(state.antigenicClades.rhos)
                        ? null
                        : state.antigenicClades.rhos[rho],
                alpha,
                rho,
                // titerValue,
                // clade,
                // refClade,
                // refStrains
            };
        }

        case SELECT_ANTIGENIC_DETAILS_VALUES: {
            const { strain, refStrain, titer } = action.payload;

            return {
                ...state,
                strain,
                refStrain,
                titerDetails: titer,
            };
        }
        // case SET_ANTIGENIC_HIDDEN_CLADE: {
        //     const { index } = action.payload;
        //     const _hiddenClades = { ...state.hiddenClades };
        //     _hiddenClades[index] = true;
        //     return {
        //         ...state,
        //         hiddenClades: _hiddenClades
        //     };
        // }
        // case SET_ANTIGENIC_HIDDEN_REF_CLADE: {
        //     const { index } = action.payload;
        //     const _hiddenRefClades = { ...state.hiddenRefClades };
        //     _hiddenRefClades[index] = true;
        //     return {
        //         ...state,
        //         hiddenRefClades: _hiddenRefClades
        //     };
        // }

        default:
            return state || antigenicDataInitalState;
    }
};

export { antigenicModelData };
