import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import { selectZoomNodeError, selectZoomNodeSuccess } from '../actions/zoomActions';
import { ZOOM_TREE_REQUEST } from '../actions/actionTypes';
import { prepareUrl } from '../../functions/functions';
import config from '../../config/envConfig';
import { fetchAxios } from '../../functions/axiosRequests';
import { sessionTimeOut } from './helpers/functions';


export const selectZoomNodeEpic = (action$, store) => action$.pipe(
    ofType(ZOOM_TREE_REQUEST),
    mergeMap(action => {
        const { zoomNodeId, zoomType, colorBy, strainSubset, strainHighlight, lineage, modelRegionId, modelId  } = action.payload;
        // console.log(action.payload);
        const url = prepareUrl(`${config.serverLink}/api/zoomTree`, { zoomNodeId, zoomType, colorBy, strainSubset, strainHighlight, lineage, modelRegionId, modelId  /*, strainId: ''*/ });
        console.log(url);

        return fetchAxios(url).then(function (response) {
            return selectZoomNodeSuccess({ ...response.data, zoomType });
        }).catch((error) => {
            const { status, data } = error.response;
            if (status === 408) return sessionTimeOut(error, store);
            if (status === 406) return selectZoomNodeError({ error: data.error, errorWebsiteText: 'An error has occured during downloading zoomed node data.', errorWebsiteType: 'fetch'});

            return selectZoomNodeError({ error: data.error, errorWebsiteText: 'An error has occured during downloading zoomed node data.', errorWebsiteType: 'fetch'});

        });
    })
);
