import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@mui/styles';
//import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import { isEqual } from 'lodash';
import { setParameters } from '../../redux/actions/parametersActions';
import { styles } from '../Tree/styles';
import { dateFormat } from '../../functions/checkBrowser';
import { resetFrequencies } from '../../redux/actions/frequenciesActions';
import { trackingFromSelector, trackingToSelector } from '../../redux/selectors/parametersSelector';
import { subtractYears } from '../../functions/functions';

const TrackingFromPicker = (props) => {
    const { trackingFrom, trackingTo, intro } = props;
    const { resetFrequencies, setParameters } = props;
    // const trackingFromHolder = useRef(trackingFrom);
    const now = new Date();
    let trackingFromHolder = trackingFrom;
    // if (trackingFrom && !isNaN(trackingFrom.getTime())) trackingFromHolder.current = trackingFrom;

    const handleTrackingFromChange = (value) => {
        if (value && !isNaN(value.getTime())) trackingFromHolder = value;
    };

    const handleAccept = (value) => {
        if (isEqual(value, trackingFrom)) return;

        setParameters({ trackingFrom: value });
        resetFrequencies();
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && !isEqual(trackingFromHolder, trackingFrom)) {
            setParameters({ trackingFrom: trackingFromHolder });
            resetFrequencies();
        }
    };

    const minDate = intro ? subtractYears(trackingTo, 10) : undefined;
    // console.log(trackingFrom, trackingTo, subtractYears(trackingTo, 10));

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
                disableToolbar
                variant="inline"
                format={dateFormat}
                id="trackingFrom"
                label="Tracking from"
                value={trackingFrom || now}
                onChange={handleTrackingFromChange}
                onAccept={handleAccept}
                autoOk="true"
                allowSameDateSelection
                minDate={minDate}
                slotProps={{
                    textField: { 
                        variant: 'standard',
                        onKeyDown: handleKeyPress
                    },
                    inputAdornment: { position: 'start'}
                }}
                fullWidth
                />
        </LocalizationProvider>
    );
};

TrackingFromPicker.propTypes = {
    trackingFrom: PropTypes.instanceOf(Date),
    trackingTo: PropTypes.instanceOf(Date),
    setParameters: PropTypes.func,
    resetFrequencies: PropTypes.func,
};

const mapStateToProps = (state) => ({
    trackingFrom: trackingFromSelector(state),
    trackingTo: trackingToSelector(state),
    intro: state.parameters.intro
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setParameters,
            resetFrequencies,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TrackingFromPicker));
