import {
    FETCH_CLADES_REQUEST,
    FETCH_CLADES_SUCCESS,
    FETCH_CLADES_ERROR,
    FETCH_ACTIVE_CLADES_REQUEST,
    FETCH_ACTIVE_CLADES_SUCCESS,
    FETCH_ACTIVE_CLADES_ERROR,
    RESET_ACTIVE_CLADES,
    RESET_SESSION,
    SIGNOUT_REQUEST
} from '../actions/actionTypes';

let cladesInitialState = { };
export const setCladesInitialState = (state) => {
    cladesInitialState = state;
};

const cladeData = (state = cladesInitialState, action) => {
    switch (action.type) {
    case RESET_SESSION:
    case SIGNOUT_REQUEST: {
        //console.log('cladesInitialState', cladesInitialState)
        return { ...cladesInitialState };
    }
    // case INIT_STRAIN_TREE_REQUEST:
    case FETCH_CLADES_REQUEST: {
        return {
            ...state, // cladesInitialState,
            // //clades: {},
            cladesStatus: 'loading',
            clades: cladesInitialState.clades
        };
    }
    case FETCH_CLADES_SUCCESS:
    // case INIT_STRAIN_TREE_SUCCESS:
    {
        const { clades } = action.payload;
        return {
            ...state,
            clades,
            cladesStatus: 'loaded'
        };
    }

    // case INIT_STRAIN_TREE_ERROR:
    case FETCH_CLADES_ERROR: {
        return {
            ...state,
            cladesStatus: 'error'
        };
    }
    case FETCH_ACTIVE_CLADES_REQUEST: {
        return {
            ...state,
            // activeClades: {},
            activeCladesStatus: 'loading'
        }
    }

    case FETCH_ACTIVE_CLADES_SUCCESS: {
        const { activeClades } = action.payload;
        return {
            ...state,
            activeClades,
            activeCladesStatus: 'loaded'
        }
    }
    case FETCH_ACTIVE_CLADES_ERROR: {
        return {
            ...state,
            activeCladesStatus: 'error'
        }
    }
    case RESET_ACTIVE_CLADES: {
        return {
            ...state,
            activeCladesStatus: 'none'
        }
    }
    default:
        return state || cladesInitialState;
    }
};


export default cladeData;
