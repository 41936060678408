import React, { useEffect, useMemo } from 'react';
import { makeStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { getDisplayedSequencesNumber } from '../../redux/selectors/treeDataSelector';
import pcg from '../../../package.json';
import { Typography, Link } from '@mui/material';
import { useLocation } from 'react-router';
import { daysToDate } from '../../functions/functions';

const { version } = pcg;

const useStyles = makeStyles({
    metaInfos: {
        marginTop: '15px',
        display: 'inline-block',

    },
    title: {
        fontFamily: 'Source Sans Pro Bold'
    },
    orgLink: {
        textDecoration: 'underline',
        color: 'black'
    },
    divider: {
        borderTop: '1px solid #ADADAD',
        borderBottom: 'none',
        borderLeft: 'none',
        borderRight: 'none',
        boxShadow: 'none',
        height: 0
    },
    text: {
        maxWidth: '60%'
    }
});


const pathsMapping = {
    '/intro': 'tree',
    '/': 'tree',
    '/intro-freq': 'freq',
    '/frequencies': 'freq',
    '/intro-map': 'map',
    '/geomap': 'map',
    '/antigenic': 'antigenic'
};


const MetaInformations = (props) => {
    const { processingMetadata, displayedSequencesNumber, comments, date } = props;
    const classes = useStyles();

    const location = useLocation();
    const { pathname } = location;
    const comments_tab = useMemo(() => { return props[`comments_${pathsMapping[pathname]}`] }, [pathname]);

    const OrgLink = ({ href, label }) => (<Link
        href={href}
        className={classes.orgLink}
        target="_blank"
        rel="noopener noreferrer" >{label}
    </Link>);


    return (
        <>
            <div className={classes.metaInfos} >
                {date && <p className={classes.text}>
                    <span className={classes.title}>Last updated:</span> {date.toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric'
                    })}<br />
                </p>
                }
                <div className={classes.comments}>
                    {(comments || comments_tab) && <>
                        <p className={classes.text}>
                            <span className={classes.title}>Comments</span><br />
                            <span dangerouslySetInnerHTML={{ __html: comments }} />
                            {comments_tab}
                        </p>
                    </>
                    }
                </div>
                <div className={classes.divider} />
                <p className={classes.text}>
                    <span className={classes.title}>Analysis by</span><br />
                    <OrgLink href="https://previr.com/center" label='M. Lässig, M. Łuksza, M. Meijers, D. Ruchnewitz' /><br />
                    <OrgLink href="https://previr.com/" label="Previr Center for Predictive Analysis of Viral Evolution" />
                </p>
                <p className={classes.text}>
                    Citation: <OrgLink href='https://www.cell.com/cell/pdf/S0092-8674(23)01076-0.pdf'
                        label='Meijers M, Ruchnewitz D, Eberhardt J, Łuksza M, Lässig M. Population immunity predicts evolutionary trajectories of SARS-CoV-2. Cell (2023)' />.
                </p>
                <div className={classes.divider} />
                <p className={classes.text}>
                    <span id="data_sources" className={classes.title}>Data sources and acknowledgments</span><br />
                    We gratefully acknowledge input data from the following worldwide networks of data collection, public health surveillance and sharing:{' '}
                    <OrgLink href="https://www.gisaid.org/" label='GISAID' />{', '}
                    <OrgLink href="https://www.ncbi.nlm.nih.gov/genbank/" label='GenBank' />{', '}
                    <OrgLink href="https://covid19.who.int/" label='WHO Corona Dashboard' />{', '}
                    <OrgLink href="https://ourworldindata.org/" label='Our World in Data' />{', '}
                    <OrgLink href="https://www.who.int/initiatives/global-influenza-surveillance-and-response-system#" label='Global Influenza Surveillance and Response System (GISRS)' />.
                    <br />
                    <br />
                    Particular thanks to Rebecca Kondor and John Steel 
                    (<OrgLink href="https://www.cdc.gov/" label="Centers for Disease Control and Prevention"/>), 
                    Nicola Lewis and Ruth Harvey
                    (<OrgLink href="https://www.crick.ac.uk/research/platforms-and-facilities/worldwide-influenza-centre" label='Worldwide Influenza Centre London' />),
                    Kanta Subbarao and Ian Barr
                    (<OrgLink href="http://www.vidrl.org.au/" label='Victorian Infectious Diseases Reference Laboratory at the Australian Peter Doherty Institute for Infection and Immunity' />),
                    Hideki Hasegawa and Shinji Watanabe
                    (<OrgLink href="https://www.niid.go.jp/niid/en/flu-e.html" label="Influenza Virus Research Center at the Japan National Institute of Infectious Diseases" />).
                    The analysis of SARS-CoV-2 includes data from a large number of <OrgLink href="https://drive.google.com/file/d/11pLtAooNEkuWymtte3OX9NCN8VXAxolO/view" label="primary publications" />.
                </p>
                <p className={classes.text}>
                    Development and UI by Hector Labs <OrgLink href='https://hectorlabs.io/' label='Hector Labs' />.
                </p>
                <div className={classes.divider} />
                <p style={{ display: 'flex', justifyContent: 'space-between' }}>
                    © M. Lässig, M. Łuksza, M. Meijers, D. Ruchnewitz
                    <a href="/notice" className={classes.orgLink}>Legal notice</a>
                </p>
            </div>

        </>

    );
}

const mapStateToProps = (state) => ({
    displayedSequencesNumber: getDisplayedSequencesNumber(state),
    processingMetadata: state.metadata.processingMetadata,
    comments: state.metadata.comments,
    comments_tree: state.metadata.comments_tree,
    comments_freq: state.metadata.comments_freq,
    comments_antigenic: state.metadata.comments_antigenic,
    comments_map: state.metadata.comments_map,
    date: state.parameters.date
});

export default connect(mapStateToProps)(MetaInformations);
