import { makeStyles } from '@mui/styles';

export const styles = makeStyles(() =>({
    textField: {
        width: '100%',
        display: 'block',
        backgroundColor: 'white !important',
        '& > div': {
            width: '100%',
        },
        "& input:-internal-autofill-selected": {
            WebkitBoxShadow: '0 0 0 1000px white inset',
            fontFamily: 'Source Sans Pro, Verdana',
            fontSize: '16px'
        },
        marginBottom: '18px'
    },
    confirm: {
        backgroundColor: '#6F6CFF',
        color: 'white',
        marginBottom: '16px',
        textTransform: 'none',
        marginRight: '16px',
        '&:hover': {
            backgroundColor: '#6F6CFF',
            color: 'white',
        },
    },
    cancel: {
        marginRight: '6px',
        marginBottom: '16px',
        backgroundColor: '#e0e0e0',
        color: '#000000',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#e0e0e0',
        },
    },
    formControlAdd: {
        marginRight: 0,
        display: 'table',
        verticalAlign: 'top'
    },
    newRoleGrid: {
        paddingLeft: '5px',
        marginTop: '8px',
        marginBottom: '5px',
    },
    checkbox: {
        padding: '0 4px',
        display:'table-cell',
        verticalAlign: 'top'
    },
    formControlLabel: {
        fontSize: '13px',
        marginTop: '2px'
    },
    tooltip: {
        margin: 0,
        padding: 0
    },
    info: {
        marginTop: '15px',
    },
    colorsGridLinear: {
        padding: '5px'
    },
    colorsGrid: {
        marginLeft: '5px',
        paddingTop: '25px'
    },
    domain: {
        overflowY: 'hidden',
        overflowX: 'hidden'
    },
    listItems: {
        paddingLeft: '0'
    },
    draggingListItem: {
        background: 'rgb(235,235,235)',
        paddingLeft: '0'
    },

    removeLinear: {
        marginLeft: '3px',
        marginTop: '0px'
    },
    removeDiscrete: {
        marginLeft: '8px',
        marginTop: '0px'
    },
    empty: {
        marginLeft: '15px'
    },
    addMargin:{
        marginLeft: '12px'
    },
    domainError: {
        color: 'red'
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    listContainer: {
        display: 'flex',
        fontSize: '18px',
        backgroundColor: '#eee',
        flexDirection: 'column',
    },
    itemContainer: {
        backgroundColor: '#fff',
        border: '1px solid black',
        padding: '25px 70px',
        margin: '15px 50px',
    },
    measurePicker: {
        marginTop: '16px',
        width: 'calc(100% - 20px)',
        padding: '16px 10px, 0px, 10px'
    },
    formControlLineage: {
        marginBottom: '15px'
    },
    helpText: {
        maxWidth: '360px'
    }
}));

export const NewScaleDialog = {
    '& .MuiDialog-container': {
        '& .MuiPaper-root': {
        borderRadius: '12px',
        width: '500px',
        maxWidth: '500px',
        },
    }
};

export const dialog = {
    '& .MuiDialog-container': {
      '& .MuiPaper-root': {
        width: '100%',
        maxWidth: '550px',
        borderRadius: '12px'
      },
    },
};

