import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { FormControl, InputLabel, MenuItem } from '@mui/material';
import CustomSelect from '../../assets/GlobalStyles/CustomSelect';
import { setLayoutParameters } from '../../redux/actions/parametersActions';
import appConfig from '../../config/appConfig';
import { bindActionCreators } from 'redux';

const useStyles = makeStyles({
    formControl: {
        margin: '8px 0px'
    },
    input: {
        height: '35px',
        lineHeight: '35px',
        boxSizing: 'border-box'
    }
});


const CladeBarTypeSelector = props => {

    const { cladeBarType, cladeTypes, setLayoutParameters } = props;
    const classes = useStyles();

    const handleValueChange = event => {
        const cladeBarType = event.target.value;
        setLayoutParameters({ cladeBarType });
    }

    // useEffect(() => {
    //     console.log('mutgene =',mutgene, 'mutposition =', mutposition, genotypeDomainStatus);
    //     if (isFetchNeeded(genotypeDomainStatus))
    //         fetchMeasureScalesDomains( {lineage, colorBy: 'genotype', mutgene, mutposition})
    // }, [mutgene, mutposition])

    return <FormControl className={classes.formControl} fullWidth>
                <InputLabel htmlFor={`${cladeBarType}_cladeBarType`}>
                    Clade bar type
                </InputLabel>
                <CustomSelect
                    value={cladeBarType}
                    onChange={handleValueChange}
                    inputProps={{ name: `${cladeBarType}`, id: `${cladeBarType}_cladeBarType` }}
                >
                    {cladeTypes.map(c => (
                        <MenuItem key={c.key} value={c.key}>{c.label}</MenuItem>
                    ))}
                </CustomSelect>
            </FormControl>;
}



CladeBarTypeSelector.propTypes = {
    cladeBarType: PropTypes.string,
    setParameters: PropTypes.func,
    cladeTypes: PropTypes.arrayOf(PropTypes.shape({key: PropTypes.string, label: PropTypes.string}))
};

const mapStateToProps = (state) => {
    return ({
        //  lineage: state.parameters.lineage,
        cladeBarType: state.parameters.cladeBarType,
        cladeTypes: state.metadata.cladeTypes || appConfig.cladeTypes
    });
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    setLayoutParameters
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(CladeBarTypeSelector);
