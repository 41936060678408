import auth from '../../../functions/auth-helper';
import { setSessionTimeout } from '../../actions/renderActions'

export const sessionTimeOut = (error, store) => {
    // console.log(error);
   
    const userStatus = store?.value.user.userStatus;
    if (userStatus === 'loaded')
        return setSessionTimeout({errorWebsiteText: "Your session has expired. Please sign in again.", errorWebsiteType: 'session'});
    else {
        auth.signout();
        return setSessionTimeout({errorWebsiteText: "Your session has expired.", errorWebsiteType: 'session'});
    }
}
