import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, Autocomplete, DialogActions, DialogContent, InputLabel, FormControl, MenuItem, Button } from '@mui/material';
import { styles } from '../styles';
import CustomSelect from '../../../../assets/GlobalStyles/CustomSelect';
import { StyledTextField } from '../../../../assets/GlobalStyles/TextField';
import { fetchLineages } from '../../../../redux/actions/lineagesActions';
import { connect } from 'react-redux';
import appConfig from '../../../../config/appConfig';

const errorState = {
    email: {
        status: false,
        message: ''
    },
    username: {
        status: false,
        message: ''
    },
    password: {
        status: false,
        message: ''
    },
    role: {
        status: false,
        message: ''
    }
};
const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const EditDialog = (props) => {
    const { handleCloseDialog, openDialog, user, lineages, fetchLineages, updateUser, roles } = props;
    const [updatedUser, setUpdatedUser] = useState(user);
    const [error, setError] = useState(errorState);
    const classes = styles();
    const defaultLin = `Aplication default (${appConfig.default.lineage})`;

    useEffect(() => {
        if (lineages.length === 0)
            fetchLineages();
    }, []);

    const handleChange = (e, type) => {
        switch(type) {
            case 'email': {
                setUpdatedUser({...updatedUser, email: e.target.value});
                if (error.email.status && e.target.value)
                    setError({...error, email: {status: false, message: ''}});
                return;
            }
            case 'password': {
                setUpdatedUser({...updatedUser, password: e.target.value});
                if (error.password.status && e.target.value)
                    setError({...error, password: {status: false, message: ''}});
                return;
            }
            case 'username': {
                setUpdatedUser({...updatedUser, username: e.target.value});
                if (error.username.status && e.target.value)
                    setError({...error, username: {status: false, message: ''}});
                return;
            }
            case 'role': {
                setUpdatedUser({...updatedUser, role: e.target.value});
                if (error.role.status && e.target.value)
                    setError({...error, role: {status: false, message: ''}});
                return;
            }
            case 'defaultLineage': {
                setUpdatedUser({...updatedUser, defaultLineage: e.target.value});
                return;
            }
        }
    };

    const validate = () => {
        const matchEmail = updatedUser.email.match(emailRegex);
        setError({
            username: !updatedUser.username ? {status: true, message: 'Username cannot be empty'} : errorState.username,
            email: !updatedUser.email ? {status: true, message: 'Email cannot be empty'} :
                !matchEmail ? {status: true, message: 'Email is not valid'} : errorState.email,
            password: !updatedUser.password ? {status: true, message: 'Password cannot be empty'} : errorState.password,
            role: !updatedUser.role ? {status: true, message: 'Role cannot be empty'} : errorState.role
        });
        const state = !updatedUser.username || !updatedUser.email || !updatedUser.password || !updatedUser.role || !matchEmail;
        return state;
    };

    const handleSubmit = () => {
        if (!validate()){
            if (!lineages.includes(updatedUser.defaultLineage)){
                const updateLinUser = {...updatedUser, defaultLineage: ''};
                updateUser(updateLinUser);
                return;
            }
            updateUser(updatedUser);
        }
    };

    return (
        <Dialog open={openDialog} onClose={handleCloseDialog} sx={{'& .MuiDialog-container': {
            '& .MuiPaper-root': {
              borderRadius: '12px'
            },
          }}}>
            <DialogTitle id="confirm-dialog-title">
                Edit user
            </DialogTitle>

            <DialogContent>
                <StyledTextField
                    id="username"
                    type="text"
                    label="Username"
                    required
                    autoComplete='off'
                    className={classes.textField}
                    value={updatedUser.username}
                    onChange={(e) => handleChange(e, 'username')}
                    error={error.username.status}
                    helperText={error.username.message}
                />
                <StyledTextField
                    id="email"
                    type="email"
                    label="Email"
                    disabled
                    required
                    className={classes.textField}
                    value={updatedUser.email || ''}
                    onChange={(e) => handleChange(e, 'email')}
                    error={error.email.status}
                    helperText={error.email.message}
                />
                <StyledTextField
                    id="password"
                    type="password"
                    label="Password"
                    autoComplete="new-password"
                    required
                    className={classes.textField}
                    value={updatedUser.password}
                    onChange={(e) => handleChange(e, 'password')}
                    error={error.password.status}
                    helperText={error.password.message}
                />
                <FormControl className={classes.formControl} fullWidth>
                    <InputLabel required htmlFor="plotType">
                        Role
                    </InputLabel>
                    <CustomSelect
                        value={updatedUser.role}
                        onChange={(e) => handleChange(e, 'role')}
                        className={classes.textField}
                        inputProps={{
                            name: 'role',
                            id: 'role',
                        }}
                    >
                        {roles.map((t) => (
                            <MenuItem key={t.name} value={t.name}>{t.name[0].toUpperCase() + t.name.slice(1)}</MenuItem>
                        ))}
                    </CustomSelect>
                        {error.role.status && <FormHelperText>Role cannot be empty</FormHelperText>}
                </FormControl>
                <Autocomplete
                    multiple
                    id="lineages"
                    className={classes.autoComplete}
                    options={lineages}
                    getOptionLabel={(option) => option}
                    value={updatedUser.lineages}
                    onChange={(e,v) => setUpdatedUser({...updatedUser, lineages: v})}
                    disableCloseOnSelect
                    renderInput={(params) => (
                    <StyledTextField
                        {...params}
                        label="Available lineages"
                        placeholder={updatedUser.lineages.length > 0 ? '' : 'All lineages'}
                    />
                    )}
                />

                <FormControl className={classes.formControl} fullWidth>
                    <InputLabel htmlFor="plotType">
                        Default lineage
                    </InputLabel>
                    <CustomSelect
                        value={updatedUser.defaultLineage || defaultLin}
                        onChange={(e) => handleChange(e, 'defaultLineage')}
                        className={classes.textField}
                        inputProps={{
                            name: 'defaultLineage',
                            id: 'defaultLineage',
                        }}
                    >
                        <MenuItem key='default' value={defaultLin}>{defaultLin}</MenuItem>
                        {updatedUser.lineages.length > 0 ?
                            updatedUser.lineages.map((lineage) => (
                                <MenuItem key={lineage} value={lineage}>{lineage}</MenuItem> )) :
                            lineages.map((lineage) => (
                                <MenuItem key={lineage} value={lineage}>{lineage}</MenuItem> ))
                        }
                    </CustomSelect>
                </FormControl>
            </DialogContent>

            <DialogActions>
                <Button className={classes.cancel}
                    onClick={handleCloseDialog}>Cancel
                </Button>
                <Button className={classes.confirm} onClick={handleSubmit}>
                    Update
                </Button>
            </DialogActions>
        </Dialog>);
};

const mapStateToProps = state => {
    const lineages = state.lineages.lineages;

    return {
        lineages
    };
};

const mapDispatchToProps = {
    fetchLineages
}

export default connect(mapStateToProps, mapDispatchToProps)(EditDialog);
