import { withStyles } from "@mui/styles";
import { TextField } from '@mui/material';

export const StyledTextField = withStyles({
    root: {
      fontFamily: 'Source Sans Pro Verdana !important',
      fontSize: '16px',
      '& .MuiInput-underline:before': {
        borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
        transition: 'none'
      },
      '& .MuiInput-underline:hover:before': {
        borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
        transition: 'none'
      },
      '& .MuiInput-underline:after': {
        borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
        transition: 'none'
      },
      '& input[type=number]': {
          '-moz-appearance': 'textfield'
      },
      '& input[type=number]::-webkit-outer-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0
      },
      '& input[type=number]::-webkit-inner-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0
      }

    },
})(TextField);
