import { cloneDeep, merge } from 'lodash';
// Action Types
import {
    SET_RENDER_STATUS,
    SET_COMPONENT_STATUS,
    RESET_COMPONENTS_STATUS,
    RESET_SESSION,
    SIGNOUT_REQUEST,
    SET_STRAINS_TREE_DIMENSIONS,
    SET_LABEL_MOVEMENT,
    // SET_SYMBOL_POSITION,
    // SET_SYMBOL_DIMENSIONS,
    CORRECT_LABEL_POSITIONS,
    SET_PARAMETERS,
    SET_HIDDEN_MENU,
    SET_HIDDEN_MENU_MOBILE,
    SET_ERROR_WEBSITE,
    FETCH_ANTIGENIC_MODEL_ERROR,
    SET_SESSION_TIMEOUT,
    FETCH_ANTIGENIC_MODELS_LIST_ERROR,
    FETCH_ANTIGENIC_RAW_MODEL_ERROR,
    FETCH_ANTIGENIC_OBSERVED_DATA_ERROR,
    FETCH_CLADES_ERROR,
    FETCH_FREQUENCIES_ERROR,
    FETCH_GEO_DATA_ERROR,
    FETCH_LINEAGES_ERROR,
    FETCH_LINEAGE_ERROR,
    FETCH_MODELS_ERROR,
    FETCH_MODEL_TYPES_ERROR,
    FETCH_MODEL_DATA_ERROR,
    FETCH_MUTATION_CLASSES_ERROR,
    FETCH_GENOTYPE_DATA_ERROR,
    FETCH_MUT_POS_DICT_ERROR,
    FETCH_MUTATION_GROUP_ERROR,
    FETCH_PREDICTIONS_ERROR,
    INIT_STRAIN_TREE_ERROR,
    SET_LINEAGE_SETTINGS_ERROR,
    FETCH_MEASURE_SCALES_DOMAINS_ERROR,
    FETCH_VISIBLE_NODES_ERROR,
    FETCH_RECALCULATED_TREE_ERROR,
    FETCH_SUBSET_TREE_ERROR,
    FETCH_TREE_FREQS_ERROR,
    FETCH_CUSTOM_TREE_ATTRS_ERROR,
    FETCH_TCELL_ANTIGENICITY_OPTIONS_ERROR,
    FETCH_VP_METHODS_ERROR,
    FETCH_VP_VALUES_ERROR,
    FETCH_TCELL_ANTIGENICITY_SCORES_ERROR,
    FETCH_SELECTED_STRAIN_ERROR,
    FETCH_ANTIGENIC_REFERENCE_STRAIN_ERROR,
    FETCH_VACCINE_CANDIDATES_ERROR,
    FETCH_STRAINS_LIST_ERROR,
    FETCH_NODE_ERROR,
    ZOOM_TREE_ERROR,
    SET_SHOW_MUTATIONS_GROUPS,
    TOGGLE_MUTATION_CLASS_LABEL_VISIBILITY,
    SET_MUTATION_CLASSES,
    SET_LAYOUT_PARAMETERS,
    SET_SHOW_CLADE_LABELS,
    ZOOM_TREE_SUCCESS,
    INIT_STRAIN_TREE_SUCCESS,
    FETCH_VISIBLE_NODES_SUCCESS,
    FETCH_RECALCULATED_TREE_SUCCESS,
    FETCH_SUBSET_TREE_SUCCESS,
    FETCH_TREE_FREQS_SUCCESS,
    FETCH_SELECTED_STRAIN_SUCCESS,
    FETCH_ANTIGENIC_REFERENCE_STRAIN_SUCCESS,
    FETCH_VP_VALUES_SUCCESS,
    FETCH_HUMAN_SEROLOGY_SUCCESS,
    FETCH_CUSTOM_TREE_ATTRS_SUCCESS,
    FETCH_GENOTYPE_DATA_SUCCESS,
    FETCH_MODEL_DATA_SUCCESS,
    FETCH_ANTIGENIC_MODEL_SUCCESS,
    SET_SHOW_REFERENCE_STRAINS,
    SET_COLOR_BY,
    RESET_GENOTYPE_STATUS,
    // FETCH_HUMAN_POOLS_SUCCESS,
    // FETCH_GENOTYPE_DATA_SUCCESS
} from '../actions/actionTypes';

import { RENDER_STATUS } from '../../config/consts';
import { emptyObject } from '../../functions/functions';

let renderInitialState = {};
export const setRenderInitialState = (state) => {
    renderInitialState = {
        ...state,
        hiddenMenu: window.innerWidth < 786 ? true : false
    };
};


const clearLabels = (labels, deleteConditions) => Object.keys(labels).reduce((acc, type) => {
    const deleteCondition = deleteConditions.reduce((accCond, c) => accCond || (c.type === type && c.condition), false);
    // const deleteCondition = (type === 'mutationLabel' && (showMutationsGroups === false));
    if (!deleteCondition) {
        acc[type] = Object.keys(labels[type]).reduce((acc_type, label_id) => {
            acc_type[label_id] = { ...labels[type][label_id], xMod: 0, yMod: 0, initialized: false };
            return acc_type;
        }, {});
    }
    return acc;
}, {});

const calculateRenderStatus = (viewToRender) => {

    const statusList = Object.values(viewToRender.components);

    if (statusList.length === 0 || statusList.every(status => status === RENDER_STATUS.NONE)) {
        return RENDER_STATUS.NONE;
    } else if (statusList.every(status => status === RENDER_STATUS.DONE)) {
        return RENDER_STATUS.DONE;
    } else {
        return 'loading';
    }
}

export default function renderStatusReducer(state = renderInitialState, action) {
   // if (state.renderStatus === RENDER_STATUS.NONE) console.log(action.type, action.payload)
    switch (action.type) {
        case SET_SESSION_TIMEOUT:
        case ZOOM_TREE_ERROR:
        case FETCH_NODE_ERROR:
        case FETCH_STRAINS_LIST_ERROR:
        case FETCH_VACCINE_CANDIDATES_ERROR:
        case FETCH_ANTIGENIC_REFERENCE_STRAIN_ERROR:
        case FETCH_SELECTED_STRAIN_ERROR:
        case FETCH_TCELL_ANTIGENICITY_SCORES_ERROR:
        case FETCH_VP_VALUES_ERROR:
        case FETCH_VP_METHODS_ERROR:
        case FETCH_TCELL_ANTIGENICITY_OPTIONS_ERROR:
        case FETCH_CUSTOM_TREE_ATTRS_ERROR:
        case FETCH_TREE_FREQS_ERROR:
        case FETCH_SUBSET_TREE_ERROR:
        case FETCH_RECALCULATED_TREE_ERROR:
        case FETCH_VISIBLE_NODES_ERROR:
        case FETCH_MEASURE_SCALES_DOMAINS_ERROR:
        case SET_LINEAGE_SETTINGS_ERROR:
        case INIT_STRAIN_TREE_ERROR:
        case FETCH_PREDICTIONS_ERROR:
        case FETCH_MUTATION_GROUP_ERROR:
        case FETCH_MUT_POS_DICT_ERROR:
        case FETCH_GENOTYPE_DATA_ERROR:
        case FETCH_MUTATION_CLASSES_ERROR:
        case FETCH_MODEL_DATA_ERROR:
        case FETCH_MODELS_ERROR:
        case FETCH_MODEL_TYPES_ERROR:
        case FETCH_LINEAGE_ERROR:
        case FETCH_LINEAGES_ERROR:
        case FETCH_GEO_DATA_ERROR:
        case FETCH_FREQUENCIES_ERROR:
        case FETCH_CLADES_ERROR:
        case FETCH_ANTIGENIC_OBSERVED_DATA_ERROR:
        case FETCH_ANTIGENIC_RAW_MODEL_ERROR:
        case FETCH_ANTIGENIC_MODEL_ERROR:
        // case FETCH_ANTIGENIC_MODELS_LIST_ERROR: 
        {
            const { errorWebsiteText, errorWebsiteType } = action.payload;

            return {
                ...state,
                errorWebsite: true,
                errorWebsiteText,
                errorWebsiteType
            }
        }
        case RESET_SESSION:
        case SIGNOUT_REQUEST: {
            return { ...renderInitialState };
        }
        case SET_ERROR_WEBSITE: {
            const { errorWebsite } = action.payload;

            return {
                ...state,
                errorWebsite,
            }
        }
        case SET_RENDER_STATUS: {
            const { renderStatus } = action.payload;

            //}
            return { ...state, renderStatus }; //, renderTimeStart, renderTime };
        }
        case SET_COMPONENT_STATUS: {
            const { viewName, componentId, status, labels } = action.payload;

            //let { renderTimeStart, renderTime } = state;
            const viewToRender = cloneDeep(state.viewToRender); //{...state.viewToRender};
            // if (status === RENDER_STATUS.START && emptyObject(view.viewComponents))
            //     renderTimeStart = new Date().getTime();
            // else if (status === RENDER_STATUS.DONE) {
            //     renderTime = new Date().getTime() - renderTimeStart;

            //}

            if (viewToRender.viewName === viewName) {
                viewToRender.components[componentId] = status;
                //console.log(componentId, viewToRender);
            } else {
                viewToRender.viewName = viewName;
                viewToRender.components = {
                    [componentId]: status
                }
            }

            const renderStatus = calculateRenderStatus(viewToRender);

            let _labels = { ...state.labels };
            if (labels) _labels = merge(_labels, { [labels.type]: labels.labels });
            // if (labels) console.log(SET_COMPONENT_STATUS, renderStatus, status, labels, _labels)
            // const statusList = Object.values(viewToRender.components);
            // const newRenderStatus = statusList.some(elem => elem !== RENDER_STATUS.DONE) || statusList.length === 0 // || statusList.length < VIEW_GRAPH_NR[viewName]
            //     ? 'loading' : RENDER_STATUS.DONE;
        
            return {
                ...state,
                viewToRender,
                renderStatus,
                labels: _labels

            }
        }
        case RESET_COMPONENTS_STATUS: {

            const { viewName, status } = action.payload;
            //console.log('RESET_COMPONENT_STATUS', viewName)
            const view = { ...state.viewToRender };



            if (view.viewName === viewName) {
                Object.keys(view.components).forEach(key => {
                    view.components[key] = status;
                })
            }

            const renderStatus = calculateRenderStatus(view);


            return {
                ...state,
                viewToRender: {
                    ...view
                },
                renderStatus,
            }
        }
        case SET_STRAINS_TREE_DIMENSIONS: {
            const { strainTreeWidth, strainTreeHeight } = action.payload;

            const labels = Object.keys(state.labels).reduce((acc, type) => {
                acc[type] = Object.keys(state.labels[type]).reduce((acc_type, id) => {
                    acc_type[id] = { ...state.labels[type][id], xMod: 0, yMod: 0, initialized: false };
                    return acc_type;
                }, {});
                return acc;
            }, {});

            const view = { ...state.viewToRender };
            if (view.viewName === 'strainTree') {
                Object.keys(view.components).forEach(key => {
                    view.components[key] = RENDER_STATUS.NONE;
                })
            }
            const renderStatus = calculateRenderStatus(view);
        
            return {
                ...state,
                strainTreeWidth,
                strainTreeHeight,
                labels,
                viewToRender: {
                    ...view
                },
                renderStatus,

            }
        }


        case SET_LAYOUT_PARAMETERS: {
            const view = { ...state.viewToRender };
            if (view.viewName === 'strainTree') {
                Object.keys(view.components).forEach(key => {
                    view.components[key] = RENDER_STATUS.NONE;
                })
            }
            const renderStatus = calculateRenderStatus(view);

            return {
                ...state,
                viewToRender: {
                    ...view
                },
                renderStatus,
            }
        }

        case SET_COLOR_BY: {
            const { prevShowRule, showRule } = action.payload;
            if (prevShowRule === showRule && showRule === 'none') return { ...state };
            const view = { ...state.viewToRender };
            if (view.viewName === 'strainTree') {
                Object.keys(view.components).forEach(key => {
                    view.components[key] = RENDER_STATUS.NONE;
                })
            }
            const renderStatus = calculateRenderStatus(view);

            return {
                ...state,
                viewToRender: {
                    ...view
                },
                renderStatus,
            }
        }

        // case SET_SHOW_REFERENCE_STRAINS: {
        //     const viewToRender = { ...state.viewToRender };
        //     viewToRender.components = { ...viewToRender.components, referenceStrains: RENDER_STATUS.NONE };
        //     const renderStatus = calculateRenderStatus(viewToRender);
        //     return {
        //         ...state,
        //         labels,
        //         viewToRender,
        //         renderStatus
        //     }
        // }

        case SET_SHOW_MUTATIONS_GROUPS: {
            const { showMutationsGroups } = action.payload;
            const labels = clearLabels(state.labels, [{ type: 'mutationLabel', condition: showMutationsGroups === false }]);
            const viewToRender = { ...state.viewToRender };


            // let renderStatus = state.renderStatus;

            viewToRender.components = { ...viewToRender.components, mutationsClasses: RENDER_STATUS.NONE };
            const renderStatus = calculateRenderStatus(viewToRender);
            return {
                ...state,
                labels,
                viewToRender,
                renderStatus
            }
        }

        case SET_SHOW_CLADE_LABELS: {
            const { showCladeLabels } = action.payload;
            const labels = showCladeLabels === false
                ? clearLabels(state.labels, [{ type: 'cladeLabel', condition: showCladeLabels === false }])
                : state.labels;
            const viewToRender = { ...state.viewToRender };


            // let renderStatus = state.renderStatus;

            viewToRender.components = { ...viewToRender.components, cladeLabels: RENDER_STATUS.NONE };
            const renderStatus = calculateRenderStatus(viewToRender);
            return {
                ...state,
                labels,
                viewToRender,
                renderStatus
            }
        }

        case TOGGLE_MUTATION_CLASS_LABEL_VISIBILITY:
        case SET_MUTATION_CLASSES: {
            const viewToRender = { ...state.viewToRender };
            //let renderStatus = state.renderStatus;

            viewToRender.components = { ...viewToRender.components, mutationsClasses: RENDER_STATUS.NONE };
            // const statusList = Object.values(viewToRender.components);
            const renderStatus = calculateRenderStatus(viewToRender);

            // renderStatus = statusList.some(elem => elem !== RENDER_STATUS.DONE) || statusList.length === 0 // || statusList.length < VIEW_GRAPH_NR[viewName]
            //     ? 'loading' : RENDER_STATUS.DONE;
            return {
                ...state,
                // labels,
                viewToRender,
                renderStatus
            }
        }


        case SET_LABEL_MOVEMENT: {
            const { id, type, subType, ...props } = action.payload;
            const labels = cloneDeep(state.labels);
            if (!labels[type]) labels[type] = {};
            const _id = `${id}${subType ? `_${subType}` : ''}`;
            labels[type][_id] = { ...labels[type][_id], ...props };
            return {
                ...state,
                labels,
            }
        }


        case CORRECT_LABEL_POSITIONS: {
            const labelNodes = action.payload;
            const labels = cloneDeep(state.labels);
            labelNodes
                //.filter(({ showLabel }) => (!showLabel === false))
                .forEach(({ id, x, y, type, mutClass }) => {
                    const _id = mutClass ? `${id}_${mutClass}` : id;
                    //console.log(`type = ${type}, id = ${_id}`);
                    labels[type][_id].x = x;
                    labels[type][_id].y = y;
                })
            return {
                ...state,
                labels
            };
        }

        case SET_HIDDEN_MENU: {
            const { hiddenMenu } = action.payload;

            return {
                ...state,
                hiddenMenu
            }
        }

        case SET_HIDDEN_MENU_MOBILE: {
            const { hiddenMenuMobile } = action.payload;

            return {
                ...state,
                hiddenMenuMobile
            }
        }

        case ZOOM_TREE_SUCCESS:
        case INIT_STRAIN_TREE_SUCCESS:
        case FETCH_VISIBLE_NODES_SUCCESS:
        case FETCH_RECALCULATED_TREE_SUCCESS:
        case FETCH_SUBSET_TREE_SUCCESS:
        case FETCH_TREE_FREQS_SUCCESS:
        case FETCH_SELECTED_STRAIN_SUCCESS:
        case FETCH_ANTIGENIC_REFERENCE_STRAIN_SUCCESS:
        case FETCH_VP_VALUES_SUCCESS:
        case FETCH_HUMAN_SEROLOGY_SUCCESS:
        case FETCH_CUSTOM_TREE_ATTRS_SUCCESS:
        // case FETCH_HUMAN_POOLS_SUCCESS:
        case FETCH_GENOTYPE_DATA_SUCCESS:
        case FETCH_MODEL_DATA_SUCCESS:
        case FETCH_ANTIGENIC_MODEL_SUCCESS:
        case RESET_GENOTYPE_STATUS:
            {
                const renderStatus = state.viewToRender?.viewName === 'strainTree' ? RENDER_STATUS.RERENDER_NEEDED : state.renderStatus;
                // console.log('renderStatus', action.type, renderStatus, state.viewToRender);
                return {
                    ...state,
                    renderStatus
                }
            }

        default:
            return state || renderInitialState;
    }
}
