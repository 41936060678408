import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import auth from '../../functions/auth-helper';
import {
    SIGNIN_REQUEST,
    SIGNOUT_REQUEST,
    GET_SIGNED_USER_REQUEST,
} from '../actions/actionTypes';
import {
    signInError,
    signInSuccess,
    signOutError,
    signOutSuccess,
    getSignedUserSuccess,
    getSignedUserError
} from '../actions/userActions';
import { prepareUrl } from '../../functions/functions';
import config from '../../config/envConfig';
import { fetchAxios, login } from '../../functions/axiosRequests';

export const signinEpic = (action$, store, dep) => action$.pipe(
    ofType(SIGNIN_REQUEST),
    mergeMap(action => {
        const url = `${config.serverLink}/api/auth/signin/`;

        return login(action.payload, url).then(function (response) {
            auth.authenticate(response.data, () => dep.history.push(`${config.frontendPrefix}/`));
            const { user } = response.data;
            return signInSuccess({
                name: user.username,
                userName: user.email,
                defaultLineage: user.defaultLineage || '',
                lineages: user.lineages || [],
                role: user.role,
                menuRight: user.menuRight,
                permissions: user.permissions
            })
        }).catch(function (error) {
           // console.log(error);
            return signInError({ error });
        });
    })
);

export const signOutEpic = (action$, store, dep) => action$.pipe(
    ofType(SIGNOUT_REQUEST),
    mergeMap((action) => {
        const url = `${config.serverLink}/api/auth/signout`;
        return fetchAxios(url).then(function (_) {
            auth.signout(() => {
                if (store.value.user.status === 'loaded')
                    dep.history.replace(`${config.frontendPrefix}/signin`);
                else
                    dep.history.replace(`${config.frontendPrefix}/intro`);
            });
            return signOutSuccess({ userName: null })
        }).catch(function (error) {
            console.log('signout error')
            return signOutError({ error });
        });
    })
);

export const getSignedUserEpic = (action$, store, dep) => action$.pipe(
    ofType(GET_SIGNED_USER_REQUEST),
    mergeMap(() => {
        const url = prepareUrl(`${config.serverLink}/api/auth/getSignedUser`);
        return fetchAxios(url).then(function (response) {
            const { user } = response.data;
            // console.log(user)
            // console.log(response.status)
                if (response.status === 299) {
                    // auth.signout(() => { dep.history.push('/signin'); });
                    return signOutSuccess({ userName: null });

                }
                return getSignedUserSuccess({
                    name: user.username,
                    userName: user.email,
                    defaultLineage: user.defaultLineage || '',
                    lineages: user.lineages || [],
                    role: user.role,
                    permissions: user.permissions,
                    menuRight: user.menuRight,
                });
        }).catch(function (error) {
            const location = dep.history.location.pathname;
            location !== '/intro' && auth.signout(() => { dep.history.push(`${config.frontendPrefix}/intro`); });

            return getSignedUserError({ error });
        });
    })
);
