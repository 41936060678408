const FONT_SIZE = {
    LARGE: 22,
    BIG: 20,
    MEDIUM: 18,
    SMALL: 16
}

const CHART_SIZE = {
    LARGE: 21,
    BIG: 18,
    MEDIUM: 16,
    SMALL: 12
}

const COUNTER_THRESHOLD = {
    BIG: 1000,
    MEDIUM: 100,
    SMALL: 10,
}

const getFontSize = (total) => {
    return total >= COUNTER_THRESHOLD.BIG
        ? FONT_SIZE.LARGE
        : total >= COUNTER_THRESHOLD.MEDIUM
            ? FONT_SIZE.BIG
            : total >= COUNTER_THRESHOLD.SMALL
                ? FONT_SIZE.MEDIUM
                : FONT_SIZE.SMALL;
}

const getChartSize = (total) => {
    return total >= COUNTER_THRESHOLD.BIG
        ? CHART_SIZE.LARGE
        : total >= COUNTER_THRESHOLD.MEDIUM
            ? CHART_SIZE.BIG
            : total >= COUNTER_THRESHOLD.SMALL
                ? CHART_SIZE.MEDIUM
                : CHART_SIZE.SMALL;
}

const pieSegment = (start, end, r, color) => {
    if (end - start === 1) end -= 0.00001;
    const a0 = 2 * Math.PI * (start - 0.25) || 0;
    const a1 = 2 * Math.PI * (end - 0.25) || 0;
    const x0 = Math.cos(a0),
        y0 = Math.sin(a0);
    const x1 = Math.cos(a1),
        y1 = Math.sin(a1);
    const largeArc = end - start > 0.5 ? 1 : 0;

    return `<path d="
        M ${r} ${r} 
        L ${r + r * x0} ${r + r * y0} 
        A ${r} ${r} 0 ${largeArc} 1 ${r + r * x1} ${r + r * y1} 
        L ${r} ${r}" 
        fill="${color}"
    />`
}

export const createPieChart = (props, colors, uniqueClades, large) => {
    const counts = uniqueClades.map(id => props[id]);
    const offsets = counts.reduce((acc, el) => {
        const last = acc[acc.length - 1];
        return [...acc, last + el]
    }, [0])
    const total = offsets[offsets.length - 1];
    const fontSize = getFontSize(total)
    const r = large ? 50 : getChartSize(total);
    const w = r * 2;

    const svgStyles = `font: ${fontSize}px 'Source Sans Pro', 'Verdana'; display: block; overflow: overlay`;
    let html = `<div>
        <svg width="${w}" height="${w}" viewbox="0 0 ${w} ${w}" 
            text-anchor="middle" style="${svgStyles}">
    `;

    for (let i = 0; i < counts.length; i++) {
        html += pieSegment(
            offsets[i] / total,
            (offsets[i] + counts[i]) / total,
            r,
            colors[i]
        );
    }

    html += `</svg></div>`;

    const el = document.createElement('div');
    el.innerHTML = html;
    return el.firstChild;
}