import { cloneDeep, get, map, set } from 'lodash';
import {
    FETCH_PREDICTIONS_REQUEST,
    FETCH_PREDICTIONS_SUCCESS,
    FETCH_PREDICTIONS_ERROR,
    FETCH_FREQUENCIES_REQUEST,
    FETCH_FREQUENCIES_SUCCESS,
    FETCH_FREQUENCIES_ERROR,
    RESET_SESSION,
    SIGNOUT_REQUEST,
    RESET_PREDICTIONS,
    SET_MODEL_TYPE,
    SET_MODEL_REGION_ID,
    SET_SELECTED_MODELS,
    // SET_SELECTED_MODELS,
} from '../actions/actionTypes';

let predictionsInitialState = {};
export const setPredictionsInitialState = (state) => {
    predictionsInitialState = state;
};


const predictionsData = (state = predictionsInitialState, action) => {
    switch (action.type) {
        case RESET_SESSION:
        case SIGNOUT_REQUEST: {
            return predictionsInitialState;
        }
        case SET_MODEL_REGION_ID:
        case SET_MODEL_TYPE:
        case RESET_PREDICTIONS: {
            return {
                ...state,
                predictionsStatus: {}
            }
        }
        case SET_SELECTED_MODELS: {
            const { selectedModels, strainSubset } = action.payload;
         
            const predictionsStatus = {}; //selectedModels.reduce     //{};//  ...state.predictionsStatus };
            const predictions = {};//  ...state.predictions };
            for (const index in selectedModels) {
                const { modelRegionId, modelType, modelId, invalid, idIncomplete } = selectedModels[index];
                const _invalid = (invalid || false);
                if (idIncomplete ) {
                    continue;
                }
                const modelPath = [ strainSubset, modelRegionId, modelType, modelId];

                const modelStatus =  _invalid ? 'nodata' : (get(state.predictionsStatus, modelPath) || 'none');
                set(predictionsStatus, modelPath, modelStatus);
                const modelPredictions = _invalid ? undefined : get(state.predictions, modelPath);
                set(predictions, modelPath, modelPredictions);
            }
            
            return {
                ...state,
                predictionsStatus,
                predictions
            }
        }
        case FETCH_PREDICTIONS_REQUEST:
        case FETCH_FREQUENCIES_REQUEST: {
            const { regionId, strainSubset, selectedModels, allRegions, regions } = action.payload;

            const _regions = allRegions ? Object.keys(regions) : [(strainSubset === 'region') ? regionId : strainSubset];


            //if (!modelId || !regionId || !modelType) return { ...state };

            const predictionsStatus = { ...state.predictionsStatus };
            const predictions = { ...state.predictions };
            if (selectedModels) {
                _regions.forEach(subsetId => {
                    // const subsetId = (strainSubset === 'region') ? regionId : strainSubset;
                    selectedModels.forEach(m => {
                        const { modelRegionId, modelType, modelId } = m;
                        // console.log(action.type, m, predictions?.[subsetId]?.[modelRegionId]?.[modelType]?.[modelId])
                        if (!predictionsStatus[subsetId]) predictionsStatus[subsetId] = {};
                        if (!predictionsStatus[subsetId][modelRegionId]) predictionsStatus[subsetId][modelRegionId] = {};
                        if (!predictionsStatus[subsetId][modelRegionId][modelType]) predictionsStatus[subsetId][modelRegionId][modelType] = {};

                        // delete predictions?.[subsetId]?.[modelRegionId]?.[modelType]?.[modelId];
                        // predictionsStatus[subsetId][modelRegionId][modelType] = { ...predictionsStatus[subsetId][modelType] };
                        predictionsStatus[subsetId][modelRegionId][modelType][modelId] = 'loading';
                    })
                });
            }

            return {
                ...state,
                predictions,
                predictionsStatus
            };
        }

        case FETCH_PREDICTIONS_SUCCESS:
        case FETCH_FREQUENCIES_SUCCESS: {
            const { predictions } = action.payload;
            //const { modelId , modelType } = parameters;

            const _predictions = cloneDeep(state.predictions);
            const predictionsStatus = cloneDeep(state.predictionsStatus);


            Object.keys(predictions).forEach(region => {
                if (!_predictions[region]) _predictions[region] = {};
                if (!predictionsStatus[region]) predictionsStatus[region] = {};

                predictions[region].forEach(m => {
                    const { modelRegionId, modelType, modelId, predictionsData } = m;
                    if (!_predictions[region][modelRegionId]) _predictions[region][modelRegionId] = {};
                    if (!_predictions[region][modelRegionId][modelType]) _predictions[region][modelRegionId][modelType] = {};
                    _predictions[region][modelRegionId][modelType][modelId] = predictionsData;

                    if (!predictionsStatus[region][modelRegionId]) predictionsStatus[region][modelRegionId] = {};
                    if (!predictionsStatus[region][modelRegionId][modelType]) predictionsStatus[region][modelRegionId][modelType] = {};
                    predictionsStatus[region][modelRegionId][modelType][modelId] = 'loaded';
                    //if (!_predictions[region][modelRegionId][modelType][modelId]) _predictions[region][modelRegionId][modelType][modelId] = {};
                })
                //     if ( !_predictions[region][modelType])
                //         _predictions[region][modelType] = {};

                //     _predictions[region][modelType][modelId] = predictions[region];



            });

            return {
                ...state,
                predictions: _predictions,
                predictionsStatus
            };
        }
        case FETCH_PREDICTIONS_ERROR:
        case FETCH_FREQUENCIES_ERROR: {
            const { regionId, parameters } = action.payload;
            const predictions = { ...state.predictions };
            const predictionsStatus = { ...state.predictionsStatus };

            if (parameters.regionId) {
                predictions[regionId] = [];
                predictionsStatus[regionId] = 'error';
            }
            return {
                ...state,
                predictions,
                predictionsStatus
            };
        }

        default:
            return state || predictionsInitialState;
    }
};

export { predictionsData };
