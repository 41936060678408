/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import { Table, TableBody, TableCell, TableRow, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText } from '@mui/material';
import _ from 'lodash'
import { getNodeDataById } from '../../redux/selectors/nodeDataSelector';
import { getCurrentColorByCustomTreeAttr } from '../../redux/selectors/metadataSelector';
import { getIsMobile } from '../../redux/selectors/statusSelector';
import { numFormat } from '../../functions/formats';
import { emptyObject } from '../../functions/functions';
import TreeLegend from '../ColorLegend/Legends/TreeLegend';
import { fetchNode } from '../../redux/actions/treeDataActions';
import { HideIcon } from '../Alerts/styles';
import { selectNodeData } from '../../redux/actions/nodeActions'
import { humanSerology } from '../../redux/reducers/humanSerologyReducer';

const styles = theme => ({
    root: {
        backgroundColor: '#fff',
        height: 'calc(100vh - 70px)',
        maxHeight: 'calc(100vh - 70px)',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'none',
        paddingTop: '5px',
        width: '100%'
    },
    table: {
        borderCollapse: 'collapse',
        padding: '10px 14px',
        height: 280,
        '& tbody': {
            display: 'table',
        },
        width: '100%'
    },
    row: {
        fontSize: 12,
        height: 'auto',
        height: 21,
    },
    headRow: {
        fontSize: 12,
        height: 'auto',
        height: 21,
        position: 'relative',
        width: '100%'
    },
    cell: {
        border: 'none',
        // whiteSpace: 'nowrap',
        verticalAlign: 'top',
        lineHeight: '21px',
        fontFamily: 'Source Sans Pro Bold',
    },
    cellValue: {
        fontWeight: 'normal',
        border: 'none',
        padding: '0 40px 0 15px',
        lineHeight: '21px',
        width: '100%',
        verticalAlign: 'top',
        textAlign: 'left',
        overflowWrap: 'break-word',
        wordBreak: 'break-word',
    },
    legendWrapper: {
        padding: '20px 15px 0 15px',
        overflowY: 'overlay',
    },
    divPadding: {
        height: '5px'
    },
    button: {
        position: 'absolute',
        padding: '0 21px 0 0 ',
        right: 0
    },
    infoMobile: {
        position: 'absolute',
        left: '17px',
        width: '90%',
        top: '132px',
        backgroundColor: 'white',
        boxShadow: '0 10px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)',
        borderRadius: '10px'
    }
});

let rowId = 0;

const createData = (rowName, value) => {
    if (value !== undefined && value !== '') {
        rowId += 1;
        return { rowId: `${rowId}_${rowName}`, rowName, value };
    }
    return null;
};

const addSpaces = val => ((!emptyObject(val)) ? val.replace(/,/g, ', ') : '');
const getNodeValue = (colorBy) => (state) => {
    const nodeData = getNodeDataById(state)
    //const key = SORT_TO_ATTR[colorBy] || colorBy;
    return nodeData[colorBy];
}

const NodeInfo = (props) => {
    const { classes, menuRight, nodeData, customNodeOptions, colorBy, nodeId, lineage, fetchNode, isMobile, humanSerologyDataType } = props;
    const { id, name, loc, EPI, date, divergence, AADivergence, NLDivergence, fitness, flux, frequency, cladeLabel, vaccine, advance, tn, tsn, regconf, antigenic_titer_absolute, antigenic_titer_drop,
        cn, csn, src, NSMutations, SMutations, ALLMutations, genotype, tcellAntigenicity, nucMut, aminoMut, clade, humanSerology } = nodeData;
    const _nucMut = (nucMut ? nucMut : props.nucMut) || [];
    const _aminoMut = (aminoMut ? _.uniq(aminoMut) : _.uniq(props.aminoMut)) || [];

    React.useEffect(() => {
        nodeId && fetchNode({ nodeId, lineage })
    }, [nodeId])

    if (emptyObject(id)) {
        return null;
    }

    const customAttrs = customNodeOptions.map(c => createData(c.label, nodeData[c.key]));
    rowId = 0;

    const rows = [
        createData('EPI', EPI),
        createData('Date', date),
        //createData('Divergence', divergence),
        createData('AA divergence', AADivergence),
        createData('NL divergence', NLDivergence),
        // createData('Node type', nodeType),
        createData('Predicted fitness', numFormat(fitness)),
        createData('Frequency', numFormat(frequency)),
        createData('Antigenic advance', numFormat(advance)),
        createData('Flux', numFormat(flux)),
        createData('Nucleotide mutations', _nucMut.join(', ')),
        createData('Aminoacid mutations', _aminoMut.join(', ')),
        // (nodeType === 'mutations') ? createData('Non-synonymous mutations number', NSMutations) : null,
        // (nodeType === 'mutations') ? createData('Synonymous mutations number', SMutations) : null,
        // (nodeType === 'mutations') ? createData('Mutations number', ALLMutations) : null,
        createData('Clade', addSpaces(cladeLabel)),
        createData('Clade id', clade),
        createData('Vaccine', vaccine),
        // createData('Reassortment-Cluster', rc),
        // createData('Reassortment-Sub-Cluster', src),
        createData('All Leafs', tn),
        createData('Leafs in Clade', tsn),
        createData('Region-Confidence [%]', regconf),
        createData('T-cell antigenicity', tcellAntigenicity),
        createData('Titer', antigenic_titer_absolute),
        createData('Titer drop', antigenic_titer_drop),
        createData('Allele', genotype),
        createData(`Human serology ${humanSerologyDataType}`, humanSerology),
        //createData('Antigenic distance' ,),
        createData('Id', id),
        ...customAttrs
        // createData('Order', order)
    ].filter(d => d !== null && d !== undefined); // .filter(row => !emptyObject(row.value));

    const handleClick = () => {
        props.selectNodeData();
    }

    // if (!emptyObject(antigenic)) rows.push(createData('Titer', antigenic));
    return (
        <>
            {!emptyObject(id) && (
                isMobile ?
                <div className={classes.infoMobile}>
                    <Table className={classes.table}>
                        <TableBody >
                            <TableRow className={classes.divPadding}/>
                            <TableRow className={classes.row}>
                                <TableCell className={classes.cellValue} colSpan={2}>
                                    <span className={classes.cell}>
                                        {name}
                                    </span>
                                    <IconButton className={classes.button} onClick={handleClick}>
                                        <HideIcon />
                                    </IconButton>
                                </TableCell>

                            </TableRow>
                            {rows.map(row => (
                                <TableRow className={classes.row} key={row.rowId}>
                                    <TableCell className={classes.cellValue} component="td" scope="row">
                                        <span className={classes.cell}>
                                            {row.rowName}:&nbsp;
                                        </span>
                                        <span >
                                            {row.value}
                                        </span>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
                :
                <div className={classes.root}>
                    <Table className={classes.table}>
                        <TableBody style={{width: '100%'}}>
                            <TableRow className={classes.divPadding}/>
                            <TableRow className={classes.headRow}>
                                <TableCell className={classes.cellValue} colSpan={2}>
                                    <span className={classes.cell}>
                                        {name}
                                    </span>
                                    <IconButton className={classes.button} onClick={handleClick}>
                                        <HideIcon />
                                    </IconButton>
                                </TableCell>

                            </TableRow>
                            {rows.map(row => (
                                <TableRow className={classes.row} key={row.rowId}>
                                    <TableCell className={classes.cellValue} component="td" scope="row">
                                        <span className={classes.cell}>
                                            {row.rowName}:&nbsp;
                                        </span>
                                        <span >
                                            {/* // {displayMutationValue(row.value)} */}
                                            {row.value}
                                        </span>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <div className={classes.legendWrapper}>
                        <TreeLegend valueGetter={getNodeValue(colorBy)} nodeInfo={true}/>
                    </div>
                </div>
            )}
        </>
    );
}

NodeInfo.propTypes = {
    classes: PropTypes.object.isRequired,
    nodeData: PropTypes.shape({
        id: PropTypes.number,
        EPI: PropTypes.string,
        name: PropTypes.string,
        date: PropTypes.string,
        // mutations: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        advance: PropTypes.number,
        fitness: PropTypes.number,
        flux: PropTypes.number,
        frequency: PropTypes.number,
        cladeLabel: PropTypes.string,
        vaccine: PropTypes.string,
        rc: PropTypes.string,
        src: PropTypes.string,
        tn: PropTypes.number,
        tsn: PropTypes.number,
        regconf: PropTypes.string,
        cn: PropTypes.number,
        csn: PropTypes.number,
    }),
    customNodeOptions: PropTypes.arrayOf(PropTypes.shape({ key: PropTypes.string, label: PropTypes.string }))
};

const mapStateToProps = (state) => {
    // const customOptions = getCustomTreeAttrsOptions(state);
    //console.log(customOptions);
    //console.log(state.parameters.colorBy);
    // const customColorByOption  = customOptions ? customOptions.node.filter(({key}) => key === state.parameters.colorBy) : null;
    const customOptions = getCurrentColorByCustomTreeAttr(state);
    // const mutations = getNodeMutations(state);
    const nodeData = getNodeDataById(state);
    // if (!emptyObject(nodeData)) console.log('nodeData', nodeData);
    // console.log('mapStateToProps', state.parameters.searchStrainMode)
    return ({
        menuRight: state.user.menuRight,
        loadingNode: state.nodeData.loadingNode,
        nodeId: state.nodeData.nodeId,
        nodeError: state.nodeData.nodeError,
        colorBy: state.parameters.colorBy,
        lineage: state.parameters.lineage,
        humanSerologyDataType: state.parameters.humanSerologyDataType,
        customNodeOptions: customOptions, //customOptions ? customOptions.node : null
        nodeData,
        isMobile: getIsMobile(),

    });
};

const mapDispatchToProps = {
    fetchNode: fetchNode,
    selectNodeData: selectNodeData
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NodeInfo));
