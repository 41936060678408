import { makeStyles } from '@mui/styles';

export const styles = makeStyles(() =>({
    colorButton: {
        marginRight: '20px',
        width: '30px',
        height: '30px',
        borderRadius: '5px',
        boxShadow: '1px 1px #e6e6e6'
    }
}))
