import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import config from '../../config/envConfig';
import { fetchAxios } from '../../functions/axiosRequests';
import {
    FETCH_ACTIVE_CLADES_REQUEST,
    FETCH_CLADES_REQUEST,
} from '../actions/actionTypes';
import {
    fetchCladesSuccess,
    fetchCladesError,
    fetchActiveCladesSuccess,
    fetchActiveCladesError,
} from '../actions/cladeActions';
import { prepareUrl } from '../../functions/functions';
import { sessionTimeOut } from './helpers/functions';

export const fetchCladesEpic = action$ => action$.pipe(
    ofType(FETCH_CLADES_REQUEST),
    mergeMap(action => {
        const { lineage } = action.payload;
        const url = prepareUrl(`${config.serverLink}/api/clades`, { lineage });
        //console.log(`fetchClades: ${url}`);

        return fetchAxios(url).then(function (response) {
            return fetchCladesSuccess(response.data);
        }).catch(function (error) {
            const { status, data } = error.response;
            if (status === 408)
                return sessionTimeOut(error);

            return fetchCladesError({ error: data.error, errorWebsiteText: 'An error has occured during downloading clades.', errorWebsiteType: 'fetch'});
        })
    }),
);


export const fetchActiveCladesEpic = action$ => action$.pipe(
    ofType(FETCH_ACTIVE_CLADES_REQUEST),
    mergeMap(action => {
        const { lineage, cladeType, predictionBaseline, cladeActiveDays } = action.payload;
        const url = prepareUrl(`${config.serverLink}/api/activeClades`, { lineage, cladeType, predictionBaseline, cladeActiveDays });
        //console.log(`fetchActiveClades: ${url}`);

        return fetchAxios(url).then(function (response) {
            return fetchActiveCladesSuccess(response.data);
        }).catch(function (error) {
            const { status, data } = error.response;
            if (status === 408)
                return sessionTimeOut(error);

            return fetchActiveCladesError({ error: data.error, errorWebsiteText: 'An error has occured on fetching active clades.', errorWebsiteType: 'fetch'});
        })
    }),
);
