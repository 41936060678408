import React, { useState, useEffect } from 'react';
import { StyledTextField } from '../../../../assets/GlobalStyles/TextField';
import { styles } from '../Dialogs/styles';
import { FormControl, InputLabel, MenuItem, Grid } from '@mui/material';
import { fetchAxios } from '../../../../functions/axiosRequests';
import appConfig from '../../../../config/appConfig';
import config from '../../../../config/envConfig';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { spliceScaleId } from './functions';

const filter = createFilterOptions();
const antigenicOptions = [
    'absolute',
    'drop',
    'fold_reduction'
]

const ScaleIdConfigurator = ({handleScaleIdChange, newScale, error, edit, initVals}) => {
    const [options, setOptions] = useState([]);
    const [isAntigenic, setIsAntigenic] = useState(false);
    const [measure, setMeasure] = useState('');
    const [additionalMeasure, setAdditionalMeasure] = useState('');
    const [idString, setIdString] = useState('');
    const classes = styles();

    useEffect(() => {
        const defaultOptions = Object.keys(appConfig.measures).map(key => key).filter(key => appConfig.measures[key].colorBy === true);
        setOptions(defaultOptions);

        if (edit){
            setMeasure(initVals.measure);
            setIdString(initVals.idString);
            if (initVals.additionalMeasure)
                setAdditionalMeasure(initVals.additionalMeasure);
        }

    }, []);

    useEffect(() => {
        if (newScale.lineage === 'none') {
            const defaultOptions = Object.keys(appConfig.measures).map(key => key).filter(key => appConfig.measures[key].colorBy === true);
            setOptions(defaultOptions);
        } else {
            const url = `${config.serverLink}/api/admin/getMeasuresForLineage?lineage=${newScale.lineage}`;

            fetchAxios(url).then(function (response) {
                console.log(response.data.measures)
                setOptions(response.data.measures);
            }).catch(function (error) {
                console.log(error)
            })
        }
    }, [newScale.lineage]);

    const handleChangeAdditionalAntigenic = (e, v) => {
        const newAdditionalmeasure = v;
        setAdditionalMeasure(newAdditionalmeasure);
        const scaleId = isAntigenic ? `${measure}.${newAdditionalmeasure}.${idString}` : `${measure}.${idString}`;
        handleScaleIdChange(scaleId);
    }

    const handleChangeMeasure = (e, v) => {
        const newMeasure = v;
        setMeasure(newMeasure);
        const scaleId = newMeasure === 'antigenic' ? `${newMeasure}.${additionalMeasure}.${idString}` : `${newMeasure}.${idString}`;
        handleScaleIdChange(scaleId);
        setIsAntigenic(newMeasure === 'antigenic');
    }

    const handleChangeId = (e) => {
        const id = e.target.value;
        setIdString(id);
        const scaleId = isAntigenic ? `${measure}.${additionalMeasure}.${id}` : `${measure}.${id}`;
        handleScaleIdChange(scaleId);
    }

    return (
      <Grid container>
        <Grid item xs={isAntigenic ? 4 : 6} >
            <Autocomplete
                freeSolo
                id="Scale type"
                disableClearable
                required
                options={options}
                value={measure}
                getOptionLabel={option => option}
                onInputChange={handleChangeMeasure}
                renderInput={(params) => (
                <StyledTextField
                    {...params}
                    required
                    className={classes.measurePicker}
                    label="Scale type"
                    error={error.scaleType.status}
                    helperText={error.scaleType.message}
                />
                )}
            />
        </Grid>
        { isAntigenic &&
            <Grid item xs={isAntigenic ? 4 : 6} style={{paddingLeft: '15px'}}>
                <Autocomplete
                    freeSolo
                    id="Scale type"
                    disableClearable
                    options={antigenicOptions}
                    getOptionLabel={option => option}
                    onInputChange={handleChangeAdditionalAntigenic}
                    value={additionalMeasure}
                    renderInput={(params) => (
                    <StyledTextField
                        {...params}
                        required
                        className={classes.measurePicker}
                        label="Scale type"
                        error={error.antigenicScaleType.status}
                        helperText={error.antigenicScaleType.message}
                    />
                    )}
                />
            </Grid>
        }
        <Grid item xs={isAntigenic ? 4 : 6} style={{textAlign: 'end'}}>
            <StyledTextField
                id="name"
                type="text"
                label="Scale name"
                required
                autoComplete='off'
                className={classes.measurePicker}
                value={idString || ''}
                onChange={handleChangeId}
                error={error.id.status}
                helperText={error.id.message}
            />
        </Grid>
      </Grid>
    );
};

export default ScaleIdConfigurator;
