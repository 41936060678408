// Action Types
import {
    FETCH_SUBSET_TREE_REQUEST,
    FETCH_SUBSET_TREE_SUCCESS,
    FETCH_SUBSET_TREE_ERROR,
    FETCH_TREE_FREQS_REQUEST,
    FETCH_TREE_FREQS_SUCCESS,
    FETCH_TREE_FREQS_ERROR,
    FETCH_CUSTOM_TREE_ATTRS_REQUEST,
    FETCH_CUSTOM_TREE_ATTRS_SUCCESS,
    FETCH_CUSTOM_TREE_ATTRS_ERROR,
    FETCH_TCELL_ANTIGENICITY_OPTIONS_REQUEST,
    FETCH_TCELL_ANTIGENICITY_OPTIONS_SUCCESS,
    FETCH_TCELL_ANTIGENICITY_OPTIONS_ERROR,
    FETCH_TCELL_ANTIGENICITY_SCORES_REQUEST,
    FETCH_TCELL_ANTIGENICITY_SCORES_SUCCESS,
    FETCH_TCELL_ANTIGENICITY_SCORES_ERROR,
    FETCH_VISIBLE_NODES_REQUEST,
    FETCH_VISIBLE_NODES_SUCCESS,
    FETCH_VISIBLE_NODES_ERROR,
    FETCH_SELECTED_STRAIN_REQUEST,
    FETCH_SELECTED_STRAIN_SUCCESS,
    FETCH_SELECTED_STRAIN_ERROR,
    SET_STRAIN_SEARCH_STATUS,
    FETCH_VACCINE_CANDIDATES_REQUEST,
    FETCH_VACCINE_CANDIDATES_SUCCESS,
    FETCH_VACCINE_CANDIDATES_ERROR,
    FETCH_VP_METHODS_REQUEST,
    FETCH_VP_METHODS_SUCCESS,
    FETCH_VP_METHODS_ERROR,
    FETCH_MUTATION_CLASSES_REQUEST,
    FETCH_MUTATION_CLASSES_SUCCESS,
    FETCH_MUTATION_CLASSES_ERROR,
    FETCH_VP_VALUES_REQUEST,
    FETCH_VP_VALUES_SUCCESS,
    FETCH_VP_VALUES_ERROR,
    RESET_VP_VALUES,
    FETCH_STRAINS_LIST_REQUEST,
    FETCH_STRAINS_LIST_SUCCESS,
    FETCH_STRAINS_LIST_ERROR,
    SET_STRAINS_LIST,
    FETCH_RECALCULATED_TREE_REQUEST,
    FETCH_RECALCULATED_TREE_SUCCESS,
    FETCH_RECALCULATED_TREE_ERROR,
    SET_MUTATION_CLASSES,
    FETCH_REFERENCE_STRAINS_REQUEST,
    FETCH_REFERENCE_STRAINS_SUCCESS,
    FETCH_REFERENCE_STRAINS_ERROR,
    FETCH_NODE_REQUEST,
    FETCH_NODE_SUCCESS,
    FETCH_NODE_ERROR,
    FETCH_ANTIGENIC_REFERENCE_STRAIN_REQUEST,
    FETCH_ANTIGENIC_REFERENCE_STRAIN_SUCCESS,
    FETCH_ANTIGENIC_REFERENCE_STRAIN_ERROR,
    CLEAR_REFERENCE_STRAINS_NODES
    // FETCH_COLOR_BY_RULE_STRAINS_SUCCESS,
    // FETCH_COLOR_BY_RULE_STRAINS_ERROR,
    // FETCH_COLOR_BY_RULE_STRAINS_REQUEST
} from './actionTypes';
// Base Actions
import { received } from '../baseActions';



const fetchVisibleNodes = (params) => received(FETCH_VISIBLE_NODES_REQUEST, params);
const fetchVisibleNodesSuccess = (response) => received(FETCH_VISIBLE_NODES_SUCCESS, response);
const fetchVisibleNodesError = (error) => received(FETCH_VISIBLE_NODES_ERROR, error);

const fetchRecalculatedTree = (payload) => received(FETCH_RECALCULATED_TREE_REQUEST, payload);
const fetchRecalculatedTreeSuccess = (payload) => received(FETCH_RECALCULATED_TREE_SUCCESS, payload);
const fetchRecalculatedTreeError = (payload) => received(FETCH_RECALCULATED_TREE_ERROR, payload);

const fetchSubsetTree = (params) => received(FETCH_SUBSET_TREE_REQUEST, params);
const fetchSubsetTreeSuccess = (response) => received(FETCH_SUBSET_TREE_SUCCESS, response);
const fetchSubsetTreeError = (error) => received(FETCH_SUBSET_TREE_ERROR, error);

const fetchTreeFreqs = (payload) => received(FETCH_TREE_FREQS_REQUEST, payload);
const fetchTreeFreqsSuccess = (response) => received(FETCH_TREE_FREQS_SUCCESS, response);
const fetchTreeFreqsError = (error) => received(FETCH_TREE_FREQS_ERROR, error);

const fetchCustomTreeAttrs = (payload) => received(FETCH_CUSTOM_TREE_ATTRS_REQUEST, payload);
const fetchCustomTreeAttrsSuccess = (response) => received(FETCH_CUSTOM_TREE_ATTRS_SUCCESS, response);
const fetchCustomTreeAttrsError = (error) => received(FETCH_CUSTOM_TREE_ATTRS_ERROR, error);

const fetchTCellAntigenicityOptions = (payload) => received(FETCH_TCELL_ANTIGENICITY_OPTIONS_REQUEST, payload);
const fetchTCellAntigenicityOptionsSuccess = (response) => received(FETCH_TCELL_ANTIGENICITY_OPTIONS_SUCCESS, response);
const fetchTCellAntigenicityOptionsError = (error) => received(FETCH_TCELL_ANTIGENICITY_OPTIONS_ERROR, error);

const fetchTCellAntigenicityScores = (payload) => received(FETCH_TCELL_ANTIGENICITY_SCORES_REQUEST, payload);
const fetchTCellAntigenicityScoresSuccess = (response) => received(FETCH_TCELL_ANTIGENICITY_SCORES_SUCCESS, response);
const fetchTCellAntigenicityScoresError = (error) => received(FETCH_TCELL_ANTIGENICITY_SCORES_ERROR, error);

const fetchSelectedStrain = (payload) => received(FETCH_SELECTED_STRAIN_REQUEST, payload);
const fetchSelectedStrainSuccess = (response) => received(FETCH_SELECTED_STRAIN_SUCCESS, response);
const fetchSelectedStrainError = (error) => received(FETCH_SELECTED_STRAIN_ERROR, error);

const fetchAntigenicReferenceStrain = (payload) => received(FETCH_ANTIGENIC_REFERENCE_STRAIN_REQUEST, payload);
const fetchAntigenicReferenceStrainSuccess = (response) => received(FETCH_ANTIGENIC_REFERENCE_STRAIN_SUCCESS, response);
const fetchAntigenicReferenceStrainError = (error) => received(FETCH_ANTIGENIC_REFERENCE_STRAIN_ERROR, error);

const fetchStrainsList = (payload) => received(FETCH_STRAINS_LIST_REQUEST, payload);
const fetchStrainsListSuccess = (response) => received(FETCH_STRAINS_LIST_SUCCESS, response);
const fetchStrainsListError = (error) => received(FETCH_STRAINS_LIST_ERROR, error);

const fetchVaccineCandidates = (payload) => received(FETCH_VACCINE_CANDIDATES_REQUEST, payload);
const fetchVaccineCandidatesSuccess = (response) => received(FETCH_VACCINE_CANDIDATES_SUCCESS, response);
const fetchVaccineCandidatesError = (error) => received(FETCH_VACCINE_CANDIDATES_ERROR, error);

const fetchVpMethods = (payload) => received(FETCH_VP_METHODS_REQUEST, payload);
const fetchVpMethodsSuccess = (payload) => received(FETCH_VP_METHODS_SUCCESS, payload);
const fetchVpMethodsError = (payload) => received(FETCH_VP_METHODS_ERROR, payload);

const fetchMutationClasses = (payload) => received(FETCH_MUTATION_CLASSES_REQUEST, payload);
const fetchMutationClassesSuccess = (payload) => received(FETCH_MUTATION_CLASSES_SUCCESS, payload);
const fetchMutationClassesError = (payload) => received(FETCH_MUTATION_CLASSES_ERROR, payload);

const setMutationClasses = (payload) => received(SET_MUTATION_CLASSES, payload);

const fetchVpValues = (payload) => received(FETCH_VP_VALUES_REQUEST, payload);
const fetchVpValuesSuccess = (payload) => received(FETCH_VP_VALUES_SUCCESS, payload);
const fetchVpValuesError = (payload) => received(FETCH_VP_VALUES_ERROR, payload);
const resetVpValues = (payload) => received(RESET_VP_VALUES, payload);

const fetchNode = (payload) => received(FETCH_NODE_REQUEST, payload);
const fetchNodeSuccess = (payload) => received(FETCH_NODE_SUCCESS, payload);
const fetchNodeError = (payload) => received(FETCH_NODE_ERROR, payload);

const fetchReferenceStrains = ( payload ) => received(FETCH_REFERENCE_STRAINS_REQUEST, payload);
const fetchReferenceStrainsSuccess = ( payload ) => received(FETCH_REFERENCE_STRAINS_SUCCESS, payload);
const fetchReferenceStrainsError = ( payload ) => received(FETCH_REFERENCE_STRAINS_ERROR, payload);
const clearReferenceStrains = (payload) => received(CLEAR_REFERENCE_STRAINS_NODES, payload);

// export const fetchColorByRuleStrains = (payload) => received(FETCH_COLOR_BY_RULE_STRAINS_REQUEST, payload);
// export const fetchColorByRuleStrainsSuccess = (payload) => received(FETCH_COLOR_BY_RULE_STRAINS_SUCCESS, payload);
// export const fetchColorByRuleStrainsError = (payload) => received(FETCH_COLOR_BY_RULE_STRAINS_ERROR, payload);

const setStrainSearchStatus = (payload) => received(SET_STRAIN_SEARCH_STATUS, payload);
const setStrainsList = (payload) => received(SET_STRAINS_LIST, payload);

export { /* fetchTreeData, */
    fetchVisibleNodes,
    fetchVisibleNodesSuccess,
    fetchVisibleNodesError,
    fetchRecalculatedTree,
    fetchRecalculatedTreeSuccess,
    fetchRecalculatedTreeError,
    fetchSubsetTree,
    fetchSubsetTreeSuccess,
    fetchSubsetTreeError,
    fetchCustomTreeAttrs,
    fetchCustomTreeAttrsSuccess,
    fetchCustomTreeAttrsError,
    fetchTCellAntigenicityOptions,
    fetchTCellAntigenicityOptionsSuccess,
    fetchTCellAntigenicityOptionsError,
    fetchTCellAntigenicityScores,
    fetchTCellAntigenicityScoresSuccess,
    fetchTCellAntigenicityScoresError,
    fetchSelectedStrain,
    fetchSelectedStrainSuccess,
    fetchSelectedStrainError,
    fetchAntigenicReferenceStrain,
    fetchAntigenicReferenceStrainSuccess,
    fetchAntigenicReferenceStrainError,
    fetchTreeFreqs,
    fetchTreeFreqsSuccess,
    fetchTreeFreqsError,
    fetchVaccineCandidates,
    fetchVaccineCandidatesSuccess,
    fetchVaccineCandidatesError,
    fetchVpMethods,
    fetchVpMethodsSuccess,
    fetchVpMethodsError,
    fetchMutationClasses,
    fetchMutationClassesSuccess,
    fetchMutationClassesError,
    setMutationClasses,
    fetchVpValues,
    fetchVpValuesSuccess,
    fetchVpValuesError,
    resetVpValues,
    setStrainSearchStatus,
    fetchStrainsList,
    fetchStrainsListSuccess,
    fetchStrainsListError,
    fetchReferenceStrains,
    fetchReferenceStrainsSuccess,
    fetchReferenceStrainsError,
    clearReferenceStrains,
    setStrainsList,
    fetchNode,
    fetchNodeSuccess,
    fetchNodeError
};
