import { createSelector } from 'reselect';
import { getTcellAntigenicityStatus, tcellAntigenicityScoresSelector, getStrainsLists } from './treeDataSelector';
import { selectedBinsSelector } from './frequenciesSelector';
import { emptyObject } from '../../functions/functions';
import appConfig from '../../config/appConfig';
import config from '../../config/envConfig';

const getMeasures = ({ metadata }) => metadata.measures;
const getMeasure = ({ metadata }, measureName) => metadata.measures[measureName];
const getMetaCustomMeasures = (state) => state.metadata.customMeasures;
const getLineages = (state) => state.lineages.lineages;
const getUserLineages = (state) => ((state.user && state.user.status === 'loaded') ? state.user.lineages : null);
const getUserDefaultLineage = (state) => ((state.user && state.user.status === 'loaded') ? state.user.defaultLineage : null);
const getLineage = (state) => state.parameters.lineage;
const getMarkBranches = ({ parameters }) => ((parameters.markBranches instanceof String) ? [] : parameters.markBranches);
const getColorByOptionsFilter = ({ metadata }) => metadata.colorByOptions;
const getVaccineCandidates = ({ metadata }) => metadata.vaccineCandidates;
const getGeoMapColorByOptionsFilter = ({ metadata }) => metadata.mapColorsOptions;
const getColorBy = ({ parameters }) => parameters.colorBy;
const getParameters = ({ parameters }) => parameters;
const getMeasureScalesDomains = ({ metadata }) => metadata.measureScalesDomains;
const getScales = ({ metadata }) => metadata.scales;
const getMutationClasses = ({ metadata }) => metadata.mutationClasses;
const getMutationsGroup = ({ parameters }) => parameters.mutationsGroup;

const getMeasuresFilter = createSelector(getColorByOptionsFilter, colorByOptionsFilter => ({ ...colorByOptionsFilter, order: true, AADivergence: true, NLDivergence: true }));
const getNestedValue = (obj, parameters, defaultVal) => {
    const key = Object.keys(obj || {})[0];
    if (!obj || emptyObject(key)) return defaultVal;
    return obj[key][parameters[key]] || defaultVal;
}


const getShowRuleForColorBy = createSelector([getMeasures, getColorBy, getParameters], (measures, colorBy, parameters) => {
    const selectedMeasure = measures[colorBy] || {};
    const showRule = selectedMeasure && typeof selectedMeasure.show === 'string'
        ? selectedMeasure.show
        : getNestedValue(selectedMeasure.show, parameters, appConfig.default.showRule)

    return showRule;
})



export const getShowRulesForMeasures = createSelector([getMeasures, getParameters], (measures, parameters) => {
    const showRules = Object.keys(measures).reduce((acc, colorBy) => {
        const selectedMeasure = measures[colorBy] || {};
        const showRule = selectedMeasure && typeof selectedMeasure.show === 'string'
            ? selectedMeasure.show
            : getNestedValue(selectedMeasure.show, parameters, appConfig.default.showRule);
        acc[colorBy] = showRule;
        return acc;
    }, {})
    return showRules;
})


export const getIgnoreStrainCutOffDateForColorBy = createSelector([getMeasures, getColorBy, getParameters], (measures, colorBy, parameters) => {
    const showRule = (measures[colorBy] && typeof measures[colorBy]?.ignoreStrainCutOffDate === 'string')
        ? measures[colorBy].ignoreStrainCutOffDate
        : getNestedValue((measures[colorBy] || {}).ignoreStrainCutOffDate, parameters, appConfig.default.ignoreStrainCutOffDate)

    return showRule;
})


const getCustomMeasures = createSelector(getMeasures, measures => Object.keys(measures).reduce((acc, k) => {
    if (measures[k].custom) acc[k] = measures[k]; return acc;
}, {}));

const getCustomTreeAttrsOptions = createSelector(getMetaCustomMeasures, ({ branch, node }) => (
    {
        branch: Object.values(branch),
        node: Object.values(node)
    }));

const getCurrentColorByCustomTreeAttr = createSelector([getMetaCustomMeasures, getColorBy], ({ node }, colorBy) => node && node[colorBy] ? [node[colorBy]] : []);


const markBranchesSelector = createSelector([getMarkBranches, getMetaCustomMeasures],
    (markBranches, { branch }) => (markBranches && branch ? Object.keys(markBranches).filter(key => markBranches[key]).map(key => ({ key, label: branch[key].label })) : [])
);

const markBranchesArraySelector = createSelector(getMarkBranches, markBranches => Object.keys(markBranches).filter(k => markBranches[k]));

const getCustomTreeBranchOptions = createSelector(getMetaCustomMeasures, ({ branch }) => (branch ? Object.values(branch) : []));

const getColorScaleRange = ({ metadata, parameters }) => metadata.measures[parameters.colorBy].color;

const getColorByMeasure = ({ metadata, parameters }) => metadata.measures[parameters.colorBy];


const getUserFilteredLineages = createSelector([getLineages, getUserLineages], (lineages, userLineages) => {
    if (!userLineages || userLineages.length === 0) return lineages.map(name => ({ id: name, label: name }));
    const lineagesDict = lineages.reduce((acc, lineage) => { acc[lineage] = true; return acc }, {});

    return userLineages.filter(l => lineagesDict[l]).map(name => ({ id: name, label: name }));
});

export const lineagesSelector = createSelector([getLineages], (lineages,) => {
    return lineages.map(name => ({ id: name, label: name }));
});

// export const getIntroLineages = createSelector(getLineages, lineages => {
    
//     const introPatogenes = ([...appConfig.introPatogenes, ...config.additionalIntroPatogenes]).reduce((acc, l) => { acc.add(l); return acc;}, new Set());
//     const introBuilds = ([...appConfig.introBuilds, ...config.additionalIntroBuilds]).reduce((acc, build) => { acc.add(build); return acc }, new Set());

//     //console.log('introPatogenes', introPatogenes, introBuilds )
//     const a = lineages.filter(l => {
//         const pathogen = l.split('_')[0];
//         const build = l.split('_').slice(1).join('_');
//         const good = introPatogenes.has(pathogen) && introBuilds.has(build);
       
//         return good; // && lineagesDict[l]
//     }).map(name => ({ id: name, label: name }));

//     return a;
// })




// const getGeoMapFilteredLineages = createSelector([getLineages, getUserLineages], (lineages, userLineages) => {
//     if (!userLineages) return lineages;
//     return userLineages;
// });

const getUserLineage = createSelector([getUserLineages, getUserDefaultLineage, getLineage], (userLineages, defaultLineage, lineage) => {
    // console.log(userLineages);
    // console.log(defaultLineage);
    // console.log(lineage);
    if (!userLineages) return lineage;
    // return userLineages
    return defaultLineage;
});

const getSelectedMutationGroups = createSelector([getMutationClasses, getMutationsGroup],
    (mutationsClasses, mutationsGroup) => {
        if (!mutationsClasses || !mutationsGroup || !mutationsClasses[mutationsGroup]) return null;
        return Object.entries(mutationsClasses[mutationsGroup]).map(([key, value]) => ({
            key, ...value
        }));
    });

const getMutationsGroupsForDivergence = createSelector(getSelectedMutationGroups, mutationsGroups => {
    const res = (mutationsGroups || []).map(({ key, label }) => ({
        key: `${key}_divergence`,
        label: `${label} divergence`,
        custom: false,
        discrete: false,
        discreteScale: false,
        frequenciesChart: false,
        numeric: true,
        scale: `${key}_divergence.default`,
        xScale: true,
        yScale: true
    }));
    // console.log('[getMutationsGroupsForDivergence]', res);
    return res;
})

const getSortedMeasures = createSelector([getMeasures, getMutationsGroupsForDivergence], (measures, divergenceMeasures) => {
    const sVal = v => (v.key === 'none' ? -1 : v.custom ? 1 : 0);

    const sortedMeasures = [...Object.keys(measures)
        .map(key => ({
            key,
            ...measures[key],
            discrete: measures[key].discrete === true || !measures[key].scale, //domain === undefined,
            numeric: measures[key].numeric === true,
            frequenciesChart: measures[key].frequenciesChart === true
        })), ...divergenceMeasures]
        .sort((c1, c2) => (sVal(c1) - sVal(c2) || c1.label.localeCompare(c2.label)));
    return sortedMeasures;
})


export const measuresSelector = createSelector(getSortedMeasures, sortedMeasures => sortedMeasures.reduce((acc, m) => { acc[m.key] = m; return acc; }, {}));

const getColorOptions = createSelector([getSortedMeasures, getColorByOptionsFilter],
    (sortedMeasures, colorByOptionsFilter) => {
        const colorOptions = sortedMeasures.filter(({ key, custom, branch }) => (!colorByOptionsFilter || colorByOptionsFilter[key] || (custom && !branch) || key === 'none'));
        const colorOptionsMap = colorOptions.reduce((_colorOptionsMap, c) => { _colorOptionsMap[c.key] = c; return _colorOptionsMap; }, {});
        // console.log(colorOptions);
        return { colorOptions, colorOptionsMap };
    });

const getGeoMapColorsOptions = createSelector([getSortedMeasures, getGeoMapColorByOptionsFilter, getColorOptions],
    (sortedMeasures, mapColorByOptionsFilter, { colorOptionsMap }) => {
        const colorOptions = sortedMeasures.filter(({ key }) => mapColorByOptionsFilter[key] && colorOptionsMap[key]);
        const colorOptionsMapResult = colorOptions.reduce((_colorOptionsMap, c) => {
            _colorOptionsMap[c.key] = c;
            return _colorOptionsMap;
        }, {});
        return { colorOptions, colorOptionsMap: colorOptionsMapResult };
    });

const getFrequencyCategories = createSelector([getSortedMeasures, getMeasuresFilter],
    (sortedMeasures, measuresFilter) => {
        // console.log(sortedMeasures);
        const freqCategoriesOptions = sortedMeasures
            .filter(({ key, custom, frequenciesChart, discreteScale }) => discreteScale && (!measuresFilter || custom || (measuresFilter[key] && frequenciesChart)));

        // console.log(freqCategoriesOptions);

        const freqCategoriesMap = freqCategoriesOptions.reduce((_freqCategoriesMap, c) => { _freqCategoriesMap[c.key] = c; return _freqCategoriesMap; }, {});
        return { freqCategoriesOptions, freqCategoriesMap };
    });



const getSelectedMutationGroupsDict = createSelector([getMutationClasses, getMutationsGroup],
    (mutationClasses, mutationsGroup) => mutationClasses && mutationsGroup && mutationClasses[mutationsGroup] || {});

const getMutationsGroupsOptions = createSelector(getMutationClasses, mutationClasses => (['', ...Object.keys(mutationClasses || {})]))

const getTreeScaleYOptions = createSelector(getSortedMeasures,
    (sortedMeasures) => {
        const treeScaleYOptions = sortedMeasures.filter(k => k.yScale);
        return treeScaleYOptions;
    });

const getTreeScaleXOptions = createSelector(getSortedMeasures,
    (sortedMeasures) => {
        const treeScaleXOptions = sortedMeasures.filter(k => k.xScale);
        return treeScaleXOptions;
    });



const getColorBins = (colorByMeasure = 'freqCategory', modelType) => (state) => {
    // console.log('[getColorBins] 1.', colorByMeasure, modelType);

    const { regions } = state.parameters;
    const _colorBy = modelType === 'antigenic' ? 'antigenic' : state.parameters[colorByMeasure];

    if (_colorBy === 'loc') {
        return regions;
    }
    if (_colorBy === 'tcellAntigenicity') {
        const tcellStatus = getTcellAntigenicityStatus(state);
        const tcellAntigenicityScoresBins = tcellAntigenicityScoresSelector(state);
        // console.log(`[getCustomTreeBranchColorBins]: tcellAntigecityScoresStatus = ${tcellAntigenicityScoresStatus}`);
        return (tcellStatus === 'loaded') ? tcellAntigenicityScoresBins : null;
    }
    // if (freqCategory === 'genotype') {
    //     return genotypeLegendBins(state);  //(state.genotype.genotypeLegend || []).reduce((acc, mut) => { acc[mut.key] = mut; return acc }, {});
    // }


    //console.log('[getColorBins] 3 freqCategory =', _colorBy);
    // console.log('measures = ',state.metadata.measureBins);

    const scaleName = state.parameters.colorScales[_colorBy] || state.metadata.measures[_colorBy]?.scale || `${_colorBy.default}.default`;
    //console.log('[getColorBins]', _colorBy, scaleName, state.metadata.measureBins?.[_colorBy]?.[scaleName])
    //const status = state.metadata.measureDomainStatuses?.[_colorBy];
    const bins = state.metadata.measureBins?.[_colorBy]?.[scaleName];
    // console.log('bins = ',bins)
    return bins; //customTreeAttrsBins[freqCategory];
};



const _getSelectedBins = (state) => {
    if (state.parameters.freqCategory === 'tcellAntigenicity') {
        const { gene, hla } = state.parameters;
        const bins = state.customTreeData.tcellAntigenicityScoresBins
            ? state.customTreeData.tcellAntigenicityScoresBins[`${gene}_${hla}`]
            : null;
        if (bins) return bins;
        // return customTreeData.tcellAntigenicityScoresBins[]
    }

    return selectedBinsSelector(state);
}

const getSelectedBins = createSelector(_getSelectedBins, selectedBins => Object.keys(selectedBins).sort());


const getLabeledVaccineCandidates = createSelector(getVaccineCandidates, vaccineCandidates => vaccineCandidates.map(v => ({ ...v, vaccine: true })).sort((s1, s2) => s1.n.localeCompare(s2.n)));

const getLabeledStrainsListWithVaccineCandidates = createSelector([getVaccineCandidates, getStrainsLists], (vaccineCandidates, strainsLists) => {
    const vcDict = (vaccineCandidates || []).reduce((acc, v) => { acc[v.id] = 1; return acc; }, {});

    const list = Object.keys(strainsLists).reduce((_strainsLists, searchId) => {
        _strainsLists[searchId] = (strainsLists[searchId] || []).map(s => ({ ...s, vaccine: !emptyObject(vcDict[s.id]) })).sort((s1, s2) => s1.n.localeCompare(s2.n))
        return _strainsLists;
    }, {});

    return list;
});


const discreteMeasureScalesDomainsSelector = createSelector([getMeasureScalesDomains, getScales], (measureScalesDomains, scales) => {
    const res = Object.keys(measureScalesDomains).reduce((acc, m) => {
        const measureScales = measureScalesDomains[m];
        acc[m] = Object.keys(measureScales).reduce((sacc, s) => {
            if (scales[s]?.discrete) sacc[s] = measureScales[s];
            return sacc;
        }, {});
        return acc;

    }, {});
    // console.log(res);
    return res;
});

const getAntigenicModelComponents = ({ metadata }) => metadata.modelsConfig.antigenicModelIdComponents;

export const getAntigenicSegmentsFields = createSelector(getAntigenicModelComponents, antigenicModelComponents => {
    const antigenicSegmentsLabels = [
        { name: 'assay', label: 'Assay' },
        { name: 'collaboratingCenter', label: 'Collaborating center' },
        { name: 'strainPropagation', label: 'Strain propagation' },
        { name: 'refStrainPropagation', label: 'Ref. strain propagation' }
    ];
    const res = Object.keys(antigenicModelComponents).reduce((acc, modelVar) => {
        const antigenicSegmentsNames = antigenicModelComponents[modelVar];
        acc[modelVar] = antigenicSegmentsLabels.filter(({ name }) => antigenicSegmentsNames.includes(name));
        return acc;
    }, {});
    //console.log(res);
    return res;
});


const getAntigenicModelTypes = ({ metadata }) => metadata?.modelsConfig.antigenicModelTypes;
const getAntigenicSegmentsNamesForFitness = ({ metadata }) => metadata?.modelsConfig.antigenicModelIdComponents['fitness'];

const antigenicModelTypesSelector = createSelector(getAntigenicModelTypes, antigenicModelTypes => antigenicModelTypes || []);

const antigenicSegmentsNamesForFitnessSelector = createSelector(getAntigenicSegmentsNamesForFitness, antigenicSegmentsNames => antigenicSegmentsNames || []);



export {
    getMeasures,
    getMeasure,
    getCustomTreeAttrsOptions,
    getUserFilteredLineages,
    getUserLineage,
    // getTreeBranchAttrs,
    getCustomTreeBranchOptions,
    markBranchesSelector,
    // getNodeCustomTreeAttrs,
    getColorOptions,
    getGeoMapColorsOptions,
    getFrequencyCategories,
    getColorByMeasure,
    getMutationClasses,
    getColorScaleRange,
    getSelectedBins,
    markBranchesArraySelector,
    //getSortedBins,
    getColorBins,
    getCustomMeasures,
    //getContinousMeasures,
    getTreeScaleXOptions,
    getTreeScaleYOptions,
    getCurrentColorByCustomTreeAttr,
    getShowRuleForColorBy,
    //getScaleNameForColorBy,
    //getScaleNameForFreqCategory,
    //getScaleNameForGeoMapColorBy,
    getLabeledVaccineCandidates,
    getLabeledStrainsListWithVaccineCandidates,
    getMutationsGroupsOptions,
    getMutationsGroup,
    getVaccineCandidates,
    discreteMeasureScalesDomainsSelector,
    getSelectedMutationGroupsDict,
    getSelectedMutationGroups,
    antigenicModelTypesSelector,
    antigenicSegmentsNamesForFitnessSelector
};
