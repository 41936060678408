import { useState } from 'react';
import * as React from 'react';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import {
    Menu,
    MenuItem,
    ListItemIcon,
    Divider,
    IconButton,
    Tooltip,
    Button
} from '@mui/material';
import {
    Help,
    Group,
    Person,
    Logout,
    Settings,
    Info
} from '@mui/icons-material';
import { styles, UserIcon, menuStyles } from './styles';
import PreferencesDialog from './dialogs/Preferences';
import config from '../../config/envConfig';

export default function UserMenu({signOut, history, adminRights, name}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openPreferencesDialog, setOpenPreferencesDialog] = useState(false);
  const open = Boolean(anchorEl);
  const classes = styles();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenPreferencesDialog = () => {
    setOpenPreferencesDialog(true);
  }

  const handleClosePreferencesDialog = () => {
    setOpenPreferencesDialog(false);
  }

  return (
    <React.Fragment>
        <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
            <Tooltip title="Account settings">
                <IconButton onClick={handleClick} size="small" sx={{ ml: 2 }}>
                    <UserIcon/>
                </IconButton>
            </Tooltip>
        </Box>
        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
                elevation: 0,
                sx: menuStyles
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            <MenuItem className={classes.tittle}>
                Hello, {name[0].toUpperCase() + name.substring(1)}!
            </MenuItem>
            <Divider className={classes.dividerUp}/>
            { adminRights && <div>
                <Link to={`${config.frontendPrefix}/settings`} className={classes.link}>
                    <MenuItem className={classes.menuItem}>
                        <ListItemIcon className={classes.icon}>
                            <Settings fontSize="small" />
                        </ListItemIcon>
                        Settings
                    </MenuItem>
                </Link>
                <Link to={`${config.frontendPrefix}/manage`} className={classes.link}>
                    <MenuItem className={classes.menuItem}>
                        <ListItemIcon className={classes.icon}>
                            <Group fontSize="small" />
                        </ListItemIcon>
                        Manage users & roles
                    </MenuItem>
                </Link>
                <Divider className={classes.dividerDown}/>
            </div>}
            <MenuItem onClick={handleOpenPreferencesDialog} className={classes.menuItem}>
                <ListItemIcon className={classes.icon}>
                    <Person fontSize="small" />
                </ListItemIcon>
                Preferences
            </MenuItem>
            <MenuItem className={classes.menuItem}>
                <ListItemIcon className={classes.icon}>
                    <Help fontSize="small" />
                </ListItemIcon>
                Help {'&'} support
            </MenuItem>
            <MenuItem className={classes.menuItem}>
                <ListItemIcon className={classes.icon}>
                    <Info fontSize="small" />
                </ListItemIcon>
                About
            </MenuItem >
            <Button className={classes.button} onClick={() => { signOut(history);}}>
                Sign out
            </Button>
        </Menu>
        <PreferencesDialog handleCloseDialog={handleClosePreferencesDialog} openDialog={openPreferencesDialog}/>
    </React.Fragment>
  );
};

