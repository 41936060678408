import React, { useState } from 'react';
import { Button } from '@mui/material';
import { styles } from '../styles';
import CacheDialog from './CacheDialog';
import config from '../../../../config/envConfig';

const initState = {
    open: false,
    type: ''
};

const ClearCache = () => {
    const [openDialog, setOpenDialog] = useState(initState);
    const classes = styles();

    const handleClick = () => {
        clearCache();
    };

    const handleCloseDialog = (type) => {
        setOpenDialog({open: false, type});
    };

    const clearCache = () => {
        const url = `${config.serverLink}/api/admin/clearCache`;
        fetch(url, { headers: { 'Secret': process.env.REACT_APP_FRONTEND_SECRET_HEADER } })
            .then(response => {
                response.status === 200 ?
                    setOpenDialog({open: true, type: 'success'}) :
                    setOpenDialog({open: true, type: 'fail'});
            });
    };

    return (
        <>
            <Button className={classes.clearCache} onClick={handleClick}>
                Clear cache
            </Button>
            <CacheDialog handleCloseDialog={handleCloseDialog} openDialog={openDialog} />
        </>

    );
};

export default ClearCache;
