import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { makeStyles } from '@mui/styles';
import { selectNodeData } from '../../redux/actions/nodeActions';
import { selectZoomNode } from '../../redux/actions/zoomActions';
import {
    setRenderStatus, setSearchStrainMode, resetComponentsStatus,
    setStrainTreeDimensions
} from '../../redux/actions/renderActions';
import { getNodeWidth, getNodeHeight } from '../../functions/cssHelpers';
import { getRanges } from '../../redux/selectors/rangeDataSelector';
import { treeD3 } from './d3/TreeD3';
import { getStrainTreeStatus, getIsMobile } from '../../redux/selectors/statusSelector';
import {
    tcellAntigenicityScoresSelector,
    getTreeNodeAttrs,
    getTreeFromTreeArray,
    getLegendSelectedNodes,
    getMutationClassesData,
    getCladeBar,
    getLongestCladeLabel,
    getDisplayedStrainNames,
} from '../../redux/selectors/treeDataSelector';
import { getAntigenicData } from '../../redux/selectors/antigenicDataSelector';
import { markBranchesArraySelector } from '../../redux/selectors/metadataSelector';
import { getMetadataMeasuresWithScales } from '../../redux/selectors/rangeDataSelector';
import appConfig from '../../config/appConfig';
import { dateToDays, emptyObject } from '../../functions/functions';
import { styles } from './styles';
import { ZoomMenu } from './ZoomMenu';
import CladeBarLayer from './treeLayers/CladeBarLayer'
import AxesLayer from './treeLayers/AxesLayer';
import LinksLayer from './treeLayers/LinksLayer';
import InternalNodesLayer from './treeLayers/InternalNodesLayer';
import StrainsLayer from './treeLayers/StrainsLayer';
import MutationsLayer from './treeLayers/MutationsLayer';
import EpitopeMutationsGroupsLayer from './treeLayers/EpitopeMutationsGroupsLayer';
import BranchNodesLayer from './treeLayers/BranchNodesLayer';
import ReassortmentsLayer from './treeLayers/ReassortmentsLayer';
import VaccinesLayer from './treeLayers/VaccinesLayer';
import RefStrainsLayer from './treeLayers/RefStrainLayer';
import ReferenceStrainsLayer from './treeLayers/ReferenceStrainsLayer';
import HighlightedStrainsLayer from './treeLayers/HighlightedStrainsLayer';
import SelectedStrainsLayer from './treeLayers/SelectedStrainsLayer';
import LabelsLayer from './treeLayers/LabelsLayer';
import { measureTimer } from '../../functions/utils';
import { useDebouncedCallback, useIsMountedRef } from '../../functions/customHooks';
import { useEventListener } from 'usehooks-ts';
import ScrollContainer from 'react-indiana-drag-scroll';
import { LAYOUT } from '../../config/dictionaries';
import { render } from 'react-dom';
import { RENDER_STATUS } from '../../config/consts';

const useStyles = makeStyles(styles);

const TreeGraph = (props) => {
    const _element = useRef();
    const cWidth = useRef(0);
    const cHeight = useRef(0);
    const isMountedRef = useIsMountedRef();
    const classes = useStyles();
    const [initialized, setInitialized] = useState(false);
    const [nWidth, setNWidth] = useState(0);
    const [nHeight, setNHeight] = useState(0);

   
    const { loading, exportMode, lineage, editMode, hiddenMenu, isMobile, renderStatus, measuresLoaded,
        tree, ranges, width, height, showCladeBar, showCladeBarLabels, showCladeLabels, antigenicModel, 
        setStrainTreeDimensions, displayedStrainNames, showStrainNames, longestCladeLabel, cladeBarType
    } = props;


    const updateDimensions = () => {
        if (_element.current && !props.loading) {
            const mountNode = _element.current;
            const { parentNode } = mountNode;
            cWidth.current = getNodeWidth(parentNode);
            cHeight.current = getNodeHeight(parentNode);
            treeD3.resizeComponent(cWidth.current, cHeight.current - 5);
            //console.log('setStrainTreeDimensions => updateDimensions, renderStatus = ', renderStatus)
            setStrainTreeDimensions({ strainTreeWidth: treeD3.getTreeWidth(), strainTreeHeight: treeD3.height });
        }
    };

    const debouncedResize = useDebouncedCallback(() => {
        updateDimensions();
    }, 200);


    const clearSelectedNode = () => {
        if (!treeD3.selectedNode && !isMobile) props.selectNodeData()
    };


    if (!exportMode) {
        useEventListener('resize', debouncedResize);
        useEventListener('mouseleave', clearSelectedNode, _element);
    }//

    useEffect(() => {
        //console.log('[TreeGraph] useEffect componentDidMount')
        measureTimer.setLayerStartTime('TreeGraph');
        const mountNode = _element.current;
        const { parentNode } = mountNode;
        const nodeWidth = getNodeWidth(parentNode);

        cWidth.current = exportMode ? width - 250 : nodeWidth;
        cHeight.current = exportMode ? height - 50 : getNodeHeight(parentNode);

        treeD3.setMountNode(mountNode);
        treeD3.setHeight(cHeight.current - 5);
        treeD3.setWidth(cWidth.current);
        treeD3.setProps(props);

        treeD3.prepareGraphArea(true)
       
        return () => {
            setInitialized(false);
            setNWidth(0);
            setNHeight(0);
        }
    }, []);

    useEffect(() => {
        treeD3.setProps(props);
    });

    useEffect(() => {
        //treeD3.resetScales();
        treeD3.clearTree();
    }, [lineage]);

    useEffect(() => {
        if (loading) return;
        // console.log('hiddenMenu update Dimensions, loading', loading)
        updateDimensions();
    }, [hiddenMenu, cladeBarType]);

    useEffect(() => {
        if (!emptyObject(tree)) {
            treeD3.initLayout();
        }
    }, [tree, antigenicModel]); 
 


    useEffect(() => {
        // console.log('[TreeGraph], useEffect measuresLoaded', measuresLoaded); 
        setInitialized(measuresLoaded && isMountedRef.current);
        if (measuresLoaded) {
        //     // console.log('[TreeGraph] useEffect [measuresLoaded], renderStatus =',renderStatus)
            treeD3.initScales(); 
        //     console.log('setStrainTreeDimensions => measuresLoaded =', measuresLoaded, 'renderStatus =',renderStatus)
            setStrainTreeDimensions({ strainTreeWidth: treeD3.getTreeWidth(), strainTreeHeight: treeD3.height });
        }
    }, [measuresLoaded]);

    
      useEffect(() => {
         if (measuresLoaded && (renderStatus === RENDER_STATUS.NONE || renderStatus === RENDER_STATUS.RERENDER_NEEDED)) {
            treeD3.initScales();
            // console.log('setStrainTreeDimensions => renderStatus = ', renderStatus)
            setStrainTreeDimensions({ strainTreeWidth: treeD3.getTreeWidth(), strainTreeHeight: treeD3.height }); 
         }
     },[renderStatus]); 
   

    const updateAxes = (type, resize, isBraceOn) => {
        if (resize === 'plus') {
            if (nWidth == 9 || nHeight == 9) return;

            if (type === 'horizontal' || isBraceOn) {
                setNWidth(parseInt(nWidth) + 1);
                cWidth.current = cWidth.current + 500;
                treeD3.setWidth(cWidth.current);
            }
            if (type === 'vertical' || isBraceOn) {
                setNHeight(parseInt(nHeight) + 1);
                cHeight.current = cHeight.current + 5000;
                treeD3.setHeight(cHeight.current);
            }
        } else {
            if (type === 'horizontal' || isBraceOn) {
                if (nWidth == 0) return;
                setNWidth(parseInt(nWidth) - 1);
                cWidth.current = cWidth.current - 500;
                treeD3.setWidth(cWidth.current);
            }
            if (type === 'vertical' || isBraceOn) {
                if (nHeight == 0) return;
                setNHeight(parseInt(nHeight) - 1);
                cHeight.current = cHeight.current - 5000;
                treeD3.setHeight(cHeight.current);
            }
        }
        // console.log('updateAxes')
        treeD3.resizeComponent(cWidth.current, cHeight.current - 5);
       
        // console.log('setStrainTreeDimensions => updateAxes')
        setStrainTreeDimensions({ strainTreeWidth: treeD3.getTreeWidth(), strainTreeHeight: treeD3.height });
        
    };


    const handleChange = (e, type, isBraceOn) => {
        // console.log(`[TreeGraph.handleChange]`);
        if (e.target.value > 9) e.target.value = 9;

        if (type === 'vertical' || isBraceOn) {
            cHeight.current = cHeight.current - nHeight * 5000;
            treeD3.setHeight(cHeight.current);

            setNHeight(e.target.value.replace(/\D/g, ''), () => {
                if (nHeight >= 0) {
                    cHeight.current = cHeight.current + nHeight * 5000;
                    treeD3.setHeight(cHeight.current);
                    treeD3.resizeComponent(cWidth.current, cHeight.current - 5);
                }
            });
        }
        if (type === 'horizontal' || isBraceOn) {
            cWidth.current = cWidth.current - nWidth * 500;
            treeD3.setWidth(cWidth.current);

            setNWidth(e.target.value.replace(/\D/g, ''), () => {
                if (nWidth >= 0) {
                    cWidth.current = cWidth.current + nWidth * 500;
                    treeD3.setWidth(cWidth.current);
                    treeD3.resizeComponent(cWidth.current, cHeight.current - 5);
                }
            });
        }
        
    };

    const graphClass = !exportMode ?
        classes.graph : !editMode ?
            classes.graphExport : classes.graphEdit;

    // console.log('initialized', initialized);
    return (
        <div className={graphClass}>
            <div className={graphClass} id='tree_div'>
                {isMobile ?
                    <svg
                        className={`svg-bg ${exportMode ? classes.treeBorder : ''}`}
                        id="tree_svg"
                        ref={_element}
                        aria-busy={loading}
                        aria-describedby="load_progress"
                    >
                        {editMode &&
                            <defs>
                                <style>
                                    @import url(`https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;400&display=swap`);
                                </style>
                            </defs>
                        }
                        <g
                            id={treeD3.componentName}
                            className="graph"
                            transform={treeD3.getComponentTransform()}
                            clipPath={treeD3.getClipPath()}
                        >
                            <AxesLayer initialized={initialized} />
                            <LinksLayer initialized={initialized} />
                            <InternalNodesLayer initialized={initialized} />
                            <StrainsLayer initialized={initialized} />
                            <MutationsLayer initialized={initialized} />
                            <EpitopeMutationsGroupsLayer initialized={initialized} />
                            <BranchNodesLayer initialized={initialized} />
                            <ReassortmentsLayer initialized={initialized} />
                            <VaccinesLayer initialized={initialized} />
                            <RefStrainsLayer initialized={initialized} />
                            <HighlightedStrainsLayer initialized={initialized} />
                            <SelectedStrainsLayer initialized={initialized} />
                            <CladeBarLayer initialized={initialized} />
                            <LabelsLayer initialized={initialized} />
                        </g>
                    </svg>
                    :
                    <ScrollContainer hideScrollbars={false} className={graphClass}>
                        <svg
                            className={`svg-bg ${exportMode ? classes.treeBorder : classes.border} `}
                            id="tree_svg"
                            ref={_element}
                            aria-busy={loading}
                            aria-describedby="load_progress"
                        >
                            {editMode &&
                                <defs>
                                    <style>
                                        @import url(`https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;400&display=swap`);
                                    </style>
                                </defs>
                            }
                            <g
                                id={treeD3.componentName}
                                className="graph"
                                transform={treeD3.getComponentTransform()}
                                clipPath={treeD3.getClipPath()}
                            >
                                <AxesLayer initialized={initialized} />
                                <LinksLayer initialized={initialized} />
                                <InternalNodesLayer initialized={initialized} />
                                <StrainsLayer initialized={initialized} />
                                <MutationsLayer initialized={initialized} />
                                <EpitopeMutationsGroupsLayer initialized={initialized} />
                                <BranchNodesLayer initialized={initialized} />
                                <ReassortmentsLayer initialized={initialized} />
                                <VaccinesLayer initialized={initialized} />
                                <RefStrainsLayer initialized={initialized} />
                                <ReferenceStrainsLayer initialized={initialized} />
                                <HighlightedStrainsLayer initialized={initialized} />
                                <SelectedStrainsLayer initialized={initialized} />
                                <CladeBarLayer initialized={initialized} />
                                <LabelsLayer initialized={initialized} />
                            </g>
                        </svg>
                    </ScrollContainer>
                }
            </div>

            {!exportMode && (
                <ZoomMenu
                    handleChange={handleChange}
                    classes={classes}
                    updateAxes={updateAxes}
                    nHeight={nHeight}
                    nWidth={nWidth}
                    isMobile={isMobile}
                />
            )}
        </div>
    );
};

TreeGraph.propTypes = {
    modelId: PropTypes.string,
    colorBy: PropTypes.string,
    // layout: PropTypes.string,
    lineage: PropTypes.string,
    renderStatus: PropTypes.string,
    antigenicModel: PropTypes.object,//objectOrStringProp,
    refClade: PropTypes.number,
    zoomNodeId: PropTypes.number,
    //visRefNode: PropTypes.number,
    nodeId: PropTypes.number,

    searchStrainMode: PropTypes.bool,
    editMode: PropTypes.bool,
    hiddenMenu: PropTypes.bool,
    isMobile: PropTypes.bool,
    loading: PropTypes.bool,
    showCladeBar: PropTypes.bool,
    showCladeBarLabels: PropTypes.bool,
    showCladeLabels: PropTypes.bool,
    antigenicTiterType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    exportMode: PropTypes.bool,
    measuresLoaded: PropTypes.bool,
    // showCladeLabels: PropTypes.bool,
    //showLeaves: PropTypes.bool,
    //showInternalNodes: PropTypes.bool,
    showColoredNodesOnly: PropTypes.bool,
    // showMutations: PropTypes.bool,
    // showMutationsGroups: PropTypes.bool,
    predictionBaseline: PropTypes.instanceOf(Date),
    tree: PropTypes.object,
    ranges: PropTypes.shape({min: PropTypes.object, max: PropTypes.object}),
    classes: PropTypes.shape({
        graph: PropTypes.string,
        graphExport: PropTypes.string,
    }),
    width: PropTypes.number,
    height: PropTypes.number,
    setStrainTreeDimensions: PropTypes.func,
    selectNodeData: PropTypes.func
};

const mapStateToProps = (state) => {
    const { highlightedStrainIds, zoomNodeStack, zoomType } = state.treeData;
    const tree = getTreeFromTreeArray(state);
    const longestCladeLabel = getLongestCladeLabel(state);

    const {
        modelId, lineage, colorBy, refClade, refStrain, cladeType, layout, zoomNodeId,
        showMutationLabels, showLeaves, showInternalNodes, showColoredNodesOnly,
        showMutations, showMutationsGroups, showCladeLabels, showVaccineAndReassortment,
        showCladeBar, showCladeBarLabels, mutationsType, antigenicTiterType, predictionBaseline,
        treeScaleTypeX, treeScaleTypeY, displayOrder, exportMode, editMode,
        freqSpan, antigenicDataType, mutationsGroup, visibleMutationClassesLabels,
        strainSubset, strainHighlight, searchStrainMode, modelRegionId,
        width, height, showReferenceStrains, strainCutOffDate, cladeBarType
    } = state.parameters;

    const treeAttrs = getTreeNodeAttrs(state);
    //const markBranches = markBranchesArraySelector(state);

    const { model } = state.modelData;
    const { clades, cladesStatus } = state.cladeData;
    const { parameters } = state;

    const loading = getStrainTreeStatus(state);

    const ranges = getRanges(state);
    const antigenicModel = getAntigenicData(state);

    const antigenicModelStatus = state.antigenic.antigenicModelStatus;
    const antigenicSearchStrainStatus = state.treeData.strainSearchStatuses.antigenic;

    //console.log('antigenicModel = ',antigenicModel, 'antigenicModelStatus = ',antigenicModelStatus);
    //const genotypeValues = getGeneMutationData(state)
    const legendSelectedNodes = getLegendSelectedNodes(state);

    const tcellAntigenicityScores = tcellAntigenicityScoresSelector(state);
    const measures = getMetadataMeasuresWithScales(state);
    const mutationClassesData = getMutationClassesData(state);
    const cladeBarData = getCladeBar(state);

    const mutationsGroups = state.metadata.mutationClasses;
    const mutationClasses =
        !emptyObject(mutationsGroups) && mutationsGroup && mutationsGroups[mutationsGroup]
            ? mutationsGroups[mutationsGroup]
            : null;

    const measuresLoaded = state.metadata.mutationClassesStatus === 'loaded' && !loading; 
    const displayedStrainNames = getDisplayedStrainNames(state);
    const showStrainNames = displayedStrainNames && layout === LAYOUT.TREE.value;
    
    return {
        tree,
        treeAttrs,
        measures,
        highlightedStrainIds,
        zoomNodeStack,
        zoomType,
        model,
        width,
        height,
        showReferenceStrains,
        antigenicModel,
        antigenicModelStatus,
        antigenicSearchStrainStatus,
        clades,
        cladesStatus,
        ranges,
        loading,
        searchStrainMode,
        exportMode,
        editMode,
        modelId,
        lineage,
        cladeBarType,
        colorBy: colorBy === 'antigenic' && refStrain === null ? 'none' : colorBy,
        markBranches: markBranchesArraySelector(state),
        layout,
        cladeBarType,
        refClade,
        refStrain,
        antigenicDataType,
        antigenicTiterType,
        cladeType,
        predictionBaseline,
        freqSpan,
        zoomNodeId,
        //zoomNode,
        showMutationLabels,
        showLeaves,
        displayOrder,
        showInternalNodes,
        showColoredNodesOnly,
        showMutations,
        showMutationsGroups,
        showCladeLabels,
        showVaccineAndReassortment,
        mutationsType,
        treeScaleTypeX,
        treeScaleTypeY,
        legendSelectedNodes,
        tcellAntigenicityScores,
        regions: parameters.regions || appConfig.regions,
        //genotypeLegend,
        nodeId: state.nodeData.nodeId,
        activeLegendOption: state.nodeData.activeLegendOption,
        mutationClassesData,
        mutationClasses,
        visibleMutationClassesLabels,
        cladeBarData,
        showCladeBar, showCladeBarLabels,
        strainSubset, strainHighlight, 
        modelRegionId,
        strainTreeWidth: state.render.strainTreeWidth,
        strainTreeHeight: state.render.strainTreeHeight,
        hiddenMenu: state.render.hiddenMenu,
        longestCladeLabel,
        isMobile: getIsMobile(),
        humanSerologyRefStrains: colorBy === 'humanSerology' && state.humanSerology.humanSerologyRefStrains,
        measuresLoaded,
        renderStatus: state.render.renderStatus,
        displayedStrainNames,
        showStrainNames,
        strainCutOffDate: dateToDays(strainCutOffDate)
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        selectNodeData,
        selectZoomNode,
        setRenderStatus,
        setSearchStrainMode,
        resetComponentsStatus,
        setStrainTreeDimensions,
    },
    dispatch,
);



export default connect(mapStateToProps, mapDispatchToProps)(TreeGraph);
