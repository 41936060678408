import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import { numFormat } from '../../../functions/formats';
import { antigenicInfoStyles } from '../styles/antigenicInfoStyles';
import ScalesLegend from '../../ColorLegend/Legends/ScalesLegend';


let rowId = 0;

const createData = (rowName, value) => {
    rowId += 1;
    return { rowId: `${rowId}_${rowName}`, rowName, value };
};

function AntigenicDetailsInfo(props) {
    const { classes, strain, refStrain, titer, clades, alpha, rho } = props;
    rowId = 0;
    const rows = [
        createData('Clade ', clades[alpha].label),
        createData('Reference clade', clades[rho].label),
        createData('Strain ', strain),
        createData('Reference strain', refStrain),
        createData('Titer', numFormat(titer)),
    ];

    return (
        <div className={classes.root}>
            <div className={classes.tableWrapper}>
                <Table className={classes.table}>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow className={classes.row} key={row.rowId}>
                                <TableCell className={classes.cell} component="th" scope="row">
                                    <span className={classes.cellTitle}>
                                        {row.rowName}:&nbsp;
                                        </span>
                                    <span >
                                        {row.value}
                                    </span>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <ScalesLegend
                    scaleId="antigenicDetails"
                    measureName="antigenic"
                    valueGetter={(state) => state.antigenic.titerDetails}
                    ticks={3}
                />
            </div>
        </div>
    );
}

AntigenicDetailsInfo.propTypes = {
    classes: PropTypes.object.isRequired,
    antigenicData: PropTypes.shape({
        titer: PropTypes.number,
        strain: PropTypes.string,
        refStrain: PropTypes.string,
    }),
};

const mapStateToProps = ({ antigenic, cladeData }) => ({
    titer: antigenic.titerDetails,
    strain: antigenic.strain,
    refStrain: antigenic.refStrain,
    alpha: antigenic.alpha,
    rho: antigenic.rho,
    clades: cladeData.clades
});

export default connect(mapStateToProps, null)(withStyles(antigenicInfoStyles)(AntigenicDetailsInfo));
