import {
    FETCH_LINEAGES_REQUEST,
    FETCH_LINEAGES_SUCCESS,
    FETCH_LINEAGES_ERROR,
    FETCH_LINEAGE_REQUEST,
    FETCH_LINEAGE_SUCCESS,
    FETCH_LINEAGE_ERROR,
    RESET_SESSION,
    SIGNOUT_REQUEST,
    GET_SIGNED_USER_SUCCESS,
    SIGNIN_SUCCESS,
    GET_SIGNED_USER_ERROR,
    SIGNIN_ERROR,
    GET_SIGNED_USER_REQUEST,
    SIGNIN_REQUEST,
    SIGNOUT_SUCCESS,
} from '../actions/actionTypes';

let lineagesInitialState = {};
export const setLineagesInitialState = (state) => {
    lineagesInitialState = state;
};

const lineagesData = (state = lineagesInitialState, action) => {
    if (action.payload && action.payload.settings) return state;
    switch (action.type) {

        // case RESET_SESSION:
        case SIGNOUT_REQUEST: {
            return {
                ...lineagesInitialState,
                //reloadLineage: true
                // lineages,
                // lineagesStatus: 'none',
                // lineageStatus: 'none'
            };
        }
        case RESET_SESSION: {
            return {
                ...state,
                lineageStatus: 'none'
            };
        }
        case SIGNOUT_SUCCESS: {
            return {
                ...state,
                lineagesStatus: 'none'
            };
        }
        case FETCH_LINEAGES_REQUEST: 
        case GET_SIGNED_USER_REQUEST: 
        case SIGNIN_REQUEST: {
            return {
                ...state,
                lineagesStatus: 'loading'
            };
        }
        case FETCH_LINEAGES_SUCCESS: 
        case GET_SIGNED_USER_SUCCESS:
        case SIGNIN_SUCCESS: {
            const { lineages } = action.payload;
            return {
                ...state,
                lineages,
                lineagesStatus: 'loaded'
            };
        }
        case FETCH_LINEAGES_ERROR: 
        case GET_SIGNED_USER_ERROR:
        case SIGNIN_ERROR: {
            return {
                ...state,
                lineagesStatus: 'error'
            };
        }
        case FETCH_LINEAGE_REQUEST: {
            return {
                ...state,
                lineageStatus: 'loading',
                //reloadLineage: false
            };
        }
        case FETCH_LINEAGE_SUCCESS: {
            const { settings } = action.payload;
            if (settings) return state;
            return {
                ...state,
                lineageStatus: 'loaded',

            };
        }
        case FETCH_LINEAGE_ERROR: {
            return {
                ...state,
                lineageStatus: 'error'
            };
        }
      
        default:
            return state || lineagesInitialState;
    }
};


export { lineagesData };
