import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel'
import { MenuItem } from '@mui/material';
import { setParameters } from '../../redux/actions/parametersActions';
import CustomSelect from '../../assets/GlobalStyles/CustomSelect';
import { resetFrequencies } from '../../redux/actions/frequenciesActions';

const styles = () => ({
    formControl: {
        // minWidth: 120,
        margin: '6px 0px'
    },
});

const MultiplicityMethodSelector = (props) => {
    const { multiplicityMethod, multiplicityMethodOptions, classes, setParameters, resetFrequencies } = props;

    const handleChange = e => {
        const multiplicityMethod = e.target.value;
        setParameters({multiplicityMethod});
        resetFrequencies();
    };

    return (
        <>
            {multiplicityMethodOptions.length > 0 &&
                <FormControl className={classes.formControl} fullWidth>
                    <InputLabel htmlFor="lineage">
                        Multiplicity method
                    </InputLabel>
                    <CustomSelect
                        value={multiplicityMethod} // {this.state.modelId}
                        onChange={handleChange}
                        inputProps={{
                            name: 'multiplicityMethod',
                            id: 'multiplicityMethod',
                        }}
                    >
                        {multiplicityMethodOptions.map((el) => (
                            <MenuItem key={`${el}`} value={el}>{el[0].toUpperCase() + el.slice(1)}</MenuItem>
                        ))}
                    </CustomSelect>
                </FormControl>
            }
        </>
    )
}

const mapStateToProps = (state) => {
    const { multiplicityMethod } = state.parameters;
    const multiplicityMethodOptions = ['nodes', 'none'];
    return ({
        multiplicityMethod,
        multiplicityMethodOptions
    })
};

const mapDispatchToProps = dispatch => ({
    setParameters: payload => dispatch(setParameters(payload)),
    resetFrequencies: payload => dispatch(resetFrequencies(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MultiplicityMethodSelector));
