import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    selectAntigenicValues,
    setAntigenicHiddenAlphaClade,
    setAntigenicHiddenRhoClade,
    toggleAntigenicDetails,
} from '../../redux/actions/antigenicActions';
import { antigenicModelStatusSelector } from '../../redux/selectors/statusSelector';
import { getAntigenicModel, selectedCladesSelector } from '../../redux/selectors/antigenicDataSelector';
import { getRanges } from '../../redux/selectors/rangeDataSelector';
import { setRenderStatus } from '../../redux/actions/renderActions';
import { emptyObject, isNull } from '../../functions/functions';
import { getScaledValue } from '../../functions/scales';
import { RENDER_STATUS } from '../../config/consts';
import { antigenicStyles } from './styles/antigenicStyles';
import MetaInformations from '../MetaInformations/MetaInformations';
const AntigenicData = (props) => {
    const _element = useRef();

    const { alphas, rhos, data, clades, alphasY, rhosR, selectAntigenicValues, loading, hiddenRhoClades, hiddenAlphaClades,
        toggleAntigenicDetails, detailsOpen, dataInitialized, setRenderStatus, showAntigenicTableValues, exportMode,
        setDeletedClades, setDeletedRhos, setAntigenicHiddenRhoClade, setAntigenicHiddenAlphaClade, wrapAntigenicTableHeaders
    } = props;

    useEffect(() => {
        if (dataInitialized) setRenderStatus(RENDER_STATUS.DONE);
    })

    const hideAlphaClade = (alpha) => {
        // console.log(`hide Clade ${alpha}`);
        setDeletedClades(alpha);
        setAntigenicHiddenAlphaClade(alpha);
    }

    const hideRhoClade = (rho) => {
        // console.log(`hide Ref Clade ${rho}`);
        setDeletedRhos(rho);
        setAntigenicHiddenRhoClade(rho);
    }

    const getClasses = () => {
        const { classes, exportMode } = props;
        const exportPropsMap = Object.keys(classes)
            .filter((key) => key.match('Export$'))
            .reduce((tmp, key) => {
                tmp[key] = key;
                return tmp;
            }, {});
        const regProps = Object.keys(classes)
            .filter((key) => !key.match('Export$'))
            .map((key) => ({ key, exportKey: exportPropsMap[`${key}Export`] }));
        return regProps.reduce((tmpProps, prop) => {
            tmpProps[prop.key] =
                exportMode && exportPropsMap[prop.exportKey] ? classes[prop.exportKey] : classes[prop.key];
            return tmpProps;
        }, {});
    }


    const classes = getClasses();
    if (emptyObject(data) && !loading)
        return <div className={classes.root}>No data for this combination of parameters</div>;
    if (emptyObject(clades) || !rhos || !alphas) {
        return <div />;
    }

    const getValue = (rho, alpha) => {
        return emptyObject(data) || loading ? null : data[rho][alpha].val;
    };
    const getAlphaY = (i) => (loading ? null : alphasY[i]);
    const getRhoR = (j) => (loading ? null : rhosR[j].rhoR);

    function getOppositeColorFromHex(hexcolor){
        const r = parseInt(hexcolor.substring(1,3),16);
        const g = parseInt(hexcolor.substring(3,5),16);
        const b = parseInt(hexcolor.substring(5,7),16);
        const yiq = ((r*299)+(g*587)+(b*114)) / 1000;
        return (yiq >= 128) ? 'black' : 'white';
    }

    function getOppositeColorFromRGB(color){
        const colorArr = color.slice(
            color.indexOf("(") + 1,
            color.indexOf(")")
        ).split(", ");
        const r = colorArr[0];
        const g = colorArr[1];
        const b = colorArr[2];
        const yiq = ((r*299)+(g*587)+(b*114)) / 1000;
        return (yiq >= 128) ? 'black' : 'white';
    }

    function getOppositeColor(color){
        return color.includes('rgb') ? getOppositeColorFromRGB(color) : getOppositeColorFromHex(color);
    }

    return (
        <div className={`${classes.rootData} ${exportMode ? classes.rootEx : ''}`} ref={_element} width="100%" height="100%">
            <div id="antigenic_data" style={{display: 'inline-block'}}>
                <table id='antigenic_table' className={classes.table} aria-busy={loading}>
                    <thead>
                        <tr>
                            <th key={`1`} className={`${classes.emptyHeader} ${exportMode ? classes.emptyHeaderEx : ''}`} />
                            {rhos.map((rho, j) => (
                                <th
                                    className={`${classes.headerTh} ${wrapAntigenicTableHeaders ? classes.wrapped : ''} ${detailsOpen ? '' : classes.stickyHeader} ${exportMode ? classes.headerThEx : ''}`}
                                    key={`1_aHeaderCol${j}`}
                                    style={{ display: !hiddenRhoClades[rho] ? 'table-cell' : 'none' }}
                                    onClick={() => {
                                        hideRhoClade(rho);
                                    }}
                                >
                                    <div className={classes.headerDiv}>
                                        {/* <span className={classes.headerSpan}> */}
                                        {clades[rho].label}
                                        {/* </span> */}
                                    </div>
                                </th>
                            ))}
                            <th className={`${classes.headerTh} ${classes.stickyHeader} ${exportMode ? classes.headerThEx : ''}`}>
                                <div className={classes.headerDiv}>CLADE FREQUENCY</div>
                            </th>
                        </tr>
                        </thead>
                        <tbody >
                        {alphas.map((alpha, i) => {
                            const alphaValue = getAlphaY(i);
                            const alphaBackground = getScaledValue('alphaYColorScale', alphaValue) || '#FFFFFF';
                            const alphaTextColor = getOppositeColor(alphaBackground);
                            return (
                                <tr
                                    key={`aRow${i}`}
                                    style={{
                                        display: !hiddenAlphaClades[alpha] ? 'table-row' : 'none',
                                    }}
                                >
                                    <td
                                        className={`${classes.name} ${wrapAntigenicTableHeaders ? classes.wrappedName : ''} ${exportMode ? classes.nameEx : ''}`}
                                        onClick={() => {
                                            hideAlphaClade(alpha);
                                        }}
                                    >
                                        {clades[alpha].label}
                                    </td>
                                    {rhos.map((rho, j) => {
                                        const value = getValue(j, i);
                                        const backgroundColor = getScaledValue('antigenicColorScale', value) || '#FFFFFF';
                                        const textColor = getOppositeColor(backgroundColor);
                                        return (
                                            <td
                                                key={`aVal${i}_${j}`}
                                                onMouseOver={() => {
                                                    !detailsOpen && selectAntigenicValues({ alpha, rho, titer: value });
                                                }}
                                                onClick={() => {
                                                    !detailsOpen && toggleAntigenicDetails(true);
                                                }}
                                                className={classes.val}
                                                style={{
                                                    backgroundColor,
                                                    display: !hiddenRhoClades[rho] ? 'table-cell' : 'none',
                                                    textAlign: 'center',
                                                    fontSize: '11px',
                                                    alignItems: 'center',
                                                    color: textColor
                                                }}
                                                title={value}
                                            >
                                                {showAntigenicTableValues && !isNull(value) ? value.toFixed(1): ''}
                                            </td>
                                        )

                                    })}
                                    <td
                                        className={classes.val}
                                        style={{
                                            backgroundColor: alphaBackground,
                                            textAlign: 'center',
                                            fontSize: '11px',
                                            alignItems: 'center',
                                            color: alphaTextColor
                                        }}
                                        title={alphasY[i]}
                                    >
                                        {showAntigenicTableValues && !isNull(alphaValue) ? alphaValue.toFixed(2): ''}
                                    </td>
                                </tr>
                            )}
                        )}
                        <tr>
                            <td className={`${classes.name} ${exportMode ? classes.nameEx : ''}`}>IMMUNE COHORT WEIGHT</td>
                            {rhosR.map(({ rho, rhoR }, j) => {
                                const value = getRhoR(j);
                                const backgroundColor = getScaledValue('rhoRColorScale', value);
                                const textColor = getOppositeColor(backgroundColor);
                                const fixedValue = isNull(value) ? '' : value > 99 ? value.toFixed(0) : value.toFixed(1)
                                return (
                                <td
                                    key={`rhoR_${j}`}
                                    className={classes.val}
                                    style={{
                                        backgroundColor,
                                        display: !hiddenRhoClades[rho] ? 'table-cell' : 'none',
                                        textAlign: 'center',
                                        fontSize: '11px',
                                        alignItems: 'center',
                                        color: textColor
                                    }}
                                    title={rhoR}
                                >
                                    { showAntigenicTableValues ? fixedValue : ''}
                                </td>
                            )})}
                        </tr>
                    </tbody>
                </table>
            </div>
            <MetaInformations />
        </div>
    );
}


AntigenicData.propTypes = {
    exportMode: PropTypes.bool,
};

const mapStateToProps = (state) => {
    const loading = antigenicModelStatusSelector(state);
    const { antigenicCladesStatus, antigenicModelStatus, detailsOpen } = state.antigenic;
    const { hiddenAlphaClades, hiddenRhoClades, exportMode, showAntigenicTableValues, wrapAntigenicTableHeaders } = state.parameters;
    const { alphasY, rhosR, alphas, rhos } = selectedCladesSelector(state);
    const data = getAntigenicModel(state);
    const ranges = getRanges(state);
    //const { measures } = state.metadata;
    // console.log(ranges);
    //console.log('3.')
    // const { antigenicTiterType, antigenicDataType } = state.parameters;
    const { clades } = state.cladeData;
    return {
        data,
        alphasY,
        rhosR,
        alphas,
        rhos,
        antigenicCladesStatus,
        antigenicModelStatus,
        clades,
        hiddenAlphaClades,
        hiddenRhoClades,
        ranges,
        loading,
        //measures,
        exportMode,
        detailsOpen,
        showAntigenicTableValues,
        wrapAntigenicTableHeaders
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            selectAntigenicValues,
            setAntigenicHiddenAlphaClade,
            setAntigenicHiddenRhoClade,
            setRenderStatus,
            toggleAntigenicDetails,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(antigenicStyles)(AntigenicData));
