import { useState } from "react";
import { StyledTextField } from "../../assets/GlobalStyles/TextField";

const FloatedInput = ({ initValue, setSettingsParameters, element, className }) => {
    const [value, setValue] = useState(initValue);

    const onChange = (e) => {
        const val = e.target.value;
        setValue(val);

        var regex = /^(?!0\.0*$|0$)\d+(\.\d+)?$/;
        if (regex.test(val))
            setSettingsParameters({ [element]: parseFloat(val) });
    }
    return (
        <StyledTextField
            type='number'
            id={element}
            className={className}
            key={`input-${element}`}
            label={element}
            onChange={(e) => onChange(e, element)}
            value={value || ''}
        />
    )
}

export default FloatedInput;