import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

const styles = () => ({
    paper: {
        padding: 10,
        opacity: 0.8,
        backgroundColor: '#504D4D',
        // border: '1px solid #dadde9',
        color: '#FFFFFF'
    }
});


class ChartTooltip extends Component {

    _tooltip = React.createRef();

    lastPos = { left: null, top: null };

    lastInfo = null;

    render() {
        const { position, info } = this.props;
        const { x, y } = position;

        // console.log(info);
        // console.log(this._tooltip.current);
        // const anchorEl = this._tooltip ? this._tooltip.current : null;
        const showTooltip = (x !== null) && (y !== null);
        if (showTooltip) {
            this.lastPos = { left: x, top: y };
            this.lastInfo = info;
        }

        const _info = info || this.lastInfo;

        const time = _info ? _info.time : null;
        const freqCategory = _info ? _info.freqCategory : null;
        const value = _info ? _info.value : null;
        const valueX = _info ? _info.valueX : null;
        const valueY = _info ? _info.valueY : null;
        const mx = _info ? _info.mx : null;
        const regionId = _info && _info.regionId ? _info.regionId : null;
        const modelType = _info && _info.modelType ? _info.modelType : null;
        const modelId = _info && _info.modelId ? _info.modelId : null;

        const pointPosStyles = showTooltip ? { top: y, left: x } : this.lastPos;


        return (
            <Tooltip
                open={showTooltip}
                title={(
                    <>
                    {time && (<Typography color="inherit"><span style={{fontFamily: 'Source Sans Pro Bold'}}>Time</span>: {time}</Typography>)}
                    {freqCategory && (<Typography color="inherit"><span style={{fontFamily: 'Source Sans Pro Bold'}}>Category</span>: {freqCategory}</Typography>)}
                    {value && (<Typography color="inherit"><span style={{fontFamily: 'Source Sans Pro Bold'}}>Freq X</span>: {value}</Typography>)}
                    {value && (<Typography color="inherit"><span style={{fontFamily: 'Source Sans Pro Bold'}}>Freq Y</span>: {valueY}</Typography>)}
                    {valueX && (<Typography color="inherit"><span style={{fontFamily: 'Source Sans Pro Bold'}}>Freq X</span>: {valueX}</Typography>)}
                    {mx && (<Typography color="inherit"><span style={{fontFamily: 'Source Sans Pro Bold'}}>Multiplicity Y</span>: {mx}</Typography>)}
                    {regionId && (<Typography color="inherit"><span style={{fontFamily: 'Source Sans Pro Bold'}}>Region id</span>: {regionId}</Typography>)}
                    {modelType && (<Typography color="inherit"><span style={{fontFamily: 'Source Sans Pro Bold'}}>Model type</span>: {modelType}</Typography>)}
                    {modelId && (<Typography color="inherit"><span style={{fontFamily: 'Source Sans Pro Bold'}}>Model id</span>: {modelId}</Typography>)}
                    </>
                )}
                placement="top-start"
                // style={{ display: showTooltip ? 'block' : 'none'}}
                // classes={{ tooltip: classes.customTooltip, popper: classes.customPopper }}
                leaveDelay={10000}
                PopperProps={{ disablePortal: true, placement: 'top-start' }}
                // PopperProps={{
                //     popperOptions: {
                //         modifiers: {
                //             offset: {
                //                 enabled: true,
                //                 offset: '40px, 40px',
                //             },
                //         },
                //     },
                // }}
            >
                {/* {children} */}
                <div id="selectedPoint" style={{position: 'absolute', top: y, ...pointPosStyles }} />
            </Tooltip>

        );
    }
}

ChartTooltip.propTypes = {
    position: PropTypes.shape({x: PropTypes.number, y: PropTypes.number }),
    info: PropTypes.object
}

export default withStyles(styles)(ChartTooltip);
