/* eslint-disable react/no-unused-prop-types */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { RENDER_STATUS } from '../../../config/consts'

import { setComponentStatus } from '../../../redux/actions/renderActions';
import { getStrainTreeStatus } from '../../../redux/selectors/statusSelector';
import { getMutationClassesDataLabels } from '../../../redux/selectors/treeDataSelector';
import { treeD3 } from '../d3/TreeD3';
import MutationsClassesLabels from '../labels/MutationsClassesLabel/MutationsClassesLabels';
import MutationsClassesLabelLinks from '../labels/MutationsClassesLabel/MutationsClassesLabelLinks';
import MutationsClassesLabelSymbols from '../labels/MutationsClassesLabel/MutationsClassesLabelSymbols';
import { merge } from 'lodash';

const viewToRender = 'strainTree';
const componentId = 'mutationsClasses';

const MutationsClassesLayer = (props) => {

    //console.log('[MutationsClassesLayer] renderStatus:',props.renderStatus);

    const _element = useRef();
    const labels = useRef({});

    const [symbolRenderCount, setSymbolRenderCount] = useState(0);
    const [linkRenderCount, setLinkRenderCount] = useState(0);
    const [labelRenderCount, setLabelRenderCount] = useState(0);
    //const [isRendering, setIsRendering] = useState(false);
 
    const { loading, mutationsGroup, renderStatus, setComponentStatus, mutationClassesData } = props;

    const symbolsCnt = useMemo(() =>  { return mutationClassesData ? mutationClassesData.length : 0 } , [mutationClassesData]);
    const labelsCnt = useMemo(() => { return mutationClassesData ? mutationClassesData.filter(({showLabel}) => showLabel).length : 0}, [mutationClassesData]);

    const mutationsRendered = (symbolRenderCount === symbolsCnt && linkRenderCount === labelsCnt && labelRenderCount === labelsCnt) || symbolsCnt === 0;
    const rerenderLabels = !mutationsRendered && (renderStatus === RENDER_STATUS.START || renderStatus === RENDER_STATUS.NONE); 

  
    // console.log(`symbolRenderCount = ${symbolRenderCount}/${symbolsCnt}, labelRenderCount = ${labelRenderCount}/${labelsCnt} linkRenderCount = ${linkRenderCount}/${labelsCnt}`)
//    const prevProps = useRef(props);

//     useEffect(() => {
//         const _props = props;
//         const changedProps = Object.entries(_props).reduce((acc, [key, value]) => {
//             if (prevProps.current[key] !== value) {
//                 acc.push({ name: key, prevValue: prevProps.current[key], nextValue: value });
//             }
//             return acc;
//         }, []);

//         // if (changedProps.length > 0) {
//         //     console.log('[MutationsClassesLayer] Changed props:', changedProps);
//         // }

//         prevProps.current = _props;
//     });


    const startRender = () => {
        // console.log('[MutationsClassesLayer] startRender');
        setTimeout(() => setComponentStatus(viewToRender, componentId, RENDER_STATUS.START), 0);
    }

    const stopPrepositioning = () => {
        //setIsRendering(false);
        setComponentStatus(viewToRender, componentId, RENDER_STATUS.DONE_PREPOSITIONING, { labels: labels.current, type: "mutationLabel" });
    }

    useEffect(() => {
       // console.log('[MutationsClassesLayer] renderStatus = ', renderStatus, 'mutationsRendered = ', mutationsRendered, 'loading =',loading, 'initialized = ', props.initialized) 
        if (loading || renderStatus === RENDER_STATUS.DONE || renderStatus === RENDER_STATUS.DONE_PREPOSITIONING) return;
       
        if (renderStatus === RENDER_STATUS.NONE) {
         //   console.log('[MutationsClassesLayer] RESETING, renderStatus = ', renderStatus)
            //setIsRendering(true);
            setSymbolRenderCount(0);
            setLinkRenderCount(0);
            setLabelRenderCount(0);
           // console.log('symbolsCnt', symbolsCnt);
            if (symbolsCnt > 0) startRender();
            else  stopPrepositioning();
            return;
        } 

        if (renderStatus === RENDER_STATUS.START && mutationsRendered) {
            stopPrepositioning();
            return
        }
    }, [mutationsRendered, renderStatus, loading]);


    const handleSymbolRendered = useCallback((symbolData) => {
        labels.current = merge(labels.current, symbolData);
        setSymbolRenderCount(c => c + 1);
    }, [rerenderLabels]);

    const handleLinkRendered = useCallback(() => {
        setLinkRenderCount(c => c + 1);
    }, [rerenderLabels])

    const handleLabelRendered = useCallback((labelData) => {
        labels.current = merge(labels.current, labelData);
        //console.log(labelData);
        setLabelRenderCount(c => c + 1);
    }, [rerenderLabels]);

   
   
    return <g id="mutationsClasses" transform={treeD3.translate(false)} ref={_element}>
        {!loading && symbolsCnt > 0 && renderStatus !== RENDER_STATUS.NONE && (
            <>
                <g id="mutationClassesLabelLinks">
                    {
                        mutationClassesData.filter(({ showLabel }) => showLabel).map(({ id, mutClass, ...props }) => (
                            <MutationsClassesLabelLinks
                                key={`${id}_${mutationsGroup}_${mutClass}_link`}
                                id={id}
                                classNamePrefix="mutationLabel" {...props}
                                onElementRendered={handleLinkRendered}
                                rerenderLabels={rerenderLabels}
                            />
                        ))
                    }
                </g>
                <g id="mutationClassesLabelSymbols">
                    {
                        mutationClassesData
                            .map(({ id, mutClass, ...props }) => (
                            <MutationsClassesLabelSymbols
                                key={`${id}_${mutationsGroup}_${mutClass}_symbol`}
                                id={id} classNamePrefix="mutationLabel" {...props}
                                onElementRendered={handleSymbolRendered}
                                rerenderLabels={rerenderLabels}
                            />
                        ))
                    }
                </g>
                <g id="mutationClassesLabels">
                    {
                        mutationClassesData.filter(({ showLabel }) => showLabel).map(({ id, mutClass, ...props }) => (
                            <MutationsClassesLabels
                                key={`${id}_${mutationsGroup}_${mutClass}_label`}
                                id={id} classNamePrefix="mutationLabel" {...props}
                                onElementRendered={handleLabelRendered}
                                rerenderLabels={rerenderLabels}
                            />
                        ))
                    }
                </g>


            </>)}
    </g>
};

MutationsClassesLayer.propTypes = {
    loading: PropTypes.bool,
    // mutationClassesData: PropTypes.ar({}),
    mutationsGroup: PropTypes.string,
    renderStatus: PropTypes.string
};

const mapStateToProps = (state, ownProps) => {
    const loading = getStrainTreeStatus(state) || !ownProps.initialized;
    const mutationClassesData = getMutationClassesDataLabels(state);
    return {
        loading,
        strainTreeStatus: getStrainTreeStatus(state),
        mutationsGroup: state.parameters.mutationsGroup,
        mutationClassesData,
        renderStatus: state.render.viewToRender.components[componentId] || RENDER_STATUS.NONE,
    };
};


const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setComponentStatus,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(MutationsClassesLayer);
