
import React, { useEffect, useState } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Paper, TablePagination, IconButton, InputAdornment, TableSortLabel,
    MenuItem, FormControl, InputLabel, Button
} from '@mui/material';
import { fetchAllScales } from '../../../redux/actions/settingsActions';
import { styles, StyledTableCell } from './styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { StyledTextField } from '../../../assets/GlobalStyles/TextField';
import { HideIcon } from '../../../components/Alerts/styles';
import Info from './Dialogs/Info';
import EditScale from './Actions/EditScale';
import AddNewScale from './Actions/AddNewScale';
import DeleteScale from './Actions/DeleteScale';
import { getBiggerForSortTable } from '../../../functions/functions';
import CustomSelect from '../../../assets/GlobalStyles/CustomSelect';

function createData(scale) {
    const { scaleId, discrete, numeric, range, lineage } = scale;
    const lineageString = lineage && lineage !== 'none' ? lineage : 'all';
    const numString = typeof numeric === 'undefined' ? 'default (false)' : numeric.toString();

    if (typeof range === 'object')
        return { scale, scaleId, discrete: discrete.toString(), numeric: numString, lineage: lineageString, range: 'custom' };
    else if (typeof range === 'undefined')
        return { scale, scaleId, discrete: discrete.toString(), numeric: numString, lineage: lineageString, range: 'colors assigned' };
    else
        return { scale, scaleId, discrete: discrete.toString(), numeric: numString, lineage: lineageString, range };
}

const orderInitState = {
    scaleId: 'none',
    numeric: 'none',
    discrete: 'none',
    lineage: 'none',
    range: 'none',
};

const searchState = {
    id: '',
    numeric: '',
    discrete: '',
    lineage: '',
}

const ScalesTable = (props) => {
    const { colorScales, fetchAllScales, colorScalesLength, scalesPalette } = props;
    const classes = styles();
    const [orderBy, setOrderBy] = useState({ ...orderInitState, scaleId: 'asc' });
    const [tableData, setTableData] = useState([]);
    const [displayedTableData, setDisplayedTableData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [searched, setSearched] = useState({ ...searchState });
    const [infoDialog, setInfoDialog] = useState(false);
    const [info, setInfo] = useState('');
    const [action, setAction] = useState('');

    useEffect(() => {
        if (colorScalesLength === 0)
            fetchAllScales();
    }, []);

    useEffect(() => {
        if (colorScalesLength === 0) return;

        const rows = colorScales.map((scale) => {
            return createData(scale);
        });

        setTableData(rows);
        const sorted = sortData([...rows], 'scaleId', 'asc');
        setDisplayedTableData(sorted);
    }, [colorScales]);

    const sortData = (data, type, order) => {
        if (order === 'none')
            return [...tableData];

        const asc = order === 'asc';
        return data.sort((a, b) => getBiggerForSortTable(a, b, type, asc));
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const requestSearch = (e, type) => {
        const search = e.target.value;
        setSearched({ ...searched, [type]: search });
    };

    const clearField = (type) => {
        setSearched({ ...searched, [type]: '' });
    }

    const filterafterSearchClicked = () => {
        let filteredRows = [ ...tableData ];

        if (searched.id !== ''){
            filteredRows = filteredRows.filter((row) => {
                return row.scaleId.toLowerCase().includes(searched.id.toLowerCase())
            });
        }

        if (searched.numeric !== ''){
            filteredRows = filteredRows.filter((row) => {
                return row.numeric.includes(searched.numeric)
            });
        }

        if (searched.discrete !== ''){
            filteredRows = filteredRows.filter((row) => {
                return row.discrete.includes(searched.discrete)
            });
        }

        if (searched.lineage !== ''){
            filteredRows = filteredRows.filter((row) => {
                return row.lineage.toLowerCase().includes(searched.lineage.toLowerCase())
            });
        }

        setDisplayedTableData(filteredRows);
        setPage(0);
    }

    const cancelSearch = () => {
        setSearched({ ...searchState });
        setDisplayedTableData(tableData);
    };

    const handleCloseInfo = () => {
        setInfoDialog(false);
    };

    const handleSort = (type) => {
        let val = '';

        if (orderBy[type] === 'none')
            val = 'asc';
        else if (orderBy[type] === 'asc')
            val = 'desc';
        else
            val = 'asc'

        const newOrderBy = {
            ...orderInitState,
            [type]: val
        };

        setOrderBy(newOrderBy);
        const sorted = sortData([...displayedTableData], type, val);
        setDisplayedTableData(sorted)
    }

    return (
        <>
            <Paper sx={{ marginRight: '15px', marginLeft: '15px' }}>
                <div>
                    <StyledTextField
                        id="id"
                        label="Scale id"
                        className={classes.search}
                        value={searched.id}
                        onChange={(searchVal) => requestSearch(searchVal, 'id')}
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end">
                                    <IconButton onClick={() => clearField('id')}>
                                        <HideIcon />
                                    </IconButton>
                                </InputAdornment>
                        }}
                    />
                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel htmlFor="plotType">
                            Numeric
                        </InputLabel>
                        <CustomSelect
                            value={searched.numeric}
                            defaultValue={''}
                            onChange={(e) => requestSearch(e, 'numeric')}
                            className={classes.searchSelect}
                            inputProps={{
                                name: 'numeric',
                                id: 'numeric',
                            }}
                            endAdornment={
                                <InputAdornment position="end" style={{ marginRight: '20px' }}>
                                    <IconButton onClick={() => clearField('numeric')}>
                                        <HideIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                        >
                            {['true', 'false', ''].map((t) => (
                                <MenuItem key={t} value={t}>{t}</MenuItem>
                            ))}
                        </CustomSelect>
                    </FormControl>
                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel htmlFor="plotType">
                            Discrete
                        </InputLabel>
                        <CustomSelect
                            value={searched.discrete}
                            defaultValue={''}
                            onChange={(e) => requestSearch(e, 'discrete')}
                            className={classes.searchSelect}
                            inputProps={{
                                name: 'discrete',
                                id: 'discrete',
                            }}
                            endAdornment={
                                <InputAdornment position="end" style={{ marginRight: '20px' }}>
                                    <IconButton onClick={() => clearField('discrete')}>
                                        <HideIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                        >
                            {['true', 'false', ''].map((t) => (
                                <MenuItem key={t} value={t}>{t}</MenuItem>
                            ))}
                        </CustomSelect>
                    </FormControl>
                    <StyledTextField
                        id="lineage"
                        label="Lineage"
                        className={classes.search}
                        value={searched.lineage}
                        onChange={(searchVal) => requestSearch(searchVal, 'lineage')}
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end">
                                    <IconButton onClick={() => clearField('lineage')}>
                                        <HideIcon />
                                    </IconButton>
                                </InputAdornment>
                        }}
                    />
                </div>
                <Button className={classes.saveButton} onClick={filterafterSearchClicked}>
                    Search
                </Button>
                <Button className={classes.cancelButton} onClick={cancelSearch}>
                    Cancel
                </Button>
                <TableContainer >
                    <Table size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={orderBy.scaleId !== 'none'}
                                        direction={orderBy.scaleId !== 'none' ? orderBy.scaleId : 'asc'}
                                        onClick={(e) => handleSort('scaleId')}
                                    >
                                        Scale id
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={orderBy.numeric !== 'none'}
                                        direction={orderBy.numeric !== 'none' ? orderBy.numeric : 'asc'}
                                        onClick={(e) => handleSort('numeric')}
                                    >
                                        Numeric
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={orderBy.discrete !== 'none'}
                                        direction={orderBy.discrete !== 'none' ? orderBy.discrete : 'asc'}
                                        onClick={(e) => handleSort('discrete')}
                                    >
                                        Discrete
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={orderBy.lineage !== 'none'}
                                        direction={orderBy.lineage !== 'none' ? orderBy.lineage : 'asc'}
                                        onClick={(e) => handleSort('lineage')}
                                    >
                                        Lineage
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={orderBy.range !== 'none'}
                                        direction={orderBy.range !== 'none' ? orderBy.range : 'asc'}
                                        onClick={(e) => handleSort('range')}
                                    >
                                        Color palette
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell align="left">Actions</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                (rowsPerPage > 0
                                    ? displayedTableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : displayedTableData
                                ).map((row, index) => (
                                    <TableRow
                                        key={`${row.scaleId}_${index}`}
                                    // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.scaleId}
                                        </TableCell>

                                        <TableCell align="left">
                                            {row.numeric}
                                        </TableCell>
                                        <TableCell align="left">
                                            {row.discrete}
                                        </TableCell>
                                        <TableCell align="left">
                                            {row.lineage}
                                        </TableCell>
                                        <TableCell align="left">
                                            {row.range}
                                        </TableCell>
                                        <TableCell align="left">
                                            <DeleteScale
                                                scale={row.scale}
                                                setInfo={setInfo}
                                                setInfoDialog={setInfoDialog}
                                                setAction={setAction}
                                                fetchAllScales={fetchAllScales}
                                            />
                                            <EditScale
                                                scale={row.scale}
                                                scalesPalette={scalesPalette}
                                                fetchAllScales={fetchAllScales}
                                                setInfo={setInfo}
                                                setInfoDialog={setInfoDialog}
                                                setAction={setAction}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            <TableRow
                                key='last'
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <td>
                                    <AddNewScale
                                        colorScales={colorScales}
                                        scalesPalette={scalesPalette}
                                        fetchAllScales={fetchAllScales}
                                        setInfo={setInfo}
                                        setInfoDialog={setInfoDialog}
                                        setAction={setAction}
                                    />
                                </td>

                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[15, 25, 100]}
                    component="div"
                    count={displayedTableData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            <Info openDialog={infoDialog} handleCloseDialog={handleCloseInfo} info={info} action={action} />
        </>
    );
};

const mapStateToProps = (state) => {
    const { colorScales, scalesPalette } = state.settings;

    return {
        colorScales,
        scalesPalette,
        colorScalesLength: colorScales.length
    }
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchAllScales,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(ScalesTable);
