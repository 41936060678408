import './geomap.css'

export const prepareSigCladesPopup = (clusterClades, colorData, props, pieChart, coloring) => {
    const totalSequences = clusterClades.map((strainId) => props[strainId]).reduce((sum, coloring) => sum + coloring, 0);
    const infoRows = clusterClades.map(strainId => `
        <div class="mapPopup__clades">
            <div class="mapPopup__clades-circle" style="background-color: ${colorData[strainId]?.color || colorData[strainId]}"></div>
            <div class="mapPopup__clades-info">
                <div class="clades-info-row">
                    <div class="clades-info-row--label">${colorData[strainId]?.label || strainId} </div>
                    <div class="clades-info-row--val">(${((props[strainId] / totalSequences) * 100).toFixed(2)}%)</div>
                </div>
                <div class="clades-info-row">
                    <div class="clades-info-row--label">${coloring === 'count' ? 'Sequences' : 'Cases'}: </div>
                    <div class="clades-info-row--val">${props[strainId].toFixed(2)}</div>
                </div>
            </div>
        </div>
    `)
    const infoString = infoRows.join('');

    const popupHtml = `
        <div class="mapPopup">
            <div class="mapPopup__chart">${nodeToString(pieChart)}</div>
            <div class="mapPopup__summary">
                <div class="mapPopup__summary-row">
                    <div class="mapPopup__summary-label">Clades:</div>
                    <div class="mapPopup__summary-value"> ${clusterClades.length}</div>
                </div>
                <div class="mapPopup__summary-row">
                    <div class="mapPopup__summary-label">${coloring === 'count' ? 'Total sequences' : 'Total cases'}:</div>
                    <div class="mapPopup__summary-value"> ${totalSequences.toFixed(coloring === 'count' ? 0 : 2)}</div>
                </div>
                <div class="mapPopup__summary-row">
                    <div class="mapPopup__summary-label">Date:</div>
                    <div class="mapPopup__summary-value"> ${formatDate()}</div>
                </div>
            </div>
            ${infoString}
        </div>
    `;
    return popupHtml
}

export const createSingleMarkerPopup = (cladeLabel, colorNum, color, coloring) => `
<div class="mapPopup">
    <div class="mapPopup__chart-single" style="background-color: ${color}"></div>
    <div class="mapPopup__summary">
        <div class="mapPopup__summary-row">
            <div class="mapPopup__summary-label">Clades:</div>
            <div class="mapPopup__summary-value">1</div>
        </div>
        <div class="mapPopup__summary-row">
            <div class="mapPopup__summary-label">${coloring === 'count' ? 'Total sequences' : 'Total cases'}:</div>
            <div class="mapPopup__summary-value">${colorNum}</div>
        </div>
        <div class="mapPopup__summary-row">
            <div class="mapPopup__summary-label">Date:</div>
            <div class="mapPopup__summary-value">${formatDate()}</div>
        </div>
    </div>
    <div class="mapPopup__clades">
        <div class="mapPopup__clades-circle" style="background-color: ${color}"></div>
        <div class="mapPopup__clades-info">
            <div class="clades-info-row">
                <div class="clades-info-row--label">${cladeLabel} </div>
                <div class="clades-info-row--val">(100%)</div>
            </div>
            <div class="clades-info-row">
                <div class="clades-info-row--label">${coloring === 'count' ? 'Sequences' : 'Cases'} </div>
                <div class="clades-info-row--val">1</div>
            </div>
        </div>
    </div>
</div>
`;

const formatDate = (date = new Date()) => {
    var dd = date.getDate();
    var mm = date.getMonth() + 1;

    var yyyy = date.getFullYear();
    if (dd < 10) {
        dd = '0' + dd;
    }
    if (mm < 10) {
        mm = '0' + mm;
    }
    return dd + '/' + mm + '/' + yyyy;
}

// https://stackoverflow.com/questions/4239587/create-string-from-htmldivelement
function nodeToString(node) {
    var tmpNode = document.createElement("div");
    tmpNode.appendChild(node.cloneNode(true));
    var str = tmpNode.innerHTML;
    tmpNode = node = null; // prevent memory leaks in IE
    return str;
}